import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { refreshTimestampUrlParam } from "apis/PowerAdapt";
import moment from "moment";
import cookie from "react-cookies";
import _ from "lodash";
import { defaultLng } from "modules/i18n/i18nConfig";

export const analysisAdvancedApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const language = getState().i18n.current || defaultLng;
            if (language) {
                headers.set("Accept-Language", language);
            }
            const token = getState().auth.token || localStorage.getItem("accessToken");
            if (token) {
                // include token in req header
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    reducerPath: "API_analysisAdvanced",
    keepUnusedDataFor: 600,
    tagTypes: ["AdvancedAnalysis", "AnalysisSection", "AnalysisSectionData"],
    endpoints: (build) => ({
        getAdvancedAnalysis: build.query({
            query: (data) => {
                const current_org = _.get(data, "org.name", "");
                return `advancedanalysis?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "AdvancedAnalysis", id: arg.org.id }];
                return [];
            },
            transformResponse: (response) => {
                const advanced_analysis_wrap = _.chain(response).map((advancedAnalysis) => {
                    return { ...advancedAnalysis, key: advancedAnalysis.id, value: advancedAnalysis.id, text: advancedAnalysis.name };
                });
                return advanced_analysis_wrap.value();
            }
        }),
        createAdvancedAnalysis: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `advancedanalysis?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [{ type: "AdvancedAnalysis", id: arg.org.id }];
                }
                return [];
            }
        }),
        updateAdvancedAnalysis: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `advancedanalysis/${data.id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [{ type: "AdvancedAnalysis", id: arg.org.id }];
                }
                return [];
            }
        }),
        patchAdvancedAnalysis: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `advancedanalysis/${data.id}?org=${current_org}`,
                    method: "PATCH",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [{ type: "AdvancedAnalysis", id: arg.org.id }];
                }
                return [];
            }
        }),
        deleteAdvancedAnalysis: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `advancedanalysis/${data.id}?org=${current_org}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [{ type: "AdvancedAnalysis", id: arg.org.id }];
                }
                return [];
            }
        }),
        getAnalysisSection: build.query({
            query: ({ org, analysis_section_id }) => {
                const current_org = _.get(org, "name", "");
                return `analysissections/${analysis_section_id}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                return [{ type: "AnalysisSection", id: arg.analysis_section_id }];
            }
        }),
        createAnalysisSection: build.mutation({
            query: ({ formdata, org }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `analysissections?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: formdata
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [{ type: "AdvancedAnalysis", id: arg.org.id }];
                }
                return [];
            }
        }),
        updateAnalysisSection: build.mutation({
            query: ({ formdata, org }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `analysissections/${formdata.id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: formdata
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "AnalysisSection", id: result.id },
                        { type: "AnalysisSectionData", id: `ref_data_${result.id}` },
                        { type: "AnalysisSectionData", id: `comp_data_${result.id}` }
                    ];
                }
                return [];
            }
        }),
        patchAnalysisSection: build.mutation({
            query: ({ formdata, org }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `analysissections/${formdata.id}?org=${current_org}`,
                    method: "PATCH",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: formdata
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "AnalysisSection", id: result.id },
                        { type: "AnalysisSectionData", id: `ref_data_${result.id}` },
                        { type: "AnalysisSectionData", id: `comp_data_${result.id}` }
                    ];
                }
                return [];
            }
        }),
        deleteAnalysisSection: build.mutation({
            query: ({ org, analysis_section_id }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `analysissections/${analysis_section_id}?org=${current_org}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [{ type: "AdvancedAnalysis", id: arg.org.id }];
                }
                return [];
            }
        }),
        getAnalysisSectionData: build.query({
            query: ({ org, analysis_section_id, start, end }) => {
                const current_org = _.get(org, "name", "");
                return `analysissections/${analysis_section_id}/data?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                const { key, analysis_section_id } = arg;
                return [{ type: "AnalysisSectionData", id: `${key}_data_${analysis_section_id}` }];
            }
        })
    })
});

export const {
    //Adv analysis part
    useGetAdvancedAnalysisQuery,
    useCreateAdvancedAnalysisMutation,
    usePatchAdvancedAnalysisMutation,
    useUpdateAdvancedAnalysisMutation,
    useDeleteAdvancedAnalysisMutation,
    //Section part
    useGetAnalysisSectionQuery,
    useDeleteAnalysisSectionMutation,
    useCreateAnalysisSectionMutation,
    useUpdateAnalysisSectionMutation,
    usePatchAnalysisSectionMutation,
    useGetAnalysisSectionDataQuery
} = analysisAdvancedApi;
