import { t } from "@lingui/macro";
import _ from "lodash";
import moment from "moment-timezone";
import { validate, version } from "uuid";

export const removeAccents = (str) => {
    return str
        .replace(/[áàãâä]/gi, "a")
        .replace(/[éè¨ê]/gi, "e")
        .replace(/[íìïî]/gi, "i")
        .replace(/[óòöôõ]/gi, "o")
        .replace(/[úùüû]/gi, "u")
        .replace(/[ç]/gi, "c")
        .replace(/[ñ]/gi, "n")
        .replace(/[^a-zA-Z0-9]/g, " ");
};

/**
 * Check if device is Mobile/Tablet or other
 * @function checkMobileAndTablet
 * @returns {boolean}
 */
export const checkMobileAndTablet = () => {
    var check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

export const csvDelimiter = [
    { key: "1", value: ",", text: "csv excel" },
    { key: "2", value: ";", text: "csv excel semicolon" },
    { key: "3", value: "\t", text: "csv excel tab" }
];

export const priceConversionRate = (value, conversions, cat_name, mt_type_name, i18n, asText = true) => {
    let price = null;
    let rate = null;
    if (!_.isFinite(value)) return null;
    if (cat_name === "gas" && mt_type_name === "p_act_import") {
        rate = _.get(conversions, "price_gas");
        price = (value * rate) / 1000; //average price per MWh elec or gas
    } else if (cat_name === "gas" && mt_type_name === "water_import") {
        rate = _.get(conversions, "price_gas_m3");
        price = value * rate;
    } else if (cat_name === "water" && mt_type_name === "water_import") {
        rate = _.get(conversions, "price_water");
        price = value * rate;
    } else if (cat_name === "water" && mt_type_name === "index_nm3") {
        rate = _.get(conversions, "price_nm3");
        price = value * rate;
    } else if (cat_name === "water" && mt_type_name === "index_tv") {
        rate = _.get(conversions, "price_tv");
        price = value * rate;
    } else if (_.includes(["elec", "elec_expert"], cat_name) && mt_type_name === "p_act_import") {
        rate = _.get(conversions, "price_elec");
        price = (value * rate) / 1000; //average price per MWh elec or gas
    }

    if (!_.isFinite(price)) return null;
    if (rate === null) {
        return null;
    }
    if (asText) {
        return `${i18n.number(price, { maximumFractionDigits: 2 })} ${_.get(conversions, "currency", "")}`;
    } else {
        return price;
    }
};

export const co2ConversionRate = (value, conversions, cat_name, mt_type_name, i18n, asText = true) => {
    let co2 = null;
    let rate = null;
    if (!_.isFinite(value)) return null;
    if (cat_name === "gas" && mt_type_name === "index_nm3") {
        rate = _.get(conversions, "co2_nm3");
    } else if (cat_name === "gas" && mt_type_name === "p_act_import") {
        rate = _.get(conversions, "co2_gas");
    } else if (cat_name === "gas" && mt_type_name === "water_import") {
        rate = _.get(conversions, "co2_m3");
    } else if (_.includes(["elec", "elec_expert"], cat_name) && mt_type_name === "p_act_import") {
        rate = _.get(conversions, "co2_elec");
    } else if (cat_name === "water" && mt_type_name === "water_import") {
        rate = _.get(conversions, "co2_water");
    } else if (cat_name === "water" && mt_type_name === "index_nm3") {
        rate = _.get(conversions, "co2_nm3");
    } else if (cat_name === "water" && mt_type_name === "index_tv") {
        rate = _.get(conversions, "co2_tv");
    }
    if (rate === null) {
        return null;
    }
    co2 = value * rate;
    if (!_.isFinite(co2)) return null;

    if (asText) {
        return `${i18n.number(co2, { maximumFractionDigits: 1 })} kgCo2e`;
    } else {
        return co2;
    }
};

export const getMaxDecimalPlaces = (numString) => {
    if (typeof numString === "string") {
        const decimalPart = parseFloat(numString.replace(",", ".")).toString().split(".")[1];

        if (!decimalPart) {
            return 0;
        }
        return decimalPart.length;
    } else if (typeof numString === "number") {
        const decimalPart = numString.toString().split(".")[1];
        if (!decimalPart) {
            return 0;
        }
        return decimalPart.length;
    } else {
        return 0;
    }
};

export const regexEmail = /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export const regexNumberFloat = /^[-+]?[0-9]*[,.]?[0-9]+([eE][-+]?[0-9]+)?$/;
export const regexNumberFloatPositive = /^[+]?\d*[,.]?\d+([eE][-+]?\d+)?$/;
export const regexNumberIntPositive = /^[+]?\d+([eE][-+]?\d+)?$/;
export const regexNumberInt = /^[-+]?\d+([eE][-+]?\d+)?$/;

export const calendarTypeOptions = [
    { key: 1, text: t`Opening`, value: 1 },
    { key: 2, text: t`Closing`, value: 2 }
];

export const validateNumber = (value, i18n, negative = true, optionnal = true, isInt = false) => {
    if (optionnal && value === null) {
        return undefined;
    }
    if (negative) {
        if (!regexNumberFloat.test(value)) return i18n._(t`invalid format`);
        if (isInt && !Number.isInteger(reparseNumber(value))) return i18n._(t`invalid format`);
        return undefined;
    } else {
        if (!regexNumberFloatPositive.test(value)) return i18n._(t`invalid format`);
        if (isInt && !Number.isInteger(reparseNumber(value))) return i18n._(t`invalid format`);
        return undefined;
    }
};

export const validateDecimal = (value, i18n, max_digit, max_dec) => {
    const max_val = Math.pow(10, max_digit - max_dec);
    const uniform_str_val = value.replace(",", ".");
    const [integerPartStr, decimalPartStr] = uniform_str_val.split(".");
    const integerPart = parseInt(integerPartStr);

    if (_.isFinite(integerPart) && integerPart > max_val) {
        return i18n._(t`Value must be less than ${max_val}`);
    }
    if (_.size(decimalPartStr) > 0) {
        if (_.every(decimalPartStr, (char) => char === "0")) {
            return undefined;
        } else if (_.size(decimalPartStr) > max_dec) {
            return i18n._(t`Make sure there are no more than ${max_dec} decimal places`);
        }
    }
};

export const reparseNumber = (value) => {
    if (_.includes([null, undefined, ""], value)) {
        return null;
    } else if (typeof value === "number") {
        return value;
    } else if (typeof value === "string") {
        const parsedNumber = Number(value?.replace(",", "."));
        if (!isNaN(parsedNumber)) {
            return parsedNumber;
        }
    }
    return null;
};

export const uuidvalidate = (uuid_str, _version = null) => {
    if (_version === null) {
        return validate(uuid_str);
    }
    return validate(uuid_str) && version(uuid_str) === _version;
};

export const isPlainObject = (value) => {
    return typeof value === "object" && value !== null && !Array.isArray(value);
};

export const checkStringInclude = (search, given, equal = false) => {
    if (typeof search !== "string" || typeof given !== "string") return false;
    const normalizedSearch = search
        .normalize("NFKD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    const normalizedGiven = given
        .normalize("NFKD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

    if (equal === false) {
        return _.includes(normalizedGiven, normalizedSearch);
    }
    return normalizedSearch === normalizedGiven;
};

export const getTheme = () => {
    const { hostname } = window.location;

    switch (hostname) {
        case "5g.sms-digital.com":
        case "pwapreprod3.poweradapt.com":
            return "old";
        case "pwatest2.poweradapt.com":
        case "pwapreprod2.poweradapt.com":
            return "default";
        case "localhost":
            //change during dev
            return "default";
        default:
            return "old";
    }
};

export const TZtoRemove = [
    "Africa/Asmera",
    "America/Argentina/ComodRivadavia",
    "America/Buenos_Aires",
    "America/Catamarca",
    "America/Cordoba",
    "America/Fort_Wayne",
    "America/Godthab",
    "America/Indianapolis",
    "America/Jujuy",
    "America/Knox_IN",
    "America/Louisville",
    "America/Mendoza",
    "America/Rosario",
    "Antarctica/South_Pole",
    "Asia/Ashkhabad",
    "Asia/Calcutta",
    "Asia/Chungking",
    "Asia/Dacca",
    "Asia/Katmandu",
    "Asia/Macao",
    "Asia/Rangoon",
    "Asia/Saigon",
    "Asia/Thimbu",
    "Asia/Ujung_Pandang",
    "Asia/Ulan_Bator",
    "Atlantic/Faeroe",
    "Australia/ACT",
    "Australia/LHI",
    "Australia/NSW",
    "Australia/North",
    "Australia/Queensland",
    "Australia/South",
    "Australia/Tasmania",
    "Australia/Victoria",
    "Australia/West",
    "Brazil/Acre",
    "Brazil/DeNoronha",
    "Brazil/East",
    "Brazil/West",
    "Canada/Atlantic",
    "Canada/Central",
    "Canada/Eastern",
    "Canada/Mountain",
    "Canada/Newfoundland",
    "Canada/Pacific",
    "Canada/Saskatchewan",
    "Canada/Yukon",
    "Chile/Continental",
    "Chile/EasterIsland",
    "Cuba",
    "Egypt",
    "Eire",
    "Europe/Kiev",
    "Europe/Uzhgorod",
    "Europe/Zaporozhye",
    "GB",
    "GB-Eire",
    "GMT+0",
    "GMT-0",
    "GMT0",
    "Greenwich",
    "Hongkong",
    "Iceland",
    "Iran",
    "Israel",
    "Jamaica",
    "Japan",
    "Kwajalein",
    "Libya",
    "Mexico/BajaNorte",
    "Mexico/BajaSur",
    "Mexico/General",
    "NZ",
    "NZ-CHAT",
    "Navajo",
    "PRC",
    "Pacific/Enderbury",
    "Pacific/Ponape",
    "Pacific/Truk",
    "Poland",
    "Portugal",
    "ROC",
    "ROK",
    "Singapore",
    "Turkey",
    "UCT",
    "US/Alaska",
    "US/Aleutian",
    "US/Arizona",
    "US/Central",
    "US/East-Indiana",
    "US/Eastern",
    "US/Hawaii",
    "US/Indiana-Starke",
    "US/Michigan",
    "US/Mountain",
    "US/Pacific",
    "US/Pacific-New",
    "US/Samoa",
    "Universal",
    "W-SU",
    "Zulu"
];

export const timeZone_list = [
    {
        key: 99999,
        text: t`No Time Zone`,
        value: -1
    },
    ...moment.tz.names().reduce((res, name, index) => {
        if (!_.includes(TZtoRemove, name)) {
            res.push({
                key: index,
                text: name,
                value: name
            });
        }
        return res;
    }, [])
];
