import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Grid, Dropdown, Modal, Button, Accordion, Table, Segment, Input, Label } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { checkStringInclude, removeAccents } from "modules/common/utils";
import { mt_type_blacklist } from "modules/dashboard/utils";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetUsagesQuery } from "modules/usage/usageService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import GenericPaginate from "modules/common/components/GenericPaginate";
import { Media } from "App";

const DropdownMeasurementHeatGeneric = (props) => {
    const { formula_key, formula, input, values, measurementsObject, intensive } = props;
    const org = useSelector((state) => state.org);
    const theme = useSelector((state) => state.common.theme);

    const measure_id = formula?.[formula_key];
    const formulaType = values?.syndataflow?.formula_type ?? null;

    //Current measure
    const [selectedMeasure, setSelectedMeasure] = useState(measure_id);

    //Pagination
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(5);
    //Filter
    const [searchName, setSearchName] = useState("");
    const [siteFilter, setSiteFilter] = useState([]);
    const [zoneFilter, setZoneFilter] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [usageFilter, setUsageFilter] = useState([]);
    const [tagFilter, setTagFilter] = useState([]);
    const [mttypeFilter, setMttypeFilter] = useState([]);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current, hasFilter: false }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });

    useEffect(() => {
        if (open) {
            const eqpt_site = values?.equipment?.site ?? null;
            eqpt_site && setSiteFilter([eqpt_site]);
            //Filter Measurements with output category
            const output_cat = values?.dataflow?.dataflowspec ?? null;
            output_cat && intensive === false && setCategoryFilter([output_cat]);
            //reset filter && page
            setZoneFilter([]);
            setUsageFilter([]);
            setTagFilter([]);
            const output_mtType = values?.measurement?.measurementtype ?? null;
            output_mtType && intensive === false && setMttypeFilter([output_mtType]);
            setPage(0);
            setRowPerPage(5);
            setSelectedMeasure(measure_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, intensive]);

    useEffect(() => {
        setPage(0);
    }, [searchName, siteFilter, zoneFilter, categoryFilter, usageFilter, tagFilter, mttypeFilter]);

    const handleOpenModal = useCallback((event, data) => {
        setOpen(true);
    }, []);

    const zoneOptions = useMemo(() => {
        if (open === false) return [];
        return _.chain(zones.data)
            .reduce((res, zone) => {
                if (_.size(siteFilter) === 0) {
                    res.push(zone);
                } else if (_.includes(siteFilter, zone.site_id)) {
                    res.push(zone);
                }
                return res;
            }, [])
            .map(({ key, text, value }) => ({ key, text, value, content: <Label color="teal">{text}</Label> }))
            .value();
    }, [siteFilter, zones, open]);

    const siteOptions = useMemo(() => {
        if (open === false) return [];
        return _.map(sites.data, ({ key, text, value }) => ({
            key,
            text,
            value,
            content: <Label color={theme === "old" ? "blue" : "olive"}>{text}</Label>
        }));
    }, [sites, open, theme]);

    const catOptions = useMemo(() => {
        if (open === false) return [];
        return _.chain(categories.data)
            .reduce((res, { key, text, value, name }) => {
                if (formulaType === 1 && _.includes(["elec", "gas", "water"], text)) {
                    //heat/refrigaration/hydrolic
                    res.push({
                        key,
                        value,
                        text: i18n._(text),
                        content: <Label color="purple">{i18n._(text)}</Label>
                    });
                } else if (formulaType === 2 && _.includes(["water", "calories", "frigories"], text)) {
                    //heat/refrigaration/hydrolic
                    res.push({
                        key,
                        value,
                        text: i18n._(text),
                        content: <Label color="purple">{i18n._(text)}</Label>
                    });
                } else if (formulaType === 3 && _.includes(["gas", "water", "calories", "frigories"], text)) {
                    //generic multiplication
                    res.push({
                        key,
                        value,
                        text: i18n._(text),
                        content: <Label color="purple">{i18n._(text)}</Label>
                    });
                } else {
                    // All other case
                    if (_.includes(["elec", "gas", "water"], text)) {
                        res.push({
                            key,
                            value,
                            text: i18n._(text),
                            content: <Label color="purple">{i18n._(text)}</Label>
                        });
                    }
                }
                return res;
            }, [])
            .orderBy((item) => {
                return removeAccents(item.text).toLowerCase();
            }, "asc")
            .value();
    }, [categories, formulaType, open]);

    const mtTypesOptions = useMemo(() => {
        if (open === false) return [];
        return _.chain(measurementtypes.data)
            .filter((item) => !_.includes(mt_type_blacklist, item.id)) // Remove MT_TYPE
            .filter((mt_type) => {
                if (intensive) return mt_type.datapoint_type === 1;
                return mt_type.datapoint_type === 3 && _.includes(["p_act_import", "index_nm3", "index_tv", "water_import"], mt_type.name);
            })
            .map(({ key, text, value }) => ({ key, text: i18n._(text), value, content: <Label color="yellow">{i18n._(text)}</Label> }))
            .orderBy((item) => {
                return removeAccents(item.text).toLowerCase();
            }, "asc")
            .value();
    }, [measurementtypes, open, intensive]);

    const usagesOptions = useMemo(() => {
        if (open === false) return [];
        return _.map(usages.data, ({ key, text, value }) => ({ key, text, value, content: <Label color="violet">{text}</Label> }));
    }, [usages, open]);

    const tagsOptions = useMemo(() => {
        if (open === false) return [];
        return _.map(tags.data, ({ key, text, value }) => ({ key, text, value, content: <Label color="grey">{text}</Label> }));
    }, [tags, open]);

    const filters = useMemo(() => {
        return (
            <Grid verticalAlign="middle" centered>
                <Grid.Column width={16}>
                    <Input
                        fluid
                        icon="search"
                        placeholder={i18n._(t`search identifier`)}
                        onChange={(e, { value }) => {
                            setSearchName(value);
                        }}
                        value={searchName}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Dropdown
                        fluid
                        options={siteOptions}
                        placeholder={i18n._(t`select sites`)}
                        search
                        noResultsMessage={i18n._(t`no result found`)}
                        multiple
                        selection
                        onChange={(e, { value }) => {
                            setSiteFilter(value);
                            setZoneFilter([]);
                        }}
                        renderLabel={(label) => ({ color: theme === "old" ? "blue" : "olive", content: label.text })}
                        value={siteFilter}
                        disabled={true}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Dropdown
                        fluid
                        disabled={_.size(siteFilter) === 0}
                        options={zoneOptions}
                        placeholder={i18n._(t`select zones`)}
                        search
                        noResultsMessage={i18n._(t`no result found`)}
                        multiple
                        selection
                        onChange={(e, { value }) => {
                            setZoneFilter(value);
                        }}
                        renderLabel={(label) => ({ color: "teal", content: label.text })}
                        value={zoneFilter}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4}>
                    <Dropdown
                        fluid
                        options={catOptions}
                        placeholder={i18n._(t`select categories`)}
                        search
                        noResultsMessage={i18n._(t`no result found`)}
                        multiple
                        selection
                        onChange={(e, { value }) => {
                            setCategoryFilter(value);
                        }}
                        renderLabel={(label) => ({ color: "purple", content: label.text })}
                        value={categoryFilter}
                        disabled={props?.disable_category}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4}>
                    <Dropdown
                        fluid
                        options={mtTypesOptions}
                        placeholder={i18n._(t`select measurementtypes`)}
                        search
                        noResultsMessage={i18n._(t`no result found`)}
                        multiple
                        selection
                        onChange={(e, { value }) => {
                            setMttypeFilter(value);
                        }}
                        renderLabel={(label) => ({ color: "yellow", content: label.text })}
                        value={mttypeFilter}
                        disabled={props?.disable_mttype}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4}>
                    <Dropdown
                        fluid
                        options={usagesOptions}
                        placeholder={i18n._(t`select usages`)}
                        search
                        noResultsMessage={i18n._(t`no result found`)}
                        multiple
                        selection
                        onChange={(e, { value }) => {
                            setUsageFilter(value);
                        }}
                        renderLabel={(label) => ({ color: "violet", content: label.text })}
                        value={usageFilter}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4}>
                    <Dropdown
                        fluid
                        options={tagsOptions}
                        placeholder={i18n._(t`select tags`)}
                        search
                        noResultsMessage={i18n._(t`no result found`)}
                        multiple
                        selection
                        onChange={(e, { value }) => {
                            setTagFilter(value);
                        }}
                        renderLabel={(label) => ({ color: "grey", content: label.text })}
                        value={tagFilter}
                    />
                </Grid.Column>
            </Grid>
        );
    }, [
        props.disable_category,
        props.disable_mttype,
        catOptions,
        categoryFilter,
        mtTypesOptions,
        mttypeFilter,
        searchName,
        siteFilter,
        siteOptions,
        tagFilter,
        tagsOptions,
        usageFilter,
        usagesOptions,
        zoneFilter,
        zoneOptions,
        theme
    ]);

    /******  PAGINATION BLOCK ******/

    const onHandlePageChange = (event, data) => {
        // Pagination activePage value
        const { activePage } = data;
        setPage(activePage - 1);
    };

    const onHandleChangeItemPageSize = (event, data) => {
        const { value } = data;
        setPage(0);
        setRowPerPage(parseInt(value));
    };

    const submitMeasurements = () => {
        if (selectedMeasure) {
            const new_formula = { ...formula, [formula_key]: selectedMeasure };
            input.onChange(new_formula);
        }
        setOpen(false);
    };

    const measures = useMemo(() => {
        if (open === false) return [];
        return (
            _.chain(measurementsObject)
                .filter((measure) => {
                    if (intensive) return _.get(measure, "measurementtype.datapoint_type", null) === 1;
                    return _.get(measure, "measurementtype.datapoint_type", null) === 3;
                }) //only time_integral ?
                .filter((measure) => _.get(measure, "measurementtype.name", null) !== "operating_hours") // For elec
                .reduce((res, measure) => {
                    if (searchName === "") {
                        res.push(measure);
                    } else if (measure.dataflow && checkStringInclude(searchName, measure.dataflow.name)) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .reduce((res, measure) => {
                    if (_.size(siteFilter) === 0) {
                        res.push(measure);
                    } else if (_.includes(siteFilter, _.get(measure, "dataflow.site", null))) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .reduce((res, measure) => {
                    if (_.size(zoneFilter) === 0) {
                        res.push(measure);
                    } else if (_.includes(zoneFilter, _.get(measure, "dataflow.zone", null))) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .reduce((res, measure) => {
                    /* Tags filter */
                    if (_.size(tagFilter) === 0) {
                        res.push(measure);
                        return res;
                    }
                    const count_tags = _.chain(measure)
                        .get("dataflow.tag_set")
                        .map((tag_id) => {
                            if (_.includes(tagFilter, tag_id)) return 1;
                            return 0;
                        })
                        .sum()
                        .value();
                    if (count_tags) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .reduce((res, measure) => {
                    if (_.size(usageFilter) === 0) {
                        res.push(measure);
                    } else if (_.includes(usageFilter, _.get(measure, "dataflow.usage", null))) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .reduce((res, measure) => {
                    if (_.size(categoryFilter) === 0) {
                        res.push(measure);
                    } else if (_.includes(categoryFilter, _.get(measure, "dataflow.dataflowspec", null))) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .reduce((res, measure) => {
                    if (_.size(mttypeFilter) === 0) {
                        res.push(measure);
                        return res;
                    } else if (_.includes(mttypeFilter, _.get(measure, "measurementtype.id"))) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .value() || []
        );
    }, [measurementsObject, categoryFilter, mttypeFilter, searchName, siteFilter, tagFilter, usageFilter, zoneFilter, open, intensive]);

    const renderMeasures = useMemo(() => {
        if (open === false) return null;
        return measures.slice(page * rowPerPage, page * rowPerPage + rowPerPage).map((item, idx) => {
            return (
                <Table.Row key={idx} style={{ cursor: "pointer" }}>
                    <Table.Cell
                        positive={selectedMeasure === String(item.id)}
                        onClick={(e) => {
                            setSelectedMeasure(String(item.id));
                        }}
                    >
                        {item.text}
                    </Table.Cell>
                </Table.Row>
            );
        });
    }, [measures, page, rowPerPage, selectedMeasure, open]);

    const MainDropdown = useMemo(() => {
        const measure = measurementsObject?.[measure_id];
        return (
            <Input
                fluid
                value={measure?.text ?? ""}
                onClick={handleOpenModal}
                error={!_.isUndefined(props?.meas_err)}
                readOnly
                placeholder={i18n._(t`select 1 measure`)}
            />
        );
    }, [handleOpenModal, measurementsObject, measure_id, props.meas_err]);

    return (
        <>
            {MainDropdown}
            {open && (
                <Modal open>
                    <Modal.Header>
                        <Trans>choose your measurement</Trans>
                    </Modal.Header>
                    <Modal.Content scrolling={false}>
                        <Media lessThan="computer">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Segment id="pwaControls" attached="top">
                                        <Accordion
                                            panels={[
                                                {
                                                    key: "filters",
                                                    title: i18n._(t`filters`),
                                                    content: { content: filters }
                                                }
                                            ]}
                                        />
                                    </Segment>
                                )
                            }
                        </Media>
                        <Media greaterThanOrEqual="computer">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Segment id="pwaControls" attached="top">
                                        {filters}
                                    </Segment>
                                )
                            }
                        </Media>
                        {_.size(measures) === 0 && (
                            <MessageDisplay message={i18n._(t`no data criteria`)} level="warning" iconName="warning circle" isLoading={false} />
                        )}
                        <div style={{ overflowX: "auto" }}>
                            <Table striped celled unstackable compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan="2" textAlign="right">
                                            <GenericPaginate
                                                rowsLength={_.size(measures)}
                                                rowPerPage={rowPerPage}
                                                page={page}
                                                onPageChange={onHandlePageChange}
                                                onChangeItemPageSize={onHandleChangeItemPageSize}
                                            />
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>{renderMeasures}</Table.Body>
                            </Table>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        {selectedMeasure === null && (
                            <Segment basic textAlign="center">
                                <MessageDisplay message={i18n._(t`select 1 measure`)} level="info" iconName="info circle" isLoading={false} />
                            </Segment>
                        )}
                        <Button
                            type="button"
                            negative
                            onClick={(e, data) => {
                                setOpen(false);
                            }}
                        >
                            <Trans>cancel</Trans>
                        </Button>
                        <Button
                            type="button"
                            positive
                            labelPosition="right"
                            icon="checkmark"
                            content={i18n._(t`validate`)}
                            onClick={(e) => {
                                e.preventDefault();
                                submitMeasurements();
                            }}
                            disabled={measure_id === selectedMeasure}
                        />
                    </Modal.Actions>
                </Modal>
            )}
        </>
    );
};

DropdownMeasurementHeatGeneric.defaultProps = {
    intensive: false
};

export default React.memo(DropdownMeasurementHeatGeneric);
