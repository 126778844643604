import React from "react";

import Animation from "react-vis/es/animation";
import { ANIMATED_SERIES_PROPS, getStackParams } from "react-vis/es/utils/series-utils";

import AbstractSeries from "react-vis/es/plot/series/abstract-series";

var _extends =
    Object.assign ||
    function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];
            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };

var _createClass = (function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ("value" in descriptor) descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps) defineProperties(Constructor.prototype, protoProps);
        if (staticProps) defineProperties(Constructor, staticProps);
        return Constructor;
    };
})();

function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
    } else {
        obj[key] = value;
    }
    return obj;
}

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
    }
}

function _possibleConstructorReturn(self, call) {
    if (!self) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }
    return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
        throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: { value: subClass, enumerable: false, writable: true, configurable: true }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : (subClass.__proto__ = superClass);
}

var predefinedClassName = "rv-xy-plot__series rv-xy-plot__series--bar";

export var VerticalSignedBarSeries = (function (_AbstractSeries) {
    _inherits(VerticalSignedBarSeries, _AbstractSeries);

    function VerticalSignedBarSeries() {
        _classCallCheck(this, VerticalSignedBarSeries);

        return _possibleConstructorReturn(
            this,
            (VerticalSignedBarSeries.__proto__ || Object.getPrototypeOf(VerticalSignedBarSeries)).apply(this, arguments)
        );
    }

    _createClass(
        VerticalSignedBarSeries,
        [
            {
                key: "render",
                value: function render() {
                    var _this2 = this;

                    var _props = this.props,
                        animation = _props.animation,
                        className = _props.className,
                        data = _props.data,
                        marginLeft = _props.marginLeft,
                        marginTop = _props.marginTop,
                        style = _props.style,
                        barWidth = _props.barWidth,
                        linePosAttr = "x",
                        lineSizeAttr = "width",
                        valuePosAttr = "y",
                        valueSizeAttr = "height";

                    if (!data) {
                        return null;
                    }

                    if (animation) {
                        return React.createElement(
                            Animation,
                            _extends({}, this.props, { animatedProps: ANIMATED_SERIES_PROPS }),
                            React.createElement(VerticalSignedBarSeries, _extends({}, this.props, { animation: null }))
                        );
                    }

                    var _getStackParams = getStackParams(this.props),
                        sameTypeTotal = _getStackParams.sameTypeTotal,
                        sameTypeIndex = _getStackParams.sameTypeIndex;

                    var distance = this._getScaleDistance(linePosAttr);
                    var lineFunctor = this._getAttributeFunctor(linePosAttr);
                    var valueFunctor = this._getAttributeFunctor(valuePosAttr);
                    var value0Functor = this._getAttr0Functor(valuePosAttr);
                    var fillFunctor = this._getAttributeFunctor("fill") || this._getAttributeFunctor("color");
                    var strokeFunctor = this._getAttributeFunctor("stroke") || this._getAttributeFunctor("color");
                    var opacityFunctor = this._getAttributeFunctor("opacity");

                    var halfSpace = (distance / 2) * barWidth;

                    return React.createElement(
                        "g",
                        {
                            className: predefinedClassName + " " + className,
                            transform: "translate(" + marginLeft + "," + marginTop + ")"
                        },
                        data.map(function (d, i) {
                            var _attrs;

                            // totalSpaceAvailable is the space we have available to draw all the
                            // bars of a same 'linePosAttr' value (a.k.a. sameTypeTotal)
                            var totalSpaceAvailable = halfSpace * 2;
                            var totalSpaceCenter = lineFunctor(d);
                            // totalSpaceStartingPoint is the first pixel were we can start drawing
                            var totalSpaceStartingPoint = totalSpaceCenter - halfSpace;
                            // spaceTakenByInterBarsPixels has the overhead space consumed by each bar of sameTypeTotal
                            var spaceTakenByInterBarsPixels = (sameTypeTotal - 1) / sameTypeTotal;
                            // spacePerBar is the space we have available to draw sameTypeIndex bar
                            var spacePerBar = totalSpaceAvailable / sameTypeTotal - spaceTakenByInterBarsPixels;
                            // barStartingPoint is the first pixel were we can start drawing sameTypeIndex bar
                            var barStartingPoint = totalSpaceStartingPoint + spacePerBar * sameTypeIndex + sameTypeIndex;

                            var attrs =
                                ((_attrs = {
                                    style: _extends(
                                        {
                                            opacity: opacityFunctor && opacityFunctor(d),
                                            stroke: strokeFunctor && strokeFunctor(d),
                                            fill: fillFunctor && fillFunctor(d)
                                        },
                                        style
                                    )
                                }),
                                _defineProperty(_attrs, linePosAttr, barStartingPoint),
                                _defineProperty(_attrs, lineSizeAttr, spacePerBar),
                                _defineProperty(_attrs, valuePosAttr, Math.min(value0Functor(d), valueFunctor(d))),
                                _defineProperty(_attrs, valueSizeAttr, Math.abs(-value0Functor(d) + valueFunctor(d))),
                                _defineProperty(_attrs, "onClick", function onClick(e) {
                                    return _this2._valueClickHandler(d, e);
                                }),
                                _defineProperty(_attrs, "onContextMenu", function onContextMenu(e) {
                                    return _this2._valueRightClickHandler(d, e);
                                }),
                                _defineProperty(_attrs, "onMouseOver", function onMouseOver(e) {
                                    return _this2._valueMouseOverHandler(d, e);
                                }),
                                _defineProperty(_attrs, "onMouseOut", function onMouseOut(e) {
                                    return _this2._valueMouseOutHandler(d, e);
                                }),
                                _defineProperty(_attrs, "key", i),
                                _attrs);

                            if (!isFinite(attrs?.x)) {
                                return null;
                            } else if (attrs?.x < 0) {
                                //Fix warning: negative width for 'rect'
                                return null;
                            }

                            return React.createElement("rect", attrs);
                        })
                    );
                }
            }
        ],
        [
            {
                key: "defaultProps",
                get: function get() {
                    return {
                        barWidth: 0.85
                    };
                }
            },
            {
                key: "getParentConfig",
                value: function getParentConfig(attr) {
                    var isDomainAdjustmentNeeded = attr === "x";
                    var zeroBaseValue = attr === "y";
                    return {
                        isDomainAdjustmentNeeded: isDomainAdjustmentNeeded,
                        zeroBaseValue: zeroBaseValue
                    };
                }
            }
        ]
    );

    return VerticalSignedBarSeries;
})(AbstractSeries);

VerticalSignedBarSeries.displayName = "VerticalSignedBarSeries";

export function CreateSignedStack(serie) {
    var last_offsets = {};
    return function (serie) {
        serie.forEach((point, index) => {
            if (!last_offsets[point.x]) {
                last_offsets[point.x] = { pos: 0, neg: 0 };
            }
            if (point.y >= 0) {
                point.y0 = last_offsets[point.x].pos;
                last_offsets[point.x].pos += point.y;
                point.y = last_offsets[point.x].pos;
            } else {
                point.y0 = last_offsets[point.x].neg;
                last_offsets[point.x].neg += point.y;
                point.y = last_offsets[point.x].neg;
            }
        });
        return serie;
    };
}
