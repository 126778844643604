import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Field } from "react-final-form";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Grid } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";
import { isPlainObject, removeAccents, validateDecimal, validateNumber } from "modules/common/utils";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";

import { DropDownAdapter, InputAdapter } from "modules/common/components/form";
import { analog_cat_whitelist, formulaTypeEnum, formulaTypeOptions, pulse_cat_whitelist, syndataflowtypeOptions } from "modules/equipment/utils";
import SyntheticFormulaAdapter, { default_generic_formula, default_heat_formula, default_sum_formula } from "../advanced/SyntheticFormulaAdapter";

const MeasurementFields = (props) => {
    const { form, values, isSynthetic, isPulse, isAnalog, measurementsObject } = props;
    const org = useSelector((state) => state.org);
    const current_lng = useSelector((state) => state.i18n.current); //force refresh for lng

    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current, hasFilter: false }, { skip: !org.current });

    /* Display group of categories based on formula_type for synthetic OR pulse case */
    const getCatOptions = useCallback(
        (formulaType) => {
            //In case pulse or analog, 'formulaType' not used
            if (categories.isSuccess) {
                return _.chain(categories.data)
                    .reduce((res, { key, text, value }) => {
                        if (isAnalog) {
                            if (_.includes(analog_cat_whitelist, text)) {
                                res.push({
                                    key,
                                    value,
                                    text: i18n._(text)
                                });
                            }
                            return res;
                        }
                        if (isPulse) {
                            if (_.includes(pulse_cat_whitelist, text)) {
                                res.push({
                                    key,
                                    value,
                                    text: i18n._(text)
                                });
                                return res;
                            }
                        }
                        if (formulaType === 1 && _.includes(["elec", "gas", "water"], text)) {
                            //heat/refrigaration/hydrolic
                            res.push({
                                key,
                                value,
                                text: i18n._(text)
                            });
                        } else if (formulaType === 2 && _.includes(["water", "calories", "frigories"], text)) {
                            //heat/refrigaration/hydrolic
                            res.push({
                                key,
                                value,
                                text: i18n._(text)
                            });
                        } else if (formulaType === 3 && _.includes(["gas", "water", "calories", "frigories"], text)) {
                            //generic multiplication
                            res.push({
                                key,
                                value,
                                text: i18n._(text)
                            });
                        } else {
                            // All other case
                            if (_.includes(["elec", "gas", "water"], text)) {
                                res.push({
                                    key,
                                    value,
                                    text: i18n._(text)
                                });
                            }
                        }
                        return res;
                    }, [])
                    .orderBy((item) => {
                        return removeAccents(item.text).toLowerCase();
                    }, "asc")
                    .value();
            }
            return [];
        },
        // eslint-disable-next-line
        [categories, isPulse, isAnalog, current_lng]
    );

    /* Display group of measurement types based on category selection AND only datapoint_type 3 (time integral) */
    const getMttypeOptions = useCallback(
        (dataflowspec) => {
            if (measurementtypes.isSuccess) {
                return _.chain(measurementtypes.data)
                    .reduce((res, { key, text, value, dataflowspec_set, datapoint_type }) => {
                        if (_.includes(["p_react_import", "p_react_import+", "p_react_import-"], text)) return res; //remove reactive mt_type for elec
                        if (isAnalog && _.includes(dataflowspec_set, dataflowspec)) {
                            if (_.includes([1, 2, 3, 13, 14], dataflowspec) && datapoint_type !== 3) {
                                // 1:elec, 2:water, 3: gas, 13:frigories, 14: calories
                                // Remove intensive link to categories above
                                return res;
                            }
                            res.push({
                                key,
                                value,
                                text: i18n._(text)
                            });
                            return res;
                        }
                        if (datapoint_type === 3 && _.includes(dataflowspec_set, dataflowspec)) {
                            if (_.includes(["p_react_import", "p_react_import+", "p_react_import-"], text)) return res; //remove reactive mt_type for elec
                            res.push({
                                key,
                                value,
                                text: i18n._(text)
                            });
                        }
                        return res;
                    }, [])
                    .orderBy((item) => {
                        return removeAccents(item.text).toLowerCase();
                    }, "asc")
                    .value();
            }
            return [];
        },
        // eslint-disable-next-line
        [measurementtypes, current_lng]
    );

    /* Display group of measurement types based on category selection AND only datapoint_type 3 (time integral) */
    const getUnitOptions = useCallback(
        (mt_type_id) => {
            if (units.isSuccess && measurementtypes.isSuccess) {
                return _.chain(units.data)
                    .reduce((res, unit) => {
                        const { key, text, value, base_unit } = unit;
                        if (value === -1) {
                            res.push({
                                key,
                                value,
                                text: i18n._(text)
                            });
                            return res;
                        }
                        if (mt_type_id === undefined) return res;

                        const mt_type = _.find(measurementtypes.data, { id: mt_type_id });
                        if (value === mt_type?.unit || base_unit === mt_type?.unit) {
                            res.push({
                                key,
                                value,
                                text: i18n._(text)
                            });
                        }

                        return res;
                    }, [])
                    .orderBy((item) => {
                        return removeAccents(item.text).toLowerCase();
                    }, "asc")
                    .value();
            }
            return [];
        },
        // eslint-disable-next-line
        [units, measurementtypes, current_lng]
    );

    /* Display standby threshold unit based on selected measurementtype */
    const getStandbyThresholdUnit = useCallback(
        (measurementtype) => {
            if (measurementtypes.isSuccess && units.isSuccess) {
                const mttype = _.find(measurementtypes.data, { id: measurementtype });
                const unit = _.find(units.data, { id: mttype?.unit });
                return unit?.intensive ?? "-";
            }
            return "-";
        },
        [measurementtypes, units]
    );

    const formulaValidate = useCallback(
        (value, allValues) => {
            const errors = {};
            switch (allValues?.syndataflow?.formula_type) {
                case 1: {
                    if (Array.isArray(value)) {
                        const lines_err = _.map(value, (line_formula) => {
                            switch (line_formula.type) {
                                case null:
                                    return {
                                        process_type: <Trans>you need to choose type item</Trans>
                                    };
                                case "factor":
                                    let factor_err = {};
                                    const factorValidate = validateNumber(line_formula.factor, i18n, false, false);
                                    if (factorValidate !== undefined) {
                                        factor_err["factor"] = factorValidate;
                                    }
                                    if (!line_formula.measure) {
                                        factor_err["measure"] = <Trans>measurement is required</Trans>;
                                    }
                                    return _.isEmpty(factor_err) ? undefined : factor_err;
                                case "measure": {
                                    if (!line_formula.measure) {
                                        return {
                                            measure: <Trans>measurement is required</Trans>
                                        };
                                    } else {
                                        //Retrieve measurement based on measurement id
                                        const measurement = measurementsObject?.[line_formula.measure];

                                        const mt_m = measurement?.measurementtype?.id;
                                        //Retrive mt from form in the current state not previous
                                        const mt_form = allValues?.measurement?.measurementtype ?? null;
                                        if (mt_form === null) {
                                            return true; //silent error because output_mt_type need to be seleted
                                        }
                                        if (mt_m !== mt_form) {
                                            return {
                                                measure: <Trans>Unit incompatible with output measurement type</Trans>
                                            };
                                        }
                                        return undefined;
                                    }
                                }
                                default:
                                    return undefined;
                            }
                        });
                        errors.formula = lines_err;
                    } else {
                        errors.formula_type = <Trans>Invalid format</Trans>;
                    }
                    break;
                }
                case 2: {
                    errors.formula = {};
                    if (isPlainObject(value)) {
                        const faValidate = validateNumber(value.f_a, i18n, false, false);
                        if (faValidate) {
                            errors.formula.f_a = faValidate;
                        }
                        if (!_.isFinite(parseInt(value.m_a))) {
                            errors.formula.m_a = <Trans>Required field</Trans>;
                        }
                        if (!_.isFinite(parseInt(value.m_b))) {
                            errors.formula.m_b = <Trans>Required field</Trans>;
                        } else {
                            const measurement = measurementsObject?.[value.m_b];
                            if (measurement?.measurementtype?.datapoint_type !== 1) {
                                errors.formula.m_b = <Trans>Invalid measurement type</Trans>;
                            }
                        }
                        if (!_.isFinite(parseInt(value.m_c))) {
                            errors.formula.m_c = <Trans>Required field</Trans>;
                        } else {
                            const measurement = measurementsObject?.[value.m_c];
                            if (measurement?.measurementtype?.datapoint_type !== 1) {
                                errors.formula.m_c = <Trans>Invalid measurement type</Trans>;
                            }
                        }
                    } else {
                        errors.formula_type = <Trans>Invalid format</Trans>;
                    }
                    break;
                }
                case 3: {
                    errors.formula = {};
                    if (isPlainObject(value)) {
                        const faValidate = validateNumber(value.f_a, i18n, false, false);
                        if (faValidate) {
                            errors.formula.f_a = faValidate;
                        }
                        if (!_.isFinite(parseInt(value.m_a))) {
                            errors.formula.m_a = <Trans>Required field</Trans>;
                        }
                        if (!_.isFinite(parseInt(value.m_b))) {
                            errors.formula.m_b = <Trans>Required field</Trans>;
                        } else {
                            const measurement = measurementsObject?.[value.m_b];
                            if (measurement?.measurementtype?.datapoint_type !== 1) {
                                errors.formula.m_b = <Trans>Invalid measurement type</Trans>;
                            }
                        }
                    } else {
                        errors.formula_type = <Trans>Invalid format</Trans>;
                    }
                    break;
                }
                default:
                    break;
            }
            return errors;
        },
        [measurementsObject]
    );

    const current_mttype = values?.measurement?.measurementtype ?? null;

    const display_standby_threshold = useMemo(() => {
        if (measurementtypes.isSuccess) {
            const mttype = _.find(measurementtypes.data, { id: current_mttype });
            if (_.includes(["p_act_import", "e_act_counter", "water_import", "index_nm3"], mttype?.name)) {
                return true;
            }
        }
        return false;
    }, [measurementtypes, current_mttype]);

    return (
        <Grid verticalAlign="top">
            {isSynthetic && (
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Field
                            name={`syndataflow.formula_type`}
                            label={i18n._(t`Formula type`)}
                            placeholder={i18n._(t`select type of formula`)}
                            //only specific categories for pulse
                            options={formulaTypeOptions}
                            isRequired={true}
                            component={DropDownAdapter}
                            customAction={(data) => {
                                form.change("dataflow.dataflowspec", null);
                                form.change("measurement.measurementtype", null);
                                form.change("dataflow.standby_threshold", null);
                                form.change("measurement.display_unit", -1);
                                switch (data) {
                                    case formulaTypeEnum.SUMDIFFERENCE: {
                                        form.change("syndataflow.formula", default_sum_formula);
                                        break;
                                    }
                                    case formulaTypeEnum.HEATENERGY: {
                                        form.change("syndataflow.formula", default_heat_formula);
                                        break;
                                    }
                                    case formulaTypeEnum.GENERICPRODUCT: {
                                        form.change("syndataflow.formula", default_generic_formula);
                                        break;
                                    }
                                    default:
                                        break;
                                }
                            }}
                            validate={(value) => {
                                if (!value) return <Trans>Required field</Trans>;
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    {values?.syndataflow?.formula_type === 1 && (
                        <Grid.Column width={8}>
                            <Field
                                name={`syndataflow.syndataflowtype`}
                                label={i18n._(t`syndataflowtype`)}
                                placeholder={i18n._(t`select syndataflowtype`)}
                                options={syndataflowtypeOptions}
                                isRequired={true}
                                component={DropDownAdapter}
                                validate={(value) => (!value ? <Trans>Required field</Trans> : undefined)}
                            />
                        </Grid.Column>
                    )}
                </Grid.Row>
            )}
            {(isSynthetic || isPulse || isAnalog) && (
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Field
                            name={`dataflow.dataflowspec`}
                            label={i18n._(t`output category`)}
                            placeholder={i18n._(t`select category`)}
                            //Process category list based on pulse/analog or synthetic
                            options={getCatOptions(values?.syndataflow?.formula_type)}
                            isRequired={true}
                            component={DropDownAdapter}
                            customAction={(data) => {
                                const mt_types_from_cat = getMttypeOptions(data); //retrieve mt_types based on semantic options
                                if (_.size(mt_types_from_cat) === 1) {
                                    const first_mt = _.head(mt_types_from_cat);
                                    if (_.isFinite(first_mt?.value)) {
                                        form.change("measurement.measurementtype", first_mt?.value);
                                    } else {
                                        form.change("measurement.measurementtype", null);
                                    }
                                } else {
                                    form.change("measurement.measurementtype", null);
                                }

                                form.change("dataflow.standby_threshold", null);
                                form.change("measurement.display_unit", -1);
                            }}
                            validate={(value) => {
                                if (!value) return <Trans>Required field</Trans>;
                                if (value === 8 || value === 29) return <Trans>you need to change default output category</Trans>;
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Field
                            name={`measurement.measurementtype`}
                            label={i18n._(t`output measurementtype`)}
                            placeholder={i18n._(t`select measurementtype for measure`)}
                            options={getMttypeOptions(values?.dataflow?.dataflowspec)}
                            isRequired={true}
                            component={DropDownAdapter}
                            disabled={!_.isFinite(values?.dataflow?.dataflowspec)}
                            customAction={(data) => {
                                form.change("measurement.display_unit", -1);
                                form.change("dataflow.standby_threshold", null);
                            }}
                            validate={(value) => {
                                if (!value) return <Trans>Required field</Trans>;
                                if (value === 13 || value === 61) return <Trans>you need to change default output measurement type</Trans>;
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            )}
            {isSynthetic && (
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Field
                            name={`syndataflow.formula`}
                            label={i18n._(t`formula`)}
                            component={SyntheticFormulaAdapter}
                            isRequired={true}
                            values={values}
                            measurementsObject={measurementsObject}
                            validate={formulaValidate}
                        />
                    </Grid.Column>
                </Grid.Row>
            )}

            {/* Display dataflow name field only for update */}
            {(values?.measurement?.id || true) && (
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Field
                            name="dataflow.name"
                            placeholder={i18n._(t`Name of measurement`)}
                            label={i18n._(t`name`)}
                            component={InputAdapter}
                            parse={identityNull}
                        />
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name={`measurement.offset`}
                        placeholder={i18n._(t`enter offset for measure`)}
                        label={i18n._(t`offset`)}
                        component={InputAdapter}
                        helperText={`${i18n._(t`offset helper text`)}`}
                        parse={identityNull}
                        inputMode="decimal"
                        isRequired={true}
                        validate={(value) => {
                            const numValidate = validateNumber(value, i18n, false, false);
                            if (numValidate !== undefined) {
                                return numValidate;
                            }
                            const max_dec = 2;
                            const max_digit = 12;

                            const decimalValidate = validateDecimal(String(value), i18n, max_digit, max_dec);
                            if (decimalValidate !== undefined) {
                                return decimalValidate;
                            }
                            return undefined;
                        }}
                        cssOverride={true}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name={`measurement.factor`}
                        placeholder={i18n._(t`enter factor for measure`)}
                        label={i18n._(t`factor`)}
                        component={InputAdapter}
                        helperText={`${i18n._(t`factor used to convert raw values received by sensor to`)} ${getStandbyThresholdUnit(
                            values?.measurement?.measurementtype
                        )}`}
                        parse={identityNull}
                        inputMode="decimal"
                        isRequired={true}
                        validate={(value) => {
                            const numValidate = validateNumber(value, i18n, false, false);
                            if (numValidate !== undefined) {
                                return numValidate;
                            }
                            const max_dec = 8;
                            const max_digit = 16;

                            const decimalValidate = validateDecimal(String(value), i18n, max_digit, max_dec);
                            if (decimalValidate !== undefined) {
                                return decimalValidate;
                            }
                            return undefined;
                        }}
                        cssOverride={true}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name={`measurement.minGauge`}
                        placeholder={i18n._(t`enter minGauge for measure`)}
                        label={i18n._(t`minGauge`)}
                        component={InputAdapter}
                        parse={identityNull}
                        inputMode="numeric"
                        isRequired={true}
                        validate={(value) => {
                            return validateNumber(value, i18n, true, false, true);
                        }}
                        cssOverride={true}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name={`measurement.maxGauge`}
                        placeholder={i18n._(t`enter maxGauge for measure`)}
                        label={i18n._(t`maxGauge`)}
                        component={InputAdapter}
                        parse={identityNull}
                        isRequired={true}
                        validate={(value) => {
                            return validateNumber(value, i18n, true, false, true);
                        }}
                        cssOverride={true}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name={`measurement.display_unit`}
                        label={i18n._(t`display_unit`)}
                        placeholder={i18n._(t`select display unit for measure`)}
                        options={getUnitOptions(values?.measurement?.measurementtype)}
                        disabled={!_.isFinite(values?.measurement?.measurementtype)}
                        component={DropDownAdapter}
                        upward={true}
                        cssOverride={true}
                    />
                </Grid.Column>
                {display_standby_threshold && (
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Field
                            name={`dataflow.standby_threshold`}
                            placeholder={i18n._(t`enter standby threshold here`)}
                            unit={getStandbyThresholdUnit(values?.measurement?.measurementtype)}
                            unitposition={"right"}
                            label={i18n._(t`Standby threshold`)}
                            component={InputAdapter}
                            parse={identityNull}
                            defaultValue={null}
                            validate={(value) => {
                                return validateNumber(value, i18n, false, true);
                            }}
                            helperText={
                                <Trans>
                                    <p>The standby threshold corresponds to the maximum power consumed by the equipment when it is in standby.</p>
                                    <p>
                                        The energy consumed by the equipment below this threshold is then calculated and displayed in the detail view.
                                    </p>
                                </Trans>
                            }
                            cssOverride={true}
                        />
                    </Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    );
};

MeasurementFields.propTypes = {
    form: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
};

export default React.memo(MeasurementFields);
