import React, { useCallback } from "react";
import _ from "lodash";
import { Grid } from "semantic-ui-react";
import DropdownMeasurement from "./DropdownMeasurement";

const FactorMeasureItem = (props) => {
    const { idx, node, formula, input, values, measurementsObject } = props;

    const onChangeFactor = useCallback(
        (event) => {
            const new_formula = _.map(formula, (item, index) => {
                if (index !== idx) {
                    return item;
                }
                return { ...item, factor: event.target.value };
            });
            input.onChange(new_formula);
        },
        [idx, formula, input]
    );

    return (
        <Grid verticalAlign="middle">
            <Grid.Column width={2}>
                <div className={`ui fluid input ${!_.isUndefined(props.factor_err) ? "field error" : ""}`}>
                    <input type="text" onChange={onChangeFactor} value={node.factor} autoComplete="off" />
                </div>
            </Grid.Column>
            <Grid.Column width={1}>*</Grid.Column>
            <Grid.Column width={13}>
                <DropdownMeasurement idx={idx} node={node} formula={formula} input={input} values={values} measurementsObject={measurementsObject} />
            </Grid.Column>
        </Grid>
    );
};

export default React.memo(FactorMeasureItem);
