import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button, Modal, Icon } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import { useDeleteEnergySavingMutation } from "../energySavingService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useSelector } from "react-redux";
import i18n from "modules/i18n/i18nConfig";
import { checkRights } from "modules/auth/utils";

const DeleteEnergySaving = (props) => {
    const [open, setOpen] = useState(false);
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);

    const { item } = props;

    const rights = checkRights(item, auth.rights);
    const can_delete = notification.srv_status.db_status === "rw" && (item?.owner === auth.user.user_id || _.includes(rights, "can_delete"));

    const [deleteAPE, { isError, isLoading, isSuccess }] = useDeleteEnergySavingMutation();

    useEffect(() => {
        if (isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (isSuccess) {
            toast.success(i18n._(t`Successful delete energy performance action plan`), toast_options);
        }
        if (isError) {
            toast.error(i18n._(t`Can't delete energy performance action plan`), toast_options_err);
        }
    }, [isError, isSuccess, isLoading]);

    return (
        <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} trigger={<Button icon="delete" />}>
            <Modal.Header>
                <Trans>delete</Trans>
            </Modal.Header>
            <Modal.Content>
                <Trans>want delete energy performance action plan {item.name}</Trans>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                    <Trans>cancel</Trans>
                </Button>
                {can_delete && (
                    <Button
                        labelPosition="right"
                        icon
                        positive
                        onClick={async (e) => {
                            await setOpen(false);
                            if (can_delete) {
                                deleteAPE({ org: org.current, data: item });
                            }
                        }}
                    >
                        <Trans>validate</Trans>
                        <Icon name="trash" inverted />
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

DeleteEnergySaving.propTypes = {
    item: PropTypes.object.isRequired
};

export default DeleteEnergySaving;
