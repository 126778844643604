import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { equipmentApi } from "./equipmentService";

const initialState = {
    init_equipments: [],
    equipments: [],
    filter: {
        searchName: "",
        siteFilter: [],
        sensorFilter: [],
        zoneFilter: [],
        eqtTypeFilter: []
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    }
};

const equipmentSlice = createSlice({
    name: "equipment",
    initialState,
    reducers: {
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        setSiteFilter: (state, action) => {
            state.filter.siteFilter = action.payload;
        },
        setSensorFilter: (state, action) => {
            state.filter.sensorFilter = action.payload;
        },
        setZoneFilter: (state, action) => {
            state.filter.zoneFilter = action.payload;
        },
        setEqtTypeFilter: (state, action) => {
            state.filter.eqtTypeFilter = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.equipments = state.init_equipments;
            state.filter = initialState.filter;
            state.pagination = initialState.pagination;
        }
    },
    extraReducers(builder) {
        builder
            .addMatcher(equipmentApi.endpoints.getEquipments.matchFulfilled, (state, action) => {
                state.equipments = action.payload;
                state.init_equipments = action.payload;
            })
            .addMatcher(isAnyOf(setSearchNameFilter, setSiteFilter, setZoneFilter, setEqtTypeFilter, setSensorFilter), (state, action) => {
                if (!state.pagination.stickPage) {
                    state.pagination.page = 1;
                }
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const {
    setSearchNameFilter,
    setSiteFilter,
    setZoneFilter,
    setEqtTypeFilter,
    setSensorFilter,
    setItemsPerPage,
    setPage,
    setStickPage,
    resetFilterWithPage
} = equipmentSlice.actions;

export default equipmentSlice.reducer;
