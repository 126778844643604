import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { t, Trans } from "@lingui/macro";
import { Card, Grid, Header, Segment } from "semantic-ui-react";
import { Crosshair, FlexibleXYPlot, HorizontalGridLines, LineMarkSeries, XAxis, YAxis } from "react-vis";

import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";
import { getOverviewIntensive } from "modules/globalview/globalViewSlice";
import MessageDisplay from "modules/common/components/MessageDisplay";
import GenerateCsv from "modules/common/components/GenerateCsv";

export const displayTitleFromUnit = {
    "°C": { title: t`temperature`, name: "temperature" },
    "%": { title: t`hygrometry`, name: "hygrometry" },
    ppm: { title: t`co2`, name: "co2" },
    "µg/m3": { title: t`fine particles`, name: "particle" }
};

const GraphicIntensive = (props) => {
    const { unit } = props;
    const dispatch = useDispatch();
    const [crosshairValues, setCrosshairValues] = useState([]);
    const org = useSelector((state) => state.org);
    const globalview = useSelector((state) => state.globalview);
    const current_lng = useSelector((state) => state.i18n.current);

    //Get specific state on redux
    const state = _.get(globalview, `${displayTitleFromUnit[unit.symbol].name}`, null);
    const series = _.get(state, "data", []);

    const isEmpty = _.every(_.map(series, (serie) => serie.isEmpty));
    // Use data in series instead of time panel to define xLine Axis
    // API always return data on each time
    const firstX = _.chain(series).head().get("data").head().get("x").value();
    const lastX = _.chain(series).head().get("data").last().get("x").value();
    const xDomain = firstX && lastX ? [firstX, lastX] : null;

    const filename = `${i18n._(t`trend`)}(${i18n._(displayTitleFromUnit[unit.symbol].title || "")})`;

    useEffect(() => {
        (async () => {
            await dispatch(
                getOverviewIntensive({
                    org: org.current,
                    time: {
                        start: moment(globalview.global_filter.time.start),
                        end: moment(globalview.global_filter.time.end)
                    },
                    unit: unit,
                    intensive: true,
                    site: globalview.global_filter.site
                })
            );
        })();
    }, [dispatch, org, unit, globalview.global_filter]);

    const tooltip = () => {
        return (
            <Crosshair values={crosshairValues}>
                <Card className="pwaOverviewTooltip" style={{ width: moment(crosshairValues[0].x).locale(current_lng).format("LLL").length * 8 }}>
                    <Card.Content>
                        <Card.Header textAlign="center">{`${moment(crosshairValues[0].x).locale(current_lng).format("LLL")}`}</Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Card.Description>
                            <Grid className="pwaOverviewTooltip">
                                {_.map(_.reverse(crosshairValues), (v, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <Grid.Column width={1} />
                                            <Grid.Column width={5} textAlign="left">{`${i18n._(v.type)}:`}</Grid.Column>
                                            <Grid.Column width={9} textAlign="right">
                                                {v.y !== null
                                                    ? `${i18n.number(v.y, { maximumFractionDigits: 2 })} ${unit.intensive || unit.symbol}`
                                                    : `- ${unit.intensive || unit.symbol}`}
                                            </Grid.Column>
                                            <Grid.Column width={1} />
                                        </React.Fragment>
                                    );
                                })}
                            </Grid>
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Crosshair>
        );
    };

    const onNearestX = (value, { index }) => {
        const cross_val = _.map(series, (serie, key) => {
            return serie.data[index];
        });
        setCrosshairValues(cross_val);
    };

    return (
        <>
            <Header as="h3" block textAlign="center" attached="top">
                <Trans>trend</Trans>
                {`: ${i18n._(displayTitleFromUnit[unit.symbol].title)} (${unit.symbol})`}
            </Header>
            {_.get(state, "status") === "loading" && (
                <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
            )}
            {_.get(state, "status") === "failed" && (
                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
            )}
            {_.get(state, "status") === "succeeded" && isEmpty && (
                <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />
            )}
            {_.get(state, "status") === "succeeded" && !isEmpty && (
                <>
                    <Segment attached textAlign="right">
                        <GenerateCsv series={series} unit={unit.intensive || unit.symbol} filename={filename} />
                    </Segment>
                    <Segment attached>
                        <FlexibleXYPlot
                            xType="time"
                            xDomain={xDomain}
                            height={400}
                            onMouseLeave={() => {
                                setCrosshairValues([]);
                            }}
                        >
                            <HorizontalGridLines />
                            <XAxis
                                title={i18n._(t`time`)}
                                tickLabelAngle={-20}
                                tickFormat={(value, index, scale, tickTotal) => {
                                    const default_fmt = multiI18nFormat(value, current_lng);
                                    if (default_fmt.indexOf("06:00") >= 0 || default_fmt.indexOf("12:00") >= 0 || default_fmt.indexOf("18:00") >= 0)
                                        return "";
                                    return default_fmt;
                                }}
                            />
                            <YAxis
                                title={unit.symbol}
                                tickFormat={(value, index, scale, tickTotal) => {
                                    const format = scale.tickFormat(tickTotal)(value);
                                    if (typeof value === "number") {
                                        return i18n.number(value, { maximumFractionDigits: 0 });
                                    }
                                    return format;
                                }}
                            />
                            {_.chain(series)
                                .filter((item) => item.disabled === false)
                                .map((serie, idx) => {
                                    return (
                                        <LineMarkSeries
                                            key={idx}
                                            color={serie.color}
                                            fill={serie.color}
                                            size={3}
                                            getNull={(d) => d.y !== null}
                                            curve={"curveMonotoneX"}
                                            data={serie.data}
                                            onNearestX={serie.title === "max" ? onNearestX : null}
                                        />
                                    );
                                })
                                .value()}
                            {_.size(crosshairValues) > 0 ? tooltip() : null}
                        </FlexibleXYPlot>
                    </Segment>
                </>
            )}
        </>
    );
};

export default GraphicIntensive;
