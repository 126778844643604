import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";

const retrieveInitialOrg = () => {
    // Initialization of current org based on localStorage
    // It's last org view
    let initOrg = null;
    if (localStorage.getItem("currentOrg")) {
        initOrg = JSON.parse(localStorage.getItem("currentOrg"));
    }
    return { current: initOrg };
};

const initialState = retrieveInitialOrg();

const orgSlice = createSlice({
    name: "org",
    initialState,
    reducers: {
        setCurrentOrg: (state, action) => {
            state.current = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addMatcher(isAnyOf(logout), (state, action) => {
            return retrieveInitialOrg();
        });
    }
});

export const { setCurrentOrg, resetCurrentOrg } = orgSlice.actions;

export default orgSlice.reducer;
