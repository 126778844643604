import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Dropdown, Header, Icon } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";
import DeleteNode from "./nodes/DeleteNode";
import UpdateNode from "./nodes/UpdateNode";
import AddNode from "./nodes/AddNode";

import i18n from "modules/i18n/i18nConfig";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";

const ContextMenu = ({ node, rangeTime, diagram, setMenu, top, left, ...props }) => {
    const [openModalUpdate, setOpenModalUpdate] = useState(false);
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);

    const org = useSelector((state) => state.org);
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });

    //Define semantic equipment options
    const eqptsOptions = useMemo(() => {
        if (equipments.isSuccess) {
            const filter_eqpts = _.chain(equipments.data)
                .reduce((res, eqpt) => {
                    if (eqpt?.site !== diagram.site) {
                        return res;
                    }
                    res.push(eqpt);
                    return res;
                }, [])
                .value();

            return [{ key: -1, value: -1, text: i18n._(t`No equipment`) }].concat(filter_eqpts);
        }
        return [{ key: -1, value: -1, text: i18n._(t`No equipment`) }];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diagram, equipments, i18n]);

    return (
        <div style={{ top, left, position: "fixed", zIndex: 1000 }}>
            {openModalDelete && (
                <DeleteNode
                    openModal={true}
                    setOpenModal={setOpenModalDelete}
                    i18n={i18n}
                    node={node.data.node_db}
                    rangeTime={rangeTime}
                    setMenu={setMenu}
                    org={org}
                />
            )}
            {openModalUpdate && (
                <UpdateNode
                    openModal={true}
                    setOpenModal={setOpenModalUpdate}
                    i18n={i18n}
                    node={node.data.node_db}
                    rangeTime={rangeTime}
                    setMenu={setMenu}
                    org={org}
                    eqptsOptions={eqptsOptions}
                />
            )}
            {openModalAdd && (
                <AddNode
                    openModal={true}
                    setOpenModal={setOpenModalAdd}
                    i18n={i18n}
                    node={node.data.node_db}
                    rangeTime={rangeTime}
                    setMenu={setMenu}
                    org={org}
                    eqptsOptions={eqptsOptions}
                />
            )}

            <Dropdown icon={null} open={true}>
                <Dropdown.Menu>
                    <Dropdown.Header style={{ margin: 0, padding: 0 }}>
                        <Header as="h5" block attached="top">
                            {node.data.node_db.equipment === null && node.data.node_db.name}
                            {node.data.node_db.equipment !== null && <div>{node.data.node_db.name || node.data.node_db.equipment_name}</div>}
                        </Header>
                    </Dropdown.Header>
                    <Dropdown.Item
                        onClick={() => {
                            setOpenModalAdd(true);
                        }}
                    >
                        <Icon name="add" />
                        <Trans>Add node</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => {
                            setOpenModalUpdate(true);
                        }}
                    >
                        <Icon name="edit" />
                        <Trans>Edit node</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => {
                            setOpenModalDelete(true);
                        }}
                    >
                        <Icon name="trash" />
                        <Trans>Delete node</Trans>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default React.memo(ContextMenu);
