import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Button, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";

export const Details = (props) => {
    const { display, equipment, text, disabled } = props;
    useSelector((state) => state.i18n.current);
    const theme = useSelector((state) => state.common.theme);
    return (
        <Button
            className={theme === "default" ? "pwaSchaefflerDetails" : ""}
            compact={theme === "old"}
            icon={theme === "old"}
            labelPosition={display === "label" && theme === "old" ? "left" : null}
            onClick={async (e) => {
                if (!disabled) {
                    if (_.chain([16]).intersection(_.get(equipment, "dataflowspec_set")).size().value() > 0) {
                        history_app.push(`/activity/${equipment.id}`);
                    } else {
                        history_app.push(`/overview/${equipment.id}`);
                    }
                }
            }}
        >
            <Icon name="chart area" />
            {display === "label" && i18n._(text)}
        </Button>
    );
};

Details.defaultProps = {
    text: "detail",
    disabled: false
};

Details.propTypes = {
    equipment: PropTypes.object.isRequired,
    display: PropTypes.string.isRequired
};

export default React.memo(Details);
