import { t } from "@lingui/macro";

export const frequencyOptions = [
    { key: "0", value: "0", text: t`daily` },
    { key: "1", value: "1", text: t`weekly` },
    { key: "2", value: "2", text: t`monthly` }
];

export const daysOptions = [
    { key: "0", value: 0, text: t`sunday` },
    { key: "1", value: 1, text: t`monday` },
    { key: "2", value: 2, text: t`tuesday` },
    { key: "3", value: 3, text: t`wednesday` },
    { key: "4", value: 4, text: t`thursday` },
    { key: "5", value: 5, text: t`friday` },
    { key: "6", value: 6, text: t`saturday` }
];

export const historicOptions = [
    { key: "0", value: 0, text: t`last 24h export` },
    { key: "1", value: 1, text: t`last 7 days` },
    { key: "2", value: 2, text: t`last 30 days` },
    { key: "3", value: 3, text: t`last week` },
    { key: "4", value: 4, text: t`last 4 weeks` },
    { key: "5", value: 5, text: t`last month` },
    { key: "6", value: 6, text: t`last 3 months` }
];

export const exportTypeOptions = [
    { key: "1", value: 1, text: t`detailed graph` },
    { key: "2", value: 2, text: t`daily graph` }
];

export const sendprotocolOptions = [
    { key: "0", value: "email", text: t`by email` },
    { key: "1", value: "ftp", text: t`by ftp` },
    { key: "2", value: "ftps", text: t`by ftps` },
    { key: "3", value: "sftp", text: t`by sftp` }
];

export const exportFormatOptions = [
    { key: "1", value: 1, text: t`csv excel` },
    { key: "2", value: 2, text: t`csv excel semicolon` },
    { key: "3", value: 3, text: t`csv excel tab` },
    { key: "4", value: 4, text: t`xlsx excel` }
];

export const exportInstantOptions = [
    { key: "1", value: false, text: t`export associated index` },
    { key: "2", value: true, text: t`export associated power` }
];
