import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { t } from "@lingui/macro";
import { Grid, Header, Segment, Button, Icon } from "semantic-ui-react";
import { DiscreteColorLegend, Hint } from "react-vis";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";

import RotatedLabelRadialChart from "modules/common/components/graphic/PieChart";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { Media } from "App";
import Palette from "modules/common/components/graphic/Colors";
import tinycolor from "tinycolor2";

const Radial = (props) => {
    const { isPrinted } = props;
    const [hint, setHint] = useState(null);
    const [series, setSeries] = useState(props.series || []);
    const [displaySeries, setDisplaySeries] = useState(false);

    useEffect(() => {
        setSeries(props.series);
    }, [props.series]);

    const remap_series = useMemo(() => {
        const transform = _.chain(series)
            .reduce((res, serie) => {
                //Process global total only on displayed serie
                const global_total = _.chain(series)
                    .filter((item) => item.disabled === false)
                    .sumBy("total")
                    .value();
                const repartition = serie.disabled === true ? null : _.defaultTo((serie?.total / Math.abs(global_total)) * 100, null);
                const point = {
                    id: serie.id,
                    title: serie.title,
                    angle: repartition,
                    percent: repartition,
                    color: serie.color,
                    disabled: serie.disabled,
                    strokeWidth: 20
                };
                res.push(point);
                return res;
            }, [])
            .value();

        if (!isPrinted) {
            return transform;
        } else {
            //mode print
            const ten_first_series = _.chain(transform)
                .reject({ disabled: true })
                .orderBy("percent", "desc") // Ten first element
                .take(5)
                .defaultTo([])
                .value();

            const other_data_percent = _.defaultTo(100 - _.chain(ten_first_series).sumBy("percent").value(), null);

            const rewrite_title = _.map(ten_first_series, (serie) => ({
                ...serie,
                title: `${serie.title} ${serie.percent ? i18n.number(serie.percent, { maximumFractionDigits: 2 }) : "-"}%`
            }));

            if (!_.isFinite(other_data_percent) || other_data_percent <= 0) {
                return rewrite_title;
            } else {
                return [
                    ...rewrite_title,
                    {
                        title: `${i18n._(t`other measures`)} ${i18n.number(other_data_percent, { maximumFractionDigits: 2 })}%`,
                        color: tinycolor(Palette.named.grey).setAlpha(0.6).toString(),
                        angle: other_data_percent,
                        percent: other_data_percent
                    }
                ];
            }
        }
    }, [series, isPrinted]);

    const hideShowSeries = (hide) => {
        const update_series = _.chain(series)
            .reduce((res, serie, idx) => {
                if (hide) {
                    if (idx === 0) {
                        res.push({ ...serie, disabled: false });
                    } else {
                        res.push({ ...serie, disabled: true });
                    }
                } else {
                    res.push({ ...serie, disabled: false });
                }
                return res;
            }, [])
            .value();

        setSeries(update_series);
    };

    const clickHandler = (serie_clk) => {
        const other_disabled = _.chain(series)
            .reject({ id: serie_clk?.id })
            .every((serie) => {
                return serie.disabled === true;
            })
            .value();

        const update_series = _.chain(series)
            .map((item) => {
                if (item.id === _.get(serie_clk, "id")) {
                    const update_serie = { ...item, disabled: other_disabled ? false : !item.disabled };
                    return update_serie;
                } else {
                    return item;
                }
            })
            .value();

        setSeries(update_series);
    };

    return (
        <>
            <Media greaterThanOrEqual="computer">
                {(mediaClassNames, renderChildren) =>
                    renderChildren && (
                        <Segment attached textAlign="right">
                            <Button
                                icon
                                labelPosition="right"
                                onClick={async (event, data) => {
                                    await setDisplaySeries(!displaySeries);
                                    hideShowSeries(!displaySeries);
                                }}
                                className="no-print"
                            >
                                {displaySeries ? i18n._(t`display series`) : i18n._(t`hide series`)}
                                <Icon name={displaySeries ? "eye" : "eye slash"} />
                            </Button>
                        </Segment>
                    )
                }
            </Media>
            <Segment attached>
                <RotatedLabelRadialChart
                    colorType="literal"
                    className="pwaGlobalOverviewRadialChart"
                    data={_.reject(remap_series, { disabled: true })}
                    onValueMouseOver={(v) => setHint(v)}
                    onSeriesMouseOut={(v) => setHint(null)}
                    radius={120}
                    labelsRadiusMultiplier={1.2}
                    labelsStyle={{ fontSize: 12 }}
                    labelsRotation={-10}
                    showLabels
                    style={{ stroke: "#fff", strokeWidth: 2, width: "100%", height: "100%" }}
                    width={360}
                    height={360}
                    margin={{ top: 0, bottom: 0 }}
                    getLabel={(d) => {
                        if (_.isFinite(d.percent) && d.percent > 1) {
                            return `${_.isFinite(d.percent) ? i18n.number(d.percent, { maximumFractionDigits: 2 }) : "-"}%`;
                        } else {
                            return "...";
                        }
                    }}
                >
                    {hint !== null && (
                        <Hint value={hint} style={{ whiteSpace: "pre" }}>
                            <Header as="h4" attached="top" block>
                                <svg height="10" width="14" style={{ marginRight: "5px" }}>
                                    <rect height="10" width="14" style={{ fill: hint.color }}></rect>
                                </svg>
                                {`${hint.title || "-"}`}
                            </Header>
                            <Header as="h4" attached>{`${
                                _.isFinite(hint.percent) ? i18n.number(hint.percent, { maximumFractionDigits: 2 }) : "-"
                            } %`}</Header>
                        </Hint>
                    )}
                </RotatedLabelRadialChart>
                <DiscreteColorLegend
                    orientation={isPrinted ? "vertical" : "horizontal"}
                    items={remap_series}
                    onItemClick={clickHandler}
                    style={{ fontSize: "0.8em" }}
                />
            </Segment>
        </>
    );
};

const RadialPanel = (props) => {
    const {
        data,
        time_periods: { ref_time, comp_time }
    } = props;
    const [series, setSeries] = useState({ ref_series: [], comp_series: null });
    const current_lng = useSelector((state) => state.i18n.current);
    const mql = window.matchMedia("print");
    const [isPrinted, setIsPrinted] = useState(false);

    //Add media print processing for graphic width customization
    const handleMediaPrint = (mqlev) => {
        if (mqlev.matches) {
            setIsPrinted(true);
        } else {
            setIsPrinted(false);
        }
    };
    useEffect(() => {
        mql.addEventListener("change", handleMediaPrint);
        return () => {
            mql.removeEventListener("change", handleMediaPrint);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //trigger action when data change && current_lng change
        (async () => {
            const { ref_series, comp_series } = _.chain(data.series)
                .reduce((res, series, serie_type) => {
                    if (series === null) {
                        res[serie_type] = null;
                        return res;
                    }
                    res[serie_type] = _.chain(series)
                        .reduce((res, serie) => {
                            if (_.size(serie.data) > 0) {
                                const all_null = _.every(serie.data, (rec) => {
                                    return rec.y === null || rec.y === 0;
                                });
                                if (all_null) return res; //remove serie where all data null or 0
                                res.push({
                                    ...serie,
                                    title: `${i18n._(serie.name)}`,
                                    data: _.map(serie.data, (item) => ({ ...item, title: `${i18n._(serie.name)}` }))
                                });
                            }
                            return res;
                        }, [])
                        .sortBy((item) => {
                            return removeAccents(item.title).toLowerCase();
                        })
                        .value();
                    return res;
                }, {})
                .value();
            await setSeries({ ref_series, comp_series });
        })();
    }, [data.series, current_lng]); //rewrite initial series if lng change or initial series change

    const renderLabel = useCallback(
        (label) => {
            const time_from_fmt = `${_.lowerCase(i18n._(t`from`))} ${moment(ref_time.start).clone().locale(current_lng).format("L")}`;
            const time_to_fmt = `${_.lowerCase(i18n._(t`to`))} ${moment(ref_time.end).clone().startOf("day").locale(current_lng).format("L")}`;
            return `${i18n._(label)} ${time_from_fmt} ${time_to_fmt}`;
        },
        [ref_time, current_lng]
    );

    const renderLabelComp = useCallback(
        (label) => {
            const comp_time_from_fmt = `${_.lowerCase(i18n._(t`from`))} ${
                comp_time ? moment(comp_time.start).clone().locale(current_lng).format("L") : "-"
            }`;
            const comp_time_to_fmt = `${_.lowerCase(i18n._(t`to`))} ${
                comp_time ? moment(comp_time.end).clone().startOf("day").locale(current_lng).format("L") : "-"
            }`;
            return `${i18n._(label)} (ref) ${comp_time_from_fmt} ${comp_time_to_fmt}`;
        },
        [comp_time, current_lng]
    );

    return (
        <Segment attached>
            <Grid centered>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Header as="h3" textAlign="center" attached="top">
                            {renderLabel("global_distribution")}
                        </Header>
                        {_.size(series.ref_series) === 0 && (
                            <MessageDisplay
                                message={i18n._(t`no data`)}
                                level="warning"
                                iconName="warning circle"
                                isLoading={false}
                                attached={true}
                            />
                        )}
                        {_.size(series.ref_series) > 0 && <Radial series={series.ref_series} isPrinted={isPrinted} />}
                    </Grid.Column>
                    {comp_time !== null && (
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Header as="h3" textAlign="center" attached="top">
                                {renderLabelComp("global_distribution")}
                            </Header>
                            {(series?.comp_series === null || _.size(series?.comp_series) === 0) && (
                                <MessageDisplay
                                    message={i18n._(t`no data`)}
                                    level="warning"
                                    iconName="warning circle"
                                    isLoading={false}
                                    attached={true}
                                />
                            )}
                            {series?.comp_series !== null && _.size(series?.comp_series) > 0 && (
                                <Radial series={series.comp_series} isPrinted={isPrinted} />
                            )}
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default React.memo(RadialPanel);
