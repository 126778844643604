import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t } from "@lingui/macro";
import { Dropdown } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { setSelectedClusters } from "modules/overview/overviewSlice";
import MessageDisplay from "modules/common/components/MessageDisplay";

const ClusterPanel = (props) => {
    const dispatch = useDispatch();
    const { tabpredicthealthscore } = useSelector((state) => state.overview);
    const clustersOptions = _.get(tabpredicthealthscore, "clusters.data", []);
    const selected_clusters = _.get(tabpredicthealthscore, "clusters.selected_clusters", []);

    const renderLabel = (item) => {
        return { basic: true, content: <span style={{ color: item.color }}>{item.name}</span> };
    };

    if (_.size(clustersOptions) > 0) {
        return (
            <Dropdown
                fluid
                compact
                basic
                button
                selection
                multiple
                options={_.map(clustersOptions, (item) => ({ ...item, content: <span style={{ color: item.color }}>{item.text}</span> }))}
                value={selected_clusters}
                renderLabel={renderLabel}
                onChange={(e, data) => {
                    if (_.size(data.value) > 0) {
                        dispatch(setSelectedClusters(data.value));
                    }
                }}
            />
        );
    } else {
        return (
            <MessageDisplay
                message={i18n._(t`No operating point found in the selected time range`)}
                level="warning"
                iconName="warning circle"
                isLoading={false}
                attached={false}
            />
        );
    }
};

export default ClusterPanel;
