import React, { useEffect, useState } from "react";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import { Segment, Grid, Header } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetAlertPacksQuery, useGetAlertPackTypesQuery } from "../../alertPackService";
import { useGetSitesQuery } from "modules/site/siteService";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import AlertPacks from "modules/alert/alert_pack/components/AlertPacks";

const PreloadAlertPacks = (props) => {
    const [ready, setReady] = useState(false);
    const org = useSelector((state) => state.org);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const getAlertPacks = useGetAlertPacksQuery({ org: org.current }, { skip: !org.current });
    const getAlertPackTypes = useGetAlertPackTypesQuery({ org: org.current }, { skip: !org.current });

    useEffect(() => {
        (async () => {
            await setReady(true);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (ready) {
        const err_list = [getAlertPacks.isError, getAlertPackTypes.isError, sites.isError];

        const status_list = [getAlertPacks.isSuccess, getAlertPackTypes.isSuccess, sites.isSuccess];

        if (_.some(err_list)) {
            return (
                <Segment attached>
                    <Grid centered verticalAlign="middle">
                        <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                            <Grid.Column width={2}>
                                <Back />
                            </Grid.Column>
                            <Grid.Column width={12} textAlign="center">
                                <Header as="h1">
                                    <Trans>alertpacks management</Trans>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={2}></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={15}>
                                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            );
        }

        if (_.every(status_list)) {
            return <AlertPacks sites={sites.data} />;
        }
    }

    return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />;
};

export default PreloadAlertPacks;
