import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { Grid, Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import PowerAdapt from "apis/PowerAdapt";
import { useSelector } from "react-redux";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { t, Trans } from "@lingui/macro";
import Gauge from "./Gauge";
import Graphic from "./Graphic";
import { roundedDate } from "modules/time/utils";
import WorstScore from "./WorstScore";
import HeaderWHelper from "modules/common/components/HeaderWHelper";
const HealthScoreRow = (props) => {
    const isMounted = useRef(false);
    const timezone = moment.tz.guess();
    const { component, hst, time, clusters, events } = props;
    const org = useSelector((state) => state.org);
    const [healthscore, setHealthscore] = useState({ status: "idle", data: [], error: null });
    const [scoreNotComputed, setScoreNotComputed] = useState(null);

    // Use string elem of date. Prevent useEffect re-render (Ex:re-render if date id object && change selected_cluster via dropdown)
    // If time in store is null: default_time refresh when change clusters
    const hst_start = roundedDate(time.start, 60).toISOString();
    const hst_end = roundedDate(time.end, 60).toISOString();

    useEffect(() => {
        isMounted.current = true;
        (async () => {
            try {
                if (_.size(clusters) > 0) {
                    isMounted.current && (await setHealthscore({ status: "loading", data: [], error: null }));
                    isMounted.current && (await setScoreNotComputed(null));
                    const rounded_start = roundedDate(time.start, 60).toISOString();
                    const rounded_end = roundedDate(time.end, 60).toISOString();
                    const response = await PowerAdapt.get(
                        `/component/${component.id}/component_healthscore?org=${org.current.name}&start=${rounded_start}&end=${rounded_end}&healthscore_type=${hst.value}&timezone=${timezone}`
                    );
                    const data = _.chain(response.data)
                        .map((cluster, idx) => {
                            const index = parseInt(idx);
                            const title = _.get(cluster, "description");
                            const color = _.chain(clusters).find({ id: index }).get("color", null).value();
                            if (color) {
                                return {
                                    index,
                                    title,
                                    disabled: false,
                                    strokeWidth: 20,
                                    color,
                                    data: _.chain(cluster)
                                        .get("points")
                                        .map((record) => {
                                            return {
                                                title,
                                                x: moment(record[0]).unix() * 1000,
                                                y: record[1],
                                                y_real: record[1],
                                                validity: record[2],
                                                color
                                            };
                                        })
                                        .value()
                                };
                            }
                            return null;
                        })
                        .filter((cluster) => cluster !== null)
                        .value();

                    isMounted.current && setHealthscore({ status: "succeeded", data, error: null });
                    if (response.status === 204) {
                        isMounted.current && setScoreNotComputed(true);
                    }
                }
            } catch (error) {
                isMounted.current &&
                    setHealthscore({ status: "failed", data: [], error: axios.isAxiosError(error) ? error.message : "An unexpected error occurred" });
            }
        })();
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hst_start, hst_end, clusters]);

    const csvName = `${i18n._(component.compID)}_${component.name}_${i18n._(hst.text)}`;

    return (
        <>
            <Header as="h3" attached={props.index === 0 ? "top" : true} block textAlign="center">
                {`${i18n._(component.compID)} / ${i18n._(component.name)} / ${i18n._(hst.text)}`}
            </Header>
            <Segment attached>
                <Grid columns={3}>
                    <Grid.Row stretched>
                        <Grid.Column mobile={16} tablet={16} computer={3}>
                            <Segment.Group style={{ display: "flex" }}>
                                <Header as={Segment} attached="top" block textAlign="center">
                                    <HeaderWHelper
                                        text={<Trans>last score</Trans>}
                                        helper={
                                            <Trans>
                                                <p>last score helper</p>
                                            </Trans>
                                        }
                                    />
                                </Header>
                                {_.includes(["idle", "loading"], healthscore.status) && (
                                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                                )}
                                {healthscore.status === "failed" && (
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                    />
                                )}
                                {scoreNotComputed && healthscore.status === "succeeded" && (
                                    <MessageDisplay
                                        message={i18n._(t`score cannot be computed`)}
                                        level="info"
                                        iconName="warning circle"
                                        isLoading={false}
                                    />
                                )}
                                {!scoreNotComputed && healthscore.status === "succeeded" && <Gauge data={healthscore.data} />}
                            </Segment.Group>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={4}>
                            <Segment.Group>
                                <Header as={Segment} attached="top" block textAlign="center">
                                    <HeaderWHelper
                                        text={<Trans>worst score</Trans>}
                                        helper={
                                            <Trans>
                                                <p>max per cluster helper</p>
                                            </Trans>
                                        }
                                    />
                                </Header>
                                {_.includes(["idle", "loading"], healthscore.status) && (
                                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                                )}
                                {healthscore.status === "failed" && (
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                    />
                                )}
                                {scoreNotComputed && healthscore.status === "succeeded" && (
                                    <MessageDisplay
                                        message={i18n._(t`score cannot be computed`)}
                                        level="info"
                                        iconName="warning circle"
                                        isLoading={false}
                                    />
                                )}
                                {!scoreNotComputed && healthscore.status === "succeeded" && <WorstScore data={healthscore.data} />}
                            </Segment.Group>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={9}>
                            <Segment.Group>
                                <Header as={Segment} attached="top" block textAlign="center">
                                    <HeaderWHelper
                                        text={<Trans>score representation</Trans>}
                                        helper={
                                            <Trans>
                                                <p>score representation helper</p>
                                                <p>score representation helper 2</p>
                                                <p>score representation helper 3</p>
                                                <p>score representation helper 4</p>
                                            </Trans>
                                        }
                                    />
                                </Header>
                                {_.includes(["idle", "loading"], healthscore.status) && (
                                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                                )}
                                {healthscore.status === "failed" && (
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                    />
                                )}
                                {scoreNotComputed && healthscore.status === "succeeded" && (
                                    <MessageDisplay
                                        message={i18n._(t`score cannot be computed`)}
                                        level="info"
                                        iconName="warning circle"
                                        isLoading={false}
                                    />
                                )}
                                {!scoreNotComputed && healthscore.status === "succeeded" && (
                                    <Graphic data={healthscore.data} time={time} csvName={csvName} events={events} />
                                )}
                            </Segment.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </>
    );
};
export default HealthScoreRow;
