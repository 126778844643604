import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";

export const measurementtypeApi = defaultApi.injectEndpoints({
    reducerPath: "apiMeasurementtype",
    endpoints: (build) => ({
        getMeasurementtypes: build.query({
            keepUnusedDataFor: 86400,
            // Put hasFilter default to 'true' to only have mt_types in organization
            // Force 'false' in query during usage in Components
            query: ({ org, hasFilter = true }) => {
                const current_org = org?.name ?? null;
                const filterParam = hasFilter ? `&filter=org` : "";
                return `measurementtypes?org=${current_org}${filterParam}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, { org, hasFilter = true }) => [
                { type: "Measurementtypes", id: `${org?.name || "unknown"}_${hasFilter ? "filter" : "nofilter"}` }
            ],
            transformResponse: (response) => {
                return _.chain(response)
                    .map((measurementtype) => {
                        return {
                            ...measurementtype,
                            key: measurementtype.id,
                            value: measurementtype.id,
                            text: measurementtype.name,
                            type: "measurementtype"
                        };
                    })
                    .value();
            }
        })
    }),
    overrideExisting: false
});

export const { useGetMeasurementtypesQuery } = measurementtypeApi;
