import React from "react";
import { Modal } from "semantic-ui-react";
import { Trans } from "@lingui/macro";
import DetachForm from "modules/equipment/components/form/DetachForm";

const DetachModal = (props) => {
    const { measurement, setOpen, equipment } = props;
    return (
        <Modal open centered={false} onClose={(e) => setOpen(null)} closeOnDimmerClick={false} closeOnEscape={false} size="fullscreen">
            <Modal.Header>
                <Trans>Detach measurement</Trans>
            </Modal.Header>
            <Modal.Content>
                <DetachForm setOpen={setOpen} measurement={measurement} equipment={equipment} />
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(DetachModal);
