import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Button, Grid, Input, Segment, Header, Popup, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";
import { checkStringInclude } from "modules/common/utils";
import { typeImportOptions } from "../utils";
import { checkRights } from "modules/auth/utils";
import { useGetImportedDataSetsQuery } from "../importedDataSetsService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetZonesQuery } from "modules/area/areaService";
import { setStickPage, setPage, setItemsPerPage, setSearchNameFilter } from "../importedDataSetsSlice";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import TableEnhanced from "modules/common/components/TableEnhanced";
import ImportedDataSetsModalForm from "./ImportedDataSetModalForm";
import DeleteImportedDataSet from "./DeleteImportedDataSet";
import UploadFileModalForm from "./UploadFileModalForm";

const ImportedDataSets = () => {
    const dispatch = useDispatch();
    const [localSearchName, setLocalSearchName] = useState("");
    //Modal management
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(null);
    const [openUpload, setOpenUpload] = useState(null);
    const [openDelete, setOpenDelete] = useState(null);

    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    const importedDataSets = useSelector((state) => state.importedDataSets);

    const user_rights = auth.rights;

    const getImportedDataSets = useGetImportedDataSetsQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });

    useEffect(() => {
        (async () => {
            await setLocalSearchName(importedDataSets.filter.searchName);
            importedDataSets.pagination.stickPage && (await dispatch(setStickPage(false)));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "zone", label: i18n._(t`zone`), textAlign: "left" },
        { id: "type_import", label: i18n._(t`Data type`), textAlign: "left" },
        { id: "owner", label: i18n._(t`owner`), textAlign: "left" },
        {
            id: "actions",
            label: i18n._(t`actions`),
            textAlign: "left",
            ordering: false,
            collapsing: true
        }
    ];

    const importedDatasets_list = useMemo(() => {
        if (getImportedDataSets.isSuccess && zones.isSuccess && sites.isSuccess) {
            const myDatasetsList = _.chain(getImportedDataSets.data)
                .reduce((res, item) => {
                    if (importedDataSets.filter.searchName === "") {
                        res.push(item);
                    } else if (checkStringInclude(importedDataSets.filter.searchName, item.name)) {
                        res.push(item);
                    }
                    return res;
                }, [])
                .reduce((res, item) => {
                    const customStyle = { cursor: "default", whiteSpace: "pre" };
                    const zone = _.find(zones.data, { id: item?.zone });
                    const site = _.find(sites.data, { id: zone?.site_id });
                    const rights = checkRights(site, auth.rights, true);
                    const can_change = notification.srv_status.db_status === "rw" && _.includes(rights, "can_change");
                    const can_delete = notification.srv_status.db_status === "rw" && _.includes(rights, "can_delete");

                    const type_import = _.find(typeImportOptions, { value: item?.type_import });

                    res.push({
                        ...item,
                        id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                        name: {
                            render: <span style={customStyle}>{item?.name ?? "-"}</span>,
                            value: item?.name ?? "-",
                            textAlign: "left",
                            datatype: "string"
                        },
                        zone: {
                            render: zone?.text ?? "-",
                            value: zone?.text ?? "-",
                            textAlign: "left",
                            datatype: "string"
                        },
                        type_import: {
                            render: <span style={customStyle}>{i18n._(type_import?.text ?? "")}</span>,
                            value: i18n._(type_import?.text ?? ""),
                            textAlign: "left",
                            datatype: "string"
                        },
                        owner: {
                            render: <span style={customStyle}>{item?.owner_email ?? "-"}</span>,
                            value: item?.owner_email ?? "-",
                            textAlign: "left",
                            datatype: "string"
                        },
                        actions: {
                            render: (
                                <Button.Group>
                                    <Popup
                                        trigger={
                                            <Button
                                                onClick={() => {
                                                    setOpenEdit(item);
                                                }}
                                                type="button"
                                                disabled={!can_change}
                                                icon="edit"
                                            />
                                        }
                                    >
                                        <Popup.Content>
                                            <Trans>Edit dataset</Trans>
                                        </Popup.Content>
                                    </Popup>
                                    <Popup
                                        trigger={
                                            <Button
                                                onClick={() => {
                                                    setOpenUpload(item);
                                                }}
                                                type="button"
                                                disabled={!can_change}
                                                icon="upload"
                                            />
                                        }
                                    >
                                        <Popup.Content>
                                            <Trans>Upload data from file for dataset</Trans>
                                        </Popup.Content>
                                    </Popup>
                                    <Popup
                                        trigger={
                                            <Button
                                                type="button"
                                                icon="eye"
                                                onClick={() => {
                                                    history_app.push(`/importeddatasets/${item.id}/productiondata`);
                                                }}
                                            />
                                        }
                                        content={i18n._(t`View imported data`)}
                                    />
                                    <Popup
                                        trigger={
                                            <Button
                                                onClick={() => {
                                                    setOpenDelete(item);
                                                }}
                                                type="button"
                                                disabled={!can_delete}
                                                icon="delete"
                                            />
                                        }
                                    >
                                        <Popup.Content>
                                            <Trans>Delete dataset</Trans>
                                        </Popup.Content>
                                    </Popup>
                                </Button.Group>
                            ),
                            value: null,
                            textAlign: "right",
                            datatype: null
                        }
                    });
                    return res;
                }, [])
                .value();
            return myDatasetsList;
        }
        return [];
    }, [sites, zones, getImportedDataSets, auth.rights, notification.srv_status.db_status, importedDataSets.filter.searchName]);

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                {/* Modals  */}
                {openAdd && <ImportedDataSetsModalForm setOpen={setOpenAdd} />}
                {openEdit && <ImportedDataSetsModalForm item={openEdit} setOpen={setOpenEdit} />}
                {openUpload && <UploadFileModalForm item={openUpload} setOpen={setOpenUpload} />}
                {openDelete && <DeleteImportedDataSet item={openDelete} setOpen={setOpenDelete} />}

                {/* Header  */}
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target="/manage" />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>datasets</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>

                {/* check importedDataset status */}
                {getImportedDataSets.isError && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {getImportedDataSets.isFetching && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        </Grid.Column>
                    </Grid.Row>
                )}

                {/* main content  */}
                {getImportedDataSets.isSuccess && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <Grid celled>
                                <Grid.Column mobile={16} tablet={3} computer={3}>
                                    <Input
                                        fluid
                                        icon="search"
                                        placeholder={i18n._(t`search by name`)}
                                        onChange={(e, { value }) => {
                                            setLocalSearchName(value);
                                            dispatch(setSearchNameFilter(value));
                                        }}
                                        value={localSearchName}
                                    />
                                </Grid.Column>
                                {notification.srv_status.db_status === "rw" && (user_rights.is_admin || _.size(user_rights?.sites_rw) > 0) && (
                                    <Grid.Column width={16}>
                                        <Button onClick={() => setOpenAdd(true)} type="button" icon labelPosition="left">
                                            <Icon name="add" />
                                            <Trans>Add dataset</Trans>
                                        </Button>
                                    </Grid.Column>
                                )}

                                {/* Table  */}
                                <Grid.Column width={16}>
                                    <TableEnhanced
                                        headCells={headCells}
                                        rows={importedDatasets_list}
                                        order="asc"
                                        orderBy="name"
                                        page={importedDataSets.pagination.page}
                                        rowsPerPage={importedDataSets.pagination.itemsPerPage}
                                        setPage={(page) => {
                                            dispatch(setPage(page));
                                        }}
                                        setItemsPerPage={(items) => {
                                            dispatch(setItemsPerPage(items));
                                        }}
                                        textItemPerPages={i18n._(t`items per page`)}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        </Segment>
    );
};

export default React.memo(ImportedDataSets);
