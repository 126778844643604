import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { equipmenttypeApi } from "./equipmenttypeService";

const initialState = {
    equipmenttypes: []
};

const equipmentTypeSlice = createSlice({
    name: "equipmenttype",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(equipmenttypeApi.endpoints.getEquipmenttypes.matchFulfilled, (state, action) => {
                state.equipmenttypes = action.payload;
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export default equipmentTypeSlice.reducer;
