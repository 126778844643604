import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal, Icon } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteExportMutation } from "../exportService";

const DeleteExport = (props) => {
    const org = useSelector((state) => state.org);
    const { item, setDeleteExport } = props;
    const [deleteExport, { isError, isLoading, isSuccess }] = useDeleteExportMutation();

    useEffect(() => {
        if (isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (isSuccess) {
            toast.success(i18n._(t`successful delete export`), toast_options);
            setDeleteExport(null);
        }
        if (isError) {
            toast.error(i18n._(t`cannot delete export`), toast_options_err);
        }
    }, [isError, isSuccess, isLoading, setDeleteExport]);

    return (
        <Modal open closeOnDimmerClick={false} closeOnEscape={false} centered={false}>
            <Modal.Header>
                <Trans>delete</Trans>
            </Modal.Header>
            <Modal.Content>
                <Trans>want delete export {item.name}</Trans>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setDeleteExport(null)}>
                    <Trans>cancel</Trans>
                </Button>
                <Button
                    labelPosition="right"
                    icon
                    positive
                    onClick={async (e) => {
                        deleteExport({ org: org.current, export_id: item.id });
                    }}
                >
                    <Trans>validate</Trans>
                    <Icon name="trash" inverted />
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

DeleteExport.propTypes = {
    item: PropTypes.object.isRequired,
    setDeleteExport: PropTypes.func.isRequired
};

export default DeleteExport;
