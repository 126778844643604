import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { Grid, Header, Image, Segment, Menu, Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears } from "@fortawesome/free-solid-svg-icons";

import { checkRights } from "modules/auth/utils";

import EquipmentImageForm from "modules/equipment/components/ImageForm";
import GeneralInfo from "modules/overview/description/components/GeneralInfo";
import MotorInfo from "modules/overview/description/components/MotorInfo";
import history_app from "history_app";

const Description = (props) => {
    const { equipment } = props;
    const { machine } = equipment;
    const [tabIdx, setTabIdx] = useState(1);
    const auth = useSelector((state) => state.auth);

    const rights = checkRights(equipment, auth.rights);
    const display_predict_btn =
        machine !== null && ((machine?.is_predict === false && machine?.machine_type?.id === 7) || machine?.is_predict === true);
    const last_kinematic_version = _.chain(machine).get("last_kinematic_version", null).value();

    return (
        <Grid divided centered>
            <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={4}>
                    <Header
                        as="h5"
                        block
                        attached="top"
                        textAlign="center"
                        style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "pre" }}
                    >
                        {equipment.name}
                    </Header>
                    <Segment attached textAlign="center">
                        <Image src={equipment.image ? equipment.image : "/media/machine_pics/default_img.png"} size="small" centered />
                        {_.includes(rights, "can_change") && <EquipmentImageForm equipment={equipment} />}
                    </Segment>
                    <Segment attached>
                        <Menu vertical icon="labeled" fluid>
                            <Menu.Item
                                active={tabIdx === 1}
                                onClick={() => {
                                    setTabIdx(1);
                                }}
                            >
                                <Icon name="info" />
                                <Trans>general informations</Trans>
                            </Menu.Item>
                            {display_predict_btn && (
                                <>
                                    <Menu.Item
                                        active={tabIdx === 2}
                                        onClick={() => {
                                            setTabIdx(2);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faGears} className="icon" />
                                        <Trans>motor</Trans>
                                    </Menu.Item>
                                    <Menu.Item
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (last_kinematic_version === 0) {
                                                history_app.push(`/kinematic/${_.get(machine, "id")}/change`);
                                            } else {
                                                history_app.push(`/equipments/${equipment?.id}/machineEvents`);
                                            }
                                        }}
                                    >
                                        <Icon name={last_kinematic_version === 0 ? "gg" : "calendar alternate outline"} />
                                        {last_kinematic_version === 0 && <Trans>initial kinematic</Trans>}
                                        {last_kinematic_version !== 0 && <Trans>event list</Trans>}
                                    </Menu.Item>
                                </>
                            )}
                            {equipment.id && (
                                <Menu.Item as={Link} to={`/equipments/${equipment.id}`}>
                                    <Icon name="edit" />
                                    <Trans>equipment edition</Trans>
                                </Menu.Item>
                            )}
                        </Menu>
                    </Segment>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={12}>
                    {tabIdx === 1 && <GeneralInfo equipment={equipment} />}
                    {tabIdx === 2 && <MotorInfo equipment={equipment} />}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default Description;
