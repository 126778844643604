import React, { useEffect, useMemo } from "react";
import { Trans, t } from "@lingui/macro";
import _ from "lodash";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Button, Header, Segment, Table } from "semantic-ui-react";
import { toast } from "react-toastify";

import i18n from "modules/i18n/i18nConfig";
import { useGetUserRightsQuery, useSetUserRightsMutation } from "../userService";
import { siteRoleOptions } from "../utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import { DropDownAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import UserRightsAddModal from "./UserRightsAddModal";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const UserRights = (props) => {
    const { sites, can_change, org, id } = props;
    const userRights = useGetUserRightsQuery({ org: org.current, user_id: parseInt(id) }, { skip: !org.current || id === undefined });

    const [setUserRights, changeRights] = useSetUserRightsMutation();

    useEffect(() => {
        if (changeRights.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (changeRights.isSuccess) {
            toast.success(i18n._(t`successful update rights`), toast_options);
        }
        if (changeRights.isError) {
            let error = i18n._(t`cannot update rights`);
            if (changeRights?.error?.data && !_.includes(changeRights.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={changeRights?.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [changeRights]);

    const submitForm = async (formData) => {
        const user_rights = _.chain(formData)
            .get("user_rights", [])
            .map((item) => {
                return { rights: item.rights, site: item.site.id };
            })
            .value();
        setUserRights({ org: org.current, user_id: parseInt(id), data: user_rights });
    };

    const initialValues = useMemo(() => {
        let availableSites = [];
        if (userRights.isSuccess) {
            const sitesWithRights = _.chain(sites)
                .reduce((res, site) => {
                    const { id, key, text, value } = site;
                    const site_right = _.find(userRights.data, { site: site.id });
                    if (site_right) {
                        res.push({ site: { id, key, text, value }, rights: site_right?.rights });
                    } else {
                        availableSites.push({ id, key, text, value });
                    }
                    return res;
                }, [])
                .orderBy((item) => {
                    return item.site.text;
                }, "asc")
                .value();

            return {
                modalOpen: false,
                user_rights: sitesWithRights,
                availableSites
            };
        }
        return {
            modalOpen: false,
            user_rights: [],
            availableSites //process available sites with no rights
        };
    }, [sites, userRights]);

    if (userRights.isError) {
        return (
            <>
                <Header attached="top" block as="h3">
                    <Trans>Rights on the sites</Trans>
                </Header>
                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
            </>
        );
    } else if (userRights.isSuccess) {
        return (
            <Form
                onSubmit={submitForm}
                mutators={{ ...arrayMutators }}
                initialValues={initialValues}
                render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                    return (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Header attached="top" block as="h3">
                                <Trans>Rights on the sites</Trans>
                            </Header>
                            <Table celled attached compact>
                                {can_change && (
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan={3}>
                                                <UserRightsAddModal form={form} values={values} />
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                )}
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            <Trans>Site</Trans>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            <Trans>Rights</Trans>
                                        </Table.HeaderCell>
                                        {can_change && <Table.HeaderCell collapsing={true} />}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <FieldArray name="user_rights">
                                        {(userRights_field) => {
                                            if (_.isEmpty(userRights_field.fields.value)) {
                                                return (
                                                    <Table.Row>
                                                        <Table.Cell colSpan={can_change ? 3 : 2}>
                                                            <MessageDisplay
                                                                message={i18n._(t`no rights on sites`)}
                                                                level="info"
                                                                iconName="info circle"
                                                                isLoading={false}
                                                                attached={false}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                );
                                            }
                                            return userRights_field.fields.map((ur_name, ur_index) => {
                                                const current_rights = userRights_field.fields.value[ur_index];
                                                const rights = _.find(siteRoleOptions, { value: current_rights?.rights });
                                                return (
                                                    <Table.Row key={ur_index}>
                                                        <Table.Cell width={8}>{_.get(current_rights, "site.text", "-")}</Table.Cell>
                                                        <Table.Cell>
                                                            {can_change && (
                                                                <Field
                                                                    name={`${ur_name}.rights`}
                                                                    placeholder={i18n._(t`select rights`)}
                                                                    options={siteRoleOptions}
                                                                    component={DropDownAdapter}
                                                                    isRequired={true}
                                                                    validate={(value) => {
                                                                        return _.isFinite(parseFloat(value)) ? undefined : (
                                                                            <Trans>rights is required</Trans>
                                                                        );
                                                                    }}
                                                                    disabled={!can_change}
                                                                />
                                                            )}
                                                            {!can_change && i18n._(_.get(rights, "text", "-"))}
                                                        </Table.Cell>
                                                        {can_change && (
                                                            <Table.Cell>
                                                                <Button
                                                                    type="button"
                                                                    icon="trash"
                                                                    color="red"
                                                                    onClick={() => {
                                                                        if (current_rights) {
                                                                            const availableSites = values?.availableSites ?? [];
                                                                            const alreadyInAvailable = _.find(availableSites, {
                                                                                id: current_rights?.site.id
                                                                            });
                                                                            if (alreadyInAvailable === undefined) {
                                                                                //update availableSites when remove rights on site
                                                                                form.change("availableSites", [
                                                                                    ...availableSites,
                                                                                    current_rights.site
                                                                                ]);
                                                                            }
                                                                            userRights_field.fields.remove(ur_index);
                                                                        }
                                                                    }}
                                                                />
                                                            </Table.Cell>
                                                        )}
                                                    </Table.Row>
                                                );
                                            });
                                        }}
                                    </FieldArray>
                                </Table.Body>
                            </Table>
                            {can_change && (
                                <Segment attached>
                                    <Button type="submit" content={i18n._(t`validate`)} disabled={submitting || invalid} />
                                </Segment>
                            )}
                        </form>
                    );
                }}
            />
        );
    } else {
        return (
            <>
                <Header attached="top" block as="h3">
                    <Trans>Rights on the sites</Trans>
                </Header>
                <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
            </>
        );
    }
};

export default React.memo(UserRights);
