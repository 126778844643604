import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { componentApi } from "./componentService";

const initialState = {
    components: []
};

const componentSlice = createSlice({
    name: "component",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(componentApi.endpoints.getComponents.matchFulfilled, (state, action) => {
                state.components = action.payload;
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export default componentSlice.reducer;
