import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button, Modal, Icon, Dropdown, Segment, Checkbox } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteWidgetMutation } from "../dashboardDndService";

const WidgetDelete = (props) => {
    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const org = useSelector((state) => state.org);

    const { item, current_dashboard } = props;

    const [deleteWidget, { isError, isLoading, isSuccess }] = useDeleteWidgetMutation();

    useEffect(() => {
        if (isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (isSuccess) {
            toast.success(i18n._(t`Successful delete widget`), toast_options);
        }
        if (isError) {
            toast.error(i18n._(t`Can't delete widget`), toast_options_err);
        }
    }, [isError, isSuccess, isLoading]);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <Dropdown.Item>
                    <Icon name="trash" />
                    <Trans>delete</Trans>
                </Dropdown.Item>
            }
        >
            <Modal.Header>
                <Trans>want delete widget {item.name}</Trans>
            </Modal.Header>
            <Modal.Content>
                <Segment basic>
                    <Checkbox
                        label={
                            <label>
                                <Trans>yes, i confirm deletion</Trans>
                            </label>
                        }
                        onChange={(e, data) => setConfirm(data.checked)}
                        checked={confirm}
                    />
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    onClick={() => {
                        setOpen(false);
                        setConfirm(false);
                    }}
                >
                    <Trans>cancel</Trans>
                </Button>
                <Button
                    labelPosition="right"
                    icon
                    positive
                    onClick={(e) => {
                        setOpen(false);
                        deleteWidget({
                            org: org.current,
                            widget_id: item.id,
                            dashboard_id: current_dashboard.id
                        });
                    }}
                    disabled={!confirm}
                >
                    <Icon name="check" />
                    <Trans>validate</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

WidgetDelete.propTypes = {
    item: PropTypes.object.isRequired
};

export default WidgetDelete;
