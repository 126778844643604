import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Grid, Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { getElecDistribution, getOperatingHours, resetTab, setTime } from "modules/overview/overviewSlice";
import { dateRangeOptions, getTimedelta } from "modules/time/utils";

import TimePanel from "modules/common/components/TimePanel";
import MessageDisplay from "modules/common/components/MessageDisplay";
import HeaderWHelper from "modules/common/components/HeaderWHelper";
import GraphicDetail from "../graphic/GraphicDetail";
import { VerticalBarSeries } from "react-vis";
import GraphicElecDistribution from "../graphic/GraphicElecDistribution";
import Accumulation from "../Accumulation";

const TabElecOperationnal = (props) => {
    const tab = "tabelecoperationnal";
    const { default_time, idx } = props;
    const measures = _.orderBy(props.measures, "id");
    const dispatch = useDispatch();
    const org = useSelector((state) => state.org);
    const { tabelecoperationnal, powertime } = useSelector((state) => state.overview);

    const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(["24h", "48h", "72h", "1d"], item.value));

    const measure_operating_hours = _.find(measures, (measure) => _.get(measure, "measurementtype.name") === "operating_hours");
    const measure_operating_cycles = _.find(measures, (measure) => _.get(measure, "measurementtype.name") === "cycles");
    const measure_p_act_import = _.find(measures, (measure) => _.get(measure, "measurementtype.name") === "p_act_import");

    const rangeTime = powertime.time
        ? {
              start: moment(powertime.time.start),
              end: moment(powertime.time.end),
              plage: powertime.time.plage
          }
        : default_time;

    useEffect(() => {
        (async () => {
            if (powertime.time) {
                const { start, end } = powertime.time;
                const duration = moment(end).diff(moment(start), "day");
                if (duration < 2) {
                    const new_range_time = getTimedelta(start, end);
                    await dispatch(
                        setTime({
                            start: new_range_time.start.toISOString(),
                            end: new_range_time.end.toISOString(),
                            plage: new_range_time.plage,
                            tab: "powertime"
                        })
                    );
                    !_.isUndefined(measure_operating_hours) &&
                        dispatch(
                            getOperatingHours({
                                org: org.current,
                                measure: measure_operating_hours,
                                start: new_range_time.start.format("YYYY-MM-DD"),
                                end: new_range_time.end.format("YYYY-MM-DD"),
                                tab,
                                type: "operatinghours"
                            })
                        );
                    !_.isUndefined(measure_p_act_import) &&
                        dispatch(
                            getElecDistribution({
                                org: org.current,
                                measure: measure_p_act_import,
                                start: new_range_time.start.toISOString(),
                                end: new_range_time.end.toISOString(),
                                tab,
                                type: "distribution"
                            })
                        );
                } else {
                    !_.isUndefined(measure_operating_hours) &&
                        dispatch(
                            getOperatingHours({
                                org: org.current,
                                measure: measure_operating_hours,
                                start: moment(start).format("YYYY-MM-DD"),
                                end: moment(end).format("YYYY-MM-DD"),
                                tab,
                                type: "operatinghours"
                            })
                        );
                    !_.isUndefined(measure_p_act_import) &&
                        dispatch(getElecDistribution({ org: org.current, measure: measure_p_act_import, start, end, tab, type: "distribution" }));
                }
            } else {
                !_.isUndefined(measure_operating_hours) &&
                    dispatch(
                        getOperatingHours({
                            org: org.current,
                            measure: measure_operating_hours,
                            start: default_time.start.format("YYYY-MM-DD"),
                            end: default_time.end.format("YYYY-MM-DD"),
                            tab,
                            type: "operatinghours"
                        })
                    );
                !_.isUndefined(measure_p_act_import) &&
                    dispatch(
                        getElecDistribution({
                            org: org.current,
                            measure: measure_p_act_import,
                            start: default_time.start.toISOString(),
                            end: default_time.end.toISOString(),
                            tab,
                            type: "distribution"
                        })
                    );
            }
        })();
        return () => {
            dispatch(resetTab(tab));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [powertime.time, idx]);

    return (
        <Grid id="pwaMain" verticalAlign="middle" centered>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <TimePanel
                        time={rangeTime}
                        rangeOptions={rangeOptions}
                        accordion={true}
                        minimumDeltaDay={1}
                        action={({ start, end, plage }) => {
                            dispatch(setTime({ start, end, plage, tab: "powertime" }));
                        }}
                        date_limit={moment()}
                    />
                </Grid.Column>
            </Grid.Row>
            {!_.isUndefined(measure_operating_hours) && !_.isUndefined(measure_operating_cycles) && (
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center">
                        <Accumulation measure_operating_hours={measure_operating_hours} measure_operating_cycles={measure_operating_cycles} />
                    </Grid.Column>
                </Grid.Row>
            )}
            {!_.isUndefined(measure_operating_hours) && (
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header as={Segment} attached="top" block textAlign="center">
                            <HeaderWHelper
                                text={<Trans>uptime analysis</Trans>}
                                helper={
                                    <Trans>
                                        <p>
                                            The graph shows the percentage of time spent above the standby threshold, corresponding to the equipment's
                                            rate of operation per day.
                                        </p>
                                    </Trans>
                                }
                            />
                        </Header>
                        {tabelecoperationnal.operatinghours.status === "loading" && (
                            <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        )}
                        {tabelecoperationnal.operatinghours.status === "failed" && (
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        )}
                        {tabelecoperationnal.operatinghours.status === "succeeded" && (
                            <GraphicDetail
                                time={rangeTime}
                                data={tabelecoperationnal.operatinghours.data}
                                SerieType={VerticalBarSeries}
                                csvName={_.get(props, "equipment.name", "export")}
                            />
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row>
                <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                    <Header as={Segment} attached="top" block textAlign="center">
                        <HeaderWHelper
                            text={<Trans>power distribution</Trans>}
                            helper={
                                <Trans>
                                    <p>Power distribution represents the proportion of time spent by the equipment in each power range.</p>
                                    <p>These ranges are divided according to the maximum and minimum measured power.</p>
                                </Trans>
                            }
                        />
                    </Header>
                    {_.isUndefined(measure_p_act_import) && (
                        <MessageDisplay message={i18n._(t`no p_act_import measure`)} level="info" iconName="info circle" isLoading={false} />
                    )}
                    {tabelecoperationnal.distribution.status === "loading" && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {tabelecoperationnal.distribution.status === "failed" && (
                        <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                    )}
                    {tabelecoperationnal.distribution.status === "succeeded" && (
                        <GraphicElecDistribution data={tabelecoperationnal.distribution.data} csvName={_.get(props, "equipment.name", "export")} />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default TabElecOperationnal;
