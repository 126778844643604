import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Icon, Modal, Popup } from "semantic-ui-react";
import { CSVLink } from "react-csv";
import { Trans } from "@lingui/macro";
import _ from "lodash";

import i18n from "modules/i18n/i18nConfig";
import { downloadGraphicData } from "../actions";
import { csvDelimiter } from "../utils";

const GenerateCsv = (props) => {
    const dispatch = useDispatch();
    const { series, filename, unit, formatted_time, disabled, popupContent } = props;

    const first_cell = unit;
    const [open, setOpen] = useState(false);
    const [delimiter, setDelimiter] = useState(",");
    const csvData = useSelector((state) => state.common.csvData);
    const current_lng = useSelector((state) => state.i18n.current);

    const translate = i18n.use(current_lng);

    useEffect(() => {
        setDelimiter(current_lng === "fr" ? ";" : ",");
        // eslint-disable-next-line
    }, [current_lng]);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                popupContent === undefined ? (
                    <Button icon="download" className="no-print" disabled={disabled} onClick={() => setOpen(true)} />
                ) : (
                    <Popup trigger={<Button icon="download" className="no-print" disabled={disabled} onClick={() => setOpen(true)} />}>
                        <Popup.Content>{popupContent}</Popup.Content>
                    </Popup>
                )
            }
        >
            <Modal.Header>
                <Trans>data export</Trans>
            </Modal.Header>
            <Modal.Content>
                <Dropdown
                    options={_.map(csvDelimiter, (item) => ({ ...item, text: translate._(item.text) }))}
                    selection
                    fluid
                    value={delimiter}
                    onChange={(e, data) => {
                        setDelimiter(data.value);
                    }}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button negative icon="arrow left" onClick={() => setOpen(false)} />
                <Button
                    as={CSVLink}
                    positive
                    icon
                    labelPosition="left"
                    asyncOnClick={true}
                    onClick={async (event, data) => {
                        await dispatch(downloadGraphicData(series, translate._(first_cell), formatted_time, delimiter));
                        await setOpen(false);
                    }}
                    separator={delimiter}
                    {...csvData}
                    filename={filename ? `${filename}.${delimiter === "\t" ? "tsv" : "csv"}` : `export.${delimiter === "\t" ? "tsv" : "csv"}`}
                >
                    <Icon name="download" />
                    <Trans>Download</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

GenerateCsv.defaultProps = {
    unit: "Date", //unit is first_cell of csv file
    formatted_time: true,
    disabled: false
};

export default React.memo(GenerateCsv);
