import React, { useMemo } from "react";
import { Button, Grid, Icon, Modal } from "semantic-ui-react";
import _ from "lodash";
import { Trans, t } from "@lingui/macro";
import { Field, Form } from "react-final-form";
import { DropDownAdapter } from "modules/common/components/form";
import i18n from "modules/i18n/i18nConfig";
import { siteRoleOptions } from "../utils";
import MessageDisplay from "modules/common/components/MessageDisplay";

const UserRightsAddModal = (props) => {
    const { form: rightsForm, values } = props;
    const { modalOpen, availableSites, user_rights } = useMemo(() => {
        return {
            modalOpen: values?.modalOpen ?? false,
            availableSites: _.orderBy(values?.availableSites ?? [], "text", "asc"),
            user_rights: values?.user_rights ?? []
        };
    }, [values]);

    const onSubmitForm = async (data) => {
        const updated_rights = _.reduce(
            availableSites,
            (res, site) => {
                if (_.includes(data.selectedSites, site.id)) {
                    res.user_rights.push({ site, rights: data.rights });
                } else {
                    res.availableSites.push(site);
                }
                return res;
            },
            { availableSites: [], user_rights }
        );

        rightsForm.change("availableSites", updated_rights.availableSites);
        rightsForm.change(
            "user_rights",
            _.orderBy(updated_rights.user_rights, (item) => item.site.text),
            "asc"
        );
        rightsForm.change("modalOpen", false);
    };

    const initialValues = useMemo(() => {
        return {
            selectedSites: [],
            rights: 1
        };
    }, []);

    return (
        <Modal
            open={modalOpen}
            trigger={
                <Button
                    type="button"
                    icon
                    labelPosition="left"
                    onClick={async (e) => {
                        rightsForm.change("modalOpen", true);
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <Icon name="add" />
                    <Trans>Add rights on sites</Trans>
                </Button>
            }
        >
            <Form
                onSubmit={onSubmitForm}
                initialValues={initialValues}
                render={({ handleSubmit, form: modalForm, invalid }) => {
                    return (
                        <>
                            <Modal.Header>
                                <Trans>Add rights on sites</Trans>
                            </Modal.Header>
                            <Modal.Content>
                                <form onSubmit={handleSubmit} className="ui form">
                                    {_.isEmpty(availableSites) && (
                                        <MessageDisplay
                                            message={i18n._(t`no sites available`)}
                                            level="info"
                                            iconName="info circle"
                                            isLoading={false}
                                            attached={false}
                                        />
                                    )}
                                    <Grid centered>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Field
                                                    name="selectedSites"
                                                    label={i18n._(t`Sites`)}
                                                    placeholder={i18n._(t`select sites`)}
                                                    options={availableSites}
                                                    component={DropDownAdapter}
                                                    multipleselect={1}
                                                    isRequired={true}
                                                    validate={(value) => (_.size(value) === 0 ? <Trans>sites are required</Trans> : undefined)}
                                                    disabled={_.isEmpty(availableSites)}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Field
                                                    name="rights"
                                                    label={i18n._(t`Rights`)}
                                                    placeholder={i18n._(t`select rights`)}
                                                    options={siteRoleOptions}
                                                    component={DropDownAdapter}
                                                    isRequired={true}
                                                    validate={(value) => {
                                                        return _.isFinite(parseFloat(value)) ? undefined : <Trans>rights is required</Trans>;
                                                    }}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    type="button"
                                    negative
                                    onClick={(e, data) => {
                                        rightsForm.change("modalOpen", false);
                                    }}
                                >
                                    <Trans>cancel</Trans>
                                </Button>
                                <Button
                                    type="button"
                                    positive
                                    onClick={() => {
                                        modalForm.submit();
                                    }}
                                    disabled={invalid}
                                >
                                    <Trans>validate</Trans>
                                </Button>
                            </Modal.Actions>
                        </>
                    );
                }}
            />
        </Modal>
    );
};

export default UserRightsAddModal;
