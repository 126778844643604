import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import i18n from "modules/i18n/i18nConfig";
import { toast } from "react-toastify";
import { Button, Modal, Icon, Popup } from "semantic-ui-react";

import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteApiImportMutation } from "../ApiImportService";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const DeleteApiImport = (props) => {
    const { item, canDelete } = props;

    const [open, setOpen] = useState(false);
    const org = useSelector((state) => state.org);

    const [deleteApiImport, deleted] = useDeleteApiImportMutation();

    useEffect(() => {
        if (deleted.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (deleted.isSuccess) {
            toast.success(i18n._(t`successful delete Api import source`), toast_options);
            setOpen(false);
        }
        if (deleted.isError) {
            let error = i18n._(t`cannot delete Api import source`);
            if (deleted.error?.data && !_.includes(deleted.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={deleted.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [deleted]);

    const handleDelete = async (e) => {
        if (canDelete) {
            setOpen(true);
            await deleteApiImport({ org: org.current, id: item.id });
        }
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            trigger={
                <Popup
                    trigger={
                        canDelete && (
                            <Button
                                type="button"
                                icon="delete"
                                onClick={async (e) => {
                                    setOpen(true);
                                }}
                            />
                        )
                    }
                >
                    <Popup.Content>
                        <Trans>Deletion of API import</Trans>
                    </Popup.Content>
                </Popup>
            }
            onClose={() => {
                setOpen(false);
            }}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>
                <Trans>want delete Api source {item.name}</Trans>
            </Modal.Header>
            <Modal.Actions>
                <Button
                    negative
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    <Trans>cancel</Trans>
                </Button>
                {canDelete && (
                    <Button labelPosition="right" icon positive onClick={handleDelete}>
                        <Icon name="check" />
                        <Trans>validate</Trans>
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteApiImport;
