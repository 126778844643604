import React, { useState } from "react";
import _ from "lodash";
import { t } from "@lingui/macro";
import { Button, Card, Segment } from "semantic-ui-react";
import { CustomSVGSeries, DiscreteColorLegend, FlexibleXYPlot, Highlight, Hint, HorizontalGridLines, LineSeries, XAxis, YAxis } from "react-vis";

import i18n from "modules/i18n/i18nConfig";
import { Media } from "App";
import MessageDisplay from "modules/common/components/MessageDisplay";

const GraphicOperatingPoints = (props) => {
    const { thresholdX, thresholdY, titleX, titleY, type, thresholdSlip } = props;
    const [brushing, setBrushing] = useState(false);
    const [lastDrawLocation, setLastDrawLocation] = useState(null);
    const [hintValue, setHintValue] = useState(null);

    const [series, setSeries] = useState(_.orderBy(props.data, "title", "desc"));

    const clickHandler = (serie_clk) => {
        const size_disable = _.chain(series)
            .filter((item) => item.disabled === false)
            .size()
            .value();
        const update_series = _.map(series, (item) => {
            if (item.index === _.get(serie_clk, "index")) return { ...item, disabled: size_disable <= 1 ? false : !item.disabled };
            return item;
        });
        setSeries(update_series);
    };

    const tooltip = () => {
        const x_val = _.isFinite(hintValue.x) ? `${i18n.number(hintValue.x, { maximumFractionDigits: 2 })}` : "-";
        const y_val = _.isFinite(hintValue.y) ? `${i18n.number(hintValue.y, { maximumFractionDigits: 2 })}` : "-";

        return (
            <Hint value={hintValue}>
                <Card>
                    <Card.Content>
                        <Card.Header style={{ color: hintValue.color }}>{hintValue.title}</Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Card.Description style={{ color: hintValue.color }}>
                            {type === "freq_rms" && `F: ${x_val}(Hz) | I: ${y_val} A`}
                            {type === "rms_slip" && `Slip: ${x_val}(rpm) | I: ${y_val} A`}
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Hint>
        );
    };

    const emptyData = _.chain(series)
        //check if all series empty
        .map((serie) =>
            _.chain(serie)
                .get("data")
                .filter((record) => record.y !== null)
                .isEmpty(serie.data)
                .value()
        )
        .every()
        .value();

    if (emptyData) {
        return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />;
    }

    const all_data_wrap = _.chain(series)
        .filter((item) => item.disabled === false)
        .reduce((res, serie) => {
            const { data } = serie;
            _.each(data, (record) => res.push(record));
            return res;
        }, []);

    const minX = all_data_wrap.sortBy("x").first().get("x").value();
    const maxX = all_data_wrap.sortBy("x").last().get("x").value();
    const minY = all_data_wrap.sortBy("y").first().get("y").value();
    const maxY = all_data_wrap.sortBy("y").last().get("y").value();

    const maxXdomain = type === "rms_slip" ? Math.max(maxX, thresholdX, thresholdSlip) * 1.1 : Math.max(maxX, thresholdX) * 1.1;

    return (
        <>
            <Segment attached textAlign="right">
                <Media greaterThanOrEqual="computer">
                    <Button
                        onClick={(event, data) => {
                            setLastDrawLocation(null);
                        }}
                        icon="zoom-out"
                        className="no-print"
                    />
                </Media>
            </Segment>
            <Segment attached>
                <FlexibleXYPlot
                    dontCheckIfEmpty
                    height={480}
                    margin={{ left: 60, right: 10, top: 10, bottom: 120 }}
                    xDomain={lastDrawLocation ? [lastDrawLocation.left, lastDrawLocation.right] : [Math.min(minX, 0), maxXdomain]}
                    yDomain={
                        lastDrawLocation ? [lastDrawLocation.bottom, lastDrawLocation.top] : [Math.min(minY, 0), Math.max(maxY, thresholdY) * 1.2]
                    }
                >
                    <DiscreteColorLegend className="pwaLegend400" orientation="horizontal" height={75} items={series} onItemClick={clickHandler} />
                    <HorizontalGridLines />
                    <Highlight
                        onBrushStart={(area) => {
                            if (area) setBrushing(true);
                        }}
                        onBrushEnd={(area) => {
                            if (area) {
                                setBrushing(false);
                                setLastDrawLocation(area);
                            }
                        }}
                    />
                    <XAxis
                        title={titleX} //Fréquence d'alimentation (Hz) "Intensite RMS (A)"
                        tickLabelAngle={-20}
                    />
                    {_.chain(series)
                        .filter((item) => item.disabled === false)
                        .map((serie, idx) => {
                            return (
                                <CustomSVGSeries
                                    key={idx}
                                    style={{
                                        stroke: serie.color,
                                        fill: serie.color,
                                        opacity: 0.3,
                                        pointerEvents: brushing ? "none" : "auto"
                                    }}
                                    size={6}
                                    getNull={(d) => {
                                        return d.y !== null;
                                    }}
                                    customComponent={serie.title === "-" ? "diamond" : "circle"}
                                    data={serie.data}
                                    markStyle={brushing ? { pointerEvents: "none" } : { pointerEvents: "auto" }}
                                    onValueMouseOver={(hintValue) => {
                                        setHintValue(hintValue);
                                    }}
                                    onValueMouseOut={() => {
                                        setHintValue(null);
                                    }}
                                />
                            );
                        })
                        .value()}
                    {type === "rms_slip" && _.isFinite(thresholdSlip) && (
                        <LineSeries
                            key={"slip_thresh"}
                            data={[
                                { x: thresholdSlip, y: 0 },
                                { x: thresholdSlip, y: Math.max(maxY, thresholdY) * 1.2 }
                            ]}
                            color={"green"}
                            getNull={(d) => d.y !== null}
                            strokeWidth={0.5}
                        />
                    )}
                    {type === "freq_rms" && _.isFinite(thresholdX) && (
                        <LineSeries
                            key={"freq_thresh"}
                            data={[
                                { x: thresholdX, y: 0 },
                                { x: thresholdX, y: Math.max(maxY, thresholdY) * 1.2 }
                            ]}
                            color={"green"}
                            getNull={(d) => d.y !== null}
                            strokeWidth={0.5}
                        />
                    )}
                    {_.isFinite(thresholdX) && _.isFinite(thresholdY) && (
                        <LineSeries
                            color={"green"}
                            data={[
                                { x: _.get(lastDrawLocation, "left") || 0, y: thresholdY },
                                { x: _.get(lastDrawLocation, "right") || maxXdomain, y: thresholdY }
                            ]}
                            strokeWidth={0.5}
                        />
                    )}
                    {hintValue ? tooltip() : null}
                    <YAxis title={titleY} tickPadding={-2} />
                </FlexibleXYPlot>
            </Segment>
        </>
    );
};

export default React.memo(GraphicOperatingPoints);
