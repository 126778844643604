import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { machinetypeApi } from "./machinetypeService";

const initialState = {
    machinetypes: []
};

const machinetypeSlice = createSlice({
    name: "machinetype",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(machinetypeApi.endpoints.getMachinetypes.matchFulfilled, (state, action) => {
                state.machinetypes = action.payload;
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export default machinetypeSlice.reducer;
