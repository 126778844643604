import _ from "lodash";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { tagApi } from "./tagService";
import { checkStringInclude } from "modules/common/utils";

const initialState = {
    //This tags list is used for local tags update in Equipment Form
    tags: [],
    filter: {
        searchName: "",
        tags: []
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    }
};

const tagSlice = createSlice({
    name: "tag",
    initialState,
    reducers: {
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        addTagLocal: (state, action) => {
            state.tags = [...state.tags, action.payload];
        }
    },
    extraReducers(builder) {
        builder
            .addMatcher(tagApi.endpoints.getTags.matchFulfilled, (state, action) => {
                //Update local tags with request defined in Service
                state.tags = action.payload;
            })
            .addMatcher(isAnyOf(setSearchNameFilter), (state, action) => {
                const filtered_tag = _.chain(state.tags)
                    .reduce((res, tag) => {
                        /* name filter */
                        if (state.filter.searchName === "") {
                            res.push(tag);
                        } else if (checkStringInclude(state.filter.searchName, tag.name)) {
                            res.push(tag);
                        }
                        return res;
                    }, [])
                    .value();
                state.filter.tags = filtered_tag;
                if (!state.pagination.stickPage) {
                    state.pagination.page = 1;
                }
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const { setSearchNameFilter, setPage, setItemsPerPage, setStickPage, addTagLocal } = tagSlice.actions;
export default tagSlice.reducer;
