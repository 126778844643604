import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { Button, Modal, Icon, Grid, Message, Popup } from "semantic-ui-react";

import { useGetNewExternalApiKeyQuery, useAddNewExternalApiKeyMutation } from "../organizationService";
import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import MessageDisplay from "modules/common/components/MessageDisplay";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const AddExternalApiKey = (props) => {
    const { can_change } = props;

    const [open, setOpen] = useState(false);
    const org = useSelector((state) => state.org);

    const generateApiKey = useGetNewExternalApiKeyQuery({ org: org.current }, { skip: !org.current || open === false });
    const [createApiKey, create] = useAddNewExternalApiKeyMutation();

    // Create Api Key
    useEffect(() => {
        if (create.isSuccess) {
            toast.success(i18n._(t`New API key added`), toast_options);
            setOpen(false);
        }
        if (create.isError) {
            let error = i18n._(t`Can't add new API key`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
            setOpen(false);
        }
    }, [create]);

    const handleClick = async () => {
        await createApiKey({ org: org.current, data: generateApiKey.data });
    };

    const handleCopy = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            toast.success(i18n._(t`Secret key copied`), toast_options);
        } catch (err) {
            toast.error(i18n._(t`Can't copy secret key`), toast_options_err);
        }
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                can_change && (
                    <Button icon labelPosition="left">
                        <Icon name="add" />
                        <Trans>Add external API key</Trans>
                    </Button>
                )
            }
        >
            <Modal.Header>
                <Trans>Add external API key</Trans>
            </Modal.Header>
            <Modal.Content>
                {generateApiKey.isError && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {generateApiKey.isFetching && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`loading data`)}
                                level="info"
                                iconName="circle notched"
                                isLoading={true}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {generateApiKey.isSuccess && (
                    <>
                        <Trans>Click on validate below to create the new API key and start authenticating with our external API.</Trans>
                        <Message info style={{ marginTop: "1rem" }}>
                            <Message.Header>
                                <Trans>Your identifier</Trans>
                            </Message.Header>
                            <Trans>{generateApiKey.data.identifier}</Trans>
                        </Message>
                        <Message warning style={{ marginTop: "1rem" }}>
                            <Message.Header>
                                <Trans>Your secret key:</Trans>
                            </Message.Header>
                            <Trans>{generateApiKey.data.key}</Trans>
                            <Popup
                                content={i18n._(t`copy to clipboard`)}
                                trigger={
                                    <Icon
                                        style={{ cursor: "pointer", marginLeft: ".5rem" }}
                                        onClick={() => handleCopy(generateApiKey.data.key)}
                                        name="copy outline"
                                    />
                                }
                            />
                            <div style={{ marginTop: ".5rem", fontStyle: "italic" }}>
                                <Trans>IMPORTANT: Please keep a copy of your secret straight away. You won't be able to get it back!</Trans>
                            </div>
                        </Message>
                    </>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button type="button" negative onClick={() => setOpen(false)}>
                    <Trans>cancel</Trans>
                </Button>
                {can_change && generateApiKey.isSuccess && (
                    <Button onClick={handleClick} type="submit" positive icon labelPosition="right">
                        <Icon name="check" />
                        <Trans>validate</Trans>
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default AddExternalApiKey;
