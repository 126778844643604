import _ from "lodash";
import cookie from "react-cookies";
import moment from "moment";

import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";

export const usageApi = defaultApi.injectEndpoints({
    reducerPath: "apiUsage",
    endpoints: (build) => {
        return {
            getUsages: build.query({
                keepUnusedDataFor: 600,
                query: ({ org }) => {
                    const current_org = _.get(org, "name", null);
                    return `usages?org=${current_org}&${refreshTimestampUrlParam()}`;
                },
                providesTags: (result, error, arg) => {
                    if (result) return [{ type: "Usages", id: arg.org.id }];
                    return [];
                },
                transformResponse: (response) => {
                    return _.map(response, (usage) => {
                        return {
                            ...usage,
                            key: usage.id,
                            text: usage.name,
                            value: usage.id,
                            type: "usage"
                        };
                    });
                }
            }),
            addUsage: build.mutation({
                query: ({ org, data }) => {
                    const current_org = _.get(org, "name", null);
                    return {
                        url: `usages?org=${current_org}`,
                        method: "POST",
                        headers: {
                            "X-CSRFTOKEN": cookie.load("csrftoken")
                        },
                        credentials: "include",
                        body: data
                    };
                },
                invalidatesTags: (result, error, arg) => {
                    if (!error) {
                        localStorage.setItem("refresh_timestamp", moment().unix());
                        return [{ type: "Usages", id: arg.org.id }];
                    }
                    return [];
                }
            }),
            updateUsage: build.mutation({
                query: ({ org, data }) => {
                    const current_org = _.get(org, "name", null);
                    return {
                        url: `usages/${data.id}?org=${current_org}`,
                        method: "PUT",
                        headers: {
                            "X-CSRFTOKEN": cookie.load("csrftoken")
                        },
                        credentials: "include",
                        body: data
                    };
                },
                invalidatesTags: (result, error, arg) => {
                    if (!error) {
                        localStorage.setItem("refresh_timestamp", moment().unix());
                        return [
                            { type: "Usage", id: arg.data.id },
                            { type: "Usages", id: arg.org.id }
                        ];
                    }
                    return [];
                }
            }),
            deleteUsage: build.mutation({
                query: ({ org, data, confirm }) => {
                    const current_org = _.get(org, "name", null);
                    const url = `usages/${data}?org=${current_org}${confirm ? "&confirm=yes" : ""}&${refreshTimestampUrlParam()}`;
                    return {
                        url,
                        method: "DELETE",
                        headers: {
                            "X-CSRFTOKEN": cookie.load("csrftoken")
                        },
                        credentials: "include",
                        body: data
                    };
                },
                invalidatesTags: (result, error, arg) => {
                    if (!error && arg.confirm) {
                        localStorage.setItem("refresh_timestamp", moment().unix());
                        return [
                            { type: "Usage", id: arg.data },
                            { type: "Usages", id: arg.org.id }
                        ];
                    }
                    return [];
                }
            })
        };
    },
    overrideExisting: false
});

export const { useGetUsagesQuery, useAddUsageMutation, useUpdateUsageMutation, useDeleteUsageMutation } = usageApi;
