import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { useReactFlow } from "reactflow";
import { t, Trans } from "@lingui/macro";
import { Field, Form } from "react-final-form";
import { Icon, Button, Modal, Divider, Segment } from "semantic-ui-react";

import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { remapForReactFlow } from "modules/hierarchy/utils";
import { useAddNodeMutation } from "modules/hierarchy/hierarchyService";

import { DropDownAdapter, InputAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

/**
 * Used for form validation. If errors, you can't submit form
 * @function validate
 * @param {object} values
 */
const validate = (values) => {
    const errors = {};
    if (values.equipment === -1 && !values.name) {
        errors.name = <Trans>node name is required</Trans>;
    }
    return errors;
};

const AddNode = (props) => {
    const {
        openModal,
        setOpenModal,
        i18n,
        node: current_node,
        rangeTime: { start, end },
        org,
        eqptsOptions,
        setMenu
    } = props;

    const { setNodes, setEdges } = useReactFlow();
    const [addNode, add] = useAddNodeMutation();

    useEffect(() => {
        if (add.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (add.isSuccess) {
            toast.success(i18n._(t`successful add node`), toast_options);
        }
        if (add.isError) {
            let error = i18n._(t`cannot add node`);
            if (add.error?.data && !_.includes(add.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={add.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [add, i18n]);

    const onSubmitForm = async (formData) => {
        const added_data = {
            ...formData,
            equipment: _.includes([null, -1], formData.equipment) ? null : formData.equipment
        };
        const action = await addNode({
            org: org.current,
            data: added_data,
            diagram_id: current_node.diagram,
            start: start.format("YYYY-MM-DD"),
            end: end.clone().add(1, "day").format("YYYY-MM-DD")
        });
        const error = _.get(action, "error", null);
        if (!error) {
            const nodes_edges = _.reduce(
                action.data,
                (res, node) => {
                    const { node: remapNode, edge } = remapForReactFlow(node);
                    if (edge) {
                        res.edges.push(edge);
                    }
                    res.nodes.push(remapNode);

                    return res;
                },
                { nodes: [], edges: [] }
            );
            await setNodes((nds) => {
                const nodes_l = [...nds];
                _.each(nodes_edges.nodes, (node) => {
                    const old_node_index = _.findIndex(nodes_l, { id: node.id });
                    if (old_node_index === -1) {
                        nodes_l.push(node);
                    } else {
                        nodes_l[old_node_index] = {
                            ...nodes_l[old_node_index],
                            data: {
                                ...nodes_l[old_node_index].data,
                                node_db: node.data.node_db
                            }
                        };
                    }
                });
                return nodes_l;
            });
            await setEdges((eds) => {
                const edges_l = [...eds];
                _.each(nodes_edges.edges, (edge) => {
                    const old_edge_index = _.findIndex(edges_l, { id: edge.id });
                    if (old_edge_index === -1) {
                        edges_l.push(edge);
                    }
                });
                return edges_l;
            });

            await setOpenModal(false);
            await setMenu(null);
        }
    };

    const initialValues = useMemo(() => {
        return {
            parent: current_node.path,
            diagram: current_node.diagram,
            equipment: -1,
            position_x: current_node.position_x + 30,
            position_y: current_node.position_y + 90,
            style: {}
        };
    }, [current_node]);

    return (
        <Form
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit, submitting, pristine, invalid, form, values }) => {
                return (
                    <Modal
                        open={openModal}
                        centered={false}
                        closeOnDimmerClick
                        closeOnEscape
                        onClose={(e) => {
                            setOpenModal(false);
                        }}
                    >
                        <Modal.Header>
                            <Trans>Add node</Trans>
                        </Modal.Header>
                        <Modal.Content>
                            <form onSubmit={handleSubmit} className="ui form">
                                <Field name="name" placeholder={i18n._(t`enter name of node`)} label={i18n._(t`name`)} component={InputAdapter} />
                                <Field name="equipment" label={i18n._(t`equipment`)} options={eqptsOptions} component={DropDownAdapter} />
                                <Divider />
                                {values.equipment === -1 && !values.name && (
                                    <Segment basic textAlign="center">
                                        <MessageDisplay
                                            message={i18n._(t`Enter a name if you don't select equipment`)}
                                            level="info"
                                            iconName="info circle"
                                            isLoading={false}
                                            attached={false}
                                        />
                                    </Segment>
                                )}
                                <Segment attached basic textAlign="right">
                                    <Button
                                        type="button"
                                        negative
                                        icon
                                        labelPosition="left"
                                        onClick={(e) => {
                                            setOpenModal(false);
                                        }}
                                    >
                                        <Icon name="arrow left" />
                                        <Trans>cancel</Trans>
                                    </Button>
                                    <Button positive icon labelPosition="right" type="submit" disabled={submitting || pristine || invalid}>
                                        <Icon name="send" />
                                        <Trans>Confirm</Trans>
                                    </Button>
                                </Segment>
                            </form>
                        </Modal.Content>
                    </Modal>
                );
            }}
        />
    );
};

export default React.memo(AddNode);
