import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Trans, t } from "@lingui/macro";
import { toast } from "react-toastify";
import { Button, Divider, Dropdown, Icon, Modal, Segment } from "semantic-ui-react";
import { Field, Form } from "react-final-form";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { aggregate_options, comparison_options, historic_options } from "../utils";
import { useCreateAdvancedAnalysisMutation } from "../analysisAdvancedService";

import { DropDownAdapter, InputAdapter, ToggleAdapter } from "modules/common/components/form";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const AnalysisAdvancedCreate = (props) => {
    const { setSelectedAdvAnalysis } = props;
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    const [openModal, setOpenModal] = useState(false);
    const [createAdvancedAnalysis, create] = useCreateAdvancedAnalysisMutation();

    const org_id = _.get(org, "current.id", null);
    const user_id = _.get(auth, "user.user_id", null);

    useEffect(() => {
        if (create.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`successful create advanced_analysis`), toast_options);
            setSelectedAdvAnalysis(create.data.id);
        }
        if (create.isError) {
            let error = i18n._(t`cannot create advanced_analysis`);
            if (create?.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create?.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [create, setSelectedAdvAnalysis]);

    const onSubmitForm = async (data, form) => {
        if (notification.srv_status.db_status === "rw") {
            try {
                const action = await createAdvancedAnalysis({ org: org.current, data });
                const error = _.get(action, "error", null);
                if (!error) {
                    resetForm(form);
                    setOpenModal(false);
                }
            } catch (error) {}
        }
    };

    const resetForm = (form) => {
        form.reset();
        form.getRegisteredFields().forEach((field) => {
            form.resetFieldState(field);
        });
    };

    const initialValues = useMemo(() => {
        return {
            name: "",
            historic: historic_options[1].value, //exclude first choice 'yesterday'
            aggregation: aggregate_options[0].value,
            comparison: comparison_options[0].value,
            is_public: false,
            analysissection_set: [],
            organization: org_id,
            owner: user_id
        };
    }, [org_id, user_id]);

    return (
        <>
            <Form
                onSubmit={onSubmitForm}
                initialValues={initialValues}
                render={({ handleSubmit, submitting, pristine, invalid, form, values }) => {
                    const { aggregation, comparison } = values;

                    const filter_historic_options = _.chain(historic_options)
                        .filter((item) => item.value !== 0) //exclude first choice 'yesterday'
                        .reduce((res, item) => {
                            if (aggregation === 1) {
                                if (item.value === 7) return res;
                                if (comparison !== 0 && item.value === 6) return res;
                            } else if (aggregation === 2) {
                                //exclude choices lower than aggregation period
                                if (item.value < 4) return res;
                            } else if (aggregation === 3) {
                                if (item.value < 6) return res;
                            }
                            res.push(item);
                            return res;
                        }, [])
                        .value();

                    return (
                        <Modal
                            open={openModal}
                            centered={false}
                            closeOnDimmerClick
                            closeOnEscape
                            onClose={(e) => {
                                resetForm(form);
                                setOpenModal(false);
                            }}
                            trigger={
                                <Dropdown.Item
                                    onClick={(e) => {
                                        setOpenModal(true);
                                    }}
                                >
                                    <Icon name="add circle" />
                                    <Trans>Create new analysis</Trans>
                                </Dropdown.Item>
                            }
                        >
                            <Modal.Header>
                                <Trans>Advanced analysis creation</Trans>
                            </Modal.Header>
                            <Modal.Content>
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Field
                                        name="name"
                                        placeholder={i18n._(t`enter name of advanced analysis`)}
                                        label={i18n._(t`name`)}
                                        isRequired={true}
                                        component={InputAdapter}
                                        validate={(value) => {
                                            if (!value) {
                                                return <Trans>advanced analysis name is required</Trans>;
                                            }
                                            return undefined;
                                        }}
                                        onKeyDown={(e) => {
                                            //Hack to prevent parent dropdown tabspace interaction
                                            if (e.keyCode === 32) {
                                                e.stopPropagation();
                                            }
                                        }}
                                    />
                                    <Field
                                        name="historic"
                                        label={i18n._(t`historic`)}
                                        options={filter_historic_options}
                                        component={DropDownAdapter}
                                    />
                                    <Field
                                        name="aggregation"
                                        label={i18n._(t`aggregation`)}
                                        options={aggregate_options}
                                        component={DropDownAdapter}
                                        customAction={(data) => {
                                            //reset historic choice based on aggregate && comparison
                                            const historic = _.get(form.getFieldState("historic"), "value", 1);
                                            const comparison = _.get(form.getFieldState("comparison"), "value", 0);
                                            if (data === 1) {
                                                // day aggregation
                                                if (historic === 7 || (comparison !== 0 && historic === 6)) {
                                                    form.change("historic", 1);
                                                }
                                            } else if (data === 2) {
                                                //week aggregation
                                                if (historic < 4) form.change("historic", 4);
                                            } else if (data === 3) {
                                                //month aggregation
                                                if (historic < 6) form.change("historic", 6);
                                            }
                                        }}
                                        validate={(value) => {
                                            if (!value) return <Trans>aggregation is required</Trans>;
                                            return undefined;
                                        }}
                                    />
                                    <Field
                                        name="comparison"
                                        label={i18n._(t`comparison`)}
                                        options={comparison_options}
                                        component={DropDownAdapter}
                                        customAction={(data) => {
                                            if (data !== 0) {
                                                //reset historic choice based on aggregate && comparison
                                                const historic = _.get(form.getFieldState("historic"), "value", 1);
                                                const aggregation = _.get(form.getFieldState("aggregation"), "value", "day");
                                                if (aggregation === 1 && historic === 6) {
                                                    form.change("historic", 1);
                                                }
                                            }
                                        }}
                                    />
                                    <Field name="is_public" label={i18n._(t`is_public`)} labelposition="right" component={ToggleAdapter} />
                                    <Divider />
                                    <Segment attached basic textAlign="right">
                                        <Button
                                            type="button"
                                            negative
                                            icon
                                            labelPosition="left"
                                            onClick={(e) => {
                                                resetForm(form);
                                                setOpenModal(false);
                                            }}
                                        >
                                            <Icon name="arrow left" />
                                            <Trans>cancel</Trans>
                                        </Button>
                                        {notification.srv_status.db_status === "rw" && (
                                            <Button positive icon labelPosition="right" type="submit" disabled={submitting || pristine || invalid}>
                                                <Icon name="send" />
                                                <Trans>Confirm</Trans>
                                            </Button>
                                        )}
                                    </Segment>
                                </form>
                            </Modal.Content>
                        </Modal>
                    );
                }}
            />
        </>
    );
};

export default AnalysisAdvancedCreate;
