import React from "react";
import { compose } from "redux";
import tinycolor from "tinycolor2";
import PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Grid, Popup } from "semantic-ui-react";
import _ from "lodash";

const MIN_VALUE_HEIGHT = 18;
const MAX_VALUE_HEIGHT = 50;
const TITLE_LINE_HEIGHT = 1.5;

const getActiveThreshold = (value, thresholds) => {
    let active = thresholds[0];
    for (const threshold of thresholds) {
        if (value >= threshold.value) {
            active = threshold;
        } else {
            break;
        }
    }
    return active;
};
const isHex = (color) => {
    const hexRegex = /^((0x){0,1}|#{0,1})([0-9A-F]{8}|[0-9A-F]{6}|[0-9A-F]{3})$/gi;
    return hexRegex.test(color);
};

const getColorFromHexRgbOrName = (color) => {
    if (color.indexOf("rgb") > -1 || isHex(color)) {
        return color;
    }
    return new tinycolor(color).toHexString();
};

const textStyles = {
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis"
};

/**
 * Rewrite Bargauge component from grafana/ui (typescript to javascript)
 * check url : https://github.com/grafana/grafana/blob/master/packages/grafana-ui/src/components/BarGauge/BarGauge.tsx
 * @class BarGauge
 * @props {
        height: 40,
        width: 350,
        thresholds: [
            { value: 0, color: "red" },
            { value: 50, color: "green" },
            { value: 100, color: "orange" }
        ],
        value: {
            text: "120",
            numeric: 120
        },
        maxValue: 150,
        minValue: 0,
        orientation: "horizontal",
        displayMode: "lcd",
        itemSpacing: 1
    }
 */
class BarGauge extends React.Component {
    render() {
        const { title } = this.props.value;
        const styles = getTitleStyles(this.props);

        if (!title) {
            return this.renderBarAndValue();
        }

        return (
            <div style={styles.wrapper}>
                <div style={styles.title}>{title}</div>
                {this.renderBarAndValue()}
            </div>
        );
    }

    renderBarAndValue() {
        switch (this.props.displayMode) {
            case "lcd":
                return this.renderRetroBars();
            case "basic":
            case "gradient":
            default:
                return this.renderBasicAndGradientBars();
        }
    }

    renderBasicAndGradientBars = () => {
        const { value } = this.props;

        const styles = getBasicAndGradientStyles(this.props);

        return (
            <div style={styles.wrapper}>
                <div className="bar-gauge__value" style={styles.value}>
                    {value.text}
                </div>
                <div style={styles.bar} />
            </div>
        );
    };

    getCellColor = (positionValue) => {
        const { thresholds, value } = this.props;
        if (positionValue === null) {
            return {
                background: "gray",
                border: "gray"
            };
        }

        const activeThreshold = getActiveThreshold(positionValue, thresholds);
        if (activeThreshold !== null) {
            const color = getColorFromHexRgbOrName(activeThreshold.color);

            // if we are past real value the cell is not "on"
            if (value === null || (positionValue !== null && positionValue > value.numeric)) {
                return {
                    background: tinycolor(color).setAlpha(0.18).toRgbString(),
                    border: "transparent",
                    isLit: false
                };
            } else {
                return {
                    background: tinycolor(color).setAlpha(0.7).toRgbString(),
                    backgroundShade: tinycolor(color).setAlpha(0.1).toRgbString(),
                    border: tinycolor(color).setAlpha(0.7).toRgbString(),
                    isLit: true
                };
            }
        }

        return {
            background: "gray",
            border: "gray"
        };
    };

    renderRetroBars = () => {
        const { maxValue, minValue, value, itemSpacing, i18n } = this.props;
        const { valueHeight, valueWidth, maxBarHeight, maxBarWidth, wrapperWidth, wrapperHeight } = {
            valueWidth: 0,
            valueHeight: 0,
            maxBarHeight: this.props.height,
            maxBarWidth: this.props.width,
            wrapperHeight: this.props.height,
            wrapperWidth: this.props.width
        };
        /* calculateBarAndValueDimensions(props); */

        const isVert = isVertical(this.props);
        const valueRange = maxValue - minValue;
        const maxSize = isVert ? maxBarHeight : maxBarWidth;
        const cellSpacing = itemSpacing;
        // retro Bar cell width
        const cellWidth = 8;
        const cellCount = Math.floor(maxSize / cellWidth);
        const cellSize = Math.floor((maxSize - cellSpacing * cellCount) / cellCount);
        const valueColor = getValueColor(this.props);
        const valueStyles = getValueStyles(value.text, valueColor, valueWidth, valueHeight);
        valueStyles.padding = "0px";

        const containerStyles = {
            padding: "0px",
            width: `${wrapperWidth}px`,
            height: `${wrapperHeight}px`,
            display: "flex"
        };

        if (isVert) {
            containerStyles.flexDirection = "column-reverse";
            containerStyles.alignItems = "center";
        } else {
            containerStyles.flexDirection = "row";
            containerStyles.alignItems = "center";
        }

        const cells = [];

        for (let i = 0; i < cellCount; i++) {
            const currentValue = minValue + (valueRange / cellCount) * i;
            const cellColor = this.getCellColor(currentValue);
            const cellStyles = {
                borderRadius: "2px"
            };

            if (cellColor.isLit) {
                cellStyles.backgroundImage = `radial-gradient(${cellColor.background} 100%, ${cellColor.backgroundShade})`;
            } else {
                cellStyles.backgroundColor = cellColor.background;
            }

            if (isVert) {
                cellStyles.height = `${cellSize}px`;
                cellStyles.width = `${maxBarWidth}px`;
                cellStyles.marginTop = `${cellSpacing}px`;
            } else {
                cellStyles.width = `${cellSize}px`;
                cellStyles.height = "100%"; //`${maxBarHeight+20}px`;
                cellStyles.marginRight = `${cellSpacing}px`;
            }

            cells.push(<div key={i.toString()} style={cellStyles} />);
        }

        return (
            <Grid
                style={{
                    width: `${this.props.width}px`,
                    margin: "auto"
                }}
                stretched
            >
                {!this.props.popupTitle && (
                    <Grid.Column width={16} style={valueStyles} textAlign="center" verticalAlign="middle">
                        <h3 style={textStyles}>{value.text}</h3>
                    </Grid.Column>
                )}
                {this.props.popupTitle && (
                    <Popup
                        content={this.props.popupTitle}
                        position="top center"
                        trigger={
                            <Grid.Column width={16} style={valueStyles} textAlign="center" verticalAlign="middle">
                                <h3 style={textStyles}>{value.text}</h3>
                            </Grid.Column>
                        }
                    />
                )}
                <Grid.Column
                    width={8}
                    textAlign="left"
                    className="unpadded"
                    style={{
                        fontSize: "0.8em",
                        color: "grey",
                        lineHeight: "1em"
                    }}
                    verticalAlign="bottom"
                >
                    {i18n.number(minValue, {
                        maximumFractionDigits: 0
                    })}
                </Grid.Column>
                <Grid.Column
                    width={8}
                    textAlign="right"
                    className="unpadded"
                    style={{
                        fontSize: "0.8em",
                        color: "grey",
                        lineHeight: "1em"
                    }}
                    verticalAlign="bottom"
                >
                    {i18n.number(maxValue, {
                        maximumFractionDigits: 0
                    })}
                </Grid.Column>
                <Grid.Column width={16} style={containerStyles}>
                    {cells}
                </Grid.Column>
            </Grid>
        );
    };
}

BarGauge.propTypes = {
    i18n: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    thresholds: PropTypes.array.isRequired,
    orientation: PropTypes.string.isRequired,
    displayMode: PropTypes.string.isRequired,
    itemSpacing: PropTypes.number.isRequired
};

BarGauge.defaultProps = {
    orientation: "horizontal",
    displayMode: "lcd",
    itemSpacing: 1
};

function isVertical(props) {
    return props.orientation === "vertical";
}

function calculateTitleDimensions(props) {
    const { title } = props.value;
    const { height, width } = props;

    if (!title) {
        return { fontSize: 0, width: 0, height: 0, placement: "above" };
    }

    if (isVertical(props)) {
        return {
            fontSize: 14,
            width: width,
            height: 14 * TITLE_LINE_HEIGHT,
            placement: "below"
        };
    }

    // if height above 40 put text to above bar
    if (height > 40) {
        const maxTitleHeightRatio = 0.35;
        const titleHeight = Math.max(Math.min(height * maxTitleHeightRatio, MAX_VALUE_HEIGHT), 17);

        return {
            fontSize: titleHeight / TITLE_LINE_HEIGHT,
            width: 0,
            height: titleHeight,
            placement: "above"
        };
    }

    // title to left of bar scenario
    const maxTitleHeightRatio = 0.6;
    const maxTitleWidthRatio = 0.2;
    const titleHeight = Math.max(height * maxTitleHeightRatio, MIN_VALUE_HEIGHT);

    return {
        fontSize: titleHeight / TITLE_LINE_HEIGHT,
        height: 0,
        width: Math.min(Math.max(width * maxTitleWidthRatio, 50), 200),
        placement: "left"
    };
}

export function getTitleStyles(props) {
    const wrapperStyles = {
        display: "flex",
        overflow: "hidden"
    };

    const titleDim = calculateTitleDimensions(props);

    const titleStyles = {
        fontSize: `${titleDim.fontSize}px`,
        whiteSpace: "pre",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        alignItems: "center",
        alignSelf: "center"
    };

    if (isVertical(props)) {
        wrapperStyles.flexDirection = "column-reverse";
        titleStyles.textAlign = "center";
    } else {
        if (titleDim.placement === "above") {
            wrapperStyles.flexDirection = "column";
        } else {
            wrapperStyles.flexDirection = "row";

            titleStyles.width = `${titleDim.width}px`;
            titleStyles.textAlign = "right";
            titleStyles.paddingRight = "10px";
        }
    }

    return {
        wrapper: wrapperStyles,
        title: titleStyles
    };
}

export function getValuePercent(value, minValue, maxValue) {
    return Math.min((value - minValue) / (maxValue - minValue), 1);
}

/**
 * Only exported to for unit test
 */
export function getBasicAndGradientStyles(props) {
    const { displayMode, maxValue, minValue, value } = props;
    const { valueWidth, valueHeight, maxBarHeight, maxBarWidth } = {
        valueWidth: 0,
        valueHeight: 0,
        maxBarHeight: props.height,
        maxBarWidth: props.width
    };
    /* calculateBarAndValueDimensions(props); */

    const valuePercent = getValuePercent(value.numeric, minValue, maxValue);
    const valueColor = getValueColor(props);
    const valueStyles = getValueStyles(value.text, valueColor, valueWidth, valueHeight);
    const isBasic = displayMode === "basic";

    const wrapperStyles = {
        display: "flex"
    };

    const barStyles = {
        borderRadius: "3px"
    };

    if (isVertical(props)) {
        const barHeight = Math.max(valuePercent * maxBarHeight, 1);

        // vertical styles
        wrapperStyles.flexDirection = "column";
        wrapperStyles.justifyContent = "flex-end";

        barStyles.transition = "height 1s";
        barStyles.height = `${barHeight}px`;
        barStyles.width = `${maxBarWidth}px`;

        if (isBasic) {
            // Basic styles
            barStyles.background = `${tinycolor(valueColor).setAlpha(0.25).toRgbString()}`;
            barStyles.borderTop = `2px solid ${valueColor}`;
        } else {
            // Gradient styles
            barStyles.background = getBarGradient(props, maxBarHeight);
        }
    } else {
        const barWidth = Math.max(valuePercent * maxBarWidth, 1);

        // Custom styles for horizontal orientation
        wrapperStyles.flexDirection = "row-reverse";
        wrapperStyles.justifyContent = "flex-end";
        wrapperStyles.alignItems = "center";

        barStyles.transition = "width 1s";
        barStyles.height = `${maxBarHeight}px`;
        barStyles.width = `${barWidth}px`;

        valueStyles.paddingLeft = "10px";

        if (isBasic) {
            // Basic styles
            barStyles.background = `${tinycolor(valueColor).setAlpha(0.25).toRgbString()}`;
            barStyles.borderRight = `2px solid ${valueColor}`;
        } else {
            // Gradient styles
            barStyles.background = getBarGradient(props, maxBarWidth);
        }
    }

    return {
        wrapper: wrapperStyles,
        bar: barStyles,
        value: valueStyles
    };
}

/**
 * Only exported to for unit test
 */
export function getBarGradient(props, maxSize) {
    const { minValue, maxValue, thresholds, value } = props;
    const cssDirection = isVertical(props) ? "0deg" : "90deg";

    let gradient = "";
    let lastpos = 0;

    for (let i = 0; i < thresholds.length; i++) {
        const threshold = thresholds[i];
        const color = getColorFromHexRgbOrName(threshold.color);
        const valuePercent = getValuePercent(threshold.value, minValue, maxValue);
        const pos = valuePercent * maxSize;
        const offset = Math.round(pos - (pos - lastpos) / 2);

        if (gradient === "") {
            gradient = `linear-gradient(${cssDirection}, ${color}, ${color}`;
        } else if (value.numeric < threshold.value) {
            break;
        } else {
            lastpos = pos;
            gradient += ` ${offset}px, ${color}`;
        }
    }

    return gradient + ")";
}

/**
 * Only exported to for unit test
 */
export function getValueColor(props) {
    const { thresholds, value } = props;

    if (!_.isFinite(value.numeric)) {
        return getColorFromHexRgbOrName("red");
    }

    const activeThreshold = getActiveThreshold(value.numeric, thresholds);

    if (activeThreshold !== null) {
        return getColorFromHexRgbOrName(activeThreshold.color);
    }

    return getColorFromHexRgbOrName("gray");
}

/**
 * Only exported to for unit test
 * Used for display value near BarGauge
 */
function getValueStyles(value, color, width, height) {
    return {
        color: color
    };
}

export default compose(withI18n())(BarGauge);
