import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import { Trans, t } from "@lingui/macro";
import { Segment, Header, Button, Icon, Table } from "semantic-ui-react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { reparseNumber } from "modules/common/utils";
import { keyToReparseInPack } from "modules/alert/alert_pack/utils";
import { useSetAlertPackOverrideMutation } from "modules/alert/alert_pack/alertPackService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import ElecDysfunctionModal from "./ElecDysfunctionModal";
import EditException from "./EditException";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

export const ElecDysfunctionOverride = (props) => {
    const org = useSelector((state) => state.org);

    const { pack, rights, overrides, notification } = props;

    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const [setAlertPackOverride, seted] = useSetAlertPackOverrideMutation();

    useEffect(() => {
        if (seted.isSuccess) {
            toast.success(i18n._(t`successful update alertpack override`), toast_options);
        }
        if (seted.isError) {
            let error = i18n._(t`cannot update alert pack override`);
            if (seted?.error?.data && !_.includes(seted.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={seted?.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [seted]);

    const filter_df_site = useMemo(() => {
        if (dataflows.isSuccess) {
            return (
                _.chain(dataflows)
                    //elec expert dataflow
                    .filter((df) => df?.dataflowspec_tech === 12) //remove elec_expert
                    //on specific pack site
                    .filter((df) => _.get(df, "site") === pack.site)
                    //add extra key for semantic usage
                    .map((df) => ({ ...df, key: df.id, value: df.id, text: df.name }))
                    .value()
            );
        }
        return [];
    }, [dataflows, pack]);

    const onSubmitForm = async (data) => {
        if (notification.srv_status.db_status === "rw") {
            let formdata = data;
            if (_.isEmpty(data)) {
                //trick to prevent empty formData cause by fields.remove(index) in FieldArray
                formdata = { dataflows_exception: [] };
            }
            formdata = {
                ...formdata,
                dataflows_exception: _.map(formdata.dataflows_exception, (exception) => {
                    return {
                        ...exception,
                        override: _.mapValues(exception?.override, (item, key) => {
                            if (_.includes(keyToReparseInPack, key)) {
                                return reparseNumber(item);
                            }
                            return item;
                        })
                    };
                })
            };
            await setAlertPackOverride({
                org: org.current,
                formdata,
                pack_id: pack.id,
                type: ["dataflows_exception"]
            });
        }
    };

    const renderAlarmIcon = (value) => (value ? <Icon name="alarm" color="green" /> : <Icon name="alarm mute" color="red" />);

    const initial = useMemo(() => {
        return {
            dataflows_exception: _.reduce(
                overrides,
                (res, item) => {
                    const dataflow = _.find(filter_df_site, { id: item.object_id });
                    if (dataflow?.name) {
                        res.push({ ...item, name: dataflow?.name });
                    }
                    return res;
                },
                []
            )
        };
    }, [overrides, filter_df_site]);

    return (
        <Segment.Group>
            <Header as="h4" attached="top" block textAlign="center">
                <Trans>dataflows exception</Trans>
            </Header>
            <Segment attached>
                <Form
                    onSubmit={onSubmitForm}
                    mutators={{ ...arrayMutators }}
                    initialValues={initial}
                    render={({
                        handleSubmit,
                        form: {
                            mutators: { push, update }
                        },
                        submitting,
                        values,
                        invalid
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} className="ui form">
                                {notification.srv_status.db_status === "rw" && _.includes(rights, "can_change") && (
                                    <Segment basic>
                                        <ElecDysfunctionModal
                                            push={push}
                                            pack={pack}
                                            dataflows_exception={_.get(values, "dataflows_exception")}
                                            dataflows={filter_df_site}
                                        />
                                    </Segment>
                                )}
                                {_.size(values.dataflows_exception) === 0 && (
                                    <MessageDisplay
                                        message={i18n._(t`no dataflows exception`)}
                                        level="info"
                                        iconName="warning circle"
                                        isLoading={false}
                                    />
                                )}
                                {_.size(values.dataflows_exception) > 0 && (
                                    <Segment basic style={{ overflowX: "auto" }}>
                                        <Table striped celled unstackable compact structured>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell rowSpan="2">
                                                        <Trans>name</Trans>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell colSpan="2">
                                                        <Trans>thd</Trans>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell colSpan="2">
                                                        <Trans>power_imbalance</Trans>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell colSpan="2" collapsing>
                                                        <Trans>cycling</Trans>
                                                    </Table.HeaderCell>
                                                    {notification.srv_status.db_status === "rw" && (
                                                        <Table.HeaderCell collapsing rowSpan="2" textAlign="center">
                                                            <Trans>actions</Trans>
                                                        </Table.HeaderCell>
                                                    )}
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.HeaderCell>
                                                        <Trans>threshold</Trans> (A)
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        <Trans>duration</Trans> (h)
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        <Trans>threshold</Trans> (%)
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        <Trans>duration</Trans> (h)
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        <Trans>active</Trans>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        <Trans>cycling number</Trans>
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <FieldArray name="dataflows_exception">
                                                    {(df_exceptions_field) =>
                                                        df_exceptions_field.fields.map((name, index) => {
                                                            const df_exception = df_exceptions_field.fields.value[index];
                                                            return (
                                                                <Table.Row key={name}>
                                                                    <Table.Cell>{_.get(df_exception, "name", "-")}</Table.Cell>
                                                                    <Table.Cell textAlign="center">
                                                                        {_.get(df_exception, "override.thd_threshold", "-")}
                                                                    </Table.Cell>
                                                                    <Table.Cell textAlign="center">
                                                                        {_.get(df_exception, "override.thd_duration", "-")}
                                                                    </Table.Cell>
                                                                    <Table.Cell textAlign="center">
                                                                        {_.get(df_exception, "override.power_imbalance_threshold", "-")}
                                                                    </Table.Cell>
                                                                    <Table.Cell textAlign="center">
                                                                        {_.get(df_exception, "override.power_imbalance_duration", "-")}
                                                                    </Table.Cell>
                                                                    <Table.Cell textAlign="center">
                                                                        {renderAlarmIcon(_.get(df_exception, "override.cycling_active"))}
                                                                    </Table.Cell>
                                                                    <Table.Cell textAlign="center">
                                                                        {_.get(df_exception, "override.cycling", "N/C")}
                                                                    </Table.Cell>
                                                                    {notification.srv_status.db_status === "rw" && (
                                                                        <Table.Cell>
                                                                            <Button.Group size="small">
                                                                                {_.includes(rights, "can_change") && (
                                                                                    <EditException idx={index} item={df_exception} update={update} />
                                                                                )}
                                                                                {_.includes(rights, "can_delete") && (
                                                                                    <Button
                                                                                        type="button"
                                                                                        icon="trash"
                                                                                        color="red"
                                                                                        onClick={() => df_exceptions_field.fields.remove(index)}
                                                                                    />
                                                                                )}
                                                                            </Button.Group>
                                                                        </Table.Cell>
                                                                    )}
                                                                </Table.Row>
                                                            );
                                                        })
                                                    }
                                                </FieldArray>
                                            </Table.Body>
                                        </Table>
                                    </Segment>
                                )}
                                {notification.srv_status.db_status === "rw" && _.includes(rights, "can_change") && (
                                    <Segment basic>
                                        <Button type="submit" content={i18n._(t`validate`)} positive disabled={submitting || invalid} />
                                    </Segment>
                                )}
                            </form>
                        );
                    }}
                />
            </Segment>
        </Segment.Group>
    );
};

export default React.memo(ElecDysfunctionOverride);
