import React, { useMemo } from "react";
import { t, Trans } from "@lingui/macro";
import { Field } from "react-final-form";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Icon, Grid, Segment, Header } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { InputAdapter, DropDownAdapter, TextAreaAdapter, ToggleAdapter } from "modules/common/components/form";
import { identity, identityNull } from "modules/common/utils/form";
import { frequency_list, field_identification_list, enedisParams, grtgazParams, importTypes, timestampFilterOptions } from "modules/import/utils";
import { reparseNumber, timeZone_list, validateNumber } from "modules/common/utils";
import { daysOptions } from "modules/export/utils";

const ConfigDetailsForm = (props) => {
    const current_lng = useSelector((state) => state.i18n.current);
    const { can_change, values, form, files, id_file } = props;

    const tz_list = useMemo(
        () => _.map(timeZone_list, ({ key, text, value }) => ({ key, value, text: i18n._(text) })),
        // eslint-disable-next-line
        [current_lng]
    );

    return (
        <>
            <Header as="h3" attached="top" block textAlign="center" className="pwaNoStretched">
                <Trans>Data import details</Trans>
            </Header>
            <Segment attached>
                <Grid stackable verticalAlign="top">
                    <Grid.Column width={16}>
                        <Field
                            name="is_active"
                            label={i18n._(t`is_active`)}
                            labelposition="right"
                            parse={identity}
                            component={ToggleAdapter}
                            disabled={!can_change}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Field
                            name="import_type"
                            placeholder={i18n._(t`import_type`)}
                            label={i18n._(t`import_type`)}
                            isRequired={true}
                            component={DropDownAdapter}
                            search={false}
                            options={importTypes}
                            disabled={!can_change || id_file !== undefined}
                            validate={(value) => {
                                if (!_.isFinite(value)) {
                                    return <Trans>Required field</Trans>;
                                }
                                return undefined;
                            }}
                            customAction={(data) => {
                                if (data === 1) {
                                    _.forEach(enedisParams, (item, key) => {
                                        form.change(key, item);
                                    });
                                    form.change(
                                        "new_fields",
                                        _.map(values?.new_fields, (field) => {
                                            //force elec && p_act_import for each column in array
                                            return {
                                                ...field,
                                                dataflowspec: 1,
                                                measurementtype: 9
                                            };
                                        })
                                    );
                                } else if (data === 2) {
                                    _.forEach(grtgazParams, (item, key) => {
                                        form.change(key, item);
                                    });
                                    form.change(
                                        "new_fields",
                                        _.map(values?.new_fields, (field) => {
                                            //force gaz && p_act_import
                                            return {
                                                ...field,
                                                dataflowspec: 3,
                                                measurementtype: 9
                                            };
                                        })
                                    );
                                }
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Field
                            name="name"
                            placeholder={i18n._(t`Enter name of import`)}
                            label={i18n._(t`name`)}
                            isRequired={true}
                            parse={identity}
                            component={InputAdapter}
                            unitposition="left"
                            unit={<Icon name="file text" />}
                            unitcolor="secondary"
                            disabled={!can_change}
                            validate={(value) => {
                                const existing_name = _.chain(files.data)
                                    .filter((file) => {
                                        return file.id !== parseInt(id_file);
                                    })
                                    .find({ name: value })
                                    .value();

                                if (existing_name) {
                                    return <Trans>an import with this name exists</Trans>;
                                }
                                if (!value) {
                                    return <Trans>Required field</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Field
                            name="description"
                            placeholder={i18n._(t`Enter description`)}
                            label={i18n._(t`description`)}
                            parse={identity}
                            rows={4}
                            component={TextAreaAdapter}
                            disabled={!can_change}
                        />
                    </Grid.Column>
                    <Grid.Column style={{ marginTop: "1.5rem" }} width={16}>
                        <Header as="h3" block textAlign="center" className="pwaNoStretched">
                            <Trans>Field and timestamp</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Field
                            label={i18n._(t`field_identification`)}
                            component={DropDownAdapter}
                            name="field_identification"
                            placeholder={i18n._(t`select a field_identification`)}
                            search={false}
                            compact
                            fluid
                            selection
                            options={field_identification_list}
                            isRequired={true}
                            disabled={!can_change || _.includes([1, 2], values.import_type)}
                            customAction={(value) => {
                                if (value === 2) {
                                    form.change("has_header", true);
                                }
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <Field
                            name="has_header"
                            label={i18n._(t`has_header`)}
                            labelposition="right"
                            parse={identity}
                            component={ToggleAdapter}
                            disabled={!can_change || _.includes([1, 2], values.import_type) || values.field_identification === 2}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Field
                            helperText={i18n._(
                                t`Timezone of the timestamp column. Timezone is applied if datetime_format is not empty or if the timezone could not be determined automatically`
                            )}
                            label={i18n._(t`Timezone`)}
                            component={DropDownAdapter}
                            name="timezone"
                            placeholder={i18n._(t`select a timezone`)}
                            compact
                            fluid
                            search
                            selection
                            noResultsMessage={i18n._(t`no result found`)}
                            options={tz_list}
                            isRequired={true}
                            disabled={!can_change || _.includes([1, 2], values.import_type)}
                            validate={(value) => {
                                if (!value) {
                                    return <Trans>Required field</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Field
                            helperText={i18n._(
                                t`The timestamp column parameter can be either a number or a string, depending on your choice of field identification`
                            )}
                            name="timestamp_column"
                            placeholder={i18n._(t`timestamp_column`)}
                            label={i18n._(t`timestamp_column`)}
                            isRequired={true}
                            parse={values.field_identification === 1 ? identityNull : identity}
                            inputMode={values.field_identification === 1 ? "numeric" : "text"}
                            component={InputAdapter}
                            unitposition="left"
                            unit={<Icon name="columns" />}
                            unitcolor="secondary"
                            disabled={!can_change || _.includes([1, 2], values.import_type)}
                            validate={(value, allValues) => {
                                if (allValues.field_identification === 1) {
                                    const tmstValidate = validateNumber(value, i18n, false, false, true);
                                    if (tmstValidate !== undefined) {
                                        return tmstValidate;
                                    }
                                    if (reparseNumber(value) < 1) {
                                        return <Trans>Minimum value is 1</Trans>;
                                    }
                                }
                                if (!value) {
                                    return <Trans>Required field</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Field
                            helperText={i18n._(
                                t`Timestamp column format (optional): If empty, the timestamp is assumed to be in ISO format.Example: %Y-%m-%d %H:%M:%S`
                            )}
                            name="datetime_format"
                            placeholder={i18n._(t`datetime_format`)}
                            label={i18n._(t`datetime_format`)}
                            parse={identity}
                            component={InputAdapter}
                            unitposition="left"
                            unit={<Icon name="calendar times" />}
                            unitcolor="secondary"
                            disabled={!can_change || _.includes([1, 2], values.import_type)}
                        />
                    </Grid.Column>
                    {!_.includes([1, 2], values.import_type) && (
                        <Grid.Column width={16}>
                            <Field
                                name="timestamp_filter"
                                helperText={
                                    <p>
                                        <Trans>
                                            In case the import contains more than 2 data per 10 minutes, the timestamp filter must be selected to only
                                            keep data at h+00min, h+10min, h+20min, h+30min, h+40min , h+50min.
                                        </Trans>
                                    </p>
                                }
                                label={i18n._(t`Timestamp filter`)}
                                placeholder={i18n._(t`timestamp_filter placeholder`)}
                                options={timestampFilterOptions}
                                component={DropDownAdapter}
                                disabled={!can_change}
                                validate={(value) => {
                                    if (!_.includes([0, 1], value)) {
                                        return <Trans>Unknown timestamp filter</Trans>;
                                    }
                                    return undefined;
                                }}
                            />
                        </Grid.Column>
                    )}
                    <Grid.Column style={{ marginTop: "1.5rem" }} width={16}>
                        <Header as="h3" block textAlign="center" className="pwaNoStretched">
                            <Trans>File transfer schedule</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Field
                            helperText={i18n._(t`Allows you to define how often the data is transferred to the server`)}
                            label={i18n._(t`frequency`)}
                            component={DropDownAdapter}
                            name="frequency"
                            placeholder={i18n._(t`select a frequency`)}
                            search={false}
                            compact
                            fluid
                            selection
                            options={frequency_list}
                            isRequired={true}
                            disabled={!can_change}
                            customAction={(value) => {
                                if (value === 0) {
                                    form.change("dayofmonth", null);
                                    form.change("dayofweek", null);
                                }
                                if (value === 1) {
                                    form.change("dayofmonth", null);
                                }
                                if (value === 2) {
                                    form.change("dayofweek", null);
                                }
                                if (value === 3) {
                                    form.change("dayofmonth", null);
                                    form.change("hour", null);
                                    form.change("dayofweek", null);
                                }
                                if (value === 4) {
                                    form.change("dayofmonth", null);
                                    form.change("dayofweek", null);
                                }
                            }}
                        />
                    </Grid.Column>
                    {values.frequency !== 3 && (
                        <Grid.Column width={16}>
                            <Field
                                name="hour"
                                placeholder={i18n._(t`Put hour value here`)}
                                label={`${values.frequency === 4 ? i18n._(t`hour (2nd reading 12 hours later)`) : i18n._(t`hour`)} (UTC)`}
                                isRequired={true}
                                parse={identityNull}
                                inputMode="numeric"
                                component={InputAdapter}
                                unitposition="right"
                                unit="h"
                                unitcolor="secondary"
                                disabled={!can_change}
                                validate={(value) => {
                                    const hourValidate = validateNumber(value, i18n, false, false, true);
                                    if (hourValidate !== undefined) {
                                        return hourValidate;
                                    }
                                    const hour = reparseNumber(value);
                                    if (hour > 23) {
                                        return <Trans>Value must be between 0 to 23</Trans>;
                                    }
                                    return undefined;
                                }}
                            />
                        </Grid.Column>
                    )}
                    <Grid.Column width={16}>
                        <Field
                            name="minute"
                            placeholder={i18n._(t`Put minute value here`)}
                            label={i18n._(t`Minute`)}
                            isRequired={true}
                            parse={identityNull}
                            inputMode="numeric"
                            component={InputAdapter}
                            unitposition="right"
                            unit="m"
                            unitcolor="secondary"
                            disabled={!can_change}
                            validate={(value) => {
                                const minuteValidate = validateNumber(value, i18n, false, false, true);
                                if (minuteValidate !== undefined) {
                                    return minuteValidate;
                                }
                                const min = reparseNumber(value);
                                if (min > 59) {
                                    return <Trans>Value must be between 0 to 59</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    {values.frequency === 2 && (
                        <Grid.Column width={16}>
                            <Field
                                name="dayofmonth"
                                placeholder={i18n._(t`dayofmonth`)}
                                label={i18n._(t`dayofmonth`)}
                                isRequired={true}
                                parse={identityNull}
                                inputMode="numeric"
                                component={InputAdapter}
                                unitposition="right"
                                unit={<Icon name="calendar alternate" />}
                                unitcolor="secondary"
                                disabled={!can_change}
                                validate={(value) => {
                                    const monthValidate = validateNumber(value, i18n, false, false, true);
                                    if (monthValidate !== undefined) {
                                        return monthValidate;
                                    }
                                    const month = reparseNumber(value);
                                    if (month < 1 || month > 31) {
                                        return <Trans>Value must be between 1 to 31</Trans>;
                                    }
                                    return undefined;
                                }}
                            />
                        </Grid.Column>
                    )}
                    {values.frequency === 1 && (
                        <Grid.Column width={16}>
                            <Field
                                name="dayofweek"
                                label={i18n._(t`dayofweek`)}
                                placeholder={i18n._(t`choose the day of the week`)}
                                options={daysOptions}
                                component={DropDownAdapter}
                                disabled={!can_change}
                                validate={(value) => {
                                    const weekValidate = validateNumber(value, i18n, false, false, true);
                                    if (weekValidate !== undefined) {
                                        return weekValidate;
                                    }
                                    const week = reparseNumber(value);
                                    if (week < 0 || week > 6) {
                                        return <Trans>Value must be between 0 to 6</Trans>;
                                    }
                                    return undefined;
                                }}
                            />
                        </Grid.Column>
                    )}
                </Grid>
            </Segment>
        </>
    );
};

export default React.memo(ConfigDetailsForm);
