import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { Trans, t } from "@lingui/macro";
import { Modal, Button, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteAnalysisSectionMutation } from "../analysisAdvancedService";

import RequestErrorRender from "modules/common/components/RequestErrorRender";

const AnalysisSectionDelete = (props) => {
    const { analysis_section, org, notification } = props;
    const [open, setOpen] = useState(false);

    const [deleteAnalysisSection, remove] = useDeleteAnalysisSectionMutation();

    useEffect(() => {
        if (remove.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (remove.isSuccess) {
            toast.success(i18n._(t`successful remove analysis section`), toast_options);
        }
        if (remove.isError) {
            let error = i18n._(t`cannot remove analysis section`);
            if (remove?.error?.data && !_.includes(remove.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={remove?.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [remove]);

    return (
        <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} trigger={<Button icon="trash" />}>
            <Modal.Header>
                <Trans>delete</Trans>
            </Modal.Header>
            <Modal.Content>
                <Trans>want delete analysis section {analysis_section.name}</Trans>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                    <Trans>cancel</Trans>
                </Button>
                {notification.srv_status.db_status === "rw" && (
                    <Button
                        labelPosition="right"
                        icon
                        positive
                        onClick={async (e) => {
                            await setOpen(false);
                            await deleteAnalysisSection({ analysis_section_id: analysis_section.id, org });
                        }}
                    >
                        <Trans>validate</Trans>
                        <Icon name="trash" inverted />
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default React.memo(AnalysisSectionDelete);
