import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import _ from "lodash";
import moment from "moment-timezone";
import { Grid, Segment, Image } from "semantic-ui-react";
import { t } from "@lingui/macro";

import logo from "../../../images/logo-full.svg";
import i18n from "modules/i18n/i18nConfig";

import MessageDisplay from "modules/common/components/MessageDisplay";
import { useGetReportQuery } from "../reportService";

const Cover = (props) => {
    const now = moment();
    const { id } = useParams();
    const org = useSelector((state) => state.org);
    const current_lng = useSelector((state) => state.i18n.current);
    const report = useGetReportQuery({ org: org.current, report_id: parseInt(id) }, { skip: !org.current || !id });

    const query = new URLSearchParams(props?.location?.search);
    const urlDate = query.get("date") ? moment(query.get("date")) : null;

    if (report.isError) {
        return (
            <Segment textAlign="center">
                <MessageDisplay message={i18n._(t`cover loading error`)} level="error" iconName="warning circle" isLoading={false} attached={false} />
            </Segment>
        );
    } else if (report.isSuccess) {
        return (
            <div className="cover-print">
                <Grid textAlign="center" verticalAlign="middle" centered stretched>
                    <Grid.Row style={{ marginTop: "4cm" }}>
                        <Grid.Column width={14}>
                            <Image src={logo} size="medium" centered />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ marginTop: "4cm" }}>
                        <Grid.Column width={14} textAlign="center">
                            <h1>{`${i18n._(t`report`)} : ${_.get(report, "data.name", "-")}`}</h1>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ marginTop: "4cm" }}>
                        <Grid.Column width={14} textAlign="center">
                            {urlDate !== null && (
                                <h1 className="wait_for_puppeteer">{`${i18n._(t`Generation date`)} : ${urlDate
                                    .locale(current_lng)
                                    .format("LL")}`}</h1>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ marginTop: "5cm" }}>
                        <Grid.Column width={14} textAlign="center">
                            <h3 className="wait_for_puppeteer">{`${i18n._(t`Report created on`)} ${now.locale(current_lng).format("LL")}`}</h3>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    } else {
        return <MessageDisplay message={i18n._(t`load report`)} level="info" iconName="circle notched" isLoading={true} />;
    }
};

export default Cover;
