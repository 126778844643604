import React from "react";
import { Grid } from "semantic-ui-react";
import DropdownMeasurement from "./DropdownMeasurement";

const MeasureItem = (props) => {
    const { idx, node, formula, input, values, measurementsObject } = props;
    return (
        <Grid verticalAlign="middle">
            <Grid.Column width={16}>
                <DropdownMeasurement
                    idx={idx}
                    node={node}
                    formula={formula}
                    input={input}
                    values={values}
                    measurementsObject={measurementsObject}
                    disable_category={true}
                    disable_mttype={true}
                />
            </Grid.Column>
        </Grid>
    );
};

export default React.memo(MeasureItem);
