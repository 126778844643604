import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { FlexibleXYPlot, HorizontalGridLines, LineSeries, VerticalBarSeries, XAxis, YAxis, Hint } from "react-vis";
import { t } from "@lingui/macro";
import { Header, Segment, Table } from "semantic-ui-react";

import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";
import GenerateCsv from "modules/common/components/GenerateCsv";
import MessageDisplay from "modules/common/components/MessageDisplay";

const GraphicConsumptions = (props) => {
    const { savedConsumption, site } = props;
    const current_lng = useSelector((state) => state.i18n.current);
    const [hintValue, setHintValue] = useState(null);

    const series = useMemo(() => {
        let series = [];
        if (savedConsumption?.graphic?.c_tracking) {
            series.push({
                ...savedConsumption?.graphic?.c_tracking,
                title: i18n._(savedConsumption?.graphic?.c_tracking?.title)
            });
        }
        if (savedConsumption?.graphic?.c_adjust) {
            series.push({
                ...savedConsumption?.graphic?.c_adjust,
                title: i18n._(savedConsumption?.graphic?.c_adjust?.title)
            });
        }
        return series;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_lng, savedConsumption]);

    const emptyData = _.chain(series)
        .map((serie) => {
            return _.chain(serie)
                .get("data")
                .filter((record) => record.y !== null)
                .isEmpty()
                .value();
        })
        .every()
        .value();

    const maxY = _.chain(series)
        .reduce((res, serie) => {
            _.each(serie.data, (item) => {
                res.push(item);
            });
            return res;
        }, [])
        .maxBy("y")
        .get("y")
        .value();

    const minY = _.chain(series)
        .reduce((res, serie) => {
            _.each(serie.data, (item) => {
                res.push(item);
            });
            return res;
        }, [])
        .minBy("y")
        .get("y")
        .value();

    // Use data in series instead of time panel to define xLine Axis
    // API always return data on each time
    const firstX = _.chain(series).find({ name: "c_tracking" }).get("data").head().get("x").value();
    const lastX = _.chain(series).find({ name: "c_tracking" }).get("data").last().get("x").value();
    const xDomain = firstX && lastX ? [firstX, lastX] : null;

    const unit = _.get(savedConsumption, "unit_consumption", "-");
    const unit_currency = site?.conversions?.currency ?? "";
    const performance_text = `${i18n._(t`Performance gain over the period:`)} ${
        !_.isFinite(savedConsumption?.sum_c_eco) ? "-" : i18n.number(savedConsumption?.sum_c_eco, { maximumFractionDigits: 2 })
    } ${unit}`;
    const performance_currency_text = !_.isFinite(savedConsumption?.sum_eco_currency)
        ? ""
        : `(${i18n.number(savedConsumption?.sum_eco_currency, { maximumFractionDigits: 2 })} ${unit_currency})`;
    const performance_percent_text = !_.isFinite(savedConsumption?.sum_eco_percent)
        ? ""
        : `(${i18n.number(savedConsumption?.sum_eco_percent, { maximumFractionDigits: 2 })} %)`;

    const c_tracking = _.chain(series).find({ name: "c_tracking" }).defaultTo(null).value();
    const c_adjust = _.chain(series).find({ name: "c_adjust" }).defaultTo(null).value();

    const tooltip = () => {
        return (
            <Hint value={hintValue}>
                <Table compact celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan={2} textAlign="center">
                                {moment(hintValue.x).locale(current_lng).format("L")}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{`${i18n._(t`c_tracking`)} (${unit})`}</Table.HeaderCell>
                            <Table.HeaderCell>{`${i18n._(t`c_adjust`)} (${unit})`}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                {_.isFinite(hintValue?.c_tracking) ? i18n.number(hintValue?.c_tracking, { maximumFractionDigits: 2 }) : "-"}
                            </Table.Cell>
                            <Table.Cell>
                                {_.isFinite(hintValue?.c_adjust) ? i18n.number(hintValue?.c_adjust, { maximumFractionDigits: 2 }) : "-"}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Hint>
        );
    };

    return (
        <>
            <Header
                as="h3"
                block
                textAlign="center"
                attached="top"
            >{`${performance_text} ${performance_currency_text} ${performance_percent_text}`}</Header>
            <Segment attached textAlign="right">
                <GenerateCsv series={series} unit={unit} filename={i18n._("ape export")} disabled={emptyData} />
            </Segment>
            {emptyData && <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />}
            {!emptyData && (
                <Segment attached>
                    <FlexibleXYPlot
                        xType="time"
                        xDomain={xDomain}
                        yDomain={[Math.min(0, minY), Math.max(5, maxY)]}
                        height={400}
                        margin={{ left: 60, right: 10, top: 10, bottom: 50 }}
                    >
                        <HorizontalGridLines />
                        <VerticalBarSeries
                            color={c_tracking?.color}
                            fill={c_tracking?.color}
                            getNull={(d) => d.y !== null}
                            data={c_tracking?.data}
                            onValueMouseOver={(hintValue) => {
                                // hintValue => {x: tmst, y:YY}
                                const adjust = _.chain(series).find({ name: "c_adjust" }).get("data", []).find({ x: hintValue.x }).value();
                                const tooltip_value = {
                                    x: hintValue.x,
                                    y: hintValue.y, //y value is position of tooltip box in graphic
                                    c_tracking: hintValue.y,
                                    c_adjust: adjust?.y ?? null
                                };
                                setHintValue(tooltip_value);
                            }}
                            onValueMouseOut={() => {
                                setHintValue(null);
                            }}
                        />
                        <LineSeries
                            curve={"curveStep"}
                            strokeDasharray={"7,4"}
                            getNull={(d) => d.y !== null}
                            color={c_adjust?.color}
                            data={c_adjust?.data}
                        />
                        {hintValue ? tooltip() : null}
                        <XAxis
                            title={i18n._(t`time`)}
                            tickLabelAngle={-20}
                            tickFormat={(value, index, scale, tickTotal) => {
                                const default_fmt = multiI18nFormat(value, current_lng);
                                if (default_fmt.indexOf("06:00") >= 0 || default_fmt.indexOf("12:00") >= 0 || default_fmt.indexOf("18:00") >= 0)
                                    return "";
                                return default_fmt;
                            }}
                        />
                        <YAxis
                            title={unit}
                            tickFormat={(value, index, scale, tickTotal) => {
                                const format = scale.tickFormat(tickTotal)(value);
                                if (typeof value === "number") {
                                    return i18n.number(value, { maximumFractionDigits: 1 });
                                }
                                return format;
                            }}
                        />
                    </FlexibleXYPlot>
                </Segment>
            )}
        </>
    );
};

export default GraphicConsumptions;
