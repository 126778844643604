import { authApi } from "modules/auth/authService";

export const commonMiddleware = (store) => (next) => (action) => {
    if (action.type.endsWith("rejected")) {
        // Check if the response status is 403
        if (action.payload && (action.payload?.originalStatus === 403 || action.payload?.status === 403)) {
            if (authApi.endpoints.logoutUser.matchRejected(action)) {
                //prevent infinite logout loop
                return;
            }
            if (action.payload?.data?.detail === "Given token not valid for any token type") {
                //If Forbidden access detected based on token expiracy => logout user
                store.dispatch(authApi.endpoints.logoutUser.initiate());
            }
        }
    }
    return next(action);
};
