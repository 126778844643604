import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { Button, Modal, Popup, Segment, Checkbox, Icon, Grid } from "semantic-ui-react";

import { useDeleteExternalApiKeyMutation } from "../organizationService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import i18n from "modules/i18n/i18nConfig";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const DeleteExternalApiKey = (props) => {
    const { item, can_change } = props;
    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const org = useSelector((state) => state.org);

    const [deleteExtApiKey, deleted] = useDeleteExternalApiKeyMutation();

    useEffect(() => {
        if (deleted.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (deleted.isSuccess) {
            toast.success(i18n._(t`Successful delete external API key`), toast_options);
            setConfirm(false);
            setOpen(false);
        }
        if (deleted.isError) {
            let error = i18n._(t`Can't delete external API key`);
            if (deleted.error?.data && !_.includes(deleted.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={deleted.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [deleted]);

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            trigger={
                <Popup trigger={<Button icon="delete" onClick={() => setOpen(true)} />}>
                    <Popup.Content>
                        <Trans>Delete API Key</Trans>
                    </Popup.Content>
                </Popup>
            }
            onClose={() => {
                setOpen(false);
                setConfirm(false);
            }}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>
                <Trans>want delete API key {item.identifier}</Trans>
            </Modal.Header>

            <Modal.Content>
                <Segment basic>
                    <div style={{ color: "#794b02", fontStyle: "italic" }}>
                        <Grid centered>
                            <Grid.Column width={16}>
                                <Trans>By deleting this API key, you will delete it permanently, without the possibility of recovering it.</Trans>
                            </Grid.Column>
                        </Grid>
                    </div>
                </Segment>
                <Segment basic>
                    <Checkbox
                        label={
                            <label>
                                <Trans>yes, i confirm deletion</Trans>
                            </label>
                        }
                        onChange={(e, data) => setConfirm(data.checked)}
                        checked={confirm}
                    />
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    type="button"
                    negative
                    onClick={() => {
                        setOpen(false);
                        setConfirm(false);
                    }}
                >
                    <Trans>cancel</Trans>
                </Button>
                {can_change && (
                    <Button
                        type="button"
                        labelPosition="right"
                        icon
                        positive
                        onClick={(e) => {
                            deleteExtApiKey({ org: org.current, data: item.id });
                        }}
                        disabled={!confirm}
                    >
                        <Icon name="check" />
                        <Trans>validate</Trans>
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteExternalApiKey;
