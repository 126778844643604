import React, { useEffect } from "react";
import _ from "lodash";
import { Checkbox, Dropdown, Icon, List, Message, Pagination, Segment, Table } from "semantic-ui-react";

import { checkMobileAndTablet, removeAccents } from "../utils";
import i18n from "modules/i18n/i18nConfig";
import moment from "moment";

function stableSort(array, order, orderBy, init_array) {
    if (order === null) {
        return init_array;
    }
    return _.chain(array)
        .orderBy((item) => {
            const obj = _.get(item, orderBy);
            if (!obj) {
                return null;
            }
            const { datatype, value } = obj;
            switch (datatype) {
                case "string":
                    if (value === null) return "";
                    return removeAccents(value).toLowerCase();
                case "number":
                    if (!_.isFinite(value)) return -Infinity;
                    break;
                case "date":
                    if (value === null) return moment(1970, "YYYY");
                    if (value && !value.isValid()) return moment(1970, "YYYY");
                    break;
                default:
                    break;
            }
            return value;
        }, order)
        .value();
}

export const CustomPagination = (props) => {
    const { rowsPerPageOptions, rowsPerPage, page, count, onChangeRowsPerPage, onChangePage, textItemPerPages } = props;
    const createDropdownOptions = (rowsPerPageOptions) => {
        return _.map(rowsPerPageOptions, (item, idx) => {
            if (![10, 20, 50, 100].includes(item)) {
                return { key: idx, value: item, text: "All" };
            }
            return { key: idx, value: item, text: item };
        });
    };

    const totalPages = Math.ceil(count / rowsPerPage);
    return (
        <List horizontal size="mini">
            <List.Item>
                {!checkMobileAndTablet() && <span>{`${textItemPerPages} `}</span>}
                <Dropdown
                    selection
                    compact
                    onChange={onChangeRowsPerPage}
                    value={rowsPerPage}
                    options={createDropdownOptions(rowsPerPageOptions)}
                    disabled={count === 0}
                    upward
                />
            </List.Item>
            <List.Item>
                <Pagination
                    size="mini"
                    siblingRange={0}
                    boundaryRange={1}
                    firstItem={null}
                    lastItem={null}
                    prevItem={{
                        content: <Icon name="angle left" />,
                        icon: true
                    }}
                    nextItem={{
                        content: <Icon name="angle right" />,
                        icon: true
                    }}
                    onPageChange={onChangePage}
                    totalPages={totalPages}
                    activePage={page}
                    disabled={count === 0}
                />
            </List.Item>
        </List>
    );
};

const CustomHeaderTable = (props) => {
    const { headCells, rowCount, numSelected, onSelectAllClick, onRequestSort, order, orderBy, useCheckboxes, displayID, printing } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <Table.Header>
            <Table.Row>
                {useCheckboxes && !printing && (
                    <Table.HeaderCell collapsing>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </Table.HeaderCell>
                )}
                {headCells.map((cell, idx) => (
                    <Table.HeaderCell
                        key={idx}
                        style={cell.id === "id" ? (displayID ? null : { display: "none" }) : null}
                        onClick={_.get(cell, "ordering") === false ? null : createSortHandler(cell.id)}
                        collapsing={cell.id === "id" ? true : cell.collapsing ? true : null}
                        textAlign={cell.textAlign}
                    >
                        {_.get(cell, "ordering") !== false && (
                            <>
                                {!printing && orderBy === cell.id && order === "asc" && <Icon name="long arrow alternate up" />}
                                {!printing && orderBy === cell.id && order === "desc" && <Icon name="long arrow alternate down" />}
                            </>
                        )}
                        {cell.label}
                    </Table.HeaderCell>
                ))}
            </Table.Row>
        </Table.Header>
    );
};

const CustomTable = (props) => {
    const mql = window.matchMedia("print");
    const [order, setOrder] = React.useState(props.order);
    const [orderBy, setOrderBy] = React.useState(props.orderBy); //use elements in props determinate first orderBy ?
    const [compact, setCompact] = React.useState(props.compact || mql.matches || false); // compact table padding if needed
    const [collapsing, setCollapsing] = React.useState(props.collapsing || false);
    const [attached, setAttached] = React.useState(props.attached || false);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(props.page);
    const [rowsPerPage, setRowsPerPage] = React.useState(mql.matches ? props.rows.length : props.rowsPerPage);
    const [printing, setPrinting] = React.useState(mql.matches);

    const { headCells, rows, textItemPerPages } = props;
    const init_rows = [...rows];

    /* Media print execution */
    const handleMediaPrint = (mqlev) => {
        setPrinting(mqlev.matches);
        setCompact(mqlev.matches);
        setCollapsing(mqlev.matches);
        setAttached(mqlev.matches);
        setPage(1);
        setRowsPerPage(mqlev.matches ? rows.length : props.rowsPerPage);
    };

    useEffect(() => {
        mql.addEventListener("change", handleMediaPrint);
        return () => {
            mql.removeEventListener("change", handleMediaPrint);
        };
    });

    useEffect(() => {
        setPage(props.page);
    }, [props.page]);

    const isSelected = (id) => selected.indexOf(id) !== -1;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - (page - 1) * rowsPerPage);

    const handleSelectAllClick = (event, data) => {
        if (data.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClickRow = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const isDesc = orderBy === property && order === "desc";
        const isDefault = orderBy === property && order === null;
        if (isAsc) {
            setOrder("desc");
        } else if (isDesc) {
            setOrder(null);
        } else if (isDefault) {
            setOrder("asc");
        }
        setOrderBy(property);
    };

    const handleChangeRowsPerPage = (event, data) => {
        setRowsPerPage(parseInt(data.value, 10));
        setPage(1);
        if (props.setItemsPerPage) {
            props.setItemsPerPage(parseInt(data.value, 10));
        }
    };

    const handleChangePage = (event, data) => {
        const { activePage } = data;
        setPage(activePage);
        if (props.setPage) {
            props.setPage(activePage);
        }
    };

    return (
        <>
            <Segment basic attached style={{ overflowX: props.overflowX }}>
                <Table unstackable striped celled compact={compact} collapsing={collapsing} selectable attached={attached}>
                    <CustomHeaderTable
                        headCells={headCells}
                        rowCount={rows.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                        order={order}
                        orderBy={orderBy}
                        useCheckboxes={props.useCheckboxes}
                        displayID={props.displayID}
                        printing={printing}
                    />
                    <Table.Body>
                        {stableSort(rows, order, orderBy, init_rows)
                            .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                            .map((row, idx) => {
                                const isItemSelected = isSelected(row.id.value);
                                return (
                                    <Table.Row
                                        key={idx}
                                        onClick={
                                            props.useCheckboxes
                                                ? (event) => {
                                                      handleClickRow(event, row.id.value);
                                                  }
                                                : null
                                        }
                                        active={isItemSelected}
                                    >
                                        {props.useCheckboxes && (
                                            <Table.Cell>
                                                <Checkbox checked={isItemSelected} />
                                            </Table.Cell>
                                        )}
                                        {_.chain(headCells)
                                            .map((head) => {
                                                const item = _.get(row, head.id, {});
                                                if (!item) {
                                                    return null;
                                                }
                                                return (
                                                    <Table.Cell
                                                        negative={item.negative}
                                                        warning={item.warning}
                                                        key={head.id}
                                                        style={head.id === "id" ? (props.displayID ? null : { display: "none" }) : null}
                                                        textAlign={item.textAlign}
                                                        colSpan={item.colSpan}
                                                    >
                                                        {item.render ? item.render : item.value ? item.value : "-"}
                                                    </Table.Cell>
                                                );
                                            })
                                            .value()}
                                    </Table.Row>
                                );
                            })}
                        {rows.length !== 0 && emptyRows > 0 && (
                            <Table.Row>
                                <Table.Cell
                                    colSpan={headCells.length}
                                    style={{
                                        backgroundColor: "#eee",
                                        height: (compact ? 36 : 42) * emptyRows
                                    }}
                                />
                            </Table.Row>
                        )}
                        {rows.length === 0 && (
                            <Table.Row>
                                <Table.Cell colSpan={headCells.length} textAlign="center">
                                    <Message info>{i18n._(props.emptyMsg)}</Message>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </Segment>
            {!printing && (
                <Segment basic attached textAlign="right" disabled={rows.length === 0}>
                    <CustomPagination
                        rowsPerPageOptions={props.rowsPerPageOptions}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        count={rows.length}
                        page={page}
                        onChangePage={handleChangePage}
                        textItemPerPages={textItemPerPages}
                    />
                </Segment>
            )}
        </>
    );
};

CustomTable.defaultProps = {
    compact: true,
    collapsing: false,
    attached: false,
    page: 1,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50],
    headCells: [],
    rows: [],
    order: "asc",
    orderBy: "id",
    useCheckboxes: false,
    displayID: false,
    textItemPerPages: "item per page",
    emptyMsg: "no data",
    overflowX: "auto"
};

export default CustomTable;
