import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { toast } from "react-toastify";
import { Form, Field } from "react-final-form";
import { Button, Icon, Grid, Divider, Segment, Header, Container } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { protocol_list } from "modules/import/utils";
import { identity, identityNull } from "modules/common/utils/form";
import { useGetImportQuery, useCreateImportMutation, useUpdateImportMutation, useGetImportsQuery } from "modules/import/importService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import { InputAdapter, DropDownAdapter, TextAreaAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import Back from "modules/common/components/back";
import ConnectionTest from "./ConnectionTest";
import FilesTables from "./FilesTables";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const Import = () => {
    const { id_import, mode } = useParams();

    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    const user_rights = auth.rights;

    const imports = useGetImportsQuery({ org: org.current }, { skip: !org.current });
    const importSource = useGetImportQuery({ org: org.current, id: id_import }, { skip: !org.current || id_import === undefined });
    const [createImportSource, create] = useCreateImportMutation();
    const [updateImportSource, update] = useUpdateImportMutation();

    useEffect(() => {
        if (create.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`new import source added`), toast_options);
            history_app.push(`/imports`);
        }
        if (create.isError) {
            let error = i18n._(t`cannot add new import source`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [create]);

    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update import source`), toast_options);
        }
        if (update.isError) {
            let error = i18n._(t`cannot update import source`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    const initialValues = useMemo(() => {
        const defaultValues = {
            showPrivatekey: false,
            name: "",
            protocol: "ftp",
            server: "",
            port: 21,
            owner: auth.rights.user_id,
            organization: org.current.id,
            username: "",
            password: "",
            fingerprint: ""
        };
        return { ...defaultValues, ...importSource.data };
    }, [importSource.data, auth, org]);

    const submitForm = async (data) => {
        if (user_rights.is_admin && notification.srv_status.db_status === "rw") {
            const formData = {
                ..._.omit(data, "showPrivatekey"),
                private_key: data.protocol !== "sftp" ? "" : data.private_key,
                port: reparseNumber(data.port)
            };
            if (id_import) {
                await updateImportSource({ org: org.current, id: id_import, data: formData });
            } else {
                await createImportSource({ org: org.current, data: formData });
            }
        }
    };

    const can_change =
        notification.srv_status.db_status === "rw" &&
        _.includes(["change", "add"], mode) &&
        (user_rights.is_admin || importSource.data?.owner === auth.user.user_id);

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target={"/imports"} />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {id_import === undefined && <Trans>Add a new import source</Trans>}
                            {id_import !== undefined && (
                                <>
                                    {can_change && <Trans>Update import source</Trans>}
                                    {!can_change && <Trans>View import source</Trans>}
                                </>
                            )}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                <Grid.Row>
                    {(() => {
                        if (imports.isError || importSource.isError) {
                            return (
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            );
                        } else if (imports.isSuccess || importSource.isSuccess) {
                            return (
                                <Grid.Column width={15}>
                                    <Container>
                                        <Form
                                            onSubmit={submitForm}
                                            initialValues={initialValues}
                                            render={({ handleSubmit, submitting, pristine, invalid, values, form }) => {
                                                return (
                                                    <form onSubmit={handleSubmit} className="ui form">
                                                        <Grid stackable>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <Divider horizontal>
                                                                        <Trans>Server information</Trans>
                                                                    </Divider>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <Field
                                                                        name="name"
                                                                        placeholder={i18n._(t`enter new import source name`)}
                                                                        label={i18n._(t`Source name`)}
                                                                        isRequired={true}
                                                                        parse={identity}
                                                                        component={InputAdapter}
                                                                        unitposition="left"
                                                                        unit={<Icon name="cloud download" />}
                                                                        unitcolor="secondary"
                                                                        disabled={!can_change}
                                                                        validate={(value) => {
                                                                            const existing_name = _.chain(imports.data)
                                                                                .filter((imp) => {
                                                                                    return imp.id !== parseInt(id_import);
                                                                                })
                                                                                .find({ name: value })
                                                                                .value();

                                                                            if (existing_name) {
                                                                                return <Trans>an import with this name exists</Trans>;
                                                                            }
                                                                            if (!value) {
                                                                                return <Trans>Required field</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={3}>
                                                                    <Field
                                                                        name="protocol"
                                                                        label={i18n._(t`Protocol`)}
                                                                        placeholder={i18n._(t`select protocol`)}
                                                                        options={protocol_list}
                                                                        component={DropDownAdapter}
                                                                        search={false}
                                                                        isRequired={true}
                                                                        disabled={!can_change}
                                                                        customAction={(data) => {
                                                                            if (data !== "sftp") {
                                                                                form.change("private_key", "");
                                                                            }
                                                                        }}
                                                                        validate={(value) => {
                                                                            if (!value) {
                                                                                return <Trans>Required field</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={10}>
                                                                    <Field
                                                                        helperText={i18n._(
                                                                            t`Server Address and Base Directory Details: Examples of this include "ftp.data.com", "34.56.17.28", and "ftp2.data.com/path/to/files".`
                                                                        )}
                                                                        name="server"
                                                                        placeholder={i18n._(t`server`)}
                                                                        label={i18n._(t`ftp_server`)}
                                                                        parse={identity}
                                                                        component={InputAdapter}
                                                                        isRequired={true}
                                                                        unitposition="left"
                                                                        unit={<Icon name="server" />}
                                                                        unitcolor="secondary"
                                                                        disabled={!can_change}
                                                                        validate={(value) => {
                                                                            if (!value) {
                                                                                return <Trans>Required field</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={3}>
                                                                    <Field
                                                                        name="port"
                                                                        placeholder={i18n._(t`port`)}
                                                                        label={i18n._(t`ftp_port`)}
                                                                        component={InputAdapter}
                                                                        isRequired={true}
                                                                        parse={identityNull}
                                                                        inputMode="numeric"
                                                                        disabled={!can_change}
                                                                        validate={(value) => {
                                                                            const portValidate = validateNumber(value, i18n, false, false, true);
                                                                            if (portValidate !== undefined) {
                                                                                return portValidate;
                                                                            }
                                                                            const p = reparseNumber(value);
                                                                            if (p < 1 || p > 65535) {
                                                                                return <Trans>Port must be between 1 and 65535</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row verticalAlign="bottom">
                                                                <Grid.Column width={8}>
                                                                    <Field
                                                                        name="username"
                                                                        placeholder={i18n._(t`enter your username`)}
                                                                        label={i18n._(t`ftp_user`)}
                                                                        component={InputAdapter}
                                                                        parse={identity}
                                                                        isRequired={true}
                                                                        unitposition="left"
                                                                        unit={<Icon name="user" />}
                                                                        unitcolor="secondary"
                                                                        autoComplete="off"
                                                                        disabled={!can_change}
                                                                        validate={(value) => {
                                                                            if (!value) {
                                                                                return <Trans>Required field</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={8}>
                                                                    <Field
                                                                        name="password"
                                                                        {...(values.private_key
                                                                            ? {
                                                                                  placeholder: i18n._(t`enter private key passphrase`),
                                                                                  label: i18n._(t`private key passphrase`)
                                                                              }
                                                                            : {
                                                                                  placeholder: i18n._(t`enter server password`),
                                                                                  label: i18n._(t`password`)
                                                                              })}
                                                                        component={InputAdapter}
                                                                        type="password"
                                                                        parse={identity}
                                                                        autoComplete="off"
                                                                        disabled={!can_change}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            {values.protocol === "sftp" && (
                                                                <Grid.Row>
                                                                    <Grid.Column width={16}>
                                                                        <Segment
                                                                            padded
                                                                            secondary
                                                                            attached={values.showPrivatekey === true ? "top" : null}
                                                                        >
                                                                            <Grid stackable>
                                                                                <Grid.Row verticalAlign="bottom">
                                                                                    <Grid.Column width={can_change ? 11 : 16}>
                                                                                        <Field
                                                                                            name="fingerprint"
                                                                                            label={i18n._(t`fingerprint`)}
                                                                                            placeholder={i18n._(t`fingerprint`)}
                                                                                            options={protocol_list}
                                                                                            component={InputAdapter}
                                                                                            disabled
                                                                                        />
                                                                                    </Grid.Column>
                                                                                    {can_change && (
                                                                                        <>
                                                                                            <Grid.Column width={1}>
                                                                                                <Button
                                                                                                    color="red"
                                                                                                    icon
                                                                                                    fluid
                                                                                                    type="button"
                                                                                                    style={{ height: "2.8rem" }}
                                                                                                    onClick={async (e) => {
                                                                                                        form.change("private_key", undefined);
                                                                                                    }}
                                                                                                >
                                                                                                    <Icon name="trash" />
                                                                                                </Button>
                                                                                            </Grid.Column>
                                                                                            <Grid.Column width={4}>
                                                                                                <Button
                                                                                                    style={{ height: "2.8rem" }}
                                                                                                    fluid
                                                                                                    icon
                                                                                                    labelPosition="left"
                                                                                                    type="button"
                                                                                                    onClick={async (e) => {
                                                                                                        form.change(
                                                                                                            "showPrivatekey",
                                                                                                            !values.showPrivatekey
                                                                                                        );
                                                                                                    }}
                                                                                                    content={
                                                                                                        values.fingerprint ? (
                                                                                                            <>
                                                                                                                <Icon name="recycle" />
                                                                                                                <div
                                                                                                                    style={{ paddingLeft: "1.5rem" }}
                                                                                                                >
                                                                                                                    <Trans>replace private key</Trans>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <Icon name="add" />
                                                                                                                <div
                                                                                                                    style={{ paddingLeft: "1.5rem" }}
                                                                                                                >
                                                                                                                    <Trans>add private key</Trans>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </Grid.Column>
                                                                                        </>
                                                                                    )}
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                        </Segment>
                                                                        {values.showPrivatekey && (
                                                                            <Segment padded secondary attached="bottom">
                                                                                <Grid stackable>
                                                                                    <Grid.Column width={16}>
                                                                                        <Field
                                                                                            helperText={i18n._(
                                                                                                t`Private key in PEM format which allows you to connect to the sftp server`
                                                                                            )}
                                                                                            name="private_key"
                                                                                            placeholder={i18n._(t`-----BEGIN XXX PRIVATE ......`)}
                                                                                            label={i18n._(t`private_key`)}
                                                                                            component={TextAreaAdapter}
                                                                                            parse={identity}
                                                                                            disabled={mode === "view" || !user_rights.is_admin}
                                                                                        />
                                                                                    </Grid.Column>
                                                                                </Grid>
                                                                            </Segment>
                                                                        )}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            )}
                                                            {can_change && (
                                                                <Grid.Row>
                                                                    <Grid.Column width={16} textAlign="right">
                                                                        <Divider />
                                                                        <Button
                                                                            type="submit"
                                                                            positive
                                                                            icon
                                                                            labelPosition="right"
                                                                            disabled={submitting || pristine || invalid}
                                                                        >
                                                                            <Icon name="check" />
                                                                            <Trans>validate</Trans>
                                                                        </Button>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            )}
                                                            {id_import && (
                                                                <Grid.Row>
                                                                    {can_change && (
                                                                        <>
                                                                            <Grid.Column width={16}>
                                                                                <Divider horizontal>
                                                                                    <Trans>Connection test</Trans>
                                                                                </Divider>
                                                                            </Grid.Column>
                                                                            <Grid.Column width={16}>
                                                                                <ConnectionTest source={importSource?.data?.id} />
                                                                            </Grid.Column>
                                                                        </>
                                                                    )}
                                                                    <Grid.Column width={16}>
                                                                        <Divider horizontal>
                                                                            <Trans>List of imports</Trans>
                                                                        </Divider>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={16}>
                                                                        <FilesTables source={importSource?.data?.id} mode={mode} />
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            )}
                                                        </Grid>
                                                    </form>
                                                );
                                            }}
                                        />
                                        {!id_import && (
                                            <Grid.Column style={{ marginTop: "1rem" }} width={16}>
                                                <MessageDisplay
                                                    message={i18n._(
                                                        t`Once the import source is created, you will be able to define imports from this server.`
                                                    )}
                                                    level="info"
                                                    iconName="exclamation"
                                                    isLoading={false}
                                                    attached={false}
                                                />
                                            </Grid.Column>
                                        )}
                                    </Container>
                                </Grid.Column>
                            );
                        } else {
                            return (
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`loading data`)}
                                        level="info"
                                        iconName="circle notched"
                                        isLoading={true}
                                        attached={false}
                                    />
                                </Grid.Column>
                            );
                        }
                    })()}
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default React.memo(Import);
