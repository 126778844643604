import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { Trans, t } from "@lingui/macro";
import { CustomSVGSeries, FlexibleXYPlot, Highlight, Hint, HorizontalGridLines, LineMarkSeries, LineSeries, XAxis, YAxis } from "react-vis";
import { Button, Header, Segment, Statistic } from "semantic-ui-react";

import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";
import { Media } from "App";
import GenerateCsv from "modules/common/components/GenerateCsv";
import Palette from "modules/common/components/graphic/Colors";
import { eventTypeOptions } from "modules/machine/utils";
import tinycolor from "tinycolor2";

const Graphic = (props) => {
    const { data, time, events } = props;
    const current_lng = useSelector((state) => state.i18n.current);
    const { tabpredicthealthscore } = useSelector((state) => state.overview);
    const selected_clusters = _.get(tabpredicthealthscore, "clusters.selected_clusters", []);
    const [brushing, setBrushing] = useState(false);
    const [lastDrawLocation, setLastDrawLocation] = useState(null);
    const [hintValue, setHintValue] = useState(null);
    const [hintValueEvent, setHintValueEvent] = useState(null);

    const { valid_clusters, invalid_clusters } = useMemo(() => {
        const filtered_clusters = _.chain(data)
            //filter via dropdown cluster
            .filter((cluster) => _.includes(selected_clusters, cluster.index));

        const valid_clusters = filtered_clusters
            .reduce((valid, serie) => {
                const rewrite_serie = {
                    ...serie,
                    data: _.reduce(
                        serie.data,
                        (res, item) => {
                            if (item.validity === 1) {
                                res.push(item);
                            }
                            return res;
                        },
                        []
                    )
                };
                if (_.size(rewrite_serie.data) > 0) {
                    valid.push(rewrite_serie);
                }
                return valid;
            }, [])
            .value();

        const invalid_clusters = filtered_clusters
            .reduce((invalid, serie) => {
                const rewrite_serie = {
                    ...serie,
                    color: Palette.named.darkGreen,
                    data: _.reduce(
                        serie.data,
                        (res, item) => {
                            if (item.validity === 0) {
                                res.push({ ...item, color: Palette.named.darkGreen });
                            }
                            return res;
                        },
                        []
                    )
                };
                if (_.size(rewrite_serie.data) > 0) {
                    invalid.push(rewrite_serie);
                }
                return invalid;
            }, [])
            .value();

        return { valid_clusters, invalid_clusters };
    }, [selected_clusters, data]);

    //Process all events and display as LineSeries
    const filtered_events = useMemo(() => {
        return _.reduce(
            events,
            (res, event) => {
                const { event_date } = event;
                if (event_date) {
                    const obj_date = moment(event_date).clone();
                    const start = lastDrawLocation ? moment(lastDrawLocation.left) : time.start;
                    const end = lastDrawLocation ? moment(lastDrawLocation.right) : time.end;
                    const y = lastDrawLocation ? lastDrawLocation.top : 10;

                    if (obj_date.isAfter(start) && obj_date.isBefore(end)) {
                        res.push({
                            ...event,
                            data: [
                                { x: obj_date.unix() * 1000, y: 0, size: 0 },
                                { x: obj_date.unix() * 1000, y, ...event, size: 3 }
                            ]
                        });
                    }
                }
                return res;
            },
            []
        );
    }, [events, lastDrawLocation, time.start, time.end]);

    const tooltip = () => {
        const score = _.isFinite(hintValue.y) ? i18n.number(hintValue.y, { maximumFractionDigits: 2 }) : "-";
        const validity = _.get(hintValue, "validity", 1);

        return (
            <Hint value={hintValue}>
                <Segment.Group>
                    <Header as={"h3"} style={{ color: hintValue.color }} block attached="top">
                        {hintValue.title}
                    </Header>
                    <Header as={"h5"} attached textAlign="center">
                        {validity === 1 && (
                            <Statistic size="mini">
                                <Statistic.Label>Score</Statistic.Label>
                                <Statistic.Value>{score}</Statistic.Value>
                            </Statistic>
                        )}
                        {validity === 0 && <Trans>Invalid score following an event declaration</Trans>}
                    </Header>
                    <Header as={"h5"} attached block textAlign="right">
                        <em>{moment(hintValue.x).locale(current_lng).format("LLL")}</em>
                    </Header>
                </Segment.Group>
            </Hint>
        );
    };

    const tooltipEvent = () => {
        const option = _.find(eventTypeOptions, { value: _.get(hintValueEvent, "event_type", null) });
        return (
            <Hint value={hintValueEvent}>
                <Header as={"h4"} attached textAlign="left" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "pre" }}>
                    {_.get(hintValueEvent, "title", "-")}
                    <Header.Subheader>{i18n._(_.get(option, "text", "-"))}</Header.Subheader>
                </Header>
                {_.get(hintValueEvent, "event_date") && (
                    <Header as={"h5"} attached block textAlign="right">
                        <em>{moment(_.get(hintValueEvent, "event_date")).locale(current_lng).format("LLL")}</em>
                    </Header>
                )}
            </Hint>
        );
    };

    return (
        <>
            <Segment attached textAlign="right">
                <Media greaterThanOrEqual="computer">
                    <Button
                        onClick={(event, data) => {
                            setLastDrawLocation(null);
                        }}
                        icon="zoom-out"
                        className="no-print"
                    />
                    <GenerateCsv series={valid_clusters} unit={""} filename={props.csvName || "export"} />
                </Media>
            </Segment>
            <Segment attached>
                <FlexibleXYPlot
                    dontCheckIfEmpty
                    xType="time"
                    xDomain={lastDrawLocation ? [lastDrawLocation.left, lastDrawLocation.right] : [time.start, time.end]}
                    yDomain={lastDrawLocation ? [lastDrawLocation.bottom, lastDrawLocation.top] : [0, 10]}
                    height={200}
                >
                    <HorizontalGridLines />
                    <Highlight
                        onBrushStart={(area) => {
                            if (area) setBrushing(true);
                        }}
                        onBrushEnd={(area) => {
                            if (area) {
                                setBrushing(false);
                                setLastDrawLocation({ ...area, bottom: Math.max(area.bottom, 0) });
                            }
                        }}
                    />
                    <LineSeries
                        color={"orange"}
                        data={[
                            { x: time.start.unix() * 1000, y: 7 },
                            { x: time.end.unix() * 1000, y: 7 }
                        ]}
                    />
                    <LineSeries
                        color={"red"}
                        data={[
                            { x: time.start.unix() * 1000, y: 9 },
                            { x: time.end.unix() * 1000, y: 9 }
                        ]}
                    />
                    <XAxis
                        title={i18n._(t`time`)}
                        tickLabelAngle={-20}
                        tickFormat={(value, index, scale, tickTotal) => {
                            return multiI18nFormat(value, current_lng);
                        }}
                    />
                    <YAxis title={i18n._(t`score`)} tickPadding={-2} />
                    {_.map(filtered_events, (event, idx) => {
                        return (
                            <LineMarkSeries
                                key={idx}
                                color={"grey"}
                                getNull={(d) => d.y !== null}
                                data={event.data}
                                sizeRange={[0, 5]}
                                style={{
                                    pointerEvents: brushing ? "none" : "auto"
                                }}
                                lineStyle={{
                                    strokeDasharray: "7 3",
                                    opacity: 0.5
                                }}
                                markStyle={{ opacity: 0.5 }} //Hide Mark item
                                onValueMouseOver={(hintValue) => {
                                    if (hintValue.y !== 0) setHintValueEvent(hintValue);
                                }}
                                onValueMouseOut={() => {
                                    setHintValueEvent(null);
                                }}
                            />
                        );
                    })}
                    {_.chain([...valid_clusters, ...invalid_clusters])
                        .map((serie, idx) => {
                            return (
                                <CustomSVGSeries
                                    key={idx}
                                    customComponent={serie.id === -1 ? "diamond" : "circle"}
                                    style={{
                                        stroke: tinycolor(serie.color).setAlpha(0.3).toString(),
                                        fill: tinycolor(serie.color).setAlpha(0.3).toString(),
                                        pointerEvents: brushing ? "none" : "auto"
                                    }}
                                    size={6}
                                    getNull={(d) => d.y !== null}
                                    data={serie.data}
                                    onValueMouseOver={(hintValue) => {
                                        setHintValue(hintValue);
                                    }}
                                    onValueMouseOut={() => {
                                        setHintValue(null);
                                    }}
                                />
                            );
                        })
                        .value()}
                    {hintValue ? tooltip() : null}
                    {hintValueEvent ? tooltipEvent() : null}
                </FlexibleXYPlot>
            </Segment>
        </>
    );
};
export default Graphic;
