import React from "react";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import history_app from "history_app";
import { Grid, Icon, Button, Popup } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetFileImportsQuery } from "modules/import/fileImportsService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import TableEnhanced from "modules/common/components/TableEnhanced";
import DeleteImportFile from "./DeleteImportFile";

const FilesTables = (props) => {
    const { source, mode } = props;
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    const user_rights = auth.rights;

    const importsFiles = useGetFileImportsQuery({ org: org.current, source }, { skip: !org.current });

    const can_add = notification.srv_status.db_status === "rw" && mode === "change" && (user_rights.is_admin || _.size(auth.rights?.sites_rw) > 0);

    // headscells
    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "is_active", label: i18n._(t`is_active`), textAlign: "left" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "owner_email", label: i18n._(t`owner_email`), textAlign: "left" },
        {
            id: "actions",
            label: i18n._(t`actions`),
            textAlign: "left",
            ordering: false,
            collapsing: true
        }
    ];

    const renderIsActive = (item) => {
        return item.is_active === true ? <Icon name="checkmark" color="green" /> : <Icon name="delete" color="red" />;
    };

    const importFiles_List = _.chain(importsFiles.data)
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "pre" };
            const can_actions = user_rights.is_admin || item?.owner === auth.user.user_id;
            const can_change = notification.srv_status.db_status === "rw" && mode === "change";

            res.push({
                ...item,
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                is_active: {
                    render: <span style={customStyle}>{renderIsActive(item)}</span>,
                    value: +_.get(item, "is_active", null),
                    textAlign: "left",
                    datatype: "number"
                },
                name: {
                    render: <span style={customStyle}>{_.get(item, "name", "-")}</span>,
                    value: _.get(item, "name", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                owner_email: {
                    render: <span style={customStyle}>{_.get(item, "owner_email", "-")}</span>,
                    value: _.get(item, "owner_email", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                actions: {
                    render: (() => (
                        <>
                            {can_actions && (
                                <Button.Group>
                                    <Popup
                                        trigger={
                                            <Button
                                                icon={can_change ? "edit" : "eye"}
                                                onClick={async (e) => {
                                                    if (can_change) {
                                                        history_app.push(`/imports/${source}/file/${item.id}/change`);
                                                    } else {
                                                        history_app.push(`/imports/${source}/file/${item.id}/view`);
                                                    }
                                                }}
                                            />
                                        }
                                    >
                                        <Popup.Content>
                                            {can_change && <Trans>Import edition</Trans>}
                                            {!can_change && <Trans>View import</Trans>}
                                        </Popup.Content>
                                    </Popup>
                                    {can_change && <DeleteImportFile item={item} />}
                                </Button.Group>
                            )}
                        </>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();
    return (
        <>
            {importsFiles.isError && (
                <Grid.Row>
                    <Grid.Column width={16}>
                        <MessageDisplay
                            message={i18n._(t`error loading data`)}
                            level="error"
                            iconName="warning circle"
                            isLoading={false}
                            attached={false}
                        />
                    </Grid.Column>
                </Grid.Row>
            )}
            {importsFiles.isFetching && (
                <Grid.Row>
                    <Grid.Column width={15}>
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />
                    </Grid.Column>
                </Grid.Row>
            )}
            {importsFiles.isSuccess && (
                <>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Icon color="grey" name="info circle" />
                                <Trans>Manage your imports here</Trans>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid celled>
                        {can_add && (
                            <Grid.Column width={16}>
                                <Button
                                    type="button"
                                    icon
                                    labelPosition="left"
                                    onClick={async (e) => {
                                        history_app.push(`/imports/${source}/file/add`);
                                    }}
                                >
                                    <Icon name="add" />
                                    <Trans>Add a new import</Trans>
                                </Button>
                            </Grid.Column>
                        )}
                        {/* Table  */}
                        <Grid.Column width={16}>
                            <TableEnhanced
                                headCells={headCells}
                                rows={importFiles_List}
                                order="asc"
                                orderBy="name"
                                textItemPerPages={i18n._(t`items per page`)}
                            />
                        </Grid.Column>
                    </Grid>
                </>
            )}
        </>
    );
};

export default React.memo(FilesTables);
