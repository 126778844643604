import React, { useMemo } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Segment, Grid, Header, Button, Input, Icon, Dropdown, Label, Popup, List } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { checkStringInclude } from "modules/common/utils";
import {
    resetFilterWithPage,
    setItemsPerPage,
    setStickPage,
    setPage,
    setSearchNameFilter,
    setSiteFilter
} from "modules/energysaving/energySavingSlice";
import history_app from "history_app";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetEnergySavingsQuery } from "../energySavingService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";

import Back from "modules/common/components/back";
import TableEnhanced from "modules/common/components/TableEnhanced";
import MessageDisplay from "modules/common/components/MessageDisplay";
import DeleteEnergySaving from "./DeleteEnergySaving";
import { checkRights } from "modules/auth/utils";
import { format_period } from "../utils";

const EnergySavings = () => {
    const dispatch = useDispatch();
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    const energysaving = useSelector((state) => state.energysaving);
    const theme = useSelector((state) => state.common.theme);
    const current_lng = useSelector((state) => state.i18n.current);

    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });

    const params = useMemo(() => {
        return { org: org.current, lng: current_lng };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [org]);

    const energySavings = useGetEnergySavingsQuery(params, { skip: !org.current });

    const err_list = [
        energySavings.isError,
        sites.isError,
        equipments.isError,
        categories.isError,
        zones.isError,
        usages.isError,
        tags.isError,
        units.isError,
        dataflows.isError,
        measurementtypes.isError,
        measurements.isError
    ];

    const status_list = [
        energySavings.isSuccess,
        sites.isSuccess,
        equipments.isSuccess,
        categories.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        units.isSuccess,
        dataflows.isSuccess,
        measurementtypes.isSuccess,
        measurements.isSuccess
    ];

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`Name`), textAlign: "left" },
        { id: "site", label: i18n._(t`Site`), textAlign: "left" },
        { id: "scope", label: i18n._(t`Scope`), textAlign: "left" },
        { id: "reference_period", label: i18n._(t`Reference period`), textAlign: "left" },
        { id: "implementation_date", label: i18n._(t`Implementation date`), textAlign: "left" },
        { id: "tracking_date", label: i18n._(t`Start of follow-up period`), textAlign: "left" },
        { id: "indicator_unit", label: i18n._(t`Indicator unit`), textAlign: "left" },
        { id: "actions", label: i18n._(t`actions`), textAlign: "left", ordering: false, collapsing: true }
    ];

    const renderEquipments = (equipments) => {
        const MAX_EQPTS_DISPLAY = 2;
        if (_.size(equipments) === 0) {
            return (
                <Label>
                    <Trans>No equipments in this plan</Trans>
                </Label>
            );
        }
        const rest_equipments = equipments.splice(MAX_EQPTS_DISPLAY);
        return (
            <Label.Group>
                {[
                    ..._.map(equipments, (equipment, idx) => {
                        return <Label key={idx}>{equipment}</Label>;
                    }),
                    _.size(rest_equipments) === 0 ? null : (
                        <Popup key={-1} trigger={<Label style={{ cursor: "pointer" }}>...</Label>} position="bottom center" on={"click"}>
                            <Popup.Content>
                                <List>
                                    {_.map(rest_equipments, (equipment, idx) => {
                                        return (
                                            <List.Item key={idx}>
                                                <Label>{equipment}</Label>
                                            </List.Item>
                                        );
                                    })}
                                </List>
                            </Popup.Content>
                        </Popup>
                    )
                ]}
            </Label.Group>
        );
    };

    const energysavings_list = useMemo(() => {
        return _.chain(energySavings.data)
            .reduce((res, item) => {
                if (energysaving.filter.searchName === "") {
                    res.push(item);
                } else if (checkStringInclude(energysaving.filter.searchName, item.name)) {
                    res.push(item);
                }
                return res;
            }, [])
            .reduce((res, item) => {
                if (_.size(energysaving.filter.siteFilter) === 0) {
                    res.push(item);
                } else if (_.includes(energysaving.filter.siteFilter, item.site)) {
                    res.push(item);
                }
                return res;
            }, [])
            .reduce((res, item) => {
                const customStyle = { cursor: "default", whiteSpace: "pre" };
                const process_scope = _.chain(item)
                    .get("numerator_measurements", [])
                    .reduce((res_m, measure_id) => {
                        //transform measurement_id list into equipement name
                        const measure = _.find(measurements.data, { id: measure_id });
                        const dataflow = _.find(dataflows.data, { id: measure?.dataflow });
                        if (dataflow?.name) {
                            res_m.push(dataflow?.name);
                        }
                        return res_m;
                    }, [])
                    .uniq() // uniqueness of equipment_name
                    .value();

                const period_fmt = format_period(item.reference_period_start, item.reference_period_end, i18n, current_lng);

                const rights = checkRights(item, auth.rights);
                const can_change =
                    notification.srv_status.db_status === "rw" && (item?.owner === auth.user.user_id || _.includes(rights, "can_change"));
                const can_delete =
                    notification.srv_status.db_status === "rw" && (item?.owner === auth.user.user_id || _.includes(rights, "can_delete"));

                res.push({
                    id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                    name: {
                        render: <span style={customStyle}>{_.get(item, "name", "-")}</span>,
                        value: _.get(item, "name", "-"),
                        textAlign: "left",
                        datatype: "string"
                    },
                    site: {
                        render: _.chain(sites.data).find({ id: item?.site }).get("name", "-").value(),
                        value: _.chain(sites.data).find({ id: item?.site }).get("name", null).value(),
                        textAlign: "left",
                        datatype: "string"
                    },
                    scope: {
                        render: renderEquipments(process_scope),
                        value: _.join(process_scope),
                        textAlign: "left",
                        datatype: "string"
                    },
                    reference_period: {
                        render: period_fmt,
                        value: period_fmt,
                        textAlign: "left",
                        datatype: "string"
                    },
                    implementation_date: {
                        render:
                            item.implementation_date !== null ? (
                                <span style={customStyle}>{`${moment(item.implementation_date).locale(current_lng).format("L")}`}</span>
                            ) : (
                                "-"
                            ),
                        value: item.implementation_date !== null ? moment(item.implementation_date) : null,
                        textAlign: "right",
                        datatype: "date"
                    },
                    tracking_date: {
                        render:
                            item.tracking_date !== null ? (
                                <span style={customStyle}>{`${moment(item.tracking_date).locale(current_lng).format("L")}`}</span>
                            ) : (
                                "-"
                            ),
                        value: item.tracking_date !== null ? moment(item.tracking_date) : null,
                        textAlign: "right",
                        datatype: "date"
                    },
                    indicator_unit: {
                        render: item?.unit ?? "-",
                        value: item?.unit ?? "-",
                        textAlign: "left",
                        datatype: "string"
                    },
                    actions: {
                        render: (() => (
                            <>
                                <Button.Group>
                                    <Popup
                                        trigger={
                                            <Button
                                                icon={can_change ? "edit" : "eye"}
                                                onClick={async (e) => {
                                                    await dispatch(setStickPage(true));
                                                    if (can_change) {
                                                        history_app.push(`energysavings/${item.id}`);
                                                    } else {
                                                        history_app.push(`energysavings/${item.id}`);
                                                    }
                                                }}
                                            />
                                        }
                                    >
                                        <Popup.Content>
                                            <Trans>APE edition</Trans>
                                        </Popup.Content>
                                    </Popup>
                                    <Popup
                                        trigger={
                                            <Button
                                                icon={"chart area"}
                                                onClick={async (e) => {
                                                    await dispatch(setStickPage(true));
                                                    history_app.push(`energysavings/${item.id}/detail`);
                                                }}
                                            />
                                        }
                                    >
                                        <Popup.Content>
                                            <Trans>APE view</Trans>
                                        </Popup.Content>
                                    </Popup>
                                    {can_delete && <DeleteEnergySaving item={item} />}
                                </Button.Group>
                            </>
                        ))(),
                        value: null,
                        textAlign: "right",
                        datatype: null
                    }
                });
                return res;
            }, [])
            .value();
    }, [dispatch, energySavings.data, auth, current_lng, energysaving, measurements.data, sites.data, dataflows.data, notification]);

    return (
        <Segment attached>
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target="/" />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Energy saving actions</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                {(() => {
                    if (_.some(err_list)) {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    } else if (_.every(status_list)) {
                        const can_add = _.size(auth.rights?.sites_rw) > 0;

                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <Grid celled>
                                        <Grid.Column mobile={16} tablet={3} computer={3}>
                                            <Input
                                                fluid
                                                icon="search"
                                                placeholder={i18n._(t`search name`)}
                                                onChange={(e, { value }) => {
                                                    dispatch(setSearchNameFilter(value));
                                                }}
                                                value={energysaving.filter.searchName}
                                            />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={3} computer={3}>
                                            <Dropdown
                                                fluid
                                                options={_.map(sites.data, ({ key, text, value }) => ({
                                                    key,
                                                    text,
                                                    value,
                                                    content: <Label color={theme === "old" ? "blue" : "olive"}>{i18n._(text)}</Label>
                                                }))}
                                                placeholder={i18n._(t`select sites`)}
                                                multiple
                                                selection
                                                search
                                                onChange={(e, { value }) => dispatch(setSiteFilter(value))}
                                                renderLabel={(label) => ({ color: theme === "old" ? "blue" : "olive", content: i18n._(label.text) })}
                                                value={energysaving.filter.siteFilter}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            {notification.srv_status.db_status === "rw" && can_add && (
                                                <Button
                                                    icon
                                                    labelPosition="left"
                                                    onClick={async (e) => {
                                                        await dispatch(resetFilterWithPage());
                                                        history_app.push(`energysavings/add`);
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <Icon name="add" />
                                                    <Trans>add an action</Trans>
                                                </Button>
                                            )}
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <TableEnhanced
                                                headCells={headCells}
                                                rows={energysavings_list}
                                                textItemPerPages={i18n._(t`items per page`)}
                                                order="asc"
                                                orderBy="name"
                                                page={energysaving.pagination.page}
                                                rowsPerPage={energysaving.pagination.itemsPerPage}
                                                setPage={(page) => {
                                                    dispatch(setPage(page));
                                                }}
                                                setItemsPerPage={(items) => {
                                                    dispatch(setItemsPerPage(items));
                                                }}
                                            />
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        );
                    } else {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    }
                })()}
            </Grid>
        </Segment>
    );
};

export default EnergySavings;
