import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { healthscoretypeApi } from "./healthscoretypeService";

const initialState = {
    healthscoretypes: []
};

const healthscoretypeSlice = createSlice({
    name: "healthscoretype",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(healthscoretypeApi.endpoints.getHealthscoretypes.matchFulfilled, (state, action) => {
                state.healthscoretypes = action.payload;
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export default healthscoretypeSlice.reducer;
