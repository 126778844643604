import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Icon, Popup, Input } from "semantic-ui-react";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { useGetZonesQuery } from "../areaService";
import { checkStringInclude } from "modules/common/utils";
import { setItemsPerPage, setPage, setSearchNameFilter } from "../areaSlice";
import { checkRights } from "modules/auth/utils";

import MessageDisplay from "modules/common/components/MessageDisplay";
import TableEnhanced from "modules/common/components/TableEnhanced";
import DeleteZone from "./DeleteZone";
import AreaModalForm from "./AreaModalForm";

const Areas = (props) => {
    const dispatch = useDispatch();
    const { id_site, active } = props;
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    const zone = useSelector((state) => state.zone);
    const [showAllDescription, setShowAllDescription] = useState(false);
    const [localSearchName, setLocalSearchName] = useState("");

    const areas = useGetZonesQuery({ org: org.current }, { skip: !org.current || id_site === undefined || active === false });

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "description", label: i18n._(t`description`), textAlign: "left" },
        { id: "actions", label: i18n._(t`actions`), textAlign: "left", ordering: false, collapsing: true }
    ];

    const areas_list = _.chain(areas.data)
        .reduce((res, area) => {
            if (area?.site_id === id_site) {
                res.push(area);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            if (zone.filter.searchName === "") {
                res.push(item);
            } else if (checkStringInclude(zone.filter.searchName, item.name)) {
                res.push(item);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "pre" };
            const rights = checkRights(item, auth.rights);
            const can_change = notification.srv_status.db_status === "rw" && _.includes(rights, "can_change");
            const can_delete = notification.srv_status.db_status === "rw" && _.includes(rights, "can_delete");

            res.push({
                ...item,
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                name: {
                    render: <span style={customStyle}>{_.get(item, "name", "-")}</span>,
                    value: _.get(item, "name", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                description: {
                    render: (
                        <span style={customStyle}>
                            {/* Description cuted if more than 100 characters  */}
                            {_.get(item, "description", "-").length > 100 && (
                                <>
                                    {_.get(item, "description", "-").slice(0, 100)}
                                    <Popup
                                        trigger={
                                            <span onClick={() => setShowAllDescription(!showAllDescription)}>
                                                {!showAllDescription && (
                                                    <>
                                                        ...
                                                        <Icon
                                                            style={{ cursor: "pointer" }}
                                                            size={"large"}
                                                            color="grey"
                                                            name="arrow alternate circle right outline"
                                                            onClick={() => setShowAllDescription(!showAllDescription)}
                                                        />
                                                    </>
                                                )}
                                            </span>
                                        }
                                    >
                                        <Popup.Content>
                                            <Trans>See all text</Trans>
                                        </Popup.Content>
                                    </Popup>
                                    {showAllDescription && (
                                        <span style={{ whiteSpace: "pre-line" }}>
                                            {_.get(item, "description", "-").slice(100)}
                                            {showAllDescription && (
                                                <Popup
                                                    trigger={
                                                        <span onClick={() => setShowAllDescription(!showAllDescription)}>
                                                            {showAllDescription && (
                                                                <Icon
                                                                    style={{ cursor: "pointer" }}
                                                                    size={"large"}
                                                                    color="grey"
                                                                    name="arrow alternate circle left outline"
                                                                    onClick={() => setShowAllDescription(!showAllDescription)}
                                                                />
                                                            )}
                                                        </span>
                                                    }
                                                >
                                                    <Popup.Content>
                                                        <Trans>Hide text</Trans>
                                                    </Popup.Content>
                                                </Popup>
                                            )}
                                        </span>
                                    )}
                                </>
                            )}

                            {/* Text description under 100 characters  */}
                            {_.get(item, "description", "-").length < 100 && _.get(item, "description", "-")}
                        </span>
                    ),
                    value: _.get(item, "description", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                actions: {
                    render: (() => (
                        <Button.Group>
                            {<AreaModalForm item={item} canChange={can_change} id_site={id_site} />}
                            {can_delete && <DeleteZone item={item} canDelete={can_delete} />}
                        </Button.Group>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <Grid centered verticalAlign="top">
            {areas.isError && (
                <Grid.Row>
                    <Grid.Column width={15}>
                        <MessageDisplay
                            message={i18n._(t`error loading data`)}
                            level="error"
                            iconName="warning circle"
                            isLoading={false}
                            attached={false}
                        />
                    </Grid.Column>
                </Grid.Row>
            )}
            {areas.isFetching && (
                <Grid.Row>
                    <Grid.Column width={15}>
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />
                    </Grid.Column>
                </Grid.Row>
            )}
            {areas.isSuccess && (
                <Grid celled>
                    <Grid.Column mobile={16} tablet={3} computer={3}>
                        <Input
                            fluid
                            icon="search"
                            placeholder={i18n._(t`search area`)}
                            onChange={(e, { value }) => {
                                setLocalSearchName(value);
                                dispatch(setSearchNameFilter(value));
                            }}
                            value={localSearchName}
                        />
                    </Grid.Column>
                    {notification.srv_status.db_status === "rw" && props.can_change && (
                        <Grid.Column width={16} textAlign="left">
                            <AreaModalForm canChange={props.can_change} id_site={id_site} />
                        </Grid.Column>
                    )}
                    {/* Table  */}
                    <Grid.Column width={16}>
                        <TableEnhanced
                            headCells={headCells}
                            rows={areas_list}
                            order="asc"
                            orderBy="name"
                            page={zone.pagination.page}
                            rowsPerPage={zone.pagination.itemsPerPage}
                            setPage={(page) => {
                                dispatch(setPage(page));
                            }}
                            setItemsPerPage={(items) => {
                                dispatch(setItemsPerPage(items));
                            }}
                            textItemPerPages={i18n._(t`items per page`)}
                        />
                    </Grid.Column>
                </Grid>
            )}
        </Grid>
    );
};

export default Areas;
