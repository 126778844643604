import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";
import moment from "moment";
import cookie from "react-cookies";

export const calendarApi = defaultApi.injectEndpoints({
    reducerPath: "apiCalendar",
    endpoints: (build) => {
        return {
            getCalendars: build.query({
                keepUnusedDataFor: 600,
                query: ({ org, id_site }) => {
                    const current_org = _.get(org, "name", null);
                    return `sites/${id_site}/calendarweeks?org=${current_org}&${refreshTimestampUrlParam()}`;
                },
                providesTags: (result, error, arg) => {
                    if (result) return [{ type: "Calendars", id: arg.id_site }];
                    return [];
                },
                transformResponse: (response, meta, arg) => {
                    const now = moment();
                    const new_response = _.reduce(
                        response,
                        (res, calendar) => {
                            if (calendar.site !== arg.id_site) {
                                return res;
                            }
                            const { validity_start, validity_end } = calendar; //YYYY-MM-DD
                            if (validity_end !== null) {
                                const start = moment(validity_start, "YYYY-MM-DD", true);
                                const end = moment(validity_end, "YYYY-MM-DD", true);
                                if (start.isValid() && end.isValid()) {
                                    if (now.isBetween(start, end.clone().subtract(1, "d"), undefined, "[]")) {
                                        res.push({ ...calendar, is_current: true });
                                    } else {
                                        res.push({
                                            ...calendar,
                                            validity_end: end.clone().subtract(1, "d").format("YYYY-MM-DD")
                                        });
                                    }
                                }
                            } else {
                                //push default calendar
                                res.push(calendar);
                            }

                            return res;
                        },
                        []
                    );
                    return new_response;
                }
            }),
            getCalendar: build.query({
                keepUnusedDataFor: 600,
                query: ({ org, id_site, id_calendar }) => {
                    const current_org = _.get(org, "name", null);
                    return `sites/${id_site}/calendarweeks/${id_calendar}?org=${current_org}&${refreshTimestampUrlParam()}`;
                },
                providesTags: (result, error, arg) => {
                    if (result) return [{ type: "Calendar", id: arg.id_calendar }];
                    return [];
                },
                transformResponse: (response) => {
                    const now = moment();
                    const remap_days = _.reduce(
                        _.range(7),
                        (res, day) => {
                            res[`day${day + 1}`] = [];
                            const day_values = response?.[`day${day + 1}`] ?? [];
                            if (_.size(day_values) === 0) {
                                return res;
                            }
                            if (_.size(day_values) % 2 !== 0) {
                                return res;
                            }
                            _.each(day_values, (time_data, idx) => {
                                if (idx % 2 !== 0) {
                                    return;
                                }
                                const start = moment(time_data, "HH:mm:ss", true);
                                if (day_values[idx + 1] === null && start.isValid()) {
                                    //Last range time item supposed to be null if 23:59
                                    res[`day${day + 1}`].push({ start: start.format("HH:mm"), end: "00:00" });
                                } else {
                                    const end = moment(day_values[idx + 1], "HH:mm:ss", true);
                                    if (start.isValid() && end.isValid()) {
                                        res[`day${day + 1}`].push({ start: start.format("HH:mm"), end: end.format("HH:mm") });
                                    }
                                }
                            });
                            return res;
                        },
                        {}
                    );

                    const { validity_start, validity_end } = response;

                    if (validity_end !== null) {
                        const start = moment(validity_start, "YYYY-MM-DD", true);
                        const end = moment(validity_end, "YYYY-MM-DD", true);
                        if (start.isValid() && end.isValid()) {
                            if (now.isBetween(start, end.clone().subtract(1, "d"), undefined, "[]")) {
                                return {
                                    ...response,
                                    ...remap_days,
                                    validity_end: end.clone().subtract(1, "d").format("YYYY-MM-DD"),
                                    is_current: true
                                };
                            } else {
                                return {
                                    ...response,
                                    ...remap_days,
                                    validity_end: end.clone().subtract(1, "d").format("YYYY-MM-DD")
                                };
                            }
                        }
                    }
                    return {
                        ...response,
                        ...remap_days
                    };
                }
            }),
            updateCalendar: build.mutation({
                query: ({ org, id_site, data }) => {
                    const current_org = _.get(org, "name", null);
                    return {
                        url: `sites/${id_site}/calendarweeks/${data.id}?org=${current_org}`,
                        method: "PUT",
                        headers: {
                            "X-CSRFTOKEN": cookie.load("csrftoken")
                        },
                        credentials: "include",
                        body: data
                    };
                },
                invalidatesTags: (result, error, arg) => {
                    if (!error) {
                        localStorage.setItem("refresh_timestamp", moment().unix());
                        return [
                            { type: "Calendar", id: arg.data.id },
                            { type: "Calendars", id: arg.id_site }
                        ];
                    }
                    return [];
                }
            }),
            addCalendar: build.mutation({
                query: ({ org, data, id_site }) => {
                    const current_org = _.get(org, "name", null);
                    return {
                        url: `sites/${id_site}/calendarweeks?org=${current_org}`,
                        method: "POST",
                        headers: {
                            "X-CSRFTOKEN": cookie.load("csrftoken")
                        },
                        credentials: "include",
                        body: data
                    };
                },
                invalidatesTags: (result, error, arg) => {
                    if (!error) {
                        localStorage.setItem("refresh_timestamp", moment().unix());
                        return [{ type: "Calendars", id: arg.id_site }];
                    }
                    return [];
                }
            }),
            deleteCalendar: build.mutation({
                query: ({ org, id_site, data }) => {
                    const current_org = _.get(org, "name", null);
                    return {
                        url: `sites/${id_site}/calendarweeks/${data}?org=${current_org}`,
                        method: "DELETE",
                        headers: {
                            "X-CSRFTOKEN": cookie.load("csrftoken")
                        },
                        credentials: "include",
                        body: data
                    };
                },
                invalidatesTags: (result, error, arg) => {
                    if (!error) {
                        localStorage.setItem("refresh_timestamp", moment().unix());
                        return [
                            { type: "Calendars", id: arg.id_site },
                            { type: "Calendar", id: arg.data }
                        ];
                    }
                    return [];
                }
            }),
            //Exceptions calendars
            getCalendarExceptions: build.query({
                keepUnusedDataFor: 600,
                query: ({ org, id_site }) => {
                    const current_org = _.get(org, "name", null);
                    return `sites/${id_site}/calendardays?org=${current_org}&${refreshTimestampUrlParam()}`;
                },
                providesTags: (result, error, arg) => {
                    if (result) return [{ type: "CalendarExceptions", id: arg.id_site }];
                    return [];
                },
                transformResponse: (response) => {
                    const new_response = _.reduce(
                        response,
                        (res, exception) => {
                            if (_.size(exception.hours) % 2 !== 0) {
                                return res;
                            }
                            let remap_hours = [];

                            // Rewrite array of hours ["00:00:00", "23:59:00"] to ["00:00", "23:59"]
                            // And null => []
                            remap_hours = _.reduce(
                                exception.hours,
                                (res_h, hour) => {
                                    if (hour === null) {
                                        //supposed to be last range time item of dat
                                        res_h.push("00:00");
                                        return res_h;
                                    }
                                    const m_hour = moment(hour, "HH:mm:ss", true);
                                    if (m_hour.isValid) {
                                        res_h.push(m_hour.format("HH:mm"));
                                    }
                                    return res_h;
                                },
                                []
                            );
                            res.push({
                                ...exception,
                                hours: remap_hours
                            });
                            return res;
                        },
                        []
                    );
                    return new_response;
                }
            }),
            getCalendarException: build.query({
                keepUnusedDataFor: 600,
                query: ({ org, id_site, id_exception }) => {
                    const current_org = _.get(org, "name", null);
                    return `sites/${id_site}/calendardays/${id_exception}?org=${current_org}&${refreshTimestampUrlParam()}`;
                },
                providesTags: (result, error, arg) => {
                    if (result) return [{ type: "CalendarException", id: arg.id_exception }];
                    return [];
                },
                transformResponse: (response) => {
                    // Rewrite array of hours ["00:00:00", "23:59:00"] to ["00:00", "23:59"]
                    // And null => []
                    const srv_hours = response?.hours ?? [];
                    if (_.size(srv_hours) % 2 !== 0) {
                        return {
                            ...response,
                            hours: []
                        };
                    }

                    const hours = _.reduce(
                        srv_hours,
                        (res, time_data, idx, all_times) => {
                            if (idx % 2 !== 0) {
                                return res;
                            }
                            const start = moment(time_data, "HH:mm:ss", true);
                            if (all_times[idx + 1] === null && start.isValid()) {
                                res.push({ start: start.format("HH:mm"), end: "00:00" });
                            } else {
                                const end = moment(all_times[idx + 1], "HH:mm:ss", true);
                                if (start.isValid() && end.isValid()) {
                                    res.push({ start: start.format("HH:mm"), end: end.format("HH:mm") });
                                }
                            }
                            return res;
                        },
                        []
                    );

                    return {
                        ...response,
                        hours
                    };
                }
            }),
            addCalendarException: build.mutation({
                query: ({ org, data, id_site }) => {
                    const current_org = _.get(org, "name", null);
                    return {
                        url: `sites/${id_site}/calendardays?org=${current_org}`,
                        method: "POST",
                        headers: {
                            "X-CSRFTOKEN": cookie.load("csrftoken")
                        },
                        credentials: "include",
                        body: data
                    };
                },
                invalidatesTags: (result, error, arg) => {
                    if (!error) {
                        localStorage.setItem("refresh_timestamp", moment().unix());
                        return [{ type: "CalendarExceptions", id: arg.id_site }];
                    }
                    return [];
                }
            }),
            updateCalendarException: build.mutation({
                query: ({ org, id_site, data }) => {
                    const current_org = _.get(org, "name", null);
                    return {
                        url: `sites/${id_site}/calendardays/${data.id}?org=${current_org}`,
                        method: "PUT",
                        headers: {
                            "X-CSRFTOKEN": cookie.load("csrftoken")
                        },
                        credentials: "include",
                        body: data
                    };
                },
                invalidatesTags: (result, error, arg) => {
                    if (!error) {
                        localStorage.setItem("refresh_timestamp", moment().unix());
                        return [
                            { type: "CalendarException", id: arg.data.id },
                            { type: "CalendarExceptions", id: arg.id_site }
                        ];
                    }
                    return [];
                }
            }),
            deleteCalendarException: build.mutation({
                query: ({ org, id_site, data }) => {
                    const current_org = _.get(org, "name", null);
                    return {
                        url: `sites/${id_site}/calendardays/${data}?org=${current_org}`,
                        method: "DELETE",
                        headers: {
                            "X-CSRFTOKEN": cookie.load("csrftoken")
                        },
                        credentials: "include",
                        body: data
                    };
                },
                invalidatesTags: (result, error, arg) => {
                    if (!error) {
                        localStorage.setItem("refresh_timestamp", moment().unix());
                        return [
                            { type: "CalendarExceptions", id: arg.id_site },
                            { type: "CalendarException", id: arg.data }
                        ];
                    }
                    return [];
                }
            })
        };
    }
});

export const {
    useGetCalendarsQuery,
    useGetCalendarQuery,
    useUpdateCalendarMutation,
    useAddCalendarMutation,
    useDeleteCalendarMutation,
    //Exceptions
    useGetCalendarExceptionsQuery,
    useGetCalendarExceptionQuery,
    useAddCalendarExceptionMutation,
    useUpdateCalendarExceptionMutation,
    useDeleteCalendarExceptionMutation
} = calendarApi;

export default calendarApi.reducer;
