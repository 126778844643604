import { MAIN_FETCH, MAIN_ERROR, MAIN_SUCCESS, CSV_SUCCESS, SET_THEME } from "../actions/types";
import { getTheme } from "../utils";

const initialState = {
    isFetching: true,
    errors: {},
    csvData: {
        data: [],
        headers: []
    },
    theme: getTheme()
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case MAIN_FETCH:
            return {
                ...state,
                isFetching: true,
                errors: {}
            };
        case MAIN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: {}
            };
        case MAIN_ERROR:
            return {
                ...state,
                errors: payload
            };
        case CSV_SUCCESS:
            return {
                ...state,
                csvData: payload
            };
        case SET_THEME:
            return {
                ...state,
                theme: payload
            };
        default:
            return state;
    }
};
