import _ from "lodash";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { sensortypeApi } from "./sensorTypeService";

const initialState = {
    sensortypes: [],
    full_sensortypes: []
};

const sensorTypeSlice = createSlice({
    name: "sensortype",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(sensortypeApi.endpoints.getSensortypes.matchFulfilled, (state, action) => {
                state.full_sensortypes = action.payload;
                state.sensortypes = _.chain(action.payload)
                    .map((sensortype) => {
                        return {
                            ...sensortype,
                            key: sensortype.id,
                            value: sensortype.name,
                            text: sensortype.name
                        };
                    })
                    .uniqBy("value")
                    .value();
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export default sensorTypeSlice.reducer;
