import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";

import TableEnhanced from "modules/common/components/TableEnhanced";

const Synthesis = (props) => {
    const { savedConsumption, site } = props;
    const current_lng = useSelector((state) => state.i18n.current);
    const unit = savedConsumption?.unit_consumption ?? "-";
    const unit_currency = site?.conversions?.currency ?? "-";

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "date", label: i18n._(t`date`), textAlign: "left" },
        { id: "c_tracking", label: `${i18n._(t`c_tracking`)} (${unit})`, textAlign: "left" },
        { id: "c_adjust", label: `${i18n._(t`c_adjust`)} (${unit})`, textAlign: "left" },
        { id: "c_eco", label: `${i18n._(t`c_eco`)} (${unit})`, textAlign: "left" }
    ];

    if (site?.conversions?.currency) {
        headCells.push({ id: "c_eco_currency", label: `${i18n._(t`c_eco_currency`)} (${unit_currency})`, textAlign: "left" });
    }

    const data = _.reduce(
        savedConsumption?.synthesis ?? [],
        (res, item) => {
            const day = moment(item?.date, "YYYY-MM-DD", true);

            res.push({
                id: { render: null, value: item?.id, textAlign: "left", datatype: "number" },
                date: {
                    render: day.isValid() ? day.locale(current_lng).format("L") : "-",
                    value: day ? day : null,
                    textAlign: "right",
                    datatype: "date"
                },
                c_tracking: {
                    render: !_.isFinite(item?.c_tracking) ? "-" : i18n.number(item?.c_tracking, { maximumFractionDigits: 2 }),
                    value: !_.isFinite(item?.c_tracking) ? null : item?.c_tracking,
                    textAlign: "right",
                    datatype: "number"
                },
                c_adjust: {
                    render: !_.isFinite(item?.c_adjust) ? "-" : i18n.number(item?.c_adjust, { maximumFractionDigits: 2 }),
                    value: !_.isFinite(item?.c_adjust) ? null : item?.c_adjust,
                    textAlign: "right",
                    datatype: "number"
                },
                c_eco: {
                    render: !_.isFinite(item?.c_eco) ? "-" : i18n.number(item?.c_eco, { maximumFractionDigits: 2 }),
                    value: !_.isFinite(item?.c_eco) ? null : item?.c_eco,
                    textAlign: "right",
                    datatype: "number"
                },
                c_eco_currency: {
                    render: !_.isFinite(item?.c_eco_currency) ? "-" : i18n.number(item?.c_eco_currency, { maximumFractionDigits: 2 }),
                    value: !_.isFinite(item?.c_eco_currency) ? null : item?.c_eco_currency,
                    textAlign: "right",
                    datatype: "number"
                }
            });

            return res;
        },
        []
    );

    return (
        <>
            <Header as="h3" block textAlign="center" attached>
                <Trans>synthesis table</Trans> ({unit})
            </Header>
            <Segment attached className="pwaTablePaginatedContainer">
                <TableEnhanced headCells={headCells} rows={data} textItemPerPages={i18n._(t`items per page`)} orderBy="date" />
            </Segment>
        </>
    );
};

export default Synthesis;
