import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Grid } from "semantic-ui-react";
import { t } from "@lingui/macro";
import { toast } from "react-toastify";

import i18n from "modules/i18n/i18nConfig";
import { processFilter } from "../utils";
import { setFilteredEquipments } from "../dashboardSlice";
import { toast_options_err } from "modules/notification/notificationMiddleware";
import { useGetSortedEquipmentsQuery } from "../dashboardService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import DashboardPagination from "./DashboardPagination";
import DashboardDisplayEquipments from "./DashboardDisplayEquipments";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const DashboardPanel = (props) => {
    const dispatch = useDispatch();
    const org = useSelector((state) => state.org);
    const filteredEquipments = useSelector((state) => state.dash.filtered_equipments);
    const sorting = useSelector((state) => state.dash.sorting);
    const filter = useSelector((state) => state.dash.filter);
    const pagination = useSelector((state) => state.dash.pagination);
    const { sites } = useSelector((state) => state.dash.filter);

    const sortedEqpts = useGetSortedEquipmentsQuery({ org: org.current, sorting, sites }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });

    useEffect(() => {
        if (sortedEqpts.isError) {
            let error = i18n._(t`cannot get equipments`);
            if (sortedEqpts.error?.data && !_.includes(sortedEqpts.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={sortedEqpts.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [sortedEqpts]);

    useEffect(() => {
        if (sortedEqpts.isSuccess && equipments.isSuccess) {
            //define a mixin function to process equipment filtering based on filter
            _.mixin({
                processFilter: (array, filter) => {
                    return processFilter(array, filter);
                }
            });
            const filteredEquipments = _.chain(sortedEqpts.data || [])
                .reduce((res, equipment_id) => {
                    //replace sorted_eqpt_id by full equipment
                    const eqpt = _.find(equipments.data, { id: equipment_id });
                    if (eqpt) res.push(eqpt);
                    return res;
                }, [])
                .processFilter(filter)
                .defaultTo([])
                .value();
            dispatch(setFilteredEquipments(filteredEquipments));
        }
    }, [dispatch, sortedEqpts, equipments, filter]);

    const displayedEquipments = useMemo(() => {
        const startIndex = (pagination.page - 1) * pagination.itemsPerPage;
        return filteredEquipments.slice(startIndex, startIndex + pagination.itemsPerPage);
    }, [filteredEquipments, pagination]);

    if (sortedEqpts.isFetching) {
        return (
            <Grid.Row>
                <Grid.Column width={16}>
                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />
                </Grid.Column>
            </Grid.Row>
        );
    }

    if (sortedEqpts.isError) {
        return (
            <Grid.Row>
                <Grid.Column width={16}>
                    <MessageDisplay
                        message={i18n._(t`error sorting data`)}
                        level="error"
                        iconName="warning circle"
                        isLoading={false}
                        attached={false}
                    />
                    ;
                </Grid.Column>
            </Grid.Row>
        );
    }

    if (sortedEqpts.isSuccess) {
        return (
            <>
                <DashboardDisplayEquipments displayedEquipments={displayedEquipments} />
                <Grid.Row>
                    <Grid.Column width={16} textAlign="right">
                        <DashboardPagination paginate_all={filteredEquipments.length || 1} />
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    }
};

export default React.memo(DashboardPanel);
