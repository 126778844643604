import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Button, Modal, Icon, Grid, Divider } from "semantic-ui-react";
import { Form, Field } from "react-final-form";

import i18n from "modules/i18n/i18nConfig";
import { InputAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { useAddTagMutation } from "../tagService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { addTagLocal } from "../tagSlice";

const TagAddModalForm = (props) => {
    const dispatch = useDispatch();
    const current_tag_form = props.form.getState().values.equipment.tag_set;
    const [open, setOpen] = useState(false);
    const org = useSelector((state) => state.org);
    const notification = useSelector((state) => state.notification);
    const tag = useSelector((state) => state.tag);
    const [addTag, { isSuccess, isError, isLoading, data }] = useAddTagMutation();

    useEffect(() => {
        (async () => {
            if (isSuccess) {
                await dispatch(
                    addTagLocal({
                        ...data,
                        key: data.id,
                        name: data.word,
                        text: data.word,
                        type: "tag",
                        value: data.id
                    })
                );
                props.form.change("equipment.tag_set", [...current_tag_form, data.id]);
                toast.success(i18n._(t`new tag added`), toast_options);
                onClose();
            }
            if (isError) {
                toast.error(i18n._(t`cannot add new tag`), toast_options_err);
                onClose();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, isError]);

    const onClose = () => {
        setOpen(false);
    };

    const validate = (values) => {
        const errors = {};

        if (!values.word) {
            errors.word = i18n._(t`tag is required`);
        }
        const existing_tag = _.find(tag.tags, { word: values.word });
        if (existing_tag) {
            errors.word = i18n._(t`this tag already exists`);
        }

        return errors;
    };

    const submitForm = async (data) => {
        if (notification.srv_status.db_status === "rw") {
            // Use local: true for preventing invalidateTags query
            await addTag({ org: org.current, data, local: true });
        }
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            onClose={onClose}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button icon="add" type="button" />}
        >
            <Modal.Header>
                <Trans>add tag</Trans>
            </Modal.Header>
            <Modal.Content>
                <Form
                    onSubmit={submitForm}
                    initialValues={{
                        word: ""
                    }}
                    validate={validate}
                    render={({ handleSubmit, submitting, pristine, invalid, form }) => {
                        return (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid verticalAlign="top" centered>
                                    <Grid.Column width={15}>
                                        <Field
                                            name="word"
                                            placeholder={i18n._(t`enter new tag`)}
                                            label={i18n._(t`new_tag`)}
                                            isRequired={true}
                                            component={InputAdapter}
                                            onKeyUp={(evt) => {
                                                if (!submitting && !pristine && !invalid && evt.key === "Enter") {
                                                    form.submit();
                                                }
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={15}>
                                        <Divider />
                                    </Grid.Column>
                                    {isLoading && (
                                        <Grid.Column width={15}>
                                            <MessageDisplay
                                                message={i18n._(t`send tag to server`)}
                                                level="info"
                                                iconName="info"
                                                isLoading={false}
                                                attached={false}
                                            />
                                        </Grid.Column>
                                    )}
                                    {isError && (
                                        <Grid.Column width={15}>
                                            <MessageDisplay
                                                message={i18n._(t`unable to add tag`)}
                                                level="error"
                                                iconName="warning circle"
                                                isLoading={false}
                                                attached={false}
                                            />
                                        </Grid.Column>
                                    )}
                                    <Grid.Column width={15} textAlign="right">
                                        <Button type="button" negative onClick={() => onClose()}>
                                            <Trans>cancel</Trans>
                                        </Button>
                                        {notification.srv_status.db_status === "rw" && (
                                            <Button type="submit" positive icon labelPosition="right" disabled={submitting || pristine || invalid}>
                                                <Icon name="check" />
                                                <Trans>validate</Trans>
                                            </Button>
                                        )}
                                    </Grid.Column>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </Modal.Content>
        </Modal>
    );
};

export default TagAddModalForm;
