import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { Field, Form } from "react-final-form";
import { Modal, Grid, Divider, Button, Segment, Header, Accordion, Icon, Message, Label } from "semantic-ui-react";

import { removeAccents } from "modules/common/utils";
import i18n from "modules/i18n/i18nConfig";
import {
    denominatorUnitsOptions,
    advancedUnitsOptions,
    graphicTypeOptions,
    groupByOptions,
    clearFieldsGraphType12,
    clearFieldsGraphType2,
    keyToReparseInAnalysis
} from "../utils";
import { identity, identityNull } from "modules/common/utils/form";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useUpdateAnalysisSectionMutation } from "../analysisAdvancedService";

import { DropDownAdapter, InputAdapter, ToggleAdapter } from "modules/common/components/form";
import DropdownAdapterMeasurement from "modules/export/components/DropdownAdapterMeasurement";
import MessageDisplay from "modules/common/components/MessageDisplay";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const AnalysisSectionUpdate = (props) => {
    const { analysis_section, categories, sites, zones, usages, tags, units, org, measurements, can_change, setOpen } = props;
    const theme = useSelector((state) => state.common.theme);

    const [updateSection, update] = useUpdateAnalysisSectionMutation();

    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update analysis section`), toast_options);
            setOpen(false);
        }
        if (update.isError) {
            let error = i18n._(t`cannot update analysis section`);
            if (update?.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update?.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update, setOpen]);

    const onSubmitForm = async (formdata) => {
        try {
            if (can_change) {
                const { id } = formdata;
                if (id) {
                    let data = {
                        ...formdata,
                        denominator: formdata.denominator === "no denominator type" ? null : formdata.denominator,
                        //rewrite factor because final_form return string instead of number
                        denominator_factor: !_.isFinite(reparseNumber(formdata.denominator_factor))
                            ? null
                            : reparseNumber(formdata.denominator_factor),
                        numerator_factor: !_.isFinite(reparseNumber(formdata.numerator_factor)) ? null : reparseNumber(formdata.numerator_factor),
                        denominator_factor_unit:
                            formdata.denominator_factor_unit === -1 || !_.isFinite(reparseNumber(formdata.denominator_factor))
                                ? null
                                : reparseNumber(formdata.denominator_factor_unit),
                        numerator_factor_unit:
                            formdata.numerator_factor_unit === -1 || !_.isFinite(reparseNumber(formdata.numerator_factor))
                                ? null
                                : reparseNumber(formdata.numerator_factor_unit)
                    };
                    if (data?.graph_type !== 3) {
                        data = { ...data, ...clearFieldsGraphType12 };
                    }
                    if (data?.graph_type === 2) {
                        data = { ...data, ...clearFieldsGraphType2 };
                    }

                    data = _.mapValues(data, (item, key) => {
                        if (_.includes(keyToReparseInAnalysis, key)) {
                            return reparseNumber(item);
                        }
                        return item;
                    });
                    await updateSection({ org, formdata: data });
                }
            }
        } catch (error) {}
    };

    const remapUnits = useMemo(() => {
        return [{ key: -1, text: i18n._(t`no unit`), value: -1 }, ..._.filter(units, (item) => item.key !== -1)];
    }, [units]);

    const initialValues = useMemo(() => {
        return {
            ...analysis_section,
            denominator: analysis_section.denominator === null ? "no denominator type" : analysis_section.denominator,
            denominator_factor_unit: analysis_section.denominator_factor_unit === null ? -1 : analysis_section.denominator_factor_unit,
            numerator_factor_unit: analysis_section.numerator_factor_unit === null ? -1 : analysis_section.numerator_factor_unit
        };
    }, [analysis_section]);

    const validate = (values) => {
        const err = {};
        const retrieve_sites = _.chain(values)
            .get("sites", [])
            .reduce((res, site_id) => {
                const site = _.find(sites, { id: site_id });
                if (site) {
                    res.push(site);
                }
                return res;
            }, []);

        const hasMultipleTimezone = retrieve_sites.groupBy("timezone").size().value() > 1;
        if (hasMultipleTimezone) {
            err.sites = <Trans>Sites must have the same timezone</Trans>;
        }

        if (_.includes(["cost", "kcost"], values.numerator_unit)) {
            const missingCurrency = _.reduce(
                ["undefined", ""],
                (result, currency) => {
                    return result || retrieve_sites.groupBy("conversions.currency").get(currency, []).size().value() > 0;
                },
                false
            );

            if (missingCurrency) {
                err.sites = <Trans>Some site has no currency definition</Trans>;
            }

            const hasMultipleCurrency = retrieve_sites.groupBy("conversions.currency").size().value() > 1;
            if (hasMultipleCurrency) {
                err.sites = <Trans>Sites must have the same currency</Trans>;
            }
        }

        if (values.graph_type === 3 && _.size(values.sites) > 1) {
            err.sites = <Trans>Only one site is allowed for ratio processing</Trans>;
        }
        return err;
    };

    return (
        <Modal
            open={true}
            centered={false}
            closeOnDimmerClick={false}
            closeOnEscape
            onClose={(e) => {
                setOpen(false);
            }}
        >
            <Modal.Header>
                <Trans>Edit Analysis section</Trans>
            </Modal.Header>
            <Modal.Content>
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialValues}
                    validate={validate}
                    render={({ handleSubmit, submitting, pristine, invalid, form, values }) => {
                        return (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid centered verticalAlign="top">
                                    <Grid.Row>
                                        <Grid.Column width={14}>
                                            <Field
                                                name="name"
                                                label={i18n._(t`section name`)}
                                                placeholder={i18n._(t`enter name of section`)}
                                                isRequired={true}
                                                component={InputAdapter}
                                                validate={(value) => {
                                                    if (!value) {
                                                        return <Trans>section name is required</Trans>;
                                                    }
                                                    return undefined;
                                                }}
                                                disabled={!can_change}
                                            />
                                            <Divider />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Field
                                                name="graph_type"
                                                placeholder={i18n._(t`select graph_type`)}
                                                noResultsMessage={i18n._(t`no result found`)}
                                                options={graphicTypeOptions}
                                                component={DropDownAdapter}
                                                defaultValue={graphicTypeOptions[0].value}
                                                customAction={(data) => {
                                                    if (data === 3) {
                                                        form.change("group_by", 1);
                                                    }
                                                }}
                                                disabled={!can_change}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    {/* NUMERATOR SECTION */}
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            {values.graph_type === 3 && (
                                                <Header size="tiny" attached="top" block textAlign="center">
                                                    <Trans>Numerator section</Trans>
                                                </Header>
                                            )}
                                            {values.graph_type === 3 && _.size(values.sites) > 1 && form.getFieldState("sites")?.dirty === false && (
                                                <MessageDisplay
                                                    message={i18n._(t`Only one site is allowed for ratio processing`)}
                                                    level="error"
                                                    iconName="warning circle"
                                                    isLoading={false}
                                                />
                                            )}
                                            {_.size(values.sites) === 0 && (
                                                <MessageDisplay
                                                    message={i18n._(t`select at least 1 site`)}
                                                    level="info"
                                                    iconName="info circle"
                                                    isLoading={false}
                                                    attached={values.graph_type === 3}
                                                />
                                            )}
                                            <Segment attached basic={values.graph_type !== 3}>
                                                <Grid centered verticalAlign="top">
                                                    {values.graph_type === 3 && (
                                                        <Grid.Row>
                                                            <Grid.Column width={14}>
                                                                <Field
                                                                    name="equipment_name"
                                                                    label={i18n._(t`equipment name filter`)}
                                                                    placeholder={i18n._(t`Enter a filter on the name of equipments`)}
                                                                    component={InputAdapter}
                                                                    parse={identity}
                                                                    disabled={!can_change}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    )}
                                                    <Grid.Row>
                                                        <Grid.Column width={7}>
                                                            <Field
                                                                name="numerator_unit"
                                                                className="icon"
                                                                icon="fire"
                                                                button
                                                                labeled
                                                                search={false}
                                                                placeholder={i18n._(t`select unit`)}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                options={advancedUnitsOptions}
                                                                component={DropDownAdapter}
                                                                defaultValue={graphicTypeOptions[0].value}
                                                                disabled={!can_change}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={7}>
                                                            <Field
                                                                name="categories"
                                                                placeholder={i18n._(t`select categories`)}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                options={_.chain(categories)
                                                                    .map(({ key, text, value }) => ({
                                                                        key,
                                                                        text: i18n._(text),
                                                                        value,
                                                                        content: <Label color="purple">{i18n._(text)}</Label>
                                                                    }))
                                                                    .orderBy((item) => {
                                                                        return removeAccents(item.text).toLowerCase();
                                                                    }, "asc")
                                                                    .value()}
                                                                multipleselect={1}
                                                                component={DropDownAdapter}
                                                                renderLabel={(label, index, defaultProps) => {
                                                                    return { ...defaultProps, color: "purple", content: i18n._(label.text) };
                                                                }}
                                                                disabled={!can_change}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={7}>
                                                            <Field
                                                                name="sites"
                                                                placeholder={i18n._(t`select site`)}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                options={_.chain(sites)
                                                                    .map(({ key, text, value }) => ({
                                                                        key,
                                                                        text,
                                                                        value,
                                                                        content: (
                                                                            <Label color={theme === "old" ? "blue" : "olive"}>{i18n._(text)}</Label>
                                                                        )
                                                                    }))
                                                                    .orderBy((item) => {
                                                                        return removeAccents(item.text).toLowerCase();
                                                                    }, "asc")
                                                                    .value()}
                                                                multipleselect={1}
                                                                component={DropDownAdapter}
                                                                renderLabel={(label, index, defaultProps) => {
                                                                    const labelProps =
                                                                        _.size(values.sites) <= 1
                                                                            ? { ...defaultProps, onRemove: null }
                                                                            : defaultProps;
                                                                    return {
                                                                        ...labelProps,
                                                                        color: theme === "old" ? "blue" : "olive",
                                                                        content: i18n._(label.text)
                                                                    };
                                                                }}
                                                                customAction={(data) => {
                                                                    const zonesFilter = _.reduce(
                                                                        values.zones,
                                                                        (res, zone_id) => {
                                                                            const zone = _.find(zones, { id: zone_id });
                                                                            if (_.includes(data, zone?.site_id)) {
                                                                                res.push(zone_id);
                                                                            }
                                                                            return res;
                                                                        },
                                                                        []
                                                                    );
                                                                    form.change("zones", zonesFilter);
                                                                    if (values?.denominator === "measurement") {
                                                                        const measure = _.find(measurements, { id: values.denominator_measurement });
                                                                        if (measure) {
                                                                            if (!_.includes(data, measure?.site?.id)) {
                                                                                form.change("denominator_measurement", null);
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                                validate={(value) => {
                                                                    if (_.size(value) === 0) {
                                                                        return <Trans>Required field</Trans>;
                                                                    }
                                                                    return undefined;
                                                                }}
                                                                disabled={!can_change}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={7}>
                                                            <Field
                                                                name="zones"
                                                                placeholder={i18n._(t`select zones`)}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                options={_.chain(zones)
                                                                    .filter((item) => {
                                                                        return _.includes(values.sites, item?.site_id);
                                                                    })
                                                                    .map(({ key, text, value }) => ({
                                                                        key,
                                                                        text,
                                                                        value,
                                                                        content: <Label color="teal">{i18n._(text)}</Label>
                                                                    }))
                                                                    .orderBy((item) => {
                                                                        return removeAccents(item.text).toLowerCase();
                                                                    }, "asc")
                                                                    .value()}
                                                                disabled={_.size(values.sites) === 0 || !can_change}
                                                                multipleselect={1}
                                                                component={DropDownAdapter}
                                                                renderLabel={(label, index, defaultProps) => {
                                                                    return {
                                                                        ...defaultProps,
                                                                        color: "teal",
                                                                        content: i18n._(label.text)
                                                                    };
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={7}>
                                                            <Field
                                                                name="usages"
                                                                placeholder={i18n._(t`select usages`)}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                options={_.chain(usages)
                                                                    .map(({ key, text, value, description }) => ({
                                                                        key,
                                                                        description,
                                                                        text,
                                                                        value,
                                                                        content: <Label color="violet">{i18n._(text)}</Label>
                                                                    }))
                                                                    .orderBy((item) => {
                                                                        return removeAccents(item.text).toLowerCase();
                                                                    }, "asc")
                                                                    .value()}
                                                                multipleselect={1}
                                                                component={DropDownAdapter}
                                                                renderLabel={(label, index, defaultProps) => {
                                                                    return { ...defaultProps, color: "violet", content: i18n._(label.text) };
                                                                }}
                                                                disabled={!can_change}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={7}>
                                                            <Field
                                                                name="tags"
                                                                placeholder={i18n._(t`select tags`)}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                options={_.chain(tags)
                                                                    .map(({ key, text, value }) => ({
                                                                        key,
                                                                        text,
                                                                        value,
                                                                        content: <Label color="grey">{i18n._(text)}</Label>
                                                                    }))
                                                                    .orderBy((item) => {
                                                                        return removeAccents(item.text).toLowerCase();
                                                                    }, "asc")
                                                                    .value()}
                                                                multipleselect={1}
                                                                component={DropDownAdapter}
                                                                renderLabel={(label, index, defaultProps) => {
                                                                    return { ...defaultProps, color: "grey", content: i18n._(label.text) };
                                                                }}
                                                                disabled={!can_change}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    {values.graph_type === 3 && (
                                                        <Grid.Row>
                                                            <Grid.Column width={7}>
                                                                <Field
                                                                    name="numerator_factor"
                                                                    placeholder={i18n._(t`enter numerator factor`)}
                                                                    label={i18n._(t`numerator_factor`)}
                                                                    component={InputAdapter}
                                                                    parse={identityNull}
                                                                    inputMode="decimal"
                                                                    defaultValue={null}
                                                                    validate={(value) => {
                                                                        return validateNumber(value, i18n, false, true);
                                                                    }}
                                                                    disabled={!can_change}
                                                                />
                                                            </Grid.Column>
                                                            <Grid.Column width={7}>
                                                                <Field
                                                                    name="numerator_factor_unit"
                                                                    label={i18n._(t`numerator_factor_unit`)}
                                                                    placeholder={i18n._(t`select numerator factor unit`)}
                                                                    options={remapUnits} //exclude 'dynamic_unit'
                                                                    component={DropDownAdapter}
                                                                    disabled={!_.isFinite(reparseNumber(values.numerator_factor)) || !can_change}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    )}
                                                    {values.graph_type !== 3 && (
                                                        <>
                                                            <Grid.Row>
                                                                <Grid.Column width={7}>
                                                                    <Field
                                                                        name="group_by"
                                                                        className="icon"
                                                                        icon="gitter"
                                                                        button
                                                                        labeled
                                                                        search={false}
                                                                        placeholder={i18n._(t`aggregate_type_placeholder`)}
                                                                        noResultsMessage={i18n._(t`no result found`)}
                                                                        options={_.chain(groupByOptions)
                                                                            .map(({ key, text, value }) => ({
                                                                                key,
                                                                                text: i18n._(text),
                                                                                value
                                                                            }))
                                                                            .orderBy((item) => {
                                                                                return removeAccents(item.text).toLowerCase();
                                                                            }, "asc")
                                                                            .value()}
                                                                        component={DropDownAdapter}
                                                                        defaultValue={groupByOptions[0].value}
                                                                        disabled={!can_change}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {/* DENOMINATOR SECTION */}
                                    {values.graph_type === 3 && (
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Header size="tiny" attached="top" block textAlign="center">
                                                    <Trans>Denominator section</Trans>
                                                </Header>
                                                <Segment attached>
                                                    <Grid centered verticalAlign="top">
                                                        <Grid.Row>
                                                            <Grid.Column width={7}>
                                                                <Field
                                                                    name="denominator"
                                                                    placeholder={i18n._(t`select denominator type`)}
                                                                    noResultsMessage={i18n._(t`no result found`)}
                                                                    options={denominatorUnitsOptions}
                                                                    component={DropDownAdapter}
                                                                    defaultValue={graphicTypeOptions[0].value}
                                                                    disabled={!can_change}
                                                                />
                                                            </Grid.Column>
                                                            {values?.denominator === "measurement" && (
                                                                <Grid.Column width={7}>
                                                                    <Field
                                                                        name="denominator_measurement"
                                                                        placeholder={i18n._(t`choose your measurement here`)}
                                                                        options={measurements}
                                                                        disabled={_.size(values.sites) === 0 || !can_change}
                                                                        component={DropdownAdapterMeasurement}
                                                                        sites={sites}
                                                                        sites_filter={values.sites}
                                                                        siteDisabled={true}
                                                                        usages={usages}
                                                                        displayAddBtn={false}
                                                                        noResultsMessage={i18n._(t`no result found`)}
                                                                        open={false}
                                                                        validate={(value) => {
                                                                            if (!value) {
                                                                                return <Trans>measurement is required</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                            )}
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column width={7}>
                                                                <Field
                                                                    name="denominator_factor"
                                                                    placeholder={i18n._(t`enter denominator factor`)}
                                                                    label={i18n._(t`denominator_factor`)}
                                                                    component={InputAdapter}
                                                                    parse={identityNull}
                                                                    inputMode="decimal"
                                                                    defaultValue={null}
                                                                    validate={(value) => {
                                                                        return validateNumber(value, i18n, false, true);
                                                                    }}
                                                                    disabled={!can_change}
                                                                />
                                                            </Grid.Column>
                                                            <Grid.Column width={7}>
                                                                <Field
                                                                    name="denominator_factor_unit"
                                                                    label={i18n._(t`denominator_factor_unit`)}
                                                                    placeholder={i18n._(t`select denominator factor unit`)}
                                                                    options={remapUnits} //exclude 'dynamic_unit'
                                                                    component={DropDownAdapter}
                                                                    disabled={!_.isFinite(reparseNumber(values.denominator_factor)) || !can_change}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                    {values.graph_type !== 2 && (
                                        <Grid.Row>
                                            <Grid.Column width={14}>
                                                <Accordion fluid styled>
                                                    <Accordion.Title
                                                        active={values.toogle_accordion || false}
                                                        onClick={(e) => {
                                                            form.change("toogle_accordion", !values.toogle_accordion);
                                                        }}
                                                    >
                                                        <Icon name="dropdown" />
                                                        <Trans>Threshold setup</Trans>
                                                    </Accordion.Title>
                                                    <Accordion.Content active={values.toogle_accordion || false}>
                                                        <Message info attached="top">
                                                            <Trans>Thresholds are displayed on the graph and used on the alerts table</Trans>
                                                        </Message>
                                                        <Segment attached>
                                                            <Field
                                                                name="reference_level"
                                                                placeholder={i18n._(t`enter reference level`)}
                                                                label={i18n._(t`reference_level`)}
                                                                component={InputAdapter}
                                                                parse={identityNull}
                                                                inputMode="decimal"
                                                                defaultValue={null}
                                                                validate={(value) => {
                                                                    return validateNumber(value, i18n, true, true);
                                                                }}
                                                                disabled={!can_change}
                                                            />
                                                            <Field
                                                                name="threshold_min"
                                                                placeholder={i18n._(t`enter threshold min`)}
                                                                label={i18n._(t`threshold_min`)}
                                                                component={InputAdapter}
                                                                parse={identityNull}
                                                                inputMode="decimal"
                                                                defaultValue={null}
                                                                validate={(value) => {
                                                                    return validateNumber(value, i18n, true, true);
                                                                }}
                                                                disabled={!can_change}
                                                            />
                                                            <Field
                                                                name="threshold_max"
                                                                placeholder={i18n._(t`enter threshold max`)}
                                                                label={i18n._(t`threshold_max`)}
                                                                component={InputAdapter}
                                                                parse={identityNull}
                                                                inputMode="decimal"
                                                                defaultValue={null}
                                                                validate={(value) => {
                                                                    return validateNumber(value, i18n, true, true);
                                                                }}
                                                                disabled={!can_change}
                                                            />
                                                        </Segment>
                                                    </Accordion.Content>
                                                </Accordion>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                    {_.includes([1, 3], values.graph_type) && (
                                        <Grid.Row>
                                            <Grid.Column width={7} textAlign="center">
                                                <Field
                                                    name="include_synthetic_table"
                                                    label={i18n._(t`include_synthetic_table`)}
                                                    labelposition="right"
                                                    component={ToggleAdapter}
                                                    toggle={false}
                                                    defaultValue={false}
                                                    disabled={!can_change}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={7} textAlign="center">
                                                <Field
                                                    name="include_alerts"
                                                    label={i18n._(t`include_alerts`)}
                                                    labelposition="right"
                                                    component={ToggleAdapter}
                                                    toggle={false}
                                                    defaultValue={false}
                                                    disabled={!can_change}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                    <Grid.Row>
                                        <Grid.Column width={14}>
                                            <Divider />
                                            {!values.sites && (
                                                <Segment basic textAlign="center">
                                                    <MessageDisplay
                                                        message={i18n._(t`select site`)}
                                                        level="info"
                                                        iconName="info circle"
                                                        isLoading={false}
                                                        attached={false}
                                                    />
                                                </Segment>
                                            )}
                                            <Segment attached basic textAlign="right">
                                                <Button
                                                    type="button"
                                                    negative
                                                    icon
                                                    labelPosition="left"
                                                    onClick={(e) => {
                                                        setOpen(false);
                                                    }}
                                                >
                                                    <Icon name="arrow left" />
                                                    <Trans>cancel</Trans>
                                                </Button>
                                                {can_change && (
                                                    <Button
                                                        positive
                                                        icon
                                                        labelPosition="right"
                                                        type="submit"
                                                        disabled={submitting || pristine || invalid || !values.sites}
                                                    >
                                                        <Icon name="send" />
                                                        <Trans>Confirm</Trans>
                                                    </Button>
                                                )}
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(AnalysisSectionUpdate);
