import React, { useMemo, useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Handle, Position } from "reactflow";
import { Header, Popup, Segment } from "semantic-ui-react";
import { useGetDiagramsQuery } from "modules/hierarchy/hierarchyService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";

import { diagramTypeOptions } from "modules/hierarchy/utils";

import Infos from "modules/dashboard/components/widgets/Infos";
import NodeContent from "./NodeContent";

const GenericNode = (props) => {
    const { data, selected, type } = props;
    const { node_db } = data;
    const [draged, setDraged] = useState(false);
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const diagrams = useGetDiagramsQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });

    useEffect(() => {
        const handleMouseUp = () => {
            setDraged(false);
        };
        document.addEventListener("mouseup", handleMouseUp);
        return () => {
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, []);

    //Display unit based on diagram_type
    const { unit, disabled_diagram } = useMemo(() => {
        if (diagrams.isSuccess) {
            const node_diagram = _.find(diagrams.data, { id: node_db.diagram });
            if (node_diagram) {
                const disabled_diagram = auth.user.email !== node_diagram?.owner_email;
                const unit = _.chain(diagramTypeOptions).find({ value: node_diagram?.type }).get("unit").defaultTo("-").value();
                return { disabled_diagram, unit };
            }
        }
        return { disabled_diagram: true, unit: "-" };
    }, [diagrams, node_db, auth]);

    const customMainStyle = type === "equipmentUnlink" ? { border: "dashed 1px" } : null;
    const nodeStyle = { flex: 1, display: "flex", flexDirection: "column", margin: 0 };
    const nodeStyleSelected = {
        border: "2px solid var(--foundational-primary)",
        boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)"
    };

    const full_equipment = useMemo(() => {
        if (equipments.isSuccess) {
            const equipment = _.find(equipments.data, { id: node_db.equipment });
            return equipment || null;
        }
        return null;
    }, [equipments, node_db]);

    return (
        <>
            {node_db.parent && <Handle type="target" position={Position.Top} style={{ zIndex: 1000 }} isConnectable={false} />}
            {type === "equipmentUnlink" && (
                <Segment
                    color={selected ? "green" : null}
                    style={{ ...nodeStyle, ...customMainStyle, ...(draged ? nodeStyleSelected : {}) }}
                    id={`node-container-${props.id}`}
                >
                    <NodeContent id={props.id} selected={selected} node_db={node_db} unit={unit} disabled_diagram={disabled_diagram} />
                </Segment>
            )}
            {type === "equipmentLink" && (
                <Popup
                    basic
                    position="top center"
                    style={{ padding: 0 }}
                    trigger={
                        <Segment
                            color={selected ? "green" : null}
                            style={{ ...nodeStyle, ...customMainStyle, ...(draged ? nodeStyleSelected : {}) }}
                            id={`node-container-${props.id}`}
                        >
                            <NodeContent id={props.id} selected={selected} node_db={node_db} unit={unit} disabled_diagram={disabled_diagram} />
                        </Segment>
                    }
                >
                    <Popup.Content>
                        <Header attached="top" block>
                            {node_db.equipment_name}
                        </Header>
                        <Segment attached>{full_equipment !== null && <Infos equipment={full_equipment} display="list" />}</Segment>
                    </Popup.Content>
                </Popup>
            )}
            {data.node_db.parent !== null && !disabled_diagram && (
                <Handle
                    onMouseDown={() => {
                        setDraged(true);
                    }}
                    onMouseUp={() => {
                        setDraged(false);
                    }}
                    style={{
                        background: draged ? "var(--foundational-primary)" : "var(--foundational-icon-disabled)",
                        border: "none",
                        borderRadius: "10px",
                        width: "25px",
                        height: "13px",
                        zIndex: 1000,
                        transform: "translateY(-40%) translateX(-50%)"
                    }}
                    type="target"
                    position={Position.Top}
                />
            )}
            <Handle
                style={{
                    border: "none",
                    background: "var(--foundational-icon-disabled)",
                    width: "10px",
                    height: "10px"
                }}
                isConnectableEnd={true}
                isConnectableStart={false}
                type="source"
                position={Position.Bottom}
            />
        </>
    );
};

export default GenericNode;
