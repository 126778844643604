import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import moment from "moment";
import _ from "lodash";
import cookie from "react-cookies";

export const alertApi = defaultApi.injectEndpoints({
    reducerPath: "apiAlert",
    endpoints: (build) => ({
        getAlerts: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `alerts?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response) => {
                return _.map(response, (alert) => {
                    //add owner copy of user for checkRights mutualization
                    return { ...alert, owner: alert.user };
                });
            },
            providesTags: (result, error, arg) => {
                if (!error) return [{ type: "Alerts", id: arg.org.id }];
                return [];
            }
        }),
        getAlert: build.query({
            query: ({ org, alert_id }) => {
                const current_org = _.get(org, "name", null);
                return `alerts/${alert_id}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                if (!error) return [{ type: "Alert", id: arg.alert_id }];
                return [];
            },
            transformResponse: (response) => {
                return { ...response, duration_threshold: response.duration_threshold / 60 };
            }
        }),
        createAlert: build.mutation({
            query: ({ org, data, user }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `alerts?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: {
                        ...data,
                        duration_threshold: data.duration_threshold * 60,
                        user: user.user_id
                    }
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [{ type: "Alerts", id: arg.org.id }];
                }
                return [];
            }
        }),
        updateAlert: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `alerts/${data.id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: { ...data, duration_threshold: data.duration_threshold * 60 }
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "Alerts", id: arg.org.id },
                        { type: "Alert", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        deleteAlert: build.mutation({
            query: ({ org, alert_id }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `alerts/${alert_id}?org=${current_org}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "Alerts", id: arg.org.id },
                        { type: "Alert", id: arg.alert_id }
                    ];
                }
                return [];
            }
        })
    }),
    overrideExisting: false
});

export const { useGetAlertsQuery, useGetAlertQuery, useCreateAlertMutation, useUpdateAlertMutation, useDeleteAlertMutation } = alertApi;
