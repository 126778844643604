import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Button, Icon, Menu, Popup } from "semantic-ui-react";
import { faRuler } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { co2ConversionRate, priceConversionRate } from "modules/common/utils";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetCategoriesQuery } from "modules/category/categoryService";

export const convertOptions = [
    { key: 0, value: 0, icon: "area graph", text: t`default display` },
    { key: 1, value: 1, icon: "currency", text: t`currency display` },
    { key: 2, value: 2, icon: "cloud", text: t`co2 display` }
];

export const ConsumptionsButton = (props) => {
    const { series, setSeries, measure } = props;
    const org = useSelector((state) => state.org);
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(0);

    const { site_conversions, category_name, mt_type_name } = useMemo(() => {
        if (dataflows.isSuccess && sites.isSuccess && categories.isSuccess) {
            const df = _.find(dataflows.data, { id: measure.dataflow });
            const site = _.find(sites.data, { id: df.site });
            const site_conversions = site?.conversions ?? null;
            const df_spec = _.find(categories.data, { id: df.dataflowspec });
            const category_name = df_spec?.name;
            return { site_conversions, category_name, mt_type_name: measure?.measurementtype?.name ?? "" };
        }
        return { site_conversions: null, category_name: "", mt_type_name: measure?.measurementtype?.name ?? "" };
    }, [dataflows, sites, categories, measure]);

    const changeDisplay = (e, data) => {
        setDisplay(data.value);
        if (data.value === 0) {
            setSeries(series);
        } else if (data.value === 1) {
            const transformSeries = _.chain(series)
                .map((serie) => {
                    const transformData = _.chain(serie)
                        .get("data", [])
                        .map((record) => {
                            return {
                                ...record,
                                y: priceConversionRate(record.y / record.factor, site_conversions, category_name, mt_type_name, i18n, false),
                                y_real: priceConversionRate(
                                    record.y_real / record.factor,
                                    site_conversions,
                                    category_name,
                                    mt_type_name,
                                    i18n,
                                    false
                                ),
                                unit: _.get(site_conversions, "currency", "-")
                            };
                        })
                        .value();

                    return {
                        ...serie,
                        title: `${i18n._(serie.name)} (${_.get(site_conversions, "currency", "-")})`,
                        data: transformData,
                        unit: _.get(site_conversions, "currency", "-")
                    };
                })
                .value();
            setSeries(transformSeries);
        } else if (data.value === 2) {
            const transformSeries = _.chain(series)
                .map((serie) => {
                    const transformData = _.chain(serie)
                        .get("data", [])
                        .map((record) => {
                            return {
                                ...record,
                                y: co2ConversionRate(record.y / record.factor, site_conversions, category_name, mt_type_name, i18n, false),
                                y_real: co2ConversionRate(record.y_real / record.factor, site_conversions, category_name, mt_type_name, i18n, false),
                                unit: "kgCo2e"
                            };
                        })
                        .value();
                    return {
                        ...serie,
                        data: transformData,
                        title: `${i18n._(serie.name)} (kgCo2e)`,
                        unit: "kgCo2e"
                    };
                })
                .value();
            setSeries(transformSeries);
        }
    };

    return (
        <Popup
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            flowing
            hoverable
            trigger={
                <Button icon className="no-print">
                    <FontAwesomeIcon icon={faRuler} className="icon" />
                </Button>
            }
            style={{ padding: 0 }}
        >
            <Popup.Content>
                <Menu vertical compact fluid>
                    {_.map(convertOptions, (option) => {
                        return (
                            <Menu.Item key={option.value} value={option.value} active={display === option.value} onClick={changeDisplay}>
                                <Icon name={option.icon} />
                                {i18n._(option.text)}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            </Popup.Content>
        </Popup>
    );
};

export default ConsumptionsButton;
