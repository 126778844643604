import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";

export const machinetypeApi = defaultApi.injectEndpoints({
    reducerPath: "apiMachinetype",
    endpoints: (build) => ({
        getMachinetypes: build.query({
            keepUnusedDataFor: 86400,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `machinetypes?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: ["Machinetype"],
            transformResponse: (response) => {
                return _.map(response, (machinetype) => ({
                    ...machinetype,
                    key: machinetype.id,
                    text: machinetype.name,
                    value: machinetype.id,
                    type: "machinetype"
                }));
            }
        })
    }),
    overrideExisting: false
});

export const { useGetMachinetypesQuery } = machinetypeApi;
