import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";

import MeasurementForm from "../form/MeasurementForm";

const AddMeasurementModal = (props) => {
    const { setOpen, equipment } = props;
    const org = useSelector((state) => state.org);

    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current, hasFilter: false }, { skip: !org.current });

    const measurementsObject = useMemo(() => {
        if (measurements.isSuccess && dataflows.isSuccess && measurementtypes.isSuccess && equipments.isSuccess) {
            return _.reduce(
                measurements.data,
                (res, measure) => {
                    const df = _.find(dataflows.data, { id: measure?.dataflow });
                    const mttype = _.find(measurementtypes.data, { id: measure?.measurementtype });
                    const eqt = _.find(equipments.data, { id: df?.equipment });
                    const representation = df && mttype ? `${df?.name} - ${mttype.repr}` : "-";
                    const new_measure = { key: measure?.id, text: representation, value: String(measure?.id) };
                    res[measure.id] = { ...measure, ...new_measure, dataflow: df, measurementtype: mttype, equipment: eqt };
                    return res;
                },
                {}
            );
        }
        return {};
    }, [measurements, dataflows, measurementtypes, equipments]);

    return (
        <Modal open centered={false} onClose={(e) => setOpen(false)} size="fullscreen">
            <Modal.Header>
                <Trans>add calculated measure</Trans>
            </Modal.Header>
            <Modal.Content>
                <MeasurementForm setOpen={setOpen} equipment={equipment} measurementsObject={measurementsObject} />
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(AddMeasurementModal);
