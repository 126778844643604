import React, { useState } from "react";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Card, Segment } from "semantic-ui-react";
import { DiscreteColorLegend, FlexibleXYPlot, Hint, HorizontalGridLines, VerticalBarSeries, XAxis, YAxis } from "react-vis";

import i18n from "modules/i18n/i18nConfig";
import GenerateCsv from "modules/common/components/GenerateCsv";
import { Media } from "App";
import MessageDisplay from "modules/common/components/MessageDisplay";

const GraphicElecDistribution = (props) => {
    const { data } = props;

    const [series, setSeries] = useState(_.orderBy(data.data, "title", "asc"));
    const [hintValue, setHintValue] = useState(null);

    if (_.size(series) === 0) {
        return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />;
    }

    const formatNum = (num) => {
        return i18n.number(num, { maximumFractionDigits: 2 });
    };

    const tooltip = () => {
        return (
            <Hint value={hintValue}>
                <Card className="pwaOverviewTooltip" style={{ width: 160 }}>
                    <Card.Content>
                        <Card.Header>
                            <span>
                                <Trans>power interval</Trans> ({hintValue.unit})
                            </span>
                            <br />
                            <span>[{` ${formatNum(hintValue.b0)} – ${formatNum(hintValue.b1)} `}]</span>
                        </Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Card.Description>
                            {hintValue.y !== null ? `${i18n.number(hintValue.y, { maximumFractionDigits: 2 })} %` : "-"}
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Hint>
        );
    };

    const clickHandler = (serie_clk) => {
        const size_disable = _.chain(series)
            .filter((item) => item.disabled === false)
            .size()
            .value();
        const update_series = _.map(series, (item) => {
            if (item.title === _.get(serie_clk, "title")) return { ...item, disabled: size_disable <= 1 ? false : !item.disabled };
            return item;
        });
        setSeries(update_series);
    };

    return (
        <>
            <Segment attached textAlign="right">
                <Media greaterThanOrEqual="computer">
                    <GenerateCsv
                        series={_.map(series, (item) => {
                            return { ...item, title: `${item.name} (%)` };
                        })}
                        unit={_.chain(series).head().get("unit", "-").value()}
                        formatted_time={false}
                        filename={props.csvName || "export"}
                    />
                </Media>
            </Segment>
            <Segment attached>
                <FlexibleXYPlot xType="linear" height={460} margin={{ left: 60, right: 10, top: 10, bottom: 100 }}>
                    <DiscreteColorLegend className="pwaLegend400" orientation="horizontal" height={75} items={series} onItemClick={clickHandler} />
                    <HorizontalGridLines />
                    <XAxis
                        title={i18n._(t`power`)}
                        tickLabelAngle={-20}
                        tickValues={data.edges}
                        tickFormat={(value, index, scale, tickTotal) => {
                            return `${i18n.number(value, {
                                maximumFractionDigits: 2
                            })}`;
                        }}
                    />
                    {_.chain(series)
                        .filter((item) => item.disabled === false)
                        .map((serie, idx) => {
                            return (
                                <VerticalBarSeries
                                    key={idx}
                                    color={serie.color}
                                    fill={serie.color}
                                    getNull={(d) => d.y !== null}
                                    data={serie.data}
                                    onValueMouseOver={(hintValue) => {
                                        setHintValue(hintValue);
                                    }}
                                    onValueMouseOut={() => {
                                        setHintValue(null);
                                    }}
                                />
                            );
                        })
                        .value()}
                    {hintValue ? tooltip() : null}
                    <YAxis title={"%"} />
                </FlexibleXYPlot>
                {!_.isUndefined(props.children) && (
                    <Segment basic style={{ marginTop: "15px" }}>
                        {props.children}
                    </Segment>
                )}
            </Segment>
        </>
    );
};

export default React.memo(GraphicElecDistribution);
