import React from "react";
import { Modal } from "semantic-ui-react";
import { Trans } from "@lingui/macro";
import EquipmentAddForm from "modules/equipment/components/form/EquipmentAddForm";

const AddEquipmentModal = (props) => {
    const { setOpen } = props;
    return (
        <Modal open centered={false} onClose={(e) => setOpen(false)} size="fullscreen">
            <Modal.Header>
                <Trans>add equipment and calculated measure</Trans>
            </Modal.Header>
            <Modal.Content>
                <EquipmentAddForm setOpen={setOpen} />
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(AddEquipmentModal);
