import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import Datetime from "react-datetime";
import { Button, Container, Icon, Input, Label, Modal, Header, Checkbox, Grid } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { Media } from "App";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useGenerateReportMutation } from "../reportService";

/**
 * Used for displaying error on datepicker
 * @function dateError
 * @param {any} elem - Moment object or string if invalid
 * @return {boolean}
 */
const dateError = (elem) => {
    if (typeof elem === "object") {
        return !elem.isValid();
    }
    return true;
};

/**
 * Used of customizing input element with semantic UI
 * @function renderInput
 * @param {object} props - extra props for input
 * @param {func} openCalendar - callback when open calendar
 * @param {func} closeCalendar - callback when close calendar
 */
const renderInput = (props, openCalendar, closeCalendar) => {
    const { err, ...rest } = props;
    return (
        <div>
            {err ? (
                <Label pointing={"below"} basic color="red">
                    <Trans>invalid day</Trans>
                </Label>
            ) : (
                ""
            )}
            <Input fluid error={err} icon="calendar" label={{ content: rest.name }} labelPosition="left" {...rest} />
        </div>
    );
};

const Generate = (props) => {
    const { report, org } = props;
    const now = moment().startOf("hour");
    const [open, setOpen] = useState(false);
    const [end, setEnd] = useState(now);
    const [userOnly, setUserOnly] = useState(false);
    const current_lng = useSelector((state) => state.i18n.current);

    const [generateReport, { isError, isSuccess, isLoading }] = useGenerateReportMutation();

    useEffect(() => {
        if (isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (isSuccess) {
            toast.success(i18n._(t`report check your email`), toast_options);
        }
        if (isError) {
            toast.error(i18n._(t`cannot generate report`), toast_options_err);
        }
    }, [isError, isSuccess, isLoading]);

    const handleGenerate = async (event, data) => {
        await generateReport({ org, report_id: report.id, end: end.format("Y-M-D"), userOnly });
        setOpen(false);
        setUserOnly(false);
    };

    return (
        <Modal
            onClose={() => {
                setOpen(false);
                setUserOnly(false);
            }}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button icon="share square" />}
        >
            <Modal.Header>
                <Trans>report generation</Trans>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header>
                        <Trans>select date for report generation</Trans>
                    </Header>
                    <Container text>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Datetime
                                        timeFormat={false}
                                        locale={current_lng}
                                        value={end}
                                        closeOnSelect={true}
                                        onChange={(moment_obj) => setEnd(moment_obj)}
                                        renderInput={renderInput}
                                        inputProps={{
                                            name: `${i18n._(t`date`)} :`,
                                            err: dateError(end)
                                        }}
                                        isValidDate={(current) => {
                                            return current.isBefore(now);
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <Checkbox
                                        label={i18n._(t`generate for me`)}
                                        onChange={(e, data) => setUserOnly(data.checked)}
                                        checked={userOnly}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Media greaterThanOrEqual="tablet">
                    {(mediaClassNames, renderChildren) =>
                        renderChildren && (
                            <>
                                <Button
                                    negative
                                    icon
                                    labelPosition="left"
                                    onClick={() => {
                                        setOpen(false);
                                        setUserOnly(false);
                                    }}
                                >
                                    <Icon name="arrow left" />
                                    <Trans>cancel</Trans>
                                </Button>
                                <Button positive icon labelPosition="right" onClick={handleGenerate} disabled={dateError(end)}>
                                    <Icon name="send" />
                                    <Trans>generate</Trans>
                                </Button>
                            </>
                        )
                    }
                </Media>
                <Media lessThan="tablet">
                    {(mediaClassNames, renderChildren) =>
                        renderChildren && (
                            <>
                                <Button
                                    negative
                                    icon
                                    onClick={() => {
                                        setOpen(false);
                                        setUserOnly(false);
                                    }}
                                >
                                    <Icon name="arrow left" />
                                </Button>
                                <Button positive icon onClick={handleGenerate} disabled={isLoading}>
                                    <Icon name="send" />
                                </Button>
                            </>
                        )
                    }
                </Media>
            </Modal.Actions>
        </Modal>
    );
};

Generate.propTypes = {
    report: PropTypes.object.isRequired,
    org: PropTypes.object.isRequired
};

export default Generate;
