import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Dropdown } from "semantic-ui-react";
import { t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { changeTheme } from "../actions";

const themeOptions = [
    //switch order when 'default' will be used as 'default return' in getTheme()
    { key: "old", value: "old", text: t`old` },
    { key: "default", value: "default", text: t`default` }
];

const ThemeSwitch = (props) => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.common.theme);
    const onChangeTheme = (event, { value }) => {
        dispatch(changeTheme(value));
    };

    return (
        <Dropdown
            inline
            item
            options={_.map(themeOptions, ({ key, value, text }) => ({ key, value, text: i18n._(text) }))}
            onChange={onChangeTheme}
            value={theme}
        />
    );
};

export default React.memo(ThemeSwitch);
