import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { toast } from "react-toastify";
import _ from "lodash";
import { Grid, Header, Segment, Button, Container } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import {
    useGetAlertPackQuery,
    useCreateAlertPackMutation,
    useUpdateAlertPackMutation,
    useGetAlertPackOverrideQuery,
    useGetAlertPackTypesQuery
} from "../alertPackService";
import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { removeAccents } from "modules/common/utils";
import { checkRights } from "modules/auth/utils";
import { reparseNumber } from "modules/common/utils";
import { keyToReparseInPack } from "../utils";
import { defaultLng } from "modules/i18n/i18nConfig";
import history_app from "history_app";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { ToggleAdapter, DropDownAdapter } from "modules/common/components/form";
import MailingAddModalForm from "modules/mailing/components/MailingAddModalForm";
import AlertSensor from "./Templates/alert_sensor/AlertSensor";
import AlertSensorOverride from "./Templates/alert_sensor/AlertSensorOverride";
import ElecDistribution from "./Templates/elec_distri/ElecDistribution";
import ElecDistributionOverride from "./Templates/elec_distri/ElecDistributionOverride";
import ElecDysfunction from "./Templates/elec_dysfunction/ElecDysfunction";
import ElecDysfunctionOverride from "./Templates/elec_dysfunction/ElecDysfunctionOverride";
import AlertPredict from "./Templates/alert_predict/AlertPredict";
import AlertPredictOverride from "./Templates/alert_predict/AlertPredictOverride";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const RenderTemplates = React.memo(({ values, form }) => {
    const { type } = values;
    switch (type) {
        case 1:
            return <AlertSensor />;
        case 2:
            return <ElecDistribution />;
        case 3:
            return <ElecDysfunction form={form} />;
        case 4:
            return <AlertPredict />;
        default:
            return null;
    }
});

const RenderAdditionnalTemplate = React.memo((props) => {
    const { initial, rights, overrides, notification } = props;
    switch (initial?.type) {
        case 1:
            return <AlertSensorOverride pack={initial} rights={rights} overrides={overrides} notification={notification} />;
        case 2:
            return <ElecDistributionOverride pack={initial} rights={rights} overrides={overrides} notification={notification} />;
        case 3:
            return <ElecDysfunctionOverride pack={initial} rights={rights} overrides={overrides} notification={notification} />;
        case 4:
            return <AlertPredictOverride pack={initial} rights={rights} overrides={overrides} notification={notification} />;
        default:
            return null;
    }
});

const AlertPack = (props) => {
    const { sites, mailings } = props;
    const { id_pack } = useParams();
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    const current_lng = useSelector((state) => state.i18n.current);
    const lng_options = useSelector((state) => state.i18n.options);
    const getAlertpack = useGetAlertPackQuery({ org: org.current, id: id_pack }, { skip: !org.current || !id_pack });
    const getAlertpackOverride = useGetAlertPackOverrideQuery({ org: org.current, id: id_pack }, { skip: !org.current || !id_pack });
    const getAlertpacktype = useGetAlertPackTypesQuery({ org: org.current }, { skip: !org.current });

    const [createAlertPack, create] = useCreateAlertPackMutation();
    const [updateAlertPack, update] = useUpdateAlertPackMutation();

    const { initial, rights, can_change } = useMemo(() => {
        const initial = {
            active: true,
            site: null,
            type: null,
            language: current_lng || defaultLng,
            configuration: {},
            mailinglist: [],
            ...getAlertpack.data
        };
        const rights = checkRights(getAlertpack.data, auth.rights);
        const can_change = notification.srv_status.db_status === "rw" && _.includes(rights, "can_change");
        return { initial, rights, can_change };
    }, [getAlertpack.data, current_lng, auth, notification]);

    // Create Alert pack
    useEffect(() => {
        if (create.isSuccess) {
            toast.success(i18n._(t`new alert pack added`), toast_options);
            history_app.push("/alerts_packs");
        }
        if (create.isError) {
            let error = i18n._(t`cannot add alert pack`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [create]);

    // Update Alert pack
    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`Send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`Successful update alert pack`), toast_options);
            history_app.push(`/alerts_packs`);
        }
        if (update.isError) {
            let error = i18n._(t`Can't update alert pack`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    const submitForm = async (formData) => {
        if (notification.srv_status.db_status === "rw") {
            const reparseConfig = _.mapValues(formData?.configuration, (item, key) => {
                if (_.includes(keyToReparseInPack, key)) {
                    if (key === "duration") {
                        return reparseNumber(item) * 60; // Convertir les heures en secondes pour l'API
                    }
                    return reparseNumber(item);
                }
                return item;
            });

            const newFormData = { ...formData, configuration: reparseConfig };

            if (_.get(formData, "id") === undefined) {
                await createAlertPack({ org: org.current, data: newFormData });
            } else {
                await updateAlertPack({ org: org.current, data: newFormData });
            }
        }
    };

    const mailings_id = useMemo(() => {
        return _.map(mailings, (item) => item.id);
    }, [mailings]);

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {id_pack === undefined && <Trans>add pack</Trans>}
                            {id_pack !== undefined && <Trans>update pack</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Container>
                            <Form
                                onSubmit={submitForm}
                                initialValues={initial}
                                render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                                    const not_visible_mailings = _.chain(values.mailinglist).difference(mailings_id).size().value() > 0;
                                    return (
                                        <>
                                            <Header as="h2" attached="top" block textAlign="center">
                                                <Trans>Alert definition</Trans>
                                            </Header>
                                            <Segment attached>
                                                <form onSubmit={handleSubmit} className="ui form">
                                                    <Field
                                                        name="active"
                                                        label={i18n._(t`is_active`)}
                                                        labelposition="right"
                                                        component={ToggleAdapter}
                                                        defaultValue={false}
                                                    />
                                                    <Field
                                                        name="site"
                                                        label={i18n._(t`site`)}
                                                        placeholder={i18n._(t`select site`)}
                                                        options={_.chain(sites)
                                                            .map(({ key, text, value }) => ({ key, text, value }))
                                                            .orderBy((item) => {
                                                                return removeAccents(item.text).toLowerCase();
                                                            }, "asc")
                                                            .value()}
                                                        component={DropDownAdapter}
                                                        disabled={!_.isUndefined(id_pack)}
                                                        isRequired={true}
                                                        validate={(value) => {
                                                            if (value === null) return <Trans>site is required</Trans>;
                                                            return undefined;
                                                        }}
                                                    />
                                                    <Field
                                                        name="type"
                                                        label={i18n._(t`packType`)}
                                                        placeholder={i18n._(t`select pack type`)}
                                                        options={getAlertpacktype.data}
                                                        component={DropDownAdapter}
                                                        disabled={!_.isUndefined(id_pack)}
                                                        isRequired={true}
                                                        validate={(value) => (!value ? <Trans>packType is required</Trans> : undefined)}
                                                    />
                                                    <Field
                                                        name="language"
                                                        label={i18n._(t`language`)}
                                                        placeholder={i18n._(t`select pack language`)}
                                                        options={lng_options}
                                                        component={DropDownAdapter}
                                                        isRequired={true}
                                                        validate={(value) => (!value ? <Trans>language is required</Trans> : undefined)}
                                                    />
                                                    <Grid centered verticalAlign="bottom" textAlign="center">
                                                        <Grid.Column
                                                            mobile={can_change ? 13 : 16}
                                                            tablet={can_change ? 15 : 16}
                                                            computer={can_change ? 15 : 16}
                                                        >
                                                            <Field
                                                                name="mailinglist"
                                                                label={i18n._(t`mailinglists`)}
                                                                placeholder={i18n._(t`choose mailinglists`)}
                                                                options={mailings}
                                                                multipleselect={1}
                                                                component={DropDownAdapter}
                                                                noResultsMessage={i18n._(t`no mailinglists found`)}
                                                                validate={(value) =>
                                                                    _.size(value) === 0 ? <Trans>mailinglist can't be empty</Trans> : undefined
                                                                }
                                                            />
                                                        </Grid.Column>
                                                        {can_change && (
                                                            <Grid.Column width={1} textAlign="center">
                                                                <MailingAddModalForm form={form} mailings={mailings} />
                                                            </Grid.Column>
                                                        )}
                                                    </Grid>
                                                    {not_visible_mailings && (
                                                        <div style={{ color: "#794b02", fontStyle: "italic" }}>
                                                            <Trans>Some mailing lists are present but you do not have permission to view them</Trans>
                                                        </div>
                                                    )}
                                                    <RenderTemplates values={values} form={form} />
                                                    {can_change && (
                                                        <Segment basic>
                                                            <Grid centered verticalAlign="top">
                                                                {_.isUndefined(id_pack) && (
                                                                    <Grid.Column width={16}>
                                                                        <MessageDisplay
                                                                            message={i18n._(t`exception_after_submit_alert`)}
                                                                            level="info"
                                                                            iconName="warning"
                                                                            isLoading={false}
                                                                            attached={false}
                                                                        />
                                                                    </Grid.Column>
                                                                )}
                                                                <Grid.Column width={16}>
                                                                    <Button
                                                                        type="submit"
                                                                        content={i18n._(t`validate`)}
                                                                        positive
                                                                        disabled={submitting || pristine || invalid}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid>
                                                        </Segment>
                                                    )}
                                                </form>
                                            </Segment>
                                            {!_.isUndefined(id_pack) && (
                                                <RenderAdditionnalTemplate
                                                    initial={initial}
                                                    rights={rights}
                                                    overrides={getAlertpackOverride.data}
                                                    notification={notification}
                                                />
                                            )}
                                        </>
                                    );
                                }}
                            />
                        </Container>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default React.memo(AlertPack);
