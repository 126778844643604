import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { Grid, Header } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { dateRangeOptions, getTimedelta } from "modules/time/utils";
import { setTime } from "modules/overview/overviewSlice";
import { useGetDetailEfficiencyQuery, useGetSummaryEfficiencyQuery } from "modules/overview/overviewService";

import TimePanel from "modules/common/components/TimePanel";
import MessageDisplay from "modules/common/components/MessageDisplay";
import GraphicDetail from "../graphic/GraphicDetail";
import GraphicSummary from "../graphic/GraphicSummary";
import { useGetEquipmentEfficiencyQuery } from "modules/equipment/equipmentService";

const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(["24h", "48h", "72h"], item.value));

const TabEfficiency = (props) => {
    const { default_time, equipment } = props;
    const dispatch = useDispatch();
    const org = useSelector((state) => state.org);
    const { powertime } = useSelector((state) => state.overview);

    const efficiency = useGetEquipmentEfficiencyQuery(
        { org: org.current, data: equipment?.efficiency },
        { skip: !org.current || !_.isFinite(equipment?.efficiency) }
    );

    const rangeTime = powertime.time
        ? {
              start: moment(powertime.time.start),
              end: moment(powertime.time.end),
              plage: powertime.time.plage
          }
        : default_time;

    const detail = useGetDetailEfficiencyQuery(
        { org: org.current, efficiency: efficiency.data, start: rangeTime?.start?.toISOString(), end: rangeTime?.end?.toISOString() },
        { skip: !org.current || _.isUndefined(efficiency.data) || efficiency.isSuccess === false }
    );

    const new_range_time = getTimedelta(rangeTime?.start.toISOString(), rangeTime?.end?.toISOString());
    const summary = useGetSummaryEfficiencyQuery(
        {
            org: org.current,
            efficiency: efficiency.data,
            start: new_range_time?.start?.format("YYYY-MM-DD"),
            end: new_range_time?.end?.format("YYYY-MM-DD")
        },
        { skip: true || !org.current || _.isUndefined(efficiency.data) || efficiency.isSuccess === false }
    );

    return (
        <Grid id="pwaMain" verticalAlign="middle" centered>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <TimePanel
                        time={rangeTime}
                        rangeOptions={rangeOptions}
                        accordion={true}
                        action={({ start, end, plage }) => {
                            dispatch(setTime({ start, end, plage, tab: "powertime" }));
                        }}
                        date_limit={moment()}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                    <Header as="h3" block textAlign="center" attached="top">
                        {efficiency.data?.name}
                    </Header>
                    {(detail.isFetching || efficiency.isFetching) && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {(detail.isError || efficiency.isError) && (
                        <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                    )}
                    {efficiency.isSuccess && detail.isSuccess && !detail.isFetching && (
                        <GraphicDetail time={rangeTime} data={detail.data} csvName={equipment?.name ?? "export"} />
                    )}
                </Grid.Column>
            </Grid.Row>
            {false && (
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={14} computer={14} textAlign="center" className="pwaGraph">
                        <Header as="h3" block textAlign="center" attached="top">
                            <Trans>long term trend</Trans>
                        </Header>
                        {(summary.isFetching || efficiency.isFetching) && (
                            <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        )}
                        {(summary.isError || efficiency.isError) && (
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        )}
                        {efficiency.isSuccess && summary.isSuccess && !summary.isFetching && (
                            <GraphicSummary time={rangeTime} data={summary.data} csvName={equipment?.name ?? "export"} />
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}
        </Grid>
    );
};

export default TabEfficiency;
