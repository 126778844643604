import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import moment from "moment";
import _ from "lodash";
import cookie from "react-cookies";

export const zoneApi = defaultApi.injectEndpoints({
    reducerPath: "apiZone",
    endpoints: (build) => ({
        getZones: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `zones?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "Zones", id: arg.org.id }];
                return [];
            },
            transformResponse: (response) => {
                //Map data for semantic usage
                return _.map(response, (zone) => {
                    return {
                        ...zone,
                        description: zone.description || "",
                        key: zone.id,
                        text: `${zone.name} - ${zone.site_name}`,
                        value: zone.id,
                        site: zone.site_id,
                        site_id: zone.site_id,
                        zone_name: zone.zone_name,
                        type: "area"
                    };
                });
            }
        }),
        addZone: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `zones?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "Zones", id: arg.org.id },
                        { type: "Sites", id: arg.org.id }
                    ];
                }
                return [];
            }
        }),
        updateZone: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `zones/${data.id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "Zone", id: arg.data.id },
                        { type: "Zones", id: arg.org.id },
                        { type: "Sites", id: arg.org.id }
                    ];
                }
                return [];
            }
        }),
        deleteZone: build.mutation({
            query: ({ org, data, confirm }) => {
                const current_org = _.get(org, "name", null);
                const url = `zones/${data}?org=${current_org}${confirm ? "&confirm=yes" : ""}&${refreshTimestampUrlParam()}`;
                return {
                    url,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error && arg.confirm) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "Zone", id: arg.data },
                        { type: "Zones", id: arg.org.id },
                        { type: "Sites", id: arg.org.id }
                    ];
                }
                return [];
            }
        })
    }),
    overrideExisting: false
});

export const { useGetZonesQuery, useDeleteZoneMutation, useAddZoneMutation, useUpdateZoneMutation } = zoneApi;
