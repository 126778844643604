import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { Menu, Icon, Popup } from "semantic-ui-react";

//import { djangoAdmin } from "apis/PowerAdapt";
import { useLogoutUserMutation } from "modules/auth/authService";

const navItemsGenerator = (path, org, user, current_lng) => {
    return [
        {
            icon: "home",
            title: <Trans>home</Trans>,
            onClick: null,
            path: `${path}`,
            display: true
        },
        {
            icon: "dashboard",
            title: <Trans>dashboard</Trans>,
            onClick: null,
            path: `${path}dashboard`,
            display: true
        },
        {
            icon: "chart pie",
            title: <Trans>globalview</Trans>,
            onClick: null,
            path: `${path}globalview`,
            display: true
        },
        {
            icon: "grid layout",
            title: <Trans>customdashboard</Trans>,
            onClick: null,
            path: `${path}customdashboard`,
            display: true
        },
        {
            icon: "sitemap",
            title: <Trans>hierarchy</Trans>,
            onClick: null,
            path: `${path}hierarchy`,
            display: true
        },
        {
            icon: "chart bar",
            title: <Trans>advancedanalytics</Trans>,
            onClick: null,
            path: `${path}advancedanalytics`,
            display: true
        },
        {
            icon: "file",
            title: <Trans>reports</Trans>,
            onClick: null,
            path: `${path}reports`,
            display: true
        },
        {
            icon: "chart line",
            title: <Trans>energysavings</Trans>,
            onClick: null,
            path: `${path}energysavings`,
            display: true
        },
        {
            icon: "envelope open",
            title: <Trans>exports</Trans>,
            onClick: null,
            path: `${path}exports`,
            display: true
        },
        {
            icon: "bullhorn",
            title: <Trans>alerts_activity</Trans>,
            onClick: null,
            path: `${path}alerts_activity`,
            display: true
        },
        {
            icon: "alarm",
            title: <Trans>alerts_packs</Trans>,
            onClick: null,
            path: `${path}alerts_packs`,
            display: true
        },
        {
            icon: "building",
            title: <Trans>manage</Trans>,
            onClick: null,
            path: `${path}manage`,
            display: true
        },
        {
            icon: "whmcs",
            title: <Trans>equipments</Trans>,
            onClick: null,
            path: `${path}equipments`,
            display: true
        },
        {
            icon: "microchip",
            title: <Trans>sensors</Trans>,
            onClick: null,
            path: `${path}sensors`,
            display: true
        },
        {
            icon: "user",
            title: <Trans>account</Trans>,
            path: `${path}users/${user.user_id}/change`,
            display: true
        }
    ];
};

export const SideBarItems = (props) => {
    const { path } = props;
    const auth = useSelector((state) => state.auth);
    const { user, rights } = auth;
    const org = useSelector((state) => state.org.current);
    const current_lng = useSelector((state) => state.i18n.current);
    const theme = useSelector((state) => state.common.theme);

    const [logoutUser] = useLogoutUserMutation();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isPopupDisabled = windowWidth < 991; // breakpoint for disabling Popup
    const renderMenu = useMemo(() => {
        const popupStyle = {
            borderRadius: "0px .3rem .3rem 0px",
            color: "var(--foundational-primary)",
            height: "47px",
            paddingTop: "14px",
            fontWeight: "bold"
        };

        return (
            <>
                {!_.isNull(org) &&
                    auth.isVerified &&
                    rights?.pw_expired === false &&
                    _.chain(navItemsGenerator(path, org, user, current_lng))
                        .filter((item) => item.display === true)
                        .map((item, idx) => {
                            if (theme === "old") {
                                return (
                                    <Menu.Item key={idx} as={item.path ? Link : null} to={item.path} onClick={item.onClick}>
                                        <Icon name={item.icon} flipped={item?.flipped} />
                                        {item.title}
                                    </Menu.Item>
                                );
                            }
                            return (
                                <div className="pwaSidebar__item-wrapper" key={idx}>
                                    <Popup
                                        style={popupStyle}
                                        offset={[0, -23]}
                                        basic
                                        disabled={isPopupDisabled}
                                        size="tiny"
                                        position="right center"
                                        content={item.title}
                                        trigger={
                                            <Menu.Item as={item.path ? Link : null} to={item.path} onClick={item.onClick}>
                                                <div className="pwaSidebar__items">
                                                    <Icon
                                                        fitted
                                                        size="large"
                                                        className="pwaSidebar__item-icon"
                                                        name={item.icon}
                                                        flipped={item?.flipped}
                                                    />
                                                    <div className="pwaSidebar__item-title">{item.title}</div>
                                                </div>
                                            </Menu.Item>
                                        }
                                    />
                                </div>
                            );
                        })
                        .value()}
                {theme === "old" ? (
                    <Menu.Item
                        onClick={() => {
                            logoutUser();
                        }}
                    >
                        <Icon name="log out" />
                        <Trans>log out</Trans>
                    </Menu.Item>
                ) : (
                    <div className="pwaSidebar__item-wrapper">
                        <Popup
                            style={popupStyle}
                            offset={[0, -23]}
                            basic
                            disabled={isPopupDisabled}
                            size="tiny"
                            position="right center"
                            content={<Trans>log out</Trans>}
                            trigger={
                                <Menu.Item
                                    onClick={() => {
                                        logoutUser();
                                    }}
                                >
                                    <div className="pwaSidebar__items">
                                        <Icon fitted size="large" className="pwaSidebar__item-icon" name="log out" />
                                        <div className="pwaSidebar__item-title">
                                            <Trans>log out</Trans>
                                        </div>
                                    </div>
                                </Menu.Item>
                            }
                        />
                    </div>
                )}
            </>
        );
    }, [theme, auth, current_lng, org, path, rights, user, isPopupDisabled, logoutUser]);

    return renderMenu;
};

SideBarItems.propTypes = {
    path: PropTypes.string.isRequired
};

export default React.memo(SideBarItems);
