import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { measurementApi } from "./measurementService";

const initialState = {
    measurements: []
};

const measurementSlice = createSlice({
    name: "measurement",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(measurementApi.endpoints.getMeasurements.matchFulfilled, (state, action) => {
                state.measurements = action.payload;
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export default measurementSlice.reducer;
