import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { mailingApi } from "./mainlingService";

const initialState = {
    mailings: [],
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    }
};

const mainlingSlice = createSlice({
    name: "mailing",
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        addMailingLocal: (state, action) => {
            state.mailings = [...state.mailings, action.payload];
        }
    },
    extraReducers(builder) {
        builder
            .addMatcher(mailingApi.endpoints.getMailings.matchFulfilled, (state, action) => {
                //Update local mailings with request defined in Service
                state.mailings = action.payload;
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const { setPage, setItemsPerPage, setStickPage, addMailingLocal } = mainlingSlice.actions;

export default mainlingSlice.reducer;
