import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { t } from "@lingui/macro";
import { Grid, Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetNodesFromDiagramQuery } from "../hierarchyService";
import { dateRangeOptions, roundedDate } from "modules/time/utils";

import TimePanel from "modules/common/components/TimePanel";
import MessageDisplay from "modules/common/components/MessageDisplay";
import DiagramFlow from "./DiagramFlow";
import { ReactFlowProvider } from "reactflow";
import { diagramTypeOptions } from "../utils";

const DiagramPanel = (props) => {
    const now = moment();
    const { diagram, equipments, sites, usages } = props;
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const [diagramTime, setDiagramTime] = useState(null);

    const rangeOptions = _.filter(dateRangeOptions(), (item) =>
        _.includes(["7d", "30d", "yesterday", "last_week", "last_month", "last_three_month"], item.value)
    );
    const rangeTime = useMemo(() => {
        //Default time display in selector
        const default_time = {
            start: roundedDate(now.clone().subtract(7, "days").startOf("days"), 10),
            end: roundedDate(now.clone().subtract(1, "day").startOf("minute"), 10),
            plage: "7d"
        };
        return diagramTime
            ? {
                  start: moment(diagramTime.start),
                  end: moment(diagramTime.end),
                  plage: diagramTime.plage
              }
            : default_time;
        // eslint-disable-next-line
    }, [diagramTime]);

    const params = useMemo(() => {
        const { start, end } = rangeTime;

        return {
            org: org.current,
            diagram_id: diagram.id,
            start: start.clone().format("YYYY-MM-DD"),
            end: end.clone().add(1, "day").format("YYYY-MM-DD")
        };
    }, [diagram.id, rangeTime, org]);

    const filter_eqpts = useMemo(() => {
        return _.chain(equipments)
            .reduce((res, eqpt) => {
                if (eqpt?.site !== diagram.site) {
                    return res;
                }
                res.push(eqpt);
                return res;
            }, [])
            .value();
    }, [equipments, diagram.site]);

    const nodesInfo = useGetNodesFromDiagramQuery(params, { skip: !org.current });
    const disabled_diagram = auth.user.email !== diagram?.owner_email;

    const diag_type = _.find(diagramTypeOptions, { value: diagram?.type });

    return (
        <>
            <Header as="h3" attached="top" block textAlign="center">{`${diagram.name} ${diag_type ? `- ${i18n._(diag_type.text)}` : ""}`}</Header>
            <Segment attached>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="center">
                            <TimePanel
                                time={rangeTime}
                                timeFormat={false}
                                rangeOptions={rangeOptions}
                                minimumDeltaDay={0}
                                accordion={true}
                                action={({ start, end, plage }) => {
                                    setDiagramTime({ start, end, plage });
                                }}
                                date_limit={moment()}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            {nodesInfo.isError && (
                                <MessageDisplay
                                    message={i18n._(t`error loading data`)}
                                    level="error"
                                    iconName="warning circle"
                                    isLoading={false}
                                    attached={false}
                                />
                            )}
                            {nodesInfo.isFetching && (
                                <MessageDisplay
                                    message={i18n._(t`loading data`)}
                                    level="info"
                                    iconName="circle notched"
                                    isLoading={true}
                                    attached={false}
                                />
                            )}
                            {!nodesInfo.isFetching && nodesInfo.isSuccess && (
                                <>
                                    {/* Wrap diagram flow into ReactFlowProvider for reactflow hook usage */}
                                    <ReactFlowProvider>
                                        <DiagramFlow
                                            nodesInfo={nodesInfo.data}
                                            org={org.current}
                                            equipments={filter_eqpts}
                                            sites={sites}
                                            usages={usages}
                                            diagram={diagram}
                                            disabled_diagram={disabled_diagram}
                                            rangeTime={rangeTime}
                                        />
                                    </ReactFlowProvider>
                                </>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </>
    );
};

export default DiagramPanel;
