import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { Trans, t } from "@lingui/macro";
import { Grid, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { dynamicValueUnit } from "modules/data/utils";
import { useGetMeasurementOverview24hQuery } from "modules/dashboard/dashboardService";
import { useGetUnitsQuery } from "modules/unit/unitService";

const renderCompare = (v7, v24) => {
    let data = {
        sign: "",
        icon: null,
        val: null
    };

    if (!_.isFinite(v7) || !_.isFinite(v24)) {
        return data;
    }

    const process_val = (Math.abs(v24 - v7) / v7) * 100;

    if (!_.isNaN(process_val)) {
        data.val = process_val;
    }

    if (_.lt(v7, v24)) {
        data.sign = "+";
        data.icon = <Icon flipped="horizontally" name="level up alternate" />;
    } else if (_.gt(v7, v24)) {
        data.sign = "-";
        data.icon = <Icon flipped="horizontally" name="level down alternate" />;
    } else {
        data.icon = <Icon name="long arrow alternate left" />;
    }

    return data;
};

const pollingInterval = 1000 * 60 * 10;

export const Val7Day = (props) => {
    const { measure } = props;
    const org = useSelector((state) => state.org);

    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const measurementOverview24h = useGetMeasurementOverview24hQuery(
        { org: org.current, measurement_id: measure?.id },
        { skip: !org.current || !_.isFinite(measure?.id) }
    );

    useEffect(() => {
        const measurementOverview24hTimer = setInterval(() => {
            measurementOverview24h.refetch();
        }, pollingInterval);

        return () => {
            clearInterval(measurementOverview24hTimer);
        };
    }, [measure, measurementOverview24h, org]);

    const { isZero, icon, sign, val, factor, new_unit } = useMemo(() => {
        if (measurementOverview24h.isSuccess && units.isSuccess) {
            const { delta_24h = null, delta_week_ago_24h = null } = measurementOverview24h.data;

            const display_unit = _.find(units.data, { id: measure?.display_unit || measure?.measurementtype?.unit });
            const auto_unit = measure?.display_unit === null;
            const { factor, new_unit } = dynamicValueUnit(delta_week_ago_24h, display_unit?.symbol, auto_unit);
            const isZero = _.isEqual(delta_week_ago_24h, 0);
            const { sign, icon, val } = renderCompare(delta_week_ago_24h, delta_24h);

            return { isZero, icon, sign, val, factor, new_unit };
        }
        return {
            isZero: false,
            icon: "",
            sign: null,
            val: null,
            factor: null,
            new_unit: ""
        };
    }, [measurementOverview24h, measure, units]);

    if (measurementOverview24h.isFetching) {
        return <MessageDisplay message={i18n._(t`loading`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }

    if (measurementOverview24h.isFetching === false && measurementOverview24h.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (measurementOverview24h.isFetching === false && measurementOverview24h.isSuccess) {
        if (!_.isFinite(measurementOverview24h.data?.delta_week_ago_24h)) {
            return (
                <MessageDisplay message={i18n._(t`no consumption 7d`)} level="warning" iconName="warning circle" isLoading={false} attached={false} />
            );
        }

        return (
            <Grid centered>
                <Grid.Column width={16} textAlign="center" className="pwaPanelTitle">
                    <Trans>consumption 24h 7d</Trans>
                </Grid.Column>
                {!isZero && (
                    <>
                        <Grid.Column width={7} textAlign="right">
                            <h3>
                                {icon}
                                {`${sign} ${i18n.number(val, {
                                    maximumFractionDigits: 1
                                })} %`}
                            </h3>
                        </Grid.Column>
                        <Grid.Column width={1} />
                    </>
                )}
                <Grid.Column width={isZero ? 16 : 7} textAlign={isZero ? "center" : "left"}>
                    <h3>
                        {`${i18n.number(measurementOverview24h.data?.delta_week_ago_24h * factor, {
                            maximumFractionDigits: 0
                        })} ${new_unit}`}
                    </h3>
                </Grid.Column>
            </Grid>
        );
    }
    return null;
};

Val7Day.propTypes = {
    measure: PropTypes.object.isRequired
};

export default Val7Day;
