import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { t } from "@lingui/macro";
import tinycolor from "tinycolor2";
import { Segment, Card, Grid } from "semantic-ui-react";
import { Crosshair, DiscreteColorLegend, FlexibleXYPlot, Hint, HorizontalGridLines, VerticalBarSeries, XAxis, YAxis } from "react-vis";

import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";
import { getTimedelta } from "modules/time/utils";
import { checkMobileAndTablet } from "modules/common/utils";
import { useGetConsoComparisonQuery } from "modules/dashboard/dashboardService";

import MessageDisplay from "modules/common/components/MessageDisplay";

const ConsumptionGraphic = (props) => {
    const org = useSelector((state) => state.org);
    const current_lng = useSelector((state) => state.i18n.current);
    const comparison_params = useSelector((state) => state.dash.comparison_params);
    const selected_equipments_ids = useSelector((state) => state.dash.selected_equipments);
    // React vis function
    const [series, setSeries] = useState([]);
    const [hintValue, setHintValue] = useState(null);
    const [crosshairValues, setCrosshairValues] = useState([]);

    const comparisonConso = useGetConsoComparisonQuery(
        { org: org.current, comparison_params, selected_equipments_ids },
        { skip: !org.current || comparison_params === null || _.size(selected_equipments_ids) === 0 }
    );

    useEffect(() => {
        if (comparisonConso.isSuccess) {
            setSeries(comparisonConso.data || []);
        }
    }, [comparisonConso]);

    const { emptyData, xDomain } = useMemo(() => {
        const emptyData = _.chain(series)
            .map((serie) =>
                _.chain(serie)
                    .get("data")
                    .filter((record) => record.y !== null)
                    .isEmpty(serie.data)
                    .value()
            )
            .every()
            .value();
        const range_time = getTimedelta(comparison_params?.comp_start, comparison_params?.comp_end);
        const xDomain =
            _.size(selected_equipments_ids) > 1 ? [range_time.start, range_time.end] : [0, _.chain(series).head().get("data", []).size().value()];
        return { emptyData, xDomain };
    }, [series, comparison_params, selected_equipments_ids]);

    if (comparisonConso.isFetching) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />;
    }
    if (comparisonConso.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />;
    }
    if (comparisonConso.isSuccess) {
        const clickHandler = (serie_clk, idx) => {
            const size_disable = _.chain(series)
                .filter((item) => item.disabled === false)
                .size()
                .value();
            const update_series = _.map(series, (item, idx_ser) => {
                if (idx === idx_ser) return { ...item, disabled: size_disable <= 1 ? false : !item.disabled };
                return item;
            });
            setSeries(update_series);
        };

        const nearestXHandler = (value, { index }) => {
            const crossval = _.chain(series)
                .filter((item) => item.disabled === false)
                .reduce((res, serie) => {
                    const data = _.chain(serie.data).find({ x: value.x }).value();
                    if (data) {
                        res.push({ title: _.get(serie, "title"), ...data });
                    }
                    return res;
                }, [])
                .value();
            setCrosshairValues(crossval);
        };

        const tooltipCrosshair = () => (
            <Crosshair values={crosshairValues}>
                <Card>
                    <Card.Content>
                        <Card.Header textAlign="center" style={{ whiteSpace: "pre", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {_.size(selected_equipments_ids) > 1 && moment(crosshairValues[0].t).locale(current_lng).format("LL")}
                            {_.size(selected_equipments_ids) <= 1 && _.chain(series).head().get("name", "-").value()}
                        </Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Grid centered verticalAlign="middle">
                            {_.chain(crosshairValues)
                                .map((item, idx) => {
                                    return (
                                        <Grid.Row key={idx} style={{ color: tinycolor(item.color).setAlpha(1).toString() }}>
                                            {_.size(selected_equipments_ids) <= 1 ? (
                                                <Grid.Column width={10} textAlign="left">
                                                    {moment(item.t).locale(current_lng).format("LL")}
                                                </Grid.Column>
                                            ) : (
                                                <Grid.Column
                                                    width={10}
                                                    textAlign="left"
                                                    style={{ whiteSpace: "pre", overflow: "hidden", textOverflow: "ellipsis" }}
                                                >
                                                    {item.title}
                                                </Grid.Column>
                                            )}
                                            <Grid.Column width={6} textAlign="right">
                                                {item.y !== null && `${i18n.number(item.y, { maximumFractionDigits: 2 })} ${item.unit}`}
                                                {item.y === null && `- ${item.unit}`}
                                            </Grid.Column>
                                        </Grid.Row>
                                    );
                                })
                                .value()}
                        </Grid>
                    </Card.Content>
                </Card>
            </Crosshair>
        );

        const tooltipHint = () => (
            <Hint value={hintValue}>
                <Card className="pwaCrosshairCard">
                    <Card.Content>
                        <Card.Header>{moment(hintValue.t).locale(current_lng).format("LL")}</Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Card.Description>
                            <Grid centered verticalAlign="middle" style={{ color: tinycolor(hintValue.color).setAlpha(1).toString() }}>
                                <Grid.Row>
                                    <Grid.Column className="pwaTooltipContentComparison" width={16} textAlign="center">
                                        {hintValue.title}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16} textAlign="center">
                                        {hintValue.y !== null && `${i18n.number(hintValue.y, { maximumFractionDigits: 2 })} ${hintValue.unit}`}
                                        {hintValue.y === null && `- ${hintValue.unit}`}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Hint>
        );

        if (emptyData) return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />;
        return (
            <Segment attached>
                <FlexibleXYPlot
                    dontCheckIfEmpty
                    xType={_.size(selected_equipments_ids) > 1 ? "time" : "linear"}
                    xDomain={xDomain}
                    //yDomain={[Math.min(0, minY), Math.max(10, maxY)]}  Disable yDomain because bad restitution on VerticalBarSeries
                    //stackBy={!hasMultipleEquipments || series.length === 2 ? null : "y"} Disable stack usage because Negative bar overlap other bars
                    onMouseLeave={() => {
                        setCrosshairValues([]);
                    }}
                    height={480}
                    margin={{ left: 60, right: 10, top: 10, bottom: 130 }}
                >
                    <DiscreteColorLegend
                        className="pwaLegend400"
                        orientation="horizontal"
                        height={75}
                        items={_.chain(series).uniqBy("title").value()}
                        onItemClick={clickHandler}
                    />
                    {_.size(selected_equipments_ids) > 1 && (
                        <XAxis
                            title={i18n._(t`time`)}
                            tickLabelAngle={-20}
                            tickFormat={(value, index, scale, tickTotal) => {
                                const default_fmt = multiI18nFormat(value, current_lng);
                                if (default_fmt.indexOf("06:00") >= 0 || default_fmt.indexOf("12:00") >= 0 || default_fmt.indexOf("18:00") >= 0)
                                    return "";
                                return default_fmt;
                            }}
                        />
                    )}
                    {_.size(selected_equipments_ids) <= 1 && (
                        <XAxis
                            title={i18n._(t`number of points`)}
                            tickLabelAngle={-20}
                            tickFormat={(value, index, scale, tickTotal) => {
                                try {
                                    const firstRecordOnFirstSerie = _.chain(series).head().get("data").head().get("t").value();
                                    const start = moment.min(moment(firstRecordOnFirstSerie), moment(comparison_params?.ref_start));
                                    const tmstRecordOnFirstSerie = moment(_.chain(series).head().get("data").get(parseInt(value)).get("t").value());
                                    const days = tmstRecordOnFirstSerie.diff(start, "days");
                                    return `j+${days}`;
                                } catch (error) {
                                    return "";
                                }
                            }}
                        />
                    )}
                    <YAxis
                        title={_.chain(series).head().get("unit").value() || "-"}
                        tickFormat={(value, index, scale, tickTotal) => {
                            const format = scale.tickFormat(tickTotal)(value);
                            if (typeof value === "number") {
                                return i18n.number(value, { minimumFractionDigits: 1, maximumFractionDigits: 2, notation: "compact" });
                            }
                            return format;
                        }}
                    />
                    <HorizontalGridLines />
                    {_.chain(series)
                        .filter((item) => item.disabled === false)
                        .map((serie, idx) => {
                            return (
                                <VerticalBarSeries
                                    key={idx}
                                    color={serie.color}
                                    fill={serie.color}
                                    size={3}
                                    getNull={(d) => d.y !== null}
                                    data={serie.data}
                                    onNearestX={!checkMobileAndTablet() ? nearestXHandler : null}
                                    onValueMouseOver={
                                        checkMobileAndTablet()
                                            ? (hintValue) => {
                                                  setHintValue(hintValue);
                                              }
                                            : null
                                    }
                                    onValueMouseOut={
                                        checkMobileAndTablet()
                                            ? () => {
                                                  setHintValue(null);
                                              }
                                            : null
                                    }
                                />
                            );
                        })
                        .value()}
                    {!checkMobileAndTablet() && _.size(crosshairValues) !== 0 && tooltipCrosshair()}
                    {checkMobileAndTablet() && !_.isNull(hintValue) && tooltipHint()}
                </FlexibleXYPlot>
            </Segment>
        );
    }
    return null;
};

export default ConsumptionGraphic;
