import React, { useCallback, useState, useMemo } from "react";
import { t, Trans } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Dropdown, Icon, Menu, Modal } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";
import { setCurrentOrg } from "../orgSlice";
import { useGetOrganizationsQuery } from "../organizationService";
import { checkStringInclude } from "modules/common/utils";

export const DropdownOrg = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const store_org = useSelector((state) => state.org);
    const user = useSelector((state) => state.auth.user);
    const theme = useSelector((state) => state.common.theme);

    const organizations = useGetOrganizationsQuery(undefined, { skip: !user });

    const orgOptions = useMemo(() => {
        if (open && organizations.isSuccess) {
            return _.map(organizations.data.organizations, ({ key, text, value }) => {
                return { key, text, value };
            });
        }
        return [];
    }, [open, organizations]);

    const onChangeOrganisation = useCallback(
        (event, data) => {
            const selected = _.find(organizations.data?.organizations, { value: data.value });
            if (selected) {
                setOpen(false);
                localStorage.setItem("currentOrg", JSON.stringify(selected));
                history_app.push("/");
                dispatch(setCurrentOrg(selected));
            }
        },
        [dispatch, organizations]
    );

    const dropdownStyle = useMemo(() => {
        if (theme === "old") {
            if (user?.is_superuser ?? false) {
                return {
                    background: `repeating-linear-gradient(
                                    -55deg,
                                    #f6ba52,
                                    #f6ba52 10px,
                                    #ffd180 10px,
                                    #ffd180 20px
                                    )`
                };
            }
        } else {
            if (user?.is_superuser ?? false) {
                return {
                    color: "var(--text-highEmph-dark)",
                    fontWeight: "bold",
                    background: `repeating-linear-gradient(-55deg,
                                  var(--chart-color-1),
                                  var(--chart-color-1) 10px,
                                  var(--chart-color-11) 10px,
                                  var(--chart-color-11) 20px
                              )`
                };
            }
            return {
                color: "var(--text-medEmph-light)",
                fontWeight: "bold"
            };
        }
        return {};
    }, [theme, user]);

    return (
        <>
            {organizations.isError && (
                <Menu.Item>
                    <Icon name="warning" color="red" />
                </Menu.Item>
            )}
            {organizations.isFetching && (
                <Menu.Item>
                    <Icon loading name="circle notch" />
                </Menu.Item>
            )}
            {organizations.isSuccess && (
                <>
                    <Menu.Item onClick={_.size(organizations.data.organizations) > 1 ? () => setOpen(true) : null} style={dropdownStyle}>
                        <Icon name="industry" />
                        {store_org?.current?.text ?? ""}
                        {_.size(organizations.data.organizations) > 1 && <Icon name="sort" />}
                    </Menu.Item>
                    {open && (
                        <Modal centered={false} onClose={() => setOpen(false)} open>
                            <Modal.Header>
                                <Trans>Select an organization</Trans>
                            </Modal.Header>
                            <Modal.Content>
                                <Dropdown
                                    searchInput={{ autoFocus: true }}
                                    selection
                                    search={(options, query) => {
                                        if (query === "") return options;
                                        return _.filter(options, (org) => {
                                            const org_name = org?.text ?? "";
                                            return checkStringInclude(query, org_name);
                                        });
                                    }}
                                    fluid
                                    selectOnNavigation={false}
                                    options={orgOptions}
                                    onChange={onChangeOrganisation}
                                    value={store_org?.current?.value ?? null}
                                    noResultsMessage={i18n._(t`no result found`)}
                                />
                            </Modal.Content>
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};

export default DropdownOrg;
