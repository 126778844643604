import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal, Icon, Dropdown } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";
import { useDeleteAdvancedAnalysisMutation } from "../analysisAdvancedService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import i18n from "modules/i18n/i18nConfig";

const AnalysisAdvancedDelete = (props) => {
    const { org, current_advanced, setSelectedAdvAnalysis, disabled } = props;
    const [open, setOpen] = useState(false);

    const [deleteAnalysis, { isError, isLoading, isSuccess }] = useDeleteAdvancedAnalysisMutation();

    useEffect(() => {
        if (isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (isSuccess) {
            toast.success(i18n._(t`successful delete analysis`), toast_options);
            setSelectedAdvAnalysis(null);
        }
        if (isError) {
            toast.error(i18n._(t`cannot delete analysis`), toast_options_err);
        }
    }, [isError, isSuccess, isLoading, setSelectedAdvAnalysis]);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <Dropdown.Item disabled={disabled}>
                    <Icon name="trash" />
                    <Trans>Delete analysis</Trans>
                </Dropdown.Item>
            }
        >
            <Modal.Header>
                <Trans>delete</Trans>
            </Modal.Header>
            <Modal.Content>
                <Trans>want delete analyse {current_advanced?.name}</Trans>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                    <Trans>cancel</Trans>
                </Button>
                {!disabled && (
                    <Button
                        labelPosition="right"
                        icon
                        positive
                        onClick={async (e) => {
                            await setOpen(false);
                            deleteAnalysis({ org, data: current_advanced });
                        }}
                    >
                        <Trans>validate</Trans>
                        <Icon name="trash" inverted />
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default AnalysisAdvancedDelete;
