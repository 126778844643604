import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal, Icon, Dropdown } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import i18n from "modules/i18n/i18nConfig";
import { useDeleteDiagramMutation } from "../hierarchyService";

const DiagramDelete = (props) => {
    const { org, current_diagram, setSelectedDiagram, disabled } = props;
    const [open, setOpen] = useState(false);

    const [deleteDiagram, { isError, isLoading, isSuccess }] = useDeleteDiagramMutation();

    useEffect(() => {
        if (isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (isSuccess) {
            toast.success(i18n._(t`successful delete diagram`), toast_options);
            setSelectedDiagram(null);
        }
        if (isError) {
            toast.error(i18n._(t`cannot delete diagram`), toast_options_err);
        }
    }, [isError, isSuccess, isLoading, setSelectedDiagram]);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <Dropdown.Item disabled={disabled}>
                    <Icon name="trash" />
                    <Trans>Delete diagram</Trans>
                </Dropdown.Item>
            }
        >
            <Modal.Header>
                <Trans>delete</Trans>
            </Modal.Header>
            <Modal.Content>
                <Trans>want delete diagram {current_diagram?.name}</Trans>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                    <Trans>cancel</Trans>
                </Button>
                {!disabled && (
                    <Button
                        labelPosition="right"
                        icon
                        positive
                        onClick={async (e) => {
                            await setOpen(false);
                            deleteDiagram({ org, data: current_diagram });
                        }}
                    >
                        <Trans>validate</Trans>
                        <Icon name="trash" inverted />
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default DiagramDelete;
