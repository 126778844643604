import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal, Button, Icon, Grid, Divider } from "semantic-ui-react";
import { Form, Field } from "react-final-form";

import i18n from "modules/i18n/i18nConfig";
import { DateTimeAdapter, InputAdapter } from "modules/common/components/form";
import { identityNull } from "modules/common/utils/form";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { useAddProductionDataMutation, useUpdateProductionDataMutation } from "modules/importedDataset/importedDataSetsService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const ProductionDataModalForm = (props) => {
    const { item, dataset_id, setOpen } = props;

    const org = useSelector((state) => state.org);
    const current_lng = useSelector((state) => state.i18n.current);

    const [createProductionData, create] = useAddProductionDataMutation();
    const [updateProductionData, update] = useUpdateProductionDataMutation();

    // update Production data
    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update imported data`), toast_options);
            setOpen(false);
        }
        if (update.isError) {
            let error = i18n._(t`cannot update imported data`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update, setOpen]);

    // create Production data
    useEffect(() => {
        if (create.isSuccess) {
            toast.success(i18n._(t`Successful add imported data`), toast_options);
            setOpen(false);
        }
        if (create.isError) {
            let error = i18n._(t`Cannot add imported data`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [create, setOpen]);

    const initialValues = useMemo(() => {
        const defaultValues = { v: 1, day: moment().startOf("day") };
        return {
            ...defaultValues,
            ...item,
            day: item !== undefined ? moment(item?.day) : defaultValues.day
        };
    }, [item]);

    const submitForm = async (formData) => {
        const data = {
            ...formData,
            day: formData?.day?.format("YYYY-MM-DD"),
            v: reparseNumber(formData?.v)
        };

        if (item) {
            await updateProductionData({ org: org.current, dataset_id, data });
        } else {
            await createProductionData({ org: org.current, dataset_id, data });
        }
    };

    return (
        <Modal centered={false} closeOnDimmerClick={false} onClose={() => setOpen(false)} open={true}>
            <Modal.Header>
                {item?.id !== undefined && <Trans>Edit imported data</Trans>}
                {item?.id === undefined && <Trans>Add imported data</Trans>}
            </Modal.Header>
            <Modal.Content>
                <Grid verticalAlign="top">
                    <Grid.Column width={16}>
                        <Form
                            onSubmit={submitForm}
                            initialValues={initialValues}
                            render={({ handleSubmit, submitting, pristine, invalid }) => {
                                return (
                                    <form onSubmit={handleSubmit} className="ui form">
                                        <Grid>
                                            <Grid.Column width={16}>
                                                <Field
                                                    name="day"
                                                    component={DateTimeAdapter}
                                                    locale={current_lng}
                                                    date_limit={null}
                                                    labeled={false}
                                                    label={i18n._(t`day`)}
                                                    labelPosition={"left"}
                                                    isRequired={true}
                                                    dateFormat={true}
                                                    timeFormat={false}
                                                    validate={(value) => {
                                                        if (typeof value === "string") {
                                                            return <Trans>Invalid format</Trans>;
                                                        }
                                                        return undefined;
                                                    }}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={16}>
                                                <Field
                                                    name="v"
                                                    placeholder={i18n._(t`enter value`)}
                                                    label={i18n._(t`value`)}
                                                    isRequired={true}
                                                    parse={identityNull}
                                                    inputMode="decimal"
                                                    component={InputAdapter}
                                                    validate={(value) => {
                                                        const valueValidate = validateNumber(value, i18n, false, false);
                                                        if (valueValidate !== undefined) {
                                                            return valueValidate;
                                                        }
                                                        return undefined;
                                                    }}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={16}>
                                                <Divider />
                                            </Grid.Column>
                                            <Grid.Column width={16} textAlign="right">
                                                <Button
                                                    type="button"
                                                    negative
                                                    onClick={() => {
                                                        setOpen(false);
                                                    }}
                                                >
                                                    <Trans>cancel</Trans>
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    positive
                                                    icon
                                                    labelPosition="right"
                                                    disabled={submitting || invalid || (item !== undefined && pristine)}
                                                >
                                                    <Icon name="check" />
                                                    <Trans>validate</Trans>
                                                </Button>
                                            </Grid.Column>
                                        </Grid>
                                    </form>
                                );
                            }}
                        />
                    </Grid.Column>
                </Grid>
            </Modal.Content>
        </Modal>
    );
};

export default ProductionDataModalForm;
