import _ from "lodash";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { sensorApi } from "./sensorService";
import { checkStringInclude } from "modules/common/utils";

const initialState = {
    init_sensors: [],
    sensors: [],
    filter: {
        searchIdentifier: "",
        siteFilter: [],
        sensorTypeFilter: []
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    }
};

const sensorSlice = createSlice({
    name: "sensor",
    initialState,
    reducers: {
        setSearchIdentifierFilter: (state, action) => {
            state.filter.searchIdentifier = action.payload;
        },
        setSiteFilter: (state, action) => {
            state.filter.siteFilter = action.payload;
        },
        setSensorTypeFilter: (state, action) => {
            state.filter.sensorTypeFilter = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.sensors = state.init_sensors;
            state.filter = initialState.filter;
            state.pagination = initialState.pagination;
        }
    },
    extraReducers(builder) {
        builder
            .addMatcher(sensorApi.endpoints.getSensors.matchFulfilled, (state, action) => {
                state.sensors = action.payload;
                state.init_sensors = action.payload;
            })
            .addMatcher(isAnyOf(setSearchIdentifierFilter, setSiteFilter, setSensorTypeFilter), (state, action) => {
                const filtered_sensors = _.chain(state.init_sensors)
                    .reduce((res, sensor) => {
                        /* name filter */
                        if (state.filter.searchIdentifier === "") {
                            res.push(sensor);
                        } else if (checkStringInclude(state.filter.searchIdentifier, sensor.identifier)) {
                            res.push(sensor);
                        }
                        return res;
                    }, [])
                    .reduce((res, sensor) => {
                        /* site filter */
                        if (_.size(state.filter.siteFilter) === 0) {
                            res.push(sensor);
                        } else if (_.includes(state.filter.siteFilter, sensor.site)) {
                            res.push(sensor);
                        }
                        return res;
                    }, [])
                    .reduce((res, sensor) => {
                        /* sensor_type filter */
                        if (_.size(state.filter.sensorTypeFilter) === 0) {
                            res.push(sensor);
                        } else if (_.includes(state.filter.sensorTypeFilter, sensor.sensor_type_name)) {
                            res.push(sensor);
                        }
                        return res;
                    }, [])
                    .value();

                state.sensors = filtered_sensors;
                if (!state.pagination.stickPage) {
                    state.pagination.page = 1;
                }
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const { setSearchIdentifierFilter, setSiteFilter, setSensorTypeFilter, setPage, setItemsPerPage, setStickPage, resetFilterWithPage } =
    sensorSlice.actions;

export default sensorSlice.reducer;
