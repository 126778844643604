import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";

export const componenttypeApi = defaultApi.injectEndpoints({
    reducerPath: "apiComponenttype",
    endpoints: (build) => ({
        getComponenttypes: build.query({
            keepUnusedDataFor: 86400,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `componenttypes?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: ["Componenttype"],
            transformResponse: (response) => {
                return _.map(response, (componenttype) => ({
                    ...componenttype,
                    key: componenttype.id,
                    text: componenttype.name,
                    value: componenttype.id
                }));
            }
        })
    }),
    overrideExisting: false
});

export const { useGetComponenttypesQuery } = componenttypeApi;
