import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t } from "@lingui/macro";
import { Button, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";
import { useGetMachinesQuery } from "modules/machine/machineService";
import { useGetMachinetypesQuery } from "modules/machine/machinetypeService";
import { useGetComponentsQuery } from "modules/machine/componentService";
import { getMachine } from "modules/machine/machineSlice";

import MessageDisplay from "modules/common/components/MessageDisplay";
import KinematicForm from "modules/predict/cinematic/components/KinematicForm";

const PaneKinematic = (props) => {
    const { equipment } = props;
    const dispatch = useDispatch();
    const org = useSelector((state) => state.org);
    const machine = useSelector((state) => state.machine);
    useSelector((state) => state.i18n.current); //force refresh for lng

    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current });
    const machinetypes = useGetMachinetypesQuery({ org: org.current }, { skip: !org.current });
    const components = useGetComponentsQuery({ org: org.current }, { skip: !org.current });

    useEffect(() => {
        (async () => {
            _.isFinite(equipment?.machine?.id) && (await dispatch(getMachine({ org: org.current, id: equipment?.machine?.id })));
        })();
        return () => {
            //dispatch(resetCurrentKinematic());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, org.current]);

    const current_machine = useMemo(() => {
        if (machines.isSuccess) {
            return _.find(machines.data, { id: equipment?.machine?.id });
        }
        return null;
    }, [machines, equipment]);

    const error_list = [machinetypes.isError, machines.isError, components.isError];
    const loading_list = [machinetypes.isFetching, machines.isFetching, components.isFetching];
    const status_list = [machinetypes.isSuccess, machines.isSuccess, components.isSuccess];

    if (!_.isFinite(equipment?.machine?.id)) {
        return <MessageDisplay message={i18n._(t`Unknown machine`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (_.some([...loading_list, machine.machine.status === "idle", machine.machine.status === "loading"])) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (_.some([...error_list, machine.machine.status === "failed"])) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (_.every([...status_list, machine.machine.status === "succeeded"])) {
        return (
            <>
                {current_machine?.last_kinematic_version === 0 && (
                    <>
                        <Segment attached>
                            <Button
                                icon="gg"
                                onClick={async (e) => {
                                    history_app.push(`/kinematic/${equipment?.machine?.id}/change`);
                                }}
                                content={i18n._(t`kinematic edition`)}
                            />
                        </Segment>
                        <MessageDisplay
                            message={i18n._(t`You have no kinematic. Please edit the kinematic`)}
                            level="info"
                            iconName="info circle"
                            isLoading={false}
                        />
                    </>
                )}

                {current_machine?.last_kinematic_version > 0 && (
                    <>
                        <MessageDisplay
                            message={i18n._(t`If you want to update your kinematic, please go to machine events tab and add new event`)}
                            level="info"
                            iconName="info circle"
                            isLoading={false}
                        />
                        <KinematicForm id_machine={equipment?.machine?.id} mode={"view"} />
                    </>
                )}
            </>
        );
    }
    return null;
};

export default React.memo(PaneKinematic);
