import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Menu, Icon, Image } from "semantic-ui-react";
import logo from "images/logo-small.png";
import logo_full from "images/logo-full.svg";

import DropDownOrg from "modules/organization/components/DropdownOrg";
import DropDownI18n from "modules/i18n/components/DropdownI18n";
import { Media } from "App";
import { useSelector } from "react-redux";
import ThemeSwitch from "modules/common/components/ThemeSwitch";

export const Navbar = (props) => {
    const { path, toggleSidebar } = props;
    const org = useSelector((state) => state.org.current);
    const theme = useSelector((state) => state.common.theme);

    if (theme === "old") {
        return (
            <>
                <Menu attached="top" className="no-print" style={{ height: "60px" }}>
                    <Menu.Item
                        onClick={() => {
                            toggleSidebar(true);
                        }}
                    >
                        <Icon name="sidebar" />
                    </Menu.Item>
                    <Menu.Item as={Link} to={`${path}`}>
                        <Media lessThan="computer">{(mediaClassNames, renderChildren) => renderChildren && <Image src={logo} size="mini" />}</Media>
                        <Media greaterThanOrEqual="computer">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && <Image src={logo_full} size="small" style={{ height: "45px", width: "auto" }} />
                            }
                        </Media>
                    </Menu.Item>
                    <Menu.Menu position="right">
                        {window.location.hostname === "localhost" && <ThemeSwitch />}
                        <DropDownI18n />
                        {!_.isNull(org) && <DropDownOrg />}
                    </Menu.Menu>
                </Menu>
            </>
        );
    }
    return (
        <Menu
            compact
            attached="top"
            borderless
            className="no-print"
            style={{ border: "none", background: "var(--foundational-white)", height: "40px", boxShadow: "0px 1px 4px 0px #999999" }}
        >
            <Menu.Item
                style={{ margin: "0px", paddingRight: ".7rem" }}
                onClick={() => {
                    toggleSidebar(true);
                }}
            >
                <Icon fitted style={{ color: "var(--foundational-icon-inactive)" }} name="sidebar" />
            </Menu.Item>
            <Menu.Item style={{ margin: "0px", paddingLeft: ".7rem", paddingRight: ".7rem" }} as={Link} to={"/"}>
                <Icon fitted style={{ color: "var(--foundational-icon-inactive)" }} name="home" />
            </Menu.Item>
            <Menu.Menu>{!_.isNull(org) && <DropDownOrg />}</Menu.Menu>
            <Menu.Menu position="right">
                {window.location.hostname === "localhost" && <ThemeSwitch />}
                <DropDownI18n />
            </Menu.Menu>
            <Menu.Item as={Link} to={`${path}`}>
                <Media lessThan="computer">{(mediaClassNames, renderChildren) => renderChildren && <Image src={logo} size="mini" />}</Media>
                <Media greaterThanOrEqual="computer">
                    {(mediaClassNames, renderChildren) =>
                        renderChildren && <Image src={logo_full} size="small" style={{ height: "35px", width: "auto" }} />
                    }
                </Media>
            </Menu.Item>
        </Menu>
    );
};

Navbar.propTypes = {
    path: PropTypes.string.isRequired,
    toggleSidebar: PropTypes.func.isRequired
};

export default React.memo(Navbar);
