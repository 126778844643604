import React from "react";
import { Grid, Header } from "semantic-ui-react";
import _ from "lodash";

import i18n from "modules/i18n/i18nConfig";

const GaugeText = (props) => {
    const { width, height, ...rest } = props;

    const fontSize = Math.min(width / 10, height / 5); //rescale fontSize of text during resize widget

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width,
                height,
                filter: _.isFinite(rest.value) ? "none" : "grayscale(100%)"
            }}
        >
            <Grid centered>
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <Header style={{ fontSize, color: rest.color }}>
                            {_.isFinite(rest.value) ? i18n.number(rest.value, { maximumFractionDigits: 2 }) : "-"}
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <Header style={{ fontSize }}>{rest?.unit ?? "-"}</Header>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};

GaugeText.defaultProps = {
    width: 100,
    height: 100,
    value: 0,
    color: "green",
    unit: ""
};

export default GaugeText;
