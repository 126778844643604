import React, { useMemo, useState } from "react";
import _ from "lodash";
import { Dropdown, Modal, Popup, Icon, Segment, Label } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { checkStringInclude } from "modules/common/utils";

const renderError = ({ invalid, error, submitError, modified }, cssOverride) => {
    if (invalid && modified) {
        return (
            <Label basic color="red" pointing={cssOverride ? "right" : "below"}>
                {error || submitError}
            </Label>
        );
    }
};

const ReportAnalysisSelector = (props) => {
    const { user_email, isRequired, options, cssOverride, meta, label, helperText, customAction = (data) => {}, ...rest } = props;
    const adv_analysis = options;
    const current_analysis = _.find(adv_analysis, { id: props.input.value });

    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false);

    const items = useMemo(() => {
        if (_.size(adv_analysis) === 0) {
            return [];
        }
        const group_by_user = _.chain(adv_analysis).groupBy("owner_email").value();
        const owner_analysis = _.pick(group_by_user, user_email);
        const other_analysis = _.omit(group_by_user, user_email);

        return _.reduce(
            { ...owner_analysis, ...other_analysis },
            (res, analysis, email) => {
                if (_.size(analysis) > 0) {
                    res.push(<Dropdown.Header key={email} icon="user" content={email} />);
                    const remap_analysis = _.chain(analysis)
                        .orderBy("default_date", "desc")
                        .reduce((res, item, idx) => {
                            const { key, text, value } = item;
                            if (search === "" || checkStringInclude(search, text)) {
                                res.push(
                                    <Dropdown.Item
                                        key={key}
                                        text={text}
                                        value={value}
                                        icon={idx === 0 && user_email === email ? { name: "home" } : null}
                                        selected={props.input.value === value}
                                        onClick={(e) => {
                                            setSearch("");
                                            setOpen(false);
                                            props.input.onChange(value);
                                            customAction(value);
                                        }}
                                    />
                                );
                            }
                            return res;
                        }, [])
                        .value();
                    if (_.size(remap_analysis) === 0) {
                        res.push(<Dropdown.Item key={`${email}_null`} text={i18n._(t`no result found`)} />);
                    } else {
                        res.push(...remap_analysis);
                    }
                }
                return res;
            },
            []
        );
    }, [adv_analysis, search, user_email, props.input, customAction]);

    return (
        <Modal
            centered={false}
            onClose={() => {
                setSearch("");
                setOpen(false);
                if (props.input.value === "") {
                    props.input.onChange("");
                    customAction("");
                }
            }}
            open={open}
            trigger={
                <div
                    className={`field ${isRequired ? "required" : ""} ${meta.invalid && meta.modified ? "error" : ""} ${
                        cssOverride ? "pwaFormField" : ""
                    }`}
                >
                    {label && <label style={{ display: "inline" }}>{label}</label>}
                    {helperText && (
                        <Popup basic trigger={<Icon circular inverted color="grey" name="question" size="tiny" />}>
                            <Segment basic style={{ width: "35vh" }}>
                                {helperText}
                            </Segment>
                        </Popup>
                    )}
                    {renderError(meta, cssOverride)}
                    <Dropdown
                        fluid
                        open={false}
                        onClick={(e) => setOpen(true)}
                        disabled={_.size(adv_analysis) === 0}
                        selection
                        value={current_analysis?.id}
                        options={adv_analysis.map(({ key, text, value }) => ({ key, text, value }))}
                        {...rest}
                    />
                </div>
            }
        >
            <Modal.Header>
                <Trans>Select an analysis</Trans>
            </Modal.Header>
            <Modal.Content>
                <Dropdown
                    className="selection"
                    searchInput={{ autoFocus: true }}
                    search
                    fluid
                    onSearchChange={(e, data) => {
                        setSearch(data.searchQuery);
                    }}
                    text={current_analysis?.name}
                >
                    <Dropdown.Menu>{items}</Dropdown.Menu>
                </Dropdown>
            </Modal.Content>
        </Modal>
    );
};

export default ReportAnalysisSelector;
