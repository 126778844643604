import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Form, Field } from "react-final-form";
import { Button, Grid, Header, Label, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { nextStep, setSupply } from "../kinematicSlice";
import { machineSupplyType, machineSupplyWiring } from "modules/predict/utils";
import { DropDownAdapter, InputAdapter } from "modules/common/components/form";
import { identity, identityNull } from "modules/common/utils/form";
import { reparseNumber, validateNumber } from "modules/common/utils";

const KinematicSupplyForm = (props) => {
    const { machine, mode } = props;
    const is_predict = _.get(machine, "is_predict", true);
    const dispatch = useDispatch();
    const component = useSelector((state) => state.component);
    const kinematic = useSelector((state) => state.kinematic);

    const drive_component = _.chain(component.components)
        .filter({ machine: _.get(machine, "id") })
        .find({ comp_type: 7 })
        .value();

    const onSubmitForm = async (data) => {
        const { machine_supply_type, machine_supply_wiring } = data;
        if (machine_supply_type === "Direct") {
            //only send machine supply info
            await dispatch(
                setSupply({
                    machine_supply_type,
                    machine_supply_wiring
                })
            );
        } else {
            const reParsedData = {
                ...data,
                hashing_frequency: reparseNumber(data?.hashing_frequency),
                freqMin: reparseNumber(data?.freqMin),
                freqMax: reparseNumber(data?.freqMax)
            };
            await dispatch(setSupply(reParsedData));
        }
        await dispatch(nextStep());
    };

    const drive_description = drive_component?.description ?? null;

    const initialValues = useMemo(() => {
        if (kinematic.kinematic.supply) {
            //previous step case
            return kinematic.kinematic.supply;
        }

        return {
            //default supply data based on machine
            machine_supply_wiring: _.get(machine, "machine_supply_wiring", machineSupplyWiring[0].value),
            machine_supply_type: _.get(machine, "machine_supply_type", machineSupplyType[0].value),
            ...drive_description
        };
    }, [kinematic.kinematic.supply, drive_description, machine]);

    return (
        <>
            <Form
                onSubmit={onSubmitForm}
                initialValues={initialValues}
                render={({ handleSubmit, invalid, values }) => {
                    return (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Header as={"h3"} attached="top" block textAlign="center">
                                <Trans>supply info</Trans>
                            </Header>
                            <Segment attached>
                                <Field
                                    name="machine_supply_wiring"
                                    label={i18n._(t`machine_supply_wiring`)}
                                    placeholder={i18n._(t`select supply wiring of machine`)}
                                    options={machineSupplyWiring}
                                    component={DropDownAdapter}
                                    isRequired={true}
                                    helperText={i18n._(t`kinematic_machine_supply_wiring helper`)}
                                    disabled={mode === "view"}
                                />
                                <Field
                                    name="machine_supply_type"
                                    label={i18n._(t`machine_supply_type`)}
                                    placeholder={i18n._(t`select supply type of machine`)}
                                    options={machineSupplyType}
                                    component={DropDownAdapter}
                                    isRequired={true}
                                    disabled={is_predict === false || mode === "view"}
                                />
                                {_.get(values, "machine_supply_type") === "Drive" && (
                                    <>
                                        <Grid columns={2} stackable>
                                            <Grid.Row stretched>
                                                <Grid.Column>
                                                    <Segment padded>
                                                        <Label attached="top" color="blue" style={{ textAlign: "center" }}>
                                                            <Trans>general information</Trans>
                                                        </Label>
                                                        <Field
                                                            name="maker"
                                                            placeholder={i18n._(t`enter maker`)}
                                                            label={i18n._(t`maker`)}
                                                            component={InputAdapter}
                                                            defaultValue={""}
                                                            parse={identity}
                                                            disabled={mode === "view"}
                                                        />
                                                        <Field
                                                            name="model"
                                                            placeholder={i18n._(t`enter model of machine`)}
                                                            label={i18n._(t`model`)}
                                                            component={InputAdapter}
                                                            defaultValue={""}
                                                            parse={identity}
                                                            disabled={mode === "view"}
                                                        />
                                                        <Field
                                                            name="hashing_frequency"
                                                            placeholder={i18n._(t`enter hashing_frequency of drive`)}
                                                            label={i18n._(t`hashing_frequency`)}
                                                            type="text"
                                                            component={InputAdapter}
                                                            parse={identityNull}
                                                            inputMode="decimal"
                                                            disabled={mode === "view"}
                                                            defaultValue={null}
                                                            validate={(value) => {
                                                                if (mode === "view") return undefined;
                                                                return validateNumber(value, i18n, false, true);
                                                            }}
                                                        />
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Segment padded>
                                                        <Label attached="top" color="blue" style={{ textAlign: "center" }}>
                                                            <Trans>Drive operating</Trans>
                                                        </Label>
                                                        <Field
                                                            name="freqMin"
                                                            placeholder={i18n._(t`enter min_frequency of drive`)}
                                                            type="text"
                                                            label={i18n._(t`min_frequency`)}
                                                            component={InputAdapter}
                                                            isRequired={true}
                                                            unit={"Hz"}
                                                            inputMode="decimal"
                                                            parse={identityNull}
                                                            defaultValue={null}
                                                            validate={(value) => {
                                                                if (mode === "view") return undefined;
                                                                const freqValid = validateNumber(value, i18n, false, false);
                                                                if (freqValid !== undefined) {
                                                                    return freqValid;
                                                                }
                                                                if (reparseNumber(value) < 15) return <Trans>min_freq 15Hz</Trans>;
                                                                return undefined;
                                                            }}
                                                            disabled={mode === "view"}
                                                        />
                                                        <Field
                                                            name="freqMax"
                                                            placeholder={i18n._(t`enter max_frequency of drive`)}
                                                            type="text"
                                                            label={i18n._(t`max_frequency`)}
                                                            component={InputAdapter}
                                                            isRequired={true}
                                                            unit={"Hz"}
                                                            parse={identityNull}
                                                            inputMode="decimal"
                                                            defaultValue={null}
                                                            validate={(value) => {
                                                                if (mode === "view") return undefined;
                                                                return validateNumber(value, i18n, false, false);
                                                            }}
                                                            disabled={mode === "view"}
                                                        />
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </>
                                )}
                            </Segment>
                            <Segment attached>
                                <Grid columns={2}>
                                    <Grid.Column />
                                    <Grid.Column textAlign="right">
                                        <Button
                                            type="submit"
                                            disabled={mode === "change" && (invalid || _.isUndefined(machine))}
                                            icon="right arrow"
                                            labelPosition="right"
                                            content={i18n._(t`next`)}
                                            compact
                                        />
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </form>
                    );
                }}
            />
        </>
    );
};

export default KinematicSupplyForm;
