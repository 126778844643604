import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { Grid, Divider, Segment, Breadcrumb, Icon } from "semantic-ui-react";
import RenderMenuItem from "modules/layout/RenderMenuItem";

export const LandingAdmin = (props) => {
    const auth = useSelector((state) => state.auth);
    const theme = useSelector((state) => state.common.theme);

    const navItemsGenerator = [
        {
            icon: "home",
            title: <Trans>home</Trans>,
            onClick: null,
            path: `/`,
            color: "red",
            display: true
        },
        {
            icon: "building",
            title: <Trans>organization</Trans>,
            onClick: null,
            path: `/organization`,
            color: "teal",
            display: auth?.rights?.is_admin === true
        },
        {
            icon: "users",
            title: <Trans>users</Trans>,
            onClick: null,
            path: `/users`,
            color: "blue",
            display: auth?.rights?.is_admin === true
        },
        {
            icon: "map marker alternate",
            title: <Trans>sites</Trans>,
            onClick: null,
            path: `/sites`,
            color: "grey",
            display: true
        },
        {
            icon: "list",
            title: <Trans>mailings</Trans>,
            onClick: null,
            path: `/mailings`,
            color: "pink",
            display: true
        },
        {
            icon: "plug",
            title: <Trans>usages</Trans>,
            onClick: null,
            path: `/usages`,
            color: "violet",
            display: true
        },
        {
            icon: "tags",
            title: <Trans>tags</Trans>,
            onClick: null,
            path: `/tags`,
            color: "orange",
            display: true
        },
        {
            icon: "file code",
            title: <Trans>imports</Trans>,
            onClick: null,
            path: `/imports`,
            color: "red",
            display: true
        },
        {
            icon: "cubes",
            title: <Trans>importeddataset</Trans>,
            path: `/importeddatasets`,
            color: "yellow",
            display: true
        },
        {
            icon: "whmcs",
            title: <Trans>equipments</Trans>,
            onClick: null,
            path: `/equipments`,
            color: "brown",
            display: true
        },
        {
            icon: "microchip",
            title: <Trans>sensors</Trans>,
            onClick: null,
            path: `/sensors`,
            color: "blue",
            display: true
        }
    ];

    return (
        <>
            <Segment attached basic>
                <Divider hidden section />
                <Grid container textAlign="left">
                    {theme === "default" && (
                        <Grid.Column width={16} textAlign="left">
                            <Breadcrumb>
                                <Breadcrumb.Section>
                                    <Link to="/">
                                        <Icon color={"green"} name="home" />
                                    </Link>
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section style={{ color: "var(--foundational-icon-disabled)" }}>
                                    <Trans>manage</Trans>
                                </Breadcrumb.Section>
                            </Breadcrumb>
                        </Grid.Column>
                    )}
                    {_.chain(navItemsGenerator)
                        .filter((item) => item.display === true)
                        .map((item, idx) => {
                            if (theme === "default" && item.icon === "home") return null;
                            return item.onClick ? (
                                <Grid.Column
                                    key={idx}
                                    mobile="8"
                                    computer="4"
                                    textAlign="center"
                                    onClick={item.onClick}
                                    style={{ cursor: "pointer" }}
                                >
                                    {<RenderMenuItem item={item} />}
                                </Grid.Column>
                            ) : (
                                <Grid.Column key={idx} mobile="8" computer="4" textAlign="center">
                                    {item.path && (
                                        <Link to={item.path}>
                                            <RenderMenuItem item={item} />
                                        </Link>
                                    )}
                                </Grid.Column>
                            );
                        })
                        .value()}
                </Grid>
                <Divider hidden section />
            </Segment>
        </>
    );
};

export default LandingAdmin;
