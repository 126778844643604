import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { categoryApi } from "./categoryService";

const initialState = {
    categories: []
};

const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(categoryApi.endpoints.getCategories.matchFulfilled, (state, action) => {
                state.categories = action.payload;
            })
            .addMatcher(isAnyOf(logout), (state, action) => {
                return initialState;
            });
    }
});

export default categorySlice.reducer;
