import React from "react";
import _ from "lodash";
import { Trans, t, number } from "@lingui/macro";
import { Segment, Table, Header, Grid } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";

const GeneralInfo = (props) => {
    const { equipment } = props;
    const { machine } = equipment;
    const drive = _.chain(machine).get("components").find({ comp_type: 7 }).value();

    return (
        <Grid textAlign="center">
            <Grid.Column mobile={16} tablet={drive && drive.description ? 8 : 16} computer={drive && drive.description ? 8 : 16}>
                <Segment.Group>
                    <Header attached block>
                        <Trans>general informations</Trans>
                    </Header>
                    <Table celled striped compact attached>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>name</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "pre" }}>
                                    {_.get(equipment, "name", "-") || "-"}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h4">
                                        <Trans>equipment_type</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>{i18n._(_.get(equipment, "equipment_type.text", "-")) || "-"}</Table.Cell>
                            </Table.Row>
                            {!_.isUndefined(machine) && (
                                <Table.Row>
                                    <Table.Cell>
                                        <Header as="h4">
                                            <Trans>machine type</Trans>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{i18n._(_.get(equipment, "machine.machine_type.text", "-")) || "-"}</Table.Cell>
                                </Table.Row>
                            )}
                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h4">
                                        <Trans>brand</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>{_.get(equipment, "brand", "-") || "-"}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h4">
                                        <Trans>model</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>{_.get(equipment, "model", "-") || "-"}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h4">
                                        <Trans>manufacturer_SN</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>{_.get(equipment, "manufacturer_SN", "-") || "-"}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h4">
                                        <Trans>manufacture_year</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>{_.get(equipment, "manufacture_year", "-") || "-"}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h4">
                                        <Trans>internal_ref</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>{_.get(equipment, "internal_ref", "-") || "-"}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h4">
                                        <Trans>description</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                                    {" "}
                                    {_.get(equipment, "description", "-") || "-"}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Segment.Group>
            </Grid.Column>
            {drive && drive.description && (
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Segment.Group>
                        <Header attached="top" block>
                            <Trans>supply info</Trans>
                        </Header>
                        <Table celled compact attached>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={5}>
                                        <Header as="h4">
                                            <Trans>machine supply wiring</Trans>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{_.get(drive, "description.machine_supply_wiring", "-") || "-"}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <Header as="h4">
                                            <Trans>machine supply type</Trans>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{_.get(drive, "description.machine_supply_type", "-") || "-"}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>

                        <Header attached block>
                            <Trans>drive info</Trans>
                        </Header>
                        <Table celled compact attached>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={6}>
                                        <Header as="h4">
                                            <Trans>drive maker</Trans>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{_.get(drive, "description.maker", "-") || "-"}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <Header as="h4">
                                            <Trans>drive model</Trans>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{_.get(drive, "description.model", "-") || "-"}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <Header as="h4">
                                            <Trans>drive hashing frequency</Trans> (Hz)
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {_.isFinite(_.get(drive, "description.hashing_frequency", null))
                                            ? i18n._(t`${number(drive.description.hashing_frequency, { maximumFractionDigits: 2 })}`)
                                            : "-"}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <Header as="h4">
                                            <Trans>drive minimum frequency</Trans> (Hz)
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {_.isFinite(_.get(drive, "description.freqMin", null))
                                            ? i18n._(t`${number(drive.description.freqMin, { maximumFractionDigits: 2 })}`)
                                            : "-"}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <Header as="h4">
                                            <Trans>drive maximum frequency</Trans> (Hz)
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {_.isFinite(_.get(drive, "description.freqMax", null))
                                            ? i18n._(t`${number(drive.description.freqMax, { maximumFractionDigits: 2 })}`)
                                            : "-"}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Segment.Group>
                </Grid.Column>
            )}
        </Grid>
    );
};

export default GeneralInfo;
