import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Hint, Sankey } from "react-vis";
import { Button, Modal, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { diagramTypeOptions } from "../utils";
import MessageDisplay from "modules/common/components/MessageDisplay";

const BLURRED_LINK_OPACITY = 0.3;
const FOCUSED_LINK_OPACITY = 0.6;

const ModalSankey = (props) => {
    const modalRef = useRef(null);
    const { setOpen, diagram } = props;
    const [sankeyWidth, setSankeyWidth] = useState(0);
    const [activeLink, setActiveLink] = useState(null);

    useEffect(() => {
        if (modalRef.current) {
            // Tu peux manipuler la div parent ici
            setSankeyWidth(modalRef.current.ref.current.offsetWidth);
        }
    }, [modalRef]);

    const { nodes, links, all_null_or_zero } = useMemo(() => {
        const nds = [...props.nodes];
        const unit = _.chain(diagramTypeOptions).find({ value: diagram?.type }).get("unit").defaultTo("-").value();
        return _.chain(nds)
            .reverse()
            .reduce(
                (res, node, idx) => {
                    res.nodes.push({ name: node?.data?.node_db?.name || node?.data?.node_db?.equipment_name, rotation: -5, unit });
                    const source = _.findIndex(nds, { id: node?.data?.node_db?.parent });
                    const target = _.findIndex(nds, { id: node?.data?.node_db?.path });
                    if (source === -1 || target === -1) return res;
                    const value = node?.data?.node_db?.consumption; // node?.data?.node_db?.consumption || 0;

                    res.links.push({ source, target, value });
                    return res;
                },
                { nodes: [], links: [] }
            )
            .thru((data) => {
                const all_null_or_zero = _.every(data.links, (link) => link.value === null || link.value === 0);
                return { ...data, all_null_or_zero };
            })
            .value();
    }, [props.nodes, diagram]);

    const _renderHint = () => {
        // calculate center x,y position of link for positioning of hint
        const x = activeLink.source.x1 + (activeLink.target.x0 - activeLink.source.x1) / 2;
        const y = activeLink.y0 - (activeLink.y0 - activeLink.y1) / 2;

        const format_val = _.isFinite(activeLink.value) ? i18n.number(activeLink.value, { maximumFractionDigits: 2 }) : "-";
        const unit = activeLink.target.unit;

        const hintValue = {
            [`${activeLink.source.name} ➞ ${activeLink.target.name}`]: `${format_val} ${unit}`
        };

        return <Hint x={x} y={y} value={hintValue} />;
    };

    return (
        <Modal ref={modalRef} onClose={() => setOpen(false)} open centered={false}>
            <Modal.Header>Sankey representation</Modal.Header>
            <Modal.Content>
                {all_null_or_zero && (
                    <MessageDisplay
                        message={i18n._(t`No representation`)}
                        level="warning"
                        iconName="warning circle"
                        isLoading={false}
                        attached={false}
                    />
                )}
                {!all_null_or_zero && (
                    <Segment basic textAlign="center">
                        <Sankey
                            nodes={nodes}
                            links={_.map(links, (link, idx) => {
                                return { ...link, opacity: activeLink && idx === activeLink.index ? FOCUSED_LINK_OPACITY : BLURRED_LINK_OPACITY };
                            })}
                            width={sankeyWidth * 0.9}
                            height={400}
                            onLinkMouseOver={(node) => setActiveLink(node)}
                            onLinkMouseOut={() => setActiveLink(null)}
                        >
                            {activeLink && _renderHint()}
                        </Sankey>
                    </Segment>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button color="red" onClick={() => setOpen(false)}>
                    <Trans>cancel</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ModalSankey;
