import { CSV_SUCCESS, SET_THEME } from "./types";

import _ from "lodash";
import moment from "moment";

export const downloadGraphicData =
    (series, first_cell = "", formatted_time = true, delimiter = ",") =>
    (dispatch, getState) => {
        const lng = getState().i18n.current;

        const headers_name = _.chain(series)
            .filter((item) => {
                return item.disabled === false;
            })
            .map((serie) => {
                return serie.title;
            })
            .value();

        //Here, x value maybe a timestamp or number (cf. pump head/flow)
        const time_range = _.chain(series)
            .reduce((res, item) => {
                _.chain(item)
                    .get("data")
                    .each((val) => res.push(val.x))
                    .value();
                return res;
            }, [])
            .sort()
            .uniq()
            .value();

        const data = _.map(time_range, (time) => {
            const values = _.map(headers_name, (name) => {
                const y = _.chain(series).find({ title: name }).get("data").find({ x: time }).get("y").value();
                if (_.isFinite(y) && delimiter === ";") {
                    //convert . => , for csv ';'
                    return parseFloat(y).toString().replace(".", ",");
                }
                return y;
            });

            let time_fmt = "";
            if (formatted_time) {
                const mom_time = moment(time);
                time_fmt = `${mom_time.locale(lng).format("L")} ${mom_time.locale(lng).format("LTS")}`;
            } else {
                time_fmt = time;
            }

            return [time_fmt, ...values];
        });

        const headers = [first_cell, ...headers_name];

        dispatch({
            type: CSV_SUCCESS,
            payload: {
                data: data,
                headers
            }
        });
    };

export const changeTheme = (theme) => (dispatch) => {
    return dispatch({
        type: SET_THEME,
        payload: theme
    });
};
