import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Grid, Header, Segment, Button, Icon, Table, Popup } from "semantic-ui-react";
import { DiscreteColorLegend, FlexibleXYPlot, Hint, HorizontalGridLines, VerticalGridLines, XAxis, YAxis } from "react-vis";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";

import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";
import { processAggregateTime } from "modules/time/utils";
import { dynamicValueUnit } from "modules/data/utils";
import { hideShowSeries, setDisabledSeries } from "modules/globalview/globalViewSlice";
import { CreateSignedStack, VerticalSignedBarSeries } from "modules/common/components/graphic/signedVerticalBarSeries";
import { rewriteCalendarType } from "modules/globalview/globalviewMiddleware";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import MessageDisplay from "modules/common/components/MessageDisplay";
import { Media } from "App";
import GenerateCsv from "modules/common/components/GenerateCsv";

const GraphicConsumption = (props) => {
    const dispatch = useDispatch();
    const { default_global_filter } = props;
    const graphicRef = useRef(null);
    const globalview = useSelector((state) => state.globalview);
    const unit = useSelector((state) => state.unit);

    const current_lng = useSelector((state) => state.i18n.current);
    const state = _.get(globalview, "consumption", null);
    const series = _.chain(state.data)
        .reduce((res, series, serie_type) => {
            res[serie_type] = _.chain(series)
                .map((serie) => {
                    return {
                        ...serie,
                        title: `${i18n._(serie.title)}`
                    };
                })
                .value();
            return res;
        }, {})
        .value();

    const [hintValue, sethintValue] = useState(null);
    const [displaySeries, setDisplaySeries] = useState(false);

    useEffect(() => {
        if (state.status === "succeeded") setDisplaySeries(false);
    }, [state.status]);

    const clickHandler = (serie_clk) => {
        dispatch(setDisabledSeries(serie_clk));
    };

    const handleSaveImage = async () => {
        if (!graphicRef.current) return;
        try {
            const canvas = await html2canvas(graphicRef.current);
            // Convertir en image
            const imageData = canvas.toDataURL("image/png");

            // Création d'un lien de téléchargement
            const a = document.createElement("a");
            a.href = imageData;
            a.download = `${i18n._(t`consumptions`)}.png`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            toast.error(i18n._(t`Image generation has failed`), toast_options_err);
        }
    };

    const renderTotal = (time, compare_time, new_unit, todo_compare, factor = 1) => {
        const { ref_total, comp_total } = _.chain(series)
            .reduce(
                (res, serie_type, key) => {
                    switch (key) {
                        case "ref":
                            const not_null_value_ref = _.chain(serie_type)
                                .filter((serie) => serie.disabled === false)
                                .reduce((res, serie) => {
                                    res.push(...serie.data);
                                    return res;
                                }, [])
                                .filter((record) => record.y !== null)
                                .value();
                            const ref_total = _.chain(not_null_value_ref).size() > 0 ? _.chain(not_null_value_ref).sumBy("y").round(2).value() : null;
                            res.ref_total = ref_total;
                            break;
                        case "compare":
                            const not_null_value_comp = _.chain(serie_type)
                                .filter((serie) => serie.disabled === false)
                                .reduce((res, serie) => {
                                    res.push(...serie.data);
                                    return res;
                                }, [])
                                .filter((record) => record.y !== null)
                                .value();
                            const comp_total =
                                _.chain(not_null_value_comp).size() > 0 ? _.chain(not_null_value_comp).sumBy("y").round(2).value() : null;
                            res.comp_total = comp_total;
                            break;
                        default:
                            break;
                    }
                    return res;
                },
                { ref_total: null, comp_total: null }
            )
            .value();

        const aggragation = globalview.global_filter?.aggregate || default_global_filter.aggregate;
        return (
            <Grid columns={todo_compare ? 2 : 1} divided stackable>
                <Grid.Row>
                    <Grid.Column>
                        {`${i18n._(t`Period total consumption`)} ${_.lowerCase(i18n._(t`from`))} ${processAggregateTime(
                            moment(time.start),
                            aggragation
                        )
                            .locale(current_lng)
                            .format("L")} ${_.lowerCase(i18n._(t`to`))} ${moment(time.end)
                            .startOf("day")
                            .locale(current_lng)
                            .format("L")} : ${i18n.number(ref_total * factor, { maximumFractionDigits: 2 })} ${new_unit || "-"}`}
                    </Grid.Column>
                    {todo_compare && (
                        <Grid.Column>
                            {`${i18n._(t`Period total consumption`)} ${_.lowerCase(i18n._(t`from`))} ${processAggregateTime(
                                moment(compare_time.start),
                                aggragation
                            )
                                .locale(current_lng)
                                .format("L")} ${_.lowerCase(i18n._(t`to`))} ${moment(compare_time.end)
                                .startOf("day")
                                .locale(current_lng)
                                .format("L")} : ${i18n.number(comp_total * factor, { maximumFractionDigits: 2 })} ${new_unit || "-"}`}
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        );
    };

    const { ref_total, comp_total } = useMemo(() => {
        return _.chain(series)
            .reduce(
                (res, serie_type, key) => {
                    const available_series_wrap = _.chain(serie_type).filter({ disabled: false });
                    switch (key) {
                        case "ref":
                            res.ref_total = available_series_wrap
                                .flatMap("data")
                                .groupBy("x")
                                .map((values) => {
                                    const all_data = _.flatMap(values, "y");
                                    const all_data_null = _.every(all_data, (data) => data === null);
                                    if (all_data_null) return null;
                                    return Math.abs(_.sumBy(values, "y"));
                                })
                                .value();
                            break;
                        case "compare":
                            res.comp_total = available_series_wrap
                                .flatMap("data")
                                .groupBy("x")
                                .map((values) => {
                                    const all_data = _.flatMap(values, "y");
                                    const all_data_null = _.every(all_data, (data) => data === null);
                                    if (all_data_null) return null;
                                    return Math.abs(_.sumBy(values, "y"));
                                })
                                .value();
                            break;
                        default:
                            break;
                    }
                    return res;
                },
                { ref_total: [], comp_total: [] }
            )
            .value();
    }, [series]);

    if (state.status === "loading" || state.status === "idle") {
        return (
            <>
                <Header as="h3" block textAlign="center" attached="top">
                    <Trans>consumptions</Trans>
                </Header>
                <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
            </>
        );
    } else if (state.status === "failed") {
        return (
            <>
                <Header as="h3" block textAlign="center" attached="top">
                    <Trans>consumptions</Trans>
                </Header>
                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
            </>
        );
    } else if (state.status === "succeeded") {
        const aggregate = _.get(globalview, "global_filter.aggregate", default_global_filter.aggregate);
        const todo_compare = _.get(globalview, "global_filter.todo_compare", default_global_filter.todo_compare);
        const calendar_type = rewriteCalendarType(_.get(globalview, "global_filter.calendar_type", default_global_filter.calendar_type));
        const site_id = _.get(globalview, "global_filter.site", default_global_filter.site);
        const unit_id = _.get(globalview, "global_filter.unit", default_global_filter.unit);
        const time = _.get(globalview, "global_filter.time", {
            start: default_global_filter.time.start.toISOString(),
            end: default_global_filter.time.end.toISOString()
        });
        const compare_time = _.get(globalview, "global_filter.compare_time", {
            start: default_global_filter.compare_time.start.toISOString(),
            end: default_global_filter.compare_time.end.toISOString()
        });

        const start = moment(time.start).startOf(aggregate);
        const end = moment(time.end).startOf(aggregate);

        const xDomain = [start, end];

        const isEmptySeries =
            _.chain(state.data)
                .reduce((res, series) => {
                    if (_.size(series) > 0) {
                        res.push(series);
                    }
                    return res;
                }, [])
                .size()
                .value() === 0;

        const max_all = _.chain(series)
            .reduce((res, serie_type) => {
                const serie = _.chain(serie_type)
                    .filter((serie) => serie.disabled === false)
                    .reduce((res, serie) => {
                        res.push(...serie.data);
                        return res;
                    }, [])
                    .value();
                res.push(...serie);
                return res;
            }, [])
            .filter((item) => item.y !== null)
            .map((item) => ({ ...item, y: Math.abs(item.y) }))
            .maxBy("y")
            .value();

        let current_unit = "";

        if (unit_id > 0) {
            current_unit = _.chain(unit.units).find({ id: unit_id }).get("symbol").value();
        } else if (unit_id === "kgCo2e") {
            current_unit = "kgCo2e";
        } else {
            const first_full_site = _.chain(site_id)
                .reduce((res, item) => {
                    const full_site = _.find(props.sites, { id: item });
                    if (full_site && _.get(full_site, "conversions.currency", null) === unit_id) {
                        res.push(full_site);
                    }
                    return res;
                }, [])
                .head()
                .value();
            current_unit = _.get(first_full_site, "conversions.currency", "-");
        }

        const { factor, new_unit } = dynamicValueUnit(_.get(max_all, "y", null), current_unit);

        const tooltip = () => {
            return (
                <Hint value={hintValue}>
                    <Table celled compact structured>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell rowSpan="2" />
                                <Table.HeaderCell colSpan="3" textAlign="center">
                                    {
                                        <>
                                            {aggregate === "day" && moment(hintValue.t).clone().locale(current_lng).format("L")}
                                            {aggregate === "week" &&
                                                `${i18n._(t`week number`)}: ${moment(hintValue.t).clone().locale(current_lng).format("WW")}`}
                                            {aggregate === "month" &&
                                                `${i18n._(t`month`)}: ${moment(hintValue.t).clone().locale(current_lng).format("MMMM YYYY")}`}
                                        </>
                                    }
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Total ({new_unit}) (repartition %)</Table.HeaderCell>
                                {calendar_type === 0 && (
                                    <>
                                        <Table.HeaderCell>
                                            {i18n._(t`Total opening`)} ({new_unit})
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {i18n._(t`Total closing`)} ({new_unit})
                                        </Table.HeaderCell>
                                    </>
                                )}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell textAlign={calendar_type === 0 ? "left" : "center"}>
                                    <svg height="10" width="14" style={{ marginRight: "5px" }}>
                                        <rect height="10" width="14" style={{ fill: hintValue.color }}></rect>
                                    </svg>
                                    {i18n._(hintValue.title)}
                                </Table.Cell>
                                <Table.Cell textAlign={calendar_type === 0 ? "right" : "center"}>
                                    {!_.isFinite(hintValue.y_real) && `-`}
                                    {_.isFinite(hintValue.y_real) &&
                                        `${i18n.number(hintValue.y_real, { maximumFractionDigits: 2 })} (${
                                            _.isFinite(hintValue.repartition) ? i18n.number(hintValue.repartition, { maximumFractionDigits: 2 }) : "-"
                                        } %)`}
                                </Table.Cell>
                                {calendar_type === 0 && (
                                    <>
                                        <Table.Cell textAlign="right">
                                            {!_.isFinite(hintValue.y_opening) && `-`}
                                            {_.isFinite(hintValue.y_opening) &&
                                                `${i18n.number(hintValue.y_opening * factor, { maximumFractionDigits: 2 })}`}
                                        </Table.Cell>
                                        <Table.Cell textAlign="right">
                                            {!_.isFinite(hintValue.y_closing) && `-`}
                                            {_.isFinite(hintValue.y_closing) &&
                                                `${i18n.number(hintValue.y_closing * factor, { maximumFractionDigits: 2 })}`}
                                        </Table.Cell>
                                    </>
                                )}
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Hint>
            );
        };

        const RenderSaveImage = () => {
            return (
                <Popup
                    trigger={
                        <Button
                            onClick={async (e) => {
                                await toast.info(i18n._(t`Preparing the image to save`), toast_options);
                                setTimeout(() => {
                                    //Add delay between toast message && image generation
                                    handleSaveImage();
                                }, 1000);
                            }}
                            icon="file image outline"
                        />
                    }
                >
                    <Popup.Content>{i18n._(t`Save as image`)}</Popup.Content>
                </Popup>
            );
        };

        const ref_series_for_export = _.chain(series)
            .get("ref")
            .map((record) => {
                return {
                    ...record,
                    data: _.map(record.data, (item) => {
                        const val = _.isFinite(item.y) ? item.y * factor : null;
                        return { ...item, x: item.t, y: val, y_real: val };
                    })
                };
            })
            .value();
        const comp_series_for_export = _.chain(series)
            .get("compare")
            .map((record) => {
                return {
                    ...record,
                    data: _.map(record.data, (item) => {
                        const val = _.isFinite(item.y) ? item.y * factor : null;
                        return { ...item, x: item.t, y: val, y_real: val };
                    })
                };
            })
            .value();

        const signedStacks = {
            ref: CreateSignedStack(),
            compare: CreateSignedStack()
        };

        return (
            <div ref={graphicRef}>
                <Header as="h3" block textAlign="center" attached="top">
                    {_.get(state, "status") !== "succeeded" && <Trans>consumptions</Trans>}
                    {_.get(state, "status") === "succeeded" && isEmptySeries && <Trans>consumptions</Trans>}
                    {_.get(state, "status") === "succeeded" && !isEmptySeries && renderTotal(time, compare_time, new_unit, todo_compare, factor)}
                </Header>
                {_.get(state, "status") === "succeeded" && isEmptySeries && (
                    <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />
                )}
                {_.get(state, "status") === "succeeded" && !isEmptySeries && (
                    <>
                        <Media greaterThanOrEqual="computer">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Segment attached textAlign="right" data-html2canvas-ignore="true">
                                        <Button
                                            icon
                                            labelPosition="right"
                                            onClick={async (event, data) => {
                                                await setDisplaySeries(!displaySeries);
                                                dispatch(hideShowSeries(!displaySeries));
                                            }}
                                            className="no-print"
                                        >
                                            {displaySeries ? i18n._(t`display series`) : i18n._(t`hide series`)}
                                            <Icon name={displaySeries ? "eye" : "eye slash"} />
                                        </Button>
                                        {<GenerateCsv series={ref_series_for_export} unit={new_unit} filename={i18n._("reference")} />}
                                        {todo_compare && (
                                            <GenerateCsv series={comp_series_for_export} unit={new_unit} filename={i18n._("comparison")} />
                                        )}
                                        <RenderSaveImage />
                                    </Segment>
                                )
                            }
                        </Media>

                        <Media lessThan="computer">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Segment attached textAlign="right" data-html2canvas-ignore="true">
                                        {<GenerateCsv series={ref_series_for_export} unit={new_unit} filename={i18n._("reference")} />}
                                        {todo_compare && (
                                            <GenerateCsv series={comp_series_for_export} unit={new_unit} filename={i18n._("comparison")} />
                                        )}
                                        <RenderSaveImage />
                                    </Segment>
                                )
                            }
                        </Media>
                        <Segment attached>
                            <FlexibleXYPlot
                                dontCheckIfEmpty
                                xType={aggregate === "day" && !todo_compare ? "time" : "ordinal"}
                                xDomain={aggregate === "day" && !todo_compare ? xDomain : null}
                                yDomain={_.isUndefined(max_all) || _.get(max_all, "y", null) * factor < 1 ? [0, 10] : null}
                                height={500}
                                margin={{ left: 60, right: 10, top: 10, bottom: 150 }}
                            >
                                <DiscreteColorLegend
                                    className="pwaLegend400"
                                    orientation="horizontal"
                                    height={75}
                                    items={_.chain(series).get("ref", []).uniqBy("title").value()}
                                    onItemClick={clickHandler}
                                />
                                <HorizontalGridLines />
                                <VerticalGridLines />
                                <XAxis
                                    title={aggregate === "month" ? i18n._(t`month`) : aggregate === "week" ? i18n._(t`weeks`) : i18n._(t`time`)}
                                    tickLabelAngle={-20}
                                    tickFormat={(value, index, scale, tickTotal) => {
                                        if (todo_compare) {
                                            switch (aggregate) {
                                                case "day":
                                                    return `${i18n._(t`day`)}+${value}`;
                                                case "week":
                                                    return `${i18n._(t`week`)}+${value}`;
                                                case "month":
                                                    return `${i18n._(t`month`)}+${value}`;
                                                default:
                                                    return value;
                                            }
                                        } else {
                                            const format = multiI18nFormat(value, current_lng);
                                            switch (aggregate) {
                                                case "day":
                                                    if (format.indexOf("06:00") >= 0 || format.indexOf("12:00") >= 0 || format.indexOf("18:00") >= 0)
                                                        return "";
                                                    return format;
                                                case "week":
                                                    return moment(parseInt(value)).locale(current_lng).format("WW");
                                                case "month":
                                                    return moment(parseInt(value)).locale(current_lng).format("MMMM YYYY");
                                                default:
                                                    return value;
                                            }
                                        }
                                    }}
                                />
                                <YAxis
                                    title={new_unit}
                                    tickFormat={(value, index, scale, tickTotal) => {
                                        const format = scale.tickFormat(tickTotal)(value);
                                        if (typeof value === "number") {
                                            return i18n.number(value, { maximumFractionDigits: 0 });
                                        }
                                        return format;
                                    }}
                                />
                                {_.chain(series)
                                    .map((serie, key) => {
                                        return _.chain(serie)
                                            .filter((item) => _.size(item.data) > 0)
                                            .filter((item) => item.disabled === false)
                                            .map((serie, idx) => {
                                                const data = signedStacks[key](
                                                    _.map(serie.data, (item, idx) => {
                                                        let val = null;
                                                        let repartition = null;
                                                        let total_stack = null;
                                                        if (_.isFinite(item.y)) {
                                                            val = item.y * factor;
                                                            if (key === "ref" && _.chain(ref_total).get(idx).isFinite().value()) {
                                                                total_stack = _.chain(ref_total).get(idx).value();
                                                                repartition = (item.y / total_stack) * 100;
                                                            } else if (key === "compare" && _.chain(comp_total).get(idx).isFinite().value()) {
                                                                total_stack = _.chain(comp_total).get(idx).value();
                                                                repartition = (item.y / total_stack) * 100;
                                                            }
                                                        }
                                                        return { ...item, y: val, y_real: val, repartition };
                                                    })
                                                );

                                                return (
                                                    <VerticalSignedBarSeries
                                                        cluster={key}
                                                        key={idx}
                                                        data={data}
                                                        color={serie.color}
                                                        fill={serie.color}
                                                        getNull={(d) => d.y !== null}
                                                        onValueMouseOver={(hintValue) => {
                                                            sethintValue(hintValue);
                                                        }}
                                                        onValueMouseOut={(hintValue) => {
                                                            sethintValue(null);
                                                        }}
                                                    />
                                                );
                                            })
                                            .value();
                                    })
                                    .value()}
                                {hintValue ? tooltip() : null}
                            </FlexibleXYPlot>
                        </Segment>
                    </>
                )}
            </div>
        );
    }
};

export default GraphicConsumption;
