import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { unitApi } from "./unitService";

const initialState = {
    units: []
};

const unitSlice = createSlice({
    name: "unit",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(unitApi.endpoints.getUnits.matchFulfilled, (state, action) => {
                state.units = action.payload;
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export default unitSlice.reducer;
