import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";
import cookie from "react-cookies";
import moment from "moment";

export const apIImportApi = defaultApi.injectEndpoints({
    reducerPath: "apiImportApi",
    endpoints: (build) => ({
        getApiImports: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `apiimports?org=${current_org}&${refreshTimestampUrlParam()}`;
            },

            providesTags: (result, error, arg) => {
                if (result) return [{ type: "ApiImports", id: arg.org.id }];
                return [];
            }
        }),
        getApiImport: build.query({
            query: ({ org, id }) => {
                const current_org = _.get(org, "name", null);
                return `apiimports/${id}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                return [{ type: "ApiImport", id: arg.id }];
            }
        }),
        createApiImport: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `apiimports?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [{ type: "ApiImports", id: arg.org.id }];
                }
                return [];
            }
        }),
        updateApiImport: build.mutation({
            query: ({ org, id, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `apiimports/${id}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "ApiImports", id: arg.org.id },
                        { type: "ApiImport", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        deleteApiImport: build.mutation({
            query: ({ org, id }) => {
                const current_org = _.get(org, "name", null);

                return {
                    url: `apiimports/${id}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "ApiImport", id: arg.data },
                        { type: "ApiImports", id: arg.org.id }
                    ];
                }
                return [];
            }
        })
    })
});

export const { useGetApiImportsQuery, useGetApiImportQuery, useUpdateApiImportMutation, useCreateApiImportMutation, useDeleteApiImportMutation } =
    apIImportApi;
