import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Form, Field } from "react-final-form";
import { toast } from "react-toastify";
import { Grid, Segment, Accordion, Icon, Message, Label, Button, Header, Popup, Divider } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { aggregate_options, groupByOptions, comparison_options, advancedUnitsOptions, graphicTypeOptions } from "../../../analysisAdvanced/utils";
import { identityNull } from "modules/common/utils/form";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { calendarTypeOptions, historic_options_widget, keyToReparseInDnD, WidgetTypeOptions } from "modules/dashboardDragNDrop/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useCreateWidgetMutation, useUpdateWidgetMutation } from "../../dashboardDndService";

import { DropDownAdapter, InputAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const StackBarConsumption = (props) => {
    const { current_dashboard, onClose, widgetChoice, widget, disabled } = props;

    const org = useSelector((state) => state.org);

    const [createWidget, create] = useCreateWidgetMutation();
    const [updateWidget, update] = useUpdateWidgetMutation();

    // Create Widget
    useEffect(() => {
        if (create.isSuccess) {
            toast.success(i18n._(t`Successful create widget`), toast_options);
            onClose();
        }
        if (create.isError) {
            let error = i18n._(t`Can't create widget`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
            onClose();
        }
    }, [create, onClose]);

    // Update Widget
    useEffect(() => {
        if (update.isSuccess) {
            toast.success(i18n._(t`Successful update widget`), toast_options);
            onClose();
        }
        if (update.isError) {
            let error = i18n._(t`Can't update widget`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
            onClose();
        }
    }, [update, onClose]);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });

    const err_list = [sites.isError, zones.isError, usages.isError, tags.isError, categories.isError];
    const status_list = [sites.isSuccess, zones.isSuccess, usages.isSuccess, tags.isSuccess, categories.isSuccess];

    const initialValues = useMemo(() => {
        const defaultValues = {
            toggle_accordion: false,
            name: null,
            numerator_unit: null,
            widget_type: widgetChoice,
            historic: null,
            sites: _.size(sites.data) === 1 && sites.data[0].id ? [sites.data?.[0].id] : [],
            zones: [],
            usages: [],
            tags: [],
            categories: [],
            reference_level: null,
            threshold_min: null,
            threshold_max: null,
            dashboard: current_dashboard.id,
            calendar_choices: 2,
            aggregation: 1,
            x: 0,
            y: 0,
            w: 5,
            h: 5
        };

        return {
            ...defaultValues,
            ...widget
        };
    }, [widget, widgetChoice, current_dashboard.id, sites.data]);

    //limit category for dropdown measurement
    const { filtered_cats } = useMemo(() => {
        if (categories.isSuccess) {
            const cats = _.chain(categories.data)
                .reduce((res, category) => {
                    if (_.includes(["elec", "gas", "water", "calories", "frigories"], category.name)) {
                        const { key, text, value } = category;
                        res.push({
                            key,
                            text: i18n._(text),
                            value
                        });
                    }
                    return res;
                }, [])
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
            return {
                filtered_cats: cats
            };
        }
        return { filtered_cats: [] };
    }, [categories]);

    const submitForm = async (formdata) => {
        let data = _.mapValues(formdata, (item, key) => {
            if (_.includes(keyToReparseInDnD, key)) {
                return reparseNumber(item);
            }
            return item;
        });

        if (widget) {
            await updateWidget({ formdata: _.omit(data, ["toggle_accordion"]), org: org.current, dashboard_id: current_dashboard.id });
        } else {
            await createWidget({ formdata: _.omit(data, ["toggle_accordion"]), org: org.current, dashboard_id: current_dashboard.id });
        }
    };

    const title = i18n._(_.chain(WidgetTypeOptions).find({ value: widgetChoice }).get("text", "-").value());

    const MyHeader = () => (
        <Header attached="top" block style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {title} 
            <Popup position="bottom center" trigger={<Icon name="question circle" />}>
                <Segment basic style={{ width: "50vh" }}>
                    <Trans>Consumption breakdown of the selected equipments over a period, displayed in a stack bar chart</Trans>
                </Segment>
            </Popup>
        </Header>
    );

    const sites_id = useMemo(() => {
        return _.map(sites.data, (item) => item.id);
    }, [sites.data]);

    const validate = (values) => {
        const err = {};
        if (!disabled) {
            const retrieve_sites = _.chain(values)
                .get("sites", [])
                .reduce((res, site_id) => {
                    const site = _.find(sites.data, { id: site_id });
                    if (site) {
                        res.push(site);
                    }
                    return res;
                }, []);

            const hasMultipleTimezone = retrieve_sites.groupBy("timezone").size().value() > 1;
            if (hasMultipleTimezone) {
                err.sites = <Trans>Sites must have the same timezone</Trans>;
            }

            if (_.includes(["cost", "kcost"], values.numerator_unit)) {
                const missingCurrency = _.reduce(
                    ["undefined", ""],
                    (result, currency) => {
                        return result || retrieve_sites.groupBy("conversions.currency").get(currency, []).size().value() > 0;
                    },
                    false
                );

                if (missingCurrency) {
                    err.sites = <Trans>Some site has no currency definition</Trans>;
                }

                const hasMultipleCurrency = retrieve_sites.groupBy("conversions.currency").size().value() > 1;
                if (hasMultipleCurrency) {
                    err.sites = <Trans>Sites must have the same currency</Trans>;
                }
            }
        }
        return err;
    };

    return (
        <>
            {(() => {
                if (_.some(err_list)) {
                    return (
                        <>
                            <MyHeader />
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={true}
                            />
                            <Segment attached textAlign="right" basic>
                                <Button type="button" negative onClick={() => onClose()}>
                                    <Trans>cancel</Trans>
                                </Button>
                            </Segment>
                        </>
                    );
                } else if (_.every(status_list)) {
                    return (
                        <Form
                            onSubmit={submitForm}
                            validate={validate}
                            initialValues={initialValues}
                            render={({ handleSubmit, submitting, pristine, invalid, values, form }) => {
                                const { aggregation, comparison } = values;

                                const filter_historic_options = _.chain(historic_options_widget)
                                    .filter((item) => ![0, 8].includes(item.value)) //exclude first choice 'yesterday' and last choice 'since midnight'
                                    .reduce((res, item) => {
                                        if (aggregation === 1) {
                                            if (item.value === 7) return res;
                                            if (comparison !== 0 && item.value === 6) return res;
                                        } else if (aggregation === 2) {
                                            if (item.value === 8) return res; //exclude since midnight option
                                            //exclude choices lower than aggregation period
                                            if (item.value < 4) return res;
                                        } else if (aggregation === 3) {
                                            if (item.value === 8) return res; //exclude since midnight option
                                            if (item.value < 6) return res;
                                        }
                                        res.push(item);
                                        return res;
                                    }, [])
                                    .value();

                                const not_visible_sites = values.id && _.chain(values.sites).difference(sites_id).size().value() > 0;

                                return (
                                    <form onSubmit={handleSubmit} className="ui form" style={{ marginTop: "1em" }}>
                                        <MyHeader />
                                        {!disabled && _.size(values.sites) === 0 && (
                                            <MessageDisplay
                                                message={i18n._(t`select at least 1 site`)}
                                                level="info"
                                                iconName="info circle"
                                                isLoading={false}
                                            />
                                        )}
                                        <Segment attached>
                                            <Grid verticalAlign="top" centered>
                                                <Grid stackable centered verticalAlign="top" style={{ marginTop: ".5rem" }}>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <Field
                                                                name="name"
                                                                disabled={disabled}
                                                                label={i18n._(t`widget name`)}
                                                                placeholder={i18n._(t`enter name of widget`)}
                                                                isRequired={true}
                                                                component={InputAdapter}
                                                                validate={(value) => {
                                                                    if (!disabled) {
                                                                        if (!value) {
                                                                            return <Trans>Required field</Trans>;
                                                                        }
                                                                    }
                                                                    return undefined;
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    //Hack to prevent parent dropdown tabspace interaction
                                                                    if (e.keyCode === 32) {
                                                                        e.stopPropagation();
                                                                    }
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <Field
                                                                name="numerator_unit"
                                                                disabled={disabled}
                                                                className="icon"
                                                                icon="fire"
                                                                button
                                                                labeled
                                                                search={false}
                                                                label={i18n._(t`select unit`)}
                                                                isRequired={true}
                                                                placeholder={i18n._(t`select unit`)}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                options={advancedUnitsOptions}
                                                                component={DropDownAdapter}
                                                                defaultValue={graphicTypeOptions[0].value}
                                                                validate={(value) => {
                                                                    if (!disabled) {
                                                                        if (!value) {
                                                                            return <Trans>Required field</Trans>;
                                                                        }
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>
                                                            <Field
                                                                label={i18n._(t`historic`)}
                                                                name="historic"
                                                                disabled={disabled}
                                                                className="icon"
                                                                icon="calendar"
                                                                isRequired={true}
                                                                button
                                                                labeled
                                                                search={false}
                                                                placeholder={i18n._(t`historic`)}
                                                                options={filter_historic_options}
                                                                component={DropDownAdapter}
                                                                validate={(value) => {
                                                                    if (!disabled) {
                                                                        if (!value && value !== 0) return <Trans>Required field</Trans>;
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Field
                                                                label={i18n._(t`aggregation`)}
                                                                name="aggregation"
                                                                disabled={disabled}
                                                                className="icon"
                                                                icon="object group"
                                                                button
                                                                labeled
                                                                search={false}
                                                                options={aggregate_options}
                                                                component={DropDownAdapter}
                                                                placeholder={i18n._(t`select aggregation`)}
                                                                isRequired={true}
                                                                customAction={(data) => {
                                                                    //reset historic choice based on aggregate && comparison
                                                                    const historic = _.get(form.getFieldState("historic"), "value", 1);
                                                                    const comparison = _.get(form.getFieldState("comparison"), "value", 0);
                                                                    if (data === 1) {
                                                                        // day aggregation
                                                                        if (historic === 7 || (comparison !== 0 && historic === 6)) {
                                                                            form.change("historic", 1);
                                                                        }
                                                                    } else if (data === 2) {
                                                                        //week aggregation
                                                                        if (historic < 4 || historic === 8) form.change("historic", 4);
                                                                    } else if (data === 3) {
                                                                        //month aggregation
                                                                        if (historic < 6 || historic === 8) form.change("historic", 6);
                                                                    }
                                                                }}
                                                                validate={(value) => {
                                                                    if (!disabled) {
                                                                        if (!value) return <Trans>Required field</Trans>;
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>
                                                            <Field
                                                                label={i18n._(t`comparison`)}
                                                                name="comparison"
                                                                className="icon"
                                                                icon="law"
                                                                button
                                                                labeled
                                                                search={false}
                                                                disabled={disabled}
                                                                placeholder={i18n._(t`select comparison`)}
                                                                options={comparison_options}
                                                                component={DropDownAdapter}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Field
                                                                label={i18n._(t`select calendar`)}
                                                                name="calendar_choices"
                                                                isRequired={true}
                                                                disabled={disabled}
                                                                className="icon"
                                                                icon="calendar outline"
                                                                button
                                                                labeled
                                                                search={false}
                                                                placeholder={i18n._(t`Select calendars`)}
                                                                options={_.map(calendarTypeOptions, ({ key, text, value }) => ({
                                                                    key,
                                                                    text: i18n._(text),
                                                                    value,
                                                                    content: <Label color="grey">{i18n._(text)}</Label>
                                                                }))}
                                                                component={DropDownAdapter}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>
                                                            <Field
                                                                name="sites"
                                                                disabled={disabled}
                                                                label={i18n._(t`select sites`)}
                                                                placeholder={i18n._(t`select sites`)}
                                                                isRequired={true}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                options={_.chain(sites.data)
                                                                    .map(({ key, text, value }) => ({ key, text, value }))
                                                                    .orderBy((item) => {
                                                                        return removeAccents(item.text).toLowerCase();
                                                                    }, "asc")
                                                                    .value()}
                                                                multipleselect={1}
                                                                component={DropDownAdapter}
                                                                renderLabel={(label, index, defaultProps) => {
                                                                    const labelProps =
                                                                        _.size(values.sites) <= 1
                                                                            ? { ...defaultProps, onRemove: null }
                                                                            : defaultProps;
                                                                    return { ...labelProps, color: "blue", content: i18n._(label.text) };
                                                                }}
                                                                validate={(value) => {
                                                                    if (!disabled) {
                                                                        if (_.size(value) < 1) return <Trans>Required field</Trans>;
                                                                    }
                                                                    return undefined;
                                                                }}
                                                                customAction={(data) => {
                                                                    if (!disabled) {
                                                                        //Remove invalid zones based on selected sites
                                                                        const zonesFilter = _.reduce(
                                                                            values.zones,
                                                                            (res, zone_id) => {
                                                                                const zone = _.find(zones.data, { id: zone_id });
                                                                                if (_.includes(data, zone?.site_id)) {
                                                                                    res.push(zone_id);
                                                                                }
                                                                                return res;
                                                                            },
                                                                            []
                                                                        );
                                                                        form.change("zones", zonesFilter);
                                                                    }
                                                                }}
                                                            />
                                                            {not_visible_sites && (
                                                                <div style={{ color: "#794b02", fontStyle: "italic" }}>
                                                                    <Trans>Some sites are present but you do not have permission to view them</Trans>
                                                                </div>
                                                            )}
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Field
                                                                label={i18n._(t`Grouping`)}
                                                                name="group_by"
                                                                disabled={disabled}
                                                                className="icon"
                                                                icon="gitter"
                                                                button
                                                                labeled
                                                                search={false}
                                                                placeholder={i18n._(t`aggregate_type_placeholder`)}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                options={groupByOptions}
                                                                component={DropDownAdapter}
                                                                defaultValue={groupByOptions[0].value}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <Divider horizontal>
                                                                <Trans>Additional filters</Trans>
                                                            </Divider>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>
                                                            <Field
                                                                name="zones"
                                                                disabled={disabled || values.sites.length === 0}
                                                                placeholder={i18n._(t`select zones`)}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                options={_.chain(zones.data)
                                                                    .filter((item) => {
                                                                        return _.includes(values.sites, item?.site_id);
                                                                    })
                                                                    .map(({ key, text, value }) => ({ key, text, value }))
                                                                    .orderBy((item) => {
                                                                        return removeAccents(item.text).toLowerCase();
                                                                    }, "asc")
                                                                    .value()}
                                                                multipleselect={1}
                                                                component={DropDownAdapter}
                                                                renderLabel={(label, index, defaultProps) => {
                                                                    return { ...defaultProps, color: "teal", content: i18n._(label.text) };
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Field
                                                                name="usages"
                                                                disabled={disabled}
                                                                placeholder={i18n._(t`select usages`)}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                options={_.chain(usages.data)
                                                                    .map(({ key, text, value }) => ({ key, text, value }))
                                                                    .orderBy((item) => {
                                                                        return removeAccents(item.text).toLowerCase();
                                                                    }, "asc")
                                                                    .value()}
                                                                multipleselect={1}
                                                                component={DropDownAdapter}
                                                                renderLabel={(label, index, defaultProps) => {
                                                                    return { ...defaultProps, color: "violet", content: i18n._(label.text) };
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>
                                                            <Field
                                                                name="tags"
                                                                disabled={disabled}
                                                                placeholder={i18n._(t`select tags`)}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                options={_.chain(tags.data)
                                                                    .map(({ key, text, value }) => ({ key, text, value }))
                                                                    .orderBy((item) => {
                                                                        return removeAccents(item.text).toLowerCase();
                                                                    }, "asc")
                                                                    .value()}
                                                                multipleselect={1}
                                                                component={DropDownAdapter}
                                                                renderLabel={(label, index, defaultProps) => {
                                                                    return { ...defaultProps, color: "grey", content: i18n._(label.text) };
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Field
                                                                name="categories"
                                                                disabled={disabled}
                                                                placeholder={i18n._(t`select categories`)}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                options={filtered_cats}
                                                                multipleselect={1}
                                                                component={DropDownAdapter}
                                                                renderLabel={(label, index, defaultProps) => {
                                                                    return { ...defaultProps, color: "purple", content: i18n._(label.text) };
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <Accordion fluid styled>
                                                                <Accordion.Title
                                                                    active={values.toogle_accordion || false}
                                                                    onClick={() => form.change("toogle_accordion", !values.toogle_accordion)}
                                                                >
                                                                    <Icon name="dropdown" />
                                                                    <Trans>Threshold setup</Trans>
                                                                </Accordion.Title>
                                                                <Accordion.Content active={values.toogle_accordion || false}>
                                                                    <Message info attached="top">
                                                                        <Trans>Thresholds are displayed on the graph</Trans>
                                                                    </Message>
                                                                    <Segment attached>
                                                                        <Field
                                                                            name="reference_level"
                                                                            disabled={disabled}
                                                                            placeholder={i18n._(t`enter reference level`)}
                                                                            label={i18n._(t`reference_level`)}
                                                                            component={InputAdapter}
                                                                            parse={identityNull}
                                                                            inputMode="decimal"
                                                                            defaultValue={null}
                                                                            validate={(value) => {
                                                                                if (!disabled) {
                                                                                    return validateNumber(value, i18n, true, true);
                                                                                }
                                                                                return undefined;
                                                                            }}
                                                                        />
                                                                        <Field
                                                                            name="threshold_min"
                                                                            disabled={disabled}
                                                                            placeholder={i18n._(t`enter threshold min`)}
                                                                            label={i18n._(t`threshold_min`)}
                                                                            component={InputAdapter}
                                                                            parse={identityNull}
                                                                            inputMode="decimal"
                                                                            defaultValue={null}
                                                                            validate={(value) => {
                                                                                if (!disabled) {
                                                                                    return validateNumber(value, i18n, true, true);
                                                                                }
                                                                                return undefined;
                                                                            }}
                                                                        />
                                                                        <Field
                                                                            name="threshold_max"
                                                                            disabled={disabled}
                                                                            placeholder={i18n._(t`enter threshold max`)}
                                                                            label={i18n._(t`threshold_max`)}
                                                                            component={InputAdapter}
                                                                            parse={identityNull}
                                                                            inputMode="decimal"
                                                                            defaultValue={null}
                                                                            validate={(value) => {
                                                                                if (!disabled) {
                                                                                    return validateNumber(value, i18n, true, true);
                                                                                }
                                                                                return undefined;
                                                                            }}
                                                                        />
                                                                    </Segment>
                                                                </Accordion.Content>
                                                            </Accordion>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid>
                                        </Segment>
                                        <Segment attached basic textAlign="right">
                                            <Button type="button" negative onClick={() => onClose()}>
                                                <Trans>cancel</Trans>
                                            </Button>
                                            {!disabled && (
                                                <Button
                                                    type="submit"
                                                    positive
                                                    icon
                                                    labelPosition="right"
                                                    disabled={submitting || pristine || invalid}
                                                >
                                                    <Icon name="check" />
                                                    <Trans>validate</Trans>
                                                </Button>
                                            )}
                                        </Segment>
                                    </form>
                                );
                            }}
                        />
                    );
                }
                return (
                    <>
                        <MyHeader />
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        <Segment attached textAlign="right" basic>
                            <Button type="button" negative onClick={() => onClose()}>
                                <Trans>cancel</Trans>
                            </Button>
                        </Segment>
                    </>
                );
            })()}
        </>
    );
};

export default StackBarConsumption;
