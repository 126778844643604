import _ from "lodash";
import { regexNumberFloat } from ".";

export const parseNumber = (value) => {
    const toNum = parseFloat(value.replace(/[^\d.]/g, ""));
    if (!_.isFinite(toNum)) {
        return null;
    }
    return toNum;
};

export const identity = (value) => value;
export const identityNull = (value) => {
    if (value === "") {
        return null;
    }
    return value;
};

export const autoParseNumber = (value) => {
    if (value === "") {
        return null;
    }
    if (regexNumberFloat.test(value)) {
        return parseFloat(value);
    }
    return value;
};

export const autoParseNumberDecimal = (value) => {
    if (value === "") {
        return null;
    }
    if (regexNumberFloat.test(value)) {
        return value;
    }
    return value;
};
