import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";

export const componentApi = defaultApi.injectEndpoints({
    reducerPath: "apiComponent",
    endpoints: (build) => ({
        getComponents: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `components?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: ["Components"]
        })
    }),
    overrideExisting: false
});

export const { useGetComponentsQuery } = componentApi;
