import React, { useState } from "react";
import { t } from "@lingui/macro";
import _ from "lodash";
import { Card, Segment } from "semantic-ui-react";
import { FlexibleXYPlot, Hint, HorizontalGridLines, LabelSeries, LineSeries, VerticalBarSeries, XAxis, YAxis } from "react-vis";

import i18n from "modules/i18n/i18nConfig";
import GenerateCsv from "modules/common/components/GenerateCsv";
import { Media } from "App";
import MessageDisplay from "modules/common/components/MessageDisplay";

const GraphicPumpHistogram = (props) => {
    const { histogram } = props;
    const [hintValue, setHintValue] = useState(null);

    if (_.isNull(histogram)) {
        return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />;
    }

    const tooltip = () => {
        return (
            <Hint value={hintValue}>
                <Card className="pwaOverviewTooltip" style={{ width: 160 }}>
                    <Card.Content>
                        <Card.Description>
                            {hintValue.y !== null ? `${i18n.number(hintValue.y, { maximumFractionDigits: 2 })} %` : "-"}
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Hint>
        );
    };

    // Retrieve mid_points (of bep_dist_bins) from histograme.data (it's 'x' value, processed in MachineService)
    // We need mid_points to display XAxis' tick label
    const mid_point_bep_dist_bins = _.chain(histogram.data)
        .map((item) => {
            return item.x;
        })
        .value();

    const has_no_data = _.every(histogram.data, (point) => point.y === null);

    if (has_no_data) {
        return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />;
    }

    return (
        <>
            <Media greaterThanOrEqual="computer">
                {(mediaClassNames, renderChildren) =>
                    renderChildren && (
                        <Segment attached textAlign="right">
                            <GenerateCsv series={[histogram]} formatted_time={false} filename={props.csvName || "export"} />
                        </Segment>
                    )
                }
            </Media>
            <Segment attached>
                <FlexibleXYPlot xType="linear" yDomain={[0, 110]} height={460} margin={{ left: 60, right: 10, top: 10, bottom: 100 }}>
                    <HorizontalGridLines />
                    <XAxis
                        title={i18n._(t`% of best efficiency`)}
                        tickLabelAngle={-20}
                        tickValues={mid_point_bep_dist_bins}
                        tickFormat={(value, index, scale, tickTotal) => {
                            //Hardcoded tick text based on tick's index
                            switch (index) {
                                case 0:
                                    return (
                                        <tspan>
                                            <tspan x="0" dy="1em">
                                                {i18n._(t`0% to 30%`)}
                                            </tspan>
                                        </tspan>
                                    );
                                case 1:
                                    return (
                                        <tspan>
                                            <tspan x="0" dy="1em">
                                                {i18n._(t`30% to 60%`)}
                                            </tspan>
                                        </tspan>
                                    );
                                case 2:
                                    return (
                                        <tspan>
                                            <tspan x="0" dy="1em">
                                                {i18n._(t`60% to 90%`)}
                                            </tspan>
                                        </tspan>
                                    );
                                case 3:
                                    return (
                                        <tspan>
                                            <tspan x="0" dy="1em">
                                                {i18n._(t`Optimal Efficiency (+/- 10%)`)}
                                            </tspan>
                                        </tspan>
                                    );
                                case 4:
                                    return (
                                        <tspan>
                                            <tspan x="0" dy="1em">
                                                {i18n._(t`60% to 90%`)}
                                            </tspan>
                                        </tspan>
                                    );
                                case 5:
                                    return (
                                        <tspan>
                                            <tspan x="0" dy="1em">
                                                {i18n._(t`30% to 60%`)}
                                            </tspan>
                                        </tspan>
                                    );
                                case 6:
                                    return (
                                        <tspan>
                                            <tspan x="0" dy="1em">
                                                {i18n._(t`0% to 30%`)}
                                            </tspan>
                                        </tspan>
                                    );
                                default:
                                    return "";
                            }
                        }}
                    />
                    <YAxis
                        title={"% usage"}
                        tickFormat={(value, index, scale, tickTotal) => {
                            const format = scale.tickFormat(tickTotal)(value);
                            if (format === "110") {
                                return "";
                            }
                            return format;
                        }}
                    />
                    <LabelSeries
                        data={[
                            { x: -0.55, y: 110, label: i18n._(t`Underflow`), style: { fontStyle: "italic", cursor: "auto" } },
                            { x: 0.55, y: 110, label: i18n._(t`Overflow`), style: { fontStyle: "italic", cursor: "auto" } }
                        ]}
                    />
                    <LineSeries
                        data={[
                            { x: 0, y: 0 },
                            { x: 0, y: 110 }
                        ]}
                        strokeWidth={0.6}
                        strokeDasharray={"7,4"}
                        color={"green"}
                    />
                    <VerticalBarSeries
                        color={histogram.color}
                        getNull={(d) => d.y !== null}
                        data={histogram.data}
                        onValueMouseOver={(hintValue) => {
                            setHintValue(hintValue);
                        }}
                        onValueMouseOut={() => {
                            setHintValue(null);
                        }}
                        barWidth={0.5}
                    />
                    {hintValue ? tooltip() : null}
                </FlexibleXYPlot>
                {!_.isUndefined(props.children) && (
                    <Segment basic style={{ marginTop: "15px" }}>
                        {props.children}
                    </Segment>
                )}
            </Segment>
        </>
    );
};

export default React.memo(GraphicPumpHistogram);
