import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import tinycolor from "tinycolor2";
import Palette from "modules/common/components/graphic/Colors";
import { Grid, Header, Segment } from "semantic-ui-react";
import { Hint } from "react-vis";
import ReactSpeedometer from "react-d3-speedometer";

import i18n from "modules/i18n/i18nConfig";
import { setTime } from "modules/overview/overviewSlice";
import { dateRangeOptions } from "modules/time/utils";
import { useGetPumpBackgroundQuery, useGetPumpMonitoringQuery } from "modules/machine/machineService";

import BarGauge from "modules/common/components/graphic/BarGauge";
import TimePanel from "modules/common/components/TimePanel";
import MessageDisplay from "modules/common/components/MessageDisplay";
import GraphicDetail from "../graphic/GraphicDetail";
import RotatedLabelRadialChart from "modules/common/components/graphic/PieChart";
import HeaderWHelper from "modules/common/components/HeaderWHelper";
import GraphicPumpHeadFlow from "../graphic/GraphicPumpHeadFlow";
import GraphicPumpHistogram from "../graphic/GraphicPumpHistogram";

const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(["24h", "48h", "72h"], item.value));

const TabPumpEfficiency = ({ default_time, equipment, pump_description_empty }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState(null);
    const org = useSelector((state) => state.org);
    const theme = useSelector((state) => state.common.theme);
    const current_lng = useSelector((state) => state.i18n.current);
    const { pumptime } = useSelector((state) => state.overview);

    const id_machine = equipment?.machine?.id ?? null;

    const rangeTime = pumptime.time
        ? {
              start: moment(pumptime.time.start),
              end: moment(pumptime.time.end),
              plage: pumptime.time.plage
          }
        : default_time;

    const start = pumptime?.time?.start ?? null;
    const end = pumptime?.time?.end ?? null;
    const pump_params = {
        org: org.current,
        id_machine,
        start: start ? start : default_time.start.toISOString(),
        end: end ? end : default_time.end.toISOString()
    };

    const pump_monitoring = useGetPumpMonitoringQuery(pump_params, { skip: !org.current || pump_description_empty });
    const backgroundImg = useGetPumpBackgroundQuery(
        { id_machine, org: org.current, theme },
        { skip: !org.current || !id_machine || pump_description_empty }
    );

    const {
        motor_efficiency = null,
        pump_efficiency = null,
        power_loss_repartition = [],
        bep_loss_total = null,
        bep_loss_percent = null,
        bep_spot_kw = null,
        bep_spot_percent = null,
        head_flow = null,
        graph_maxx = null,
        graph_maxy = null,
        histogram = null,
        bep_dist_bins = [],
        detail = [],
        spot_ts = null
    } = pump_monitoring?.data || {};

    const display_spot_ts =
        _.isFinite(spot_ts) &&
        moment(spot_ts).isValid() &&
        `${moment(spot_ts).locale(current_lng).format("L")} ${moment(spot_ts).locale(current_lng).format("LTS")}`;

    if (pump_description_empty) {
        return <MessageDisplay message={i18n._(t`pump not configured`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (pump_monitoring.isFetching) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (pump_monitoring.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }
    if (pump_monitoring.isSuccess) {
        return (
            <Grid id="pwaMain" verticalAlign="top" centered>
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <TimePanel
                            time={rangeTime}
                            rangeOptions={rangeOptions}
                            accordion={true}
                            action={({ start, end, plage }) => {
                                dispatch(setTime({ start, end, plage, tab: "pumptime" }));
                            }}
                            maximumDeltaDay={90}
                            date_limit={moment()}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={4} textAlign="center">
                        <Header block textAlign="center" attached="top" style={{ maxHeight: "40px" }}>
                            <HeaderWHelper
                                text={<Trans>pump efficiency</Trans>}
                                helper={
                                    <Trans>
                                        <p>
                                            Pump efficiency (The proportion of mechanical power input at the rotating shaft which is effectively
                                            converted to hydraulic power).
                                        </p>
                                    </Trans>
                                }
                            />
                        </Header>
                        {!_.isFinite(pump_efficiency) && (
                            <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />
                        )}
                        {_.isFinite(pump_efficiency) && (
                            <>
                                <Segment attached style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
                                    <ReactSpeedometer
                                        value={_.chain(pump_efficiency).clamp(0, 100).value()}
                                        currentValueText={`${i18n.number(_.chain(pump_efficiency).clamp(0, 100).value(), {
                                            maximumFractionDigits: 0
                                        })} %`}
                                        ringWidth={25}
                                        customSegmentStops={[0, 70, 85, 100]}
                                        segmentColors={[Palette.named.red, Palette.named.orange, Palette.named.green]}
                                        paddingHorizontal={0}
                                        paddingVertical={0}
                                        needleHeightRatio={0.8}
                                        needleColor={"gray"}
                                        minValue={0}
                                        maxValue={100}
                                        width={210}
                                        height={140}
                                    />
                                </Segment>
                                {display_spot_ts && (
                                    <Segment attached>
                                        <span>
                                            <b>{display_spot_ts}</b>
                                        </span>
                                    </Segment>
                                )}
                            </>
                        )}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={4} textAlign="center">
                        <Header block textAlign="center" attached="top" style={{ maxHeight: "40px" }}>
                            <HeaderWHelper
                                text={<Trans>motor efficiency</Trans>}
                                helper={
                                    <Trans>
                                        <p>
                                            Motor efficiency (The proportion of electrical power input which is effectively converted to mechanical
                                            power output at the rotating shaft).
                                        </p>
                                    </Trans>
                                }
                            />
                        </Header>
                        {!_.isFinite(motor_efficiency) && (
                            <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />
                        )}
                        {_.isFinite(motor_efficiency) && (
                            <>
                                <Segment attached style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
                                    <ReactSpeedometer
                                        value={_.chain(motor_efficiency).clamp(0, 100).value()}
                                        currentValueText={`${i18n.number(_.chain(motor_efficiency).clamp(0, 100).value(), {
                                            maximumFractionDigits: 0
                                        })} %`}
                                        ringWidth={25}
                                        customSegmentStops={[0, 70, 85, 100]}
                                        segmentColors={[Palette.named.red, Palette.named.orange, Palette.named.green]}
                                        paddingHorizontal={0}
                                        paddingVertical={0}
                                        needleHeightRatio={0.8}
                                        needleColor={"gray"}
                                        minValue={0}
                                        maxValue={100}
                                        width={210}
                                        height={140}
                                    />
                                </Segment>
                                {display_spot_ts && (
                                    <Segment attached>
                                        <span>
                                            <b>{display_spot_ts}</b>
                                        </span>
                                    </Segment>
                                )}
                            </>
                        )}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={6} textAlign="center">
                        <Header block textAlign="center" attached="top" style={{ maxHeight: "40px" }}>
                            <Header as="h3" style={{ display: "inline-flex", alignItems: "center" }} size="small">
                                <Trans>power loss repartition</Trans>
                            </Header>
                        </Header>
                        {_.size(power_loss_repartition) === 0 && (
                            <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />
                        )}
                        {_.size(power_loss_repartition) > 0 && (
                            <>
                                <Segment attached style={{ minHeight: "200px" }}>
                                    <RotatedLabelRadialChart
                                        className="pwaGlobalOverviewRadialChart"
                                        getLabel={(d) => (d.percent > 1 ? i18n._(d.name) : "...")}
                                        colorType="literal"
                                        data={power_loss_repartition}
                                        onValueMouseOver={(v) => setValue(v)}
                                        onSeriesMouseOut={(v) => setValue(null)}
                                        radius={60}
                                        labelsRadiusMultiplier={1.2}
                                        labelsStyle={{ fontSize: 12 }}
                                        labelsRotation={-20}
                                        showLabels
                                        style={{ stroke: "#fff", strokeWidth: 2 }}
                                        width={210}
                                        height={190}
                                        margin={{ top: 0, bottom: 0 }}
                                    >
                                        {value !== null && (
                                            <Hint value={value}>
                                                <Segment style={{ color: tinycolor(value.color).setAlpha(1).toString() }}>{`${i18n._(value.name)}: ${
                                                    value.percent
                                                } %`}</Segment>
                                            </Hint>
                                        )}
                                    </RotatedLabelRadialChart>
                                </Segment>
                                {display_spot_ts && (
                                    <Segment attached>
                                        <span>
                                            <b>{display_spot_ts}</b>
                                        </span>
                                    </Segment>
                                )}
                            </>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={7} textAlign="center">
                        <Header attached="top" block textAlign="center" style={{ maxHeight: "40px" }}>
                            <HeaderWHelper
                                text={
                                    <>
                                        <Trans>Overconsumption</Trans>
                                        {_.isFinite(bep_spot_kw) && ` : ${i18n.number(bep_spot_kw, { maximumFractionDigits: 2 })} kW`}
                                    </>
                                }
                                helper={
                                    <Trans>
                                        <p>Wasted power due to the difference between current operating point and BEP (last data).</p>
                                    </Trans>
                                }
                            />
                            {display_spot_ts && <span style={{ fontWeight: "bold", whiteSpace: "pre" }}>({display_spot_ts})</span>}
                        </Header>
                        {!_.isFinite(bep_spot_percent) && (
                            <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />
                        )}
                        {_.isFinite(bep_spot_percent) && (
                            <Segment attached>
                                <BarGauge
                                    value={{
                                        text: `${i18n.number(bep_spot_percent, { maximumFractionDigits: 1 })} %`,
                                        numeric: bep_spot_percent
                                    }}
                                    minValue={0}
                                    maxValue={100}
                                    thresholds={[
                                        { value: 0, color: Palette.named.green },
                                        { value: 15, color: Palette.named.orange },
                                        { value: 30, color: Palette.named.red }
                                    ]}
                                    height={40}
                                    width={200}
                                    itemSpacing={2}
                                />
                            </Segment>
                        )}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={7} textAlign="center">
                        <Header attached="top" block textAlign="center" style={{ maxHeight: "40px" }}>
                            <HeaderWHelper
                                text={
                                    <>
                                        <Trans>Losses compared with the BEP over the period</Trans>
                                        {_.isFinite(bep_loss_total) && ` : ${i18n.number(bep_loss_total, { maximumFractionDigits: 2 })} kWh`}
                                    </>
                                }
                                helper={
                                    <Trans>
                                        <p>
                                            Amount of energy that could have been saved if the pump was operating at its best efficiency over the
                                            period.
                                        </p>
                                    </Trans>
                                }
                            />
                        </Header>
                        {!_.isFinite(bep_loss_percent) && (
                            <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />
                        )}
                        {_.isFinite(bep_loss_percent) && (
                            <Segment attached>
                                <BarGauge
                                    value={{
                                        text: `${i18n.number(bep_loss_percent, { maximumFractionDigits: 1 })} %`,
                                        numeric: bep_loss_percent
                                    }}
                                    minValue={0}
                                    maxValue={100}
                                    thresholds={[
                                        { value: 0, color: Palette.named.green },
                                        { value: 15, color: Palette.named.orange },
                                        { value: 30, color: Palette.named.red }
                                    ]}
                                    height={40}
                                    width={200}
                                    itemSpacing={2}
                                />
                            </Segment>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header as="h3" block textAlign="center" attached="top">
                            <Trans>pump efficiency</Trans>
                        </Header>
                        <GraphicPumpHeadFlow
                            serie={head_flow}
                            csvName={equipment?.name ?? "export"}
                            backgroundImage={backgroundImg.data}
                            maxX={graph_maxx}
                            maxY={graph_maxy}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header attached="top" block textAlign="center">
                            <HeaderWHelper
                                text={<Trans>histogram pump</Trans>}
                                helper={
                                    <Trans>
                                        <p>The histogram shows the distribution of the pump's operating zones.</p>
                                        <p>
                                            The central "optimum efficiency" zone corresponds to the area where the pump runs at its best efficiency
                                            (between 90% and 100% of its maximum efficiency).
                                        </p>
                                    </Trans>
                                }
                            />
                        </Header>
                        <GraphicPumpHistogram histogram={histogram} bep_dist_bins={bep_dist_bins} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header as="h3" block textAlign="center" attached="top">
                            <Trans>detail</Trans>
                        </Header>
                        <GraphicDetail time={rangeTime} data={detail} csvName={equipment?.name ?? "export"} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
    return null;
};

export default TabPumpEfficiency;
