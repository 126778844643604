import { t } from "@lingui/macro";

export const diagramTypeOptions = [
    { key: 1, text: t`Elec kWh`, value: 1, unit: "kWh" },
    { key: 2, text: t`Gas kWh`, value: 2, unit: "kWh" },
    { key: 3, text: t`Gas m3`, value: 3, unit: "m3" },
    { key: 4, text: t`Gas Nm3`, value: 4, unit: "Nm3" },
    { key: 5, text: t`Fluid m3`, value: 5, unit: "m3" },
    { key: 6, text: t`Fluid Nm3`, value: 6, unit: "Nm3" },
    { key: 7, text: t`Fluid TV`, value: 7, unit: "TV" }
];

export const NodeActionType = {
    Add: "add",
    Update: "update",
    Delete: "delete",
    Move: "move"
};

export const remapForReactFlow = (node) => {
    let edge = null;
    if (node.parent) {
        //processing edges here
        edge = {
            id: `edge-${node.parent}-${node.path}`,
            type: "smoothstep",
            source: `${node.parent}`,
            target: `${node.path}`,
            selected: false
        };
    }

    const remapNode = {
        id: `${node.path}`,
        type: node.equipment ? "equipmentLink" : "equipmentUnlink",
        position: {
            x: node.position_x,
            y: node.position_y
        },
        data: {
            //data used in Node Component (cf. https://reactflow.dev/docs/api/nodes/node-options/)
            node_db: node
        },
        style: { width: "180px", height: "60px", display: "flex" } //Define default width/height for node
    };

    return { edge, node: remapNode };
};
