import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Trans, t } from "@lingui/macro";
import { toast } from "react-toastify";
import { Button, Divider, Dropdown, Icon, Modal, Segment } from "semantic-ui-react";
import { Field, Form } from "react-final-form";

import i18n from "modules/i18n/i18nConfig";
import { InputAdapter, TextAreaAdapter, ToggleAdapter } from "modules/common/components/form";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { identity } from "modules/common/utils/form";
import { useCreateDashboardMutation } from "../dashboardDndService";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const DashboardCreate = (props) => {
    const { setSelectedDashboard, dashboards } = props;
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    const [openModal, setOpenModal] = useState(false);
    const [createDashboard, create] = useCreateDashboardMutation();

    const org_id = _.get(org, "current.id", null);
    const user_id = _.get(auth, "user.user_id", null);

    useEffect(() => {
        if (create.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`Successful create dashboard`), toast_options);
            setSelectedDashboard(create.data.id);
        }
        if (create.isError) {
            let error = i18n._(t`Can't create dashboard`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [create, setSelectedDashboard]);

    const onSubmitForm = async (data, form) => {
        if (notification.srv_status.db_status === "rw") {
            try {
                const action = await createDashboard({ org: org.current, data });
                const error = _.get(action, "error", null);
                if (!error) {
                    resetForm(form);
                    setOpenModal(false);
                }
            } catch (error) {}
        }
    };

    const resetForm = (form) => {
        form.reset();
        form.getRegisteredFields().forEach((field) => {
            form.resetFieldState(field);
        });
    };

    const initialValues = useMemo(() => {
        return {
            name: "",
            description: "",
            is_public: false,
            organization: org_id,
            owner: user_id
        };
    }, [org_id, user_id]);

    return (
        <>
            <Form
                onSubmit={onSubmitForm}
                initialValues={initialValues}
                render={({ handleSubmit, submitting, pristine, invalid, form, values }) => {
                    return (
                        <Modal
                            open={openModal}
                            centered={false}
                            closeOnDimmerClick
                            closeOnEscape
                            onClose={(e) => {
                                resetForm(form);
                                setOpenModal(false);
                            }}
                            trigger={
                                <Dropdown.Item
                                    onClick={(e) => {
                                        setOpenModal(true);
                                    }}
                                >
                                    <Icon name="add circle" />
                                    <Trans>Create new dashboard</Trans>
                                </Dropdown.Item>
                            }
                        >
                            <Modal.Header>
                                <Trans>Dashboard creation</Trans>
                            </Modal.Header>
                            <Modal.Content>
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Field
                                        name="name"
                                        placeholder={i18n._(t`enter name of dashboard`)}
                                        label={i18n._(t`name`)}
                                        isRequired={true}
                                        component={InputAdapter}
                                        validate={(value) => {
                                            if (!value) {
                                                return <Trans>Required field</Trans>;
                                            }
                                            const existing_name = _.chain(dashboards).find({ name: value }).value();
                                            if (existing_name) {
                                                return <Trans>Dashboard with this name exists</Trans>;
                                            }
                                            return undefined;
                                        }}
                                        onKeyDown={(e) => {
                                            //Hack to prevent parent dropdown tabspace interaction
                                            if (e.keyCode === 32) {
                                                e.stopPropagation();
                                            }
                                        }}
                                    />
                                    <Field
                                        name="description"
                                        placeholder={i18n._(t`enter description of dashboard`)}
                                        parse={identity}
                                        label={i18n._(t`description`)}
                                        component={TextAreaAdapter}
                                    />
                                    <Field name="is_public" label={i18n._(t`Public dashboard ?`)} labelposition="right" component={ToggleAdapter} />
                                    <Divider />
                                    <Segment attached basic textAlign="right">
                                        <Button
                                            type="button"
                                            negative
                                            icon
                                            labelPosition="left"
                                            onClick={(e) => {
                                                resetForm(form);
                                                setOpenModal(false);
                                            }}
                                        >
                                            <Icon name="arrow left" />
                                            <Trans>cancel</Trans>
                                        </Button>
                                        {notification.srv_status.db_status === "rw" && (
                                            <Button positive icon labelPosition="right" type="submit" disabled={submitting || pristine || invalid}>
                                                <Icon name="send" />
                                                <Trans>Confirm</Trans>
                                            </Button>
                                        )}
                                    </Segment>
                                </form>
                            </Modal.Content>
                        </Modal>
                    );
                }}
            />
        </>
    );
};

export default DashboardCreate;
