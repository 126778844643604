import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import _ from "lodash";
import { Grid, Segment, Header } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import Export from "modules/export/components/Export";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMailingsQuery } from "modules/mailing/mainlingService";
import { useGetExportQuery, useGetExportsQuery } from "modules/export/exportService";
import { useGetUnitsQuery } from "modules/unit/unitService";

const PreloadExport = (props) => {
    const { id } = useParams();
    const org = useSelector((state) => state.org);
    const mailing = useSelector((state) => state.mailing);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const mailings = useGetMailingsQuery({ org: org.current }, { skip: !org.current });
    const expts = useGetExportsQuery({ org: org.current }, { skip: !org.current });
    const expt = useGetExportQuery({ org: org.current, export_id: parseInt(id) }, { skip: !org.current || id === undefined });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });

    const error_list = [
        sites.isError,
        zones.isError,
        usages.isError,
        tags.isError,
        units.isError,
        categories.isError,
        measurements.isError,
        measurementtypes.isError,
        mailings.isError,
        dataflows.isError,
        equipments.isError,
        expts.isError
    ];

    const status_list = [
        measurements.isSuccess,
        measurementtypes.isSuccess,
        sites.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        units.isSuccess,
        categories.isSuccess,
        dataflows.isSuccess,
        equipments.isSuccess,
        expts.isSuccess,
        mailings.isSuccess
    ];

    if (_.some([...error_list, expt.isError])) {
        return (
            <Segment attached>
                <Grid centered verticalAlign="middle">
                    <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                        <Grid.Column width={2}>
                            <Back />
                        </Grid.Column>
                        <Grid.Column width={12} textAlign="center">
                            <Header as="h1">
                                <Trans>export management</Trans>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    } else if (id && _.every([...status_list, expt.isSuccess])) {
        return <Export export={expt.data} mailings={mailing.mailings} measurements={measurements.data} sites={sites.data} usages={usages.data} />;
    } else if (id === undefined && _.every(status_list)) {
        return <Export export={undefined} mailings={mailing.mailings} measurements={measurements.data} sites={sites.data} usages={usages.data} />;
    } else {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />;
    }
};

export default PreloadExport;
