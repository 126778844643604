import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { t } from "@lingui/macro";
import moment from "moment";
import _ from "lodash";
import tinycolor from "tinycolor2";
import { Crosshair, DiscreteColorLegend, FlexibleXYPlot, Highlight, Hint, HorizontalGridLines, LineMarkSeries, XAxis, YAxis } from "react-vis";
import { Segment, Button, Card, Grid } from "semantic-ui-react";

import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";
import { checkMobileAndTablet } from "modules/common/utils";
import { useGetDetailComparisonQuery } from "modules/dashboard/dashboardService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import { Media } from "App";

const DetailGraphic = (props) => {
    const org = useSelector((state) => state.org);
    const current_lng = useSelector((state) => state.i18n.current);
    const comparison_params = useSelector((state) => state.dash.comparison_params);
    const selected_equipments_ids = useSelector((state) => state.dash.selected_equipments);
    // React vis function
    const [series, setSeries] = useState([]);
    const [brushing, setBrushing] = useState(false);
    const [lastDrawLocation, setLastDrawLocation] = useState(null);
    const [hintValue, setHintValue] = useState(null);
    const [crosshairValues, setCrosshairValues] = useState([]);

    const comparisonDetail = useGetDetailComparisonQuery(
        { org: org.current, comparison_params, selected_equipments_ids },
        { skip: !org.current || comparison_params === null || _.size(selected_equipments_ids) === 0 }
    );

    useEffect(() => {
        if (comparisonDetail.isSuccess) {
            setSeries(comparisonDetail.data || []);
        }
    }, [comparisonDetail]);

    const { emptyData, maxY, minY } = useMemo(() => {
        const emptyData = _.chain(series)
            .map((serie) =>
                _.chain(serie)
                    .get("data")
                    .filter((record) => record.y !== null)
                    .isEmpty(serie.data)
                    .value()
            )
            .every()
            .value();
        const maxY = _.chain(series)
            .filter((item) => item.disabled === false)
            .reduce((res, serie) => {
                _.each(serie.data, (item) => {
                    res.push(item);
                });
                return res;
            }, [])
            .maxBy("y")
            .get("y")
            .value();

        const minY = _.chain(series)
            .filter((item) => item.disabled === false)
            .reduce((res, serie) => {
                _.each(serie.data, (item) => {
                    res.push(item);
                });
                return res;
            }, [])
            .minBy("y")
            .get("y")
            .value();
        return { emptyData, maxY, minY };
    }, [series]);

    const xDomain = useMemo(() => {
        return _.size(selected_equipments_ids) > 1
            ? [moment(comparison_params?.comp_start), moment(comparison_params?.comp_end)]
            : [0, _.chain(series).head().get("data", []).size().value()];
    }, [series, comparison_params, selected_equipments_ids]);

    if (comparisonDetail.isFetching) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />;
    }
    if (comparisonDetail.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />;
    }
    if (comparisonDetail.isSuccess) {
        const clickHandler = (serie_clk, idx) => {
            const size_disable = _.chain(series)
                .filter((item) => item.disabled === false)
                .size()
                .value();
            const update_series = _.map(series, (item, idx_ser) => {
                if (idx_ser === idx) return { ...item, disabled: size_disable <= 1 ? false : !item.disabled };
                return item;
            });
            setSeries(update_series);
        };

        const nearestXHandler = (value, { index }) => {
            const crossval = _.chain(series)
                .filter((item) => item.disabled === false)
                .reduce((res, serie) => {
                    const data = _.chain(serie.data).find({ x: value.x }).value();
                    if (data) {
                        res.push({ title: _.get(serie, "title"), ...data });
                    }
                    return res;
                }, [])
                .value();
            setCrosshairValues(crossval);
        };
        const tooltipCrosshair = () => (
            <Crosshair values={crosshairValues}>
                <Card style={{ zIndex: 10 }}>
                    <Card.Content>
                        <Card.Header textAlign="center" style={{ whiteSpace: "pre", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {_.size(selected_equipments_ids) > 1 && moment(crosshairValues[0].t).locale(current_lng).format("LLL")}
                            {_.size(selected_equipments_ids) <= 1 && _.chain(series).head().get("name", "-").value()}
                        </Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Grid centered verticalAlign="middle">
                            {_.chain(crosshairValues)
                                .map((item, idx) => {
                                    return (
                                        <Grid.Row key={idx} style={{ color: tinycolor(item.color).setAlpha(1).toString() }}>
                                            {_.size(selected_equipments_ids) <= 1 ? (
                                                <Grid.Column width={10} textAlign="left">
                                                    {moment(item.t).locale(current_lng).format("LLL")}
                                                </Grid.Column>
                                            ) : (
                                                <Grid.Column
                                                    width={10}
                                                    textAlign="left"
                                                    style={{ whiteSpace: "pre", overflow: "hidden", textOverflow: "ellipsis" }}
                                                >
                                                    {item.title}
                                                </Grid.Column>
                                            )}
                                            <Grid.Column width={6} textAlign="right">
                                                {item.y !== null && `${i18n.number(item.y, { maximumFractionDigits: 2 })} ${item.unit}`}
                                                {item.y === null && `- ${item.unit}`}
                                            </Grid.Column>
                                        </Grid.Row>
                                    );
                                })
                                .value()}
                        </Grid>
                    </Card.Content>
                </Card>
            </Crosshair>
        );

        const tooltipHint = () => (
            <Hint value={hintValue}>
                <Card className="pwaOverviewTooltip" style={{ width: moment(hintValue.t).locale(current_lng).format("LLL").length * 8 }}>
                    <Card.Content>
                        <Card.Header>{moment(hintValue.t).locale(current_lng).format("LLL")}</Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Card.Description>
                            <Grid centered verticalAlign="middle" style={{ color: tinycolor(hintValue.color).setAlpha(1).toString() }}>
                                <Grid.Row>
                                    <Grid.Column className="pwaTooltipContentComparison" width={16} textAlign="center">
                                        {hintValue.title}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16} textAlign="center">
                                        {hintValue.y !== null && `${i18n.number(hintValue.y, { maximumFractionDigits: 2 })} ${hintValue.unit}`}
                                        {hintValue.y === null && `- ${hintValue.unit}`}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Hint>
        );

        if (emptyData) return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />;
        return (
            <>
                {/* Display zoomOut btn only above computer */}
                <Media greaterThanOrEqual="computer">
                    {(mediaClassNames, renderChildren) =>
                        renderChildren && (
                            <Segment attached textAlign="right">
                                <Button
                                    onClick={(event, data) => {
                                        setLastDrawLocation(null);
                                    }}
                                    icon="zoom-out"
                                    className="no-print"
                                />
                            </Segment>
                        )
                    }
                </Media>
                <Segment attached>
                    <FlexibleXYPlot
                        dontCheckIfEmpty
                        xType={_.size(selected_equipments_ids) > 1 ? "time" : "linear"}
                        xDomain={lastDrawLocation ? lastDrawLocation : xDomain}
                        yDomain={[Math.min(0, minY), Math.max(10, maxY)]}
                        height={480}
                        margin={{ left: 60, right: 10, top: 10, bottom: 130 }}
                        onMouseLeave={() => {
                            setCrosshairValues([]);
                        }}
                    >
                        <DiscreteColorLegend
                            className="pwaLegend400"
                            orientation="horizontal"
                            height={75}
                            items={series}
                            onItemClick={clickHandler}
                        />
                        <HorizontalGridLines />
                        {_.size(selected_equipments_ids) > 1 && (
                            <XAxis
                                title={i18n._(t`time`)}
                                tickLabelAngle={-20}
                                tickFormat={(value, index, scale, tickTotal) => {
                                    return multiI18nFormat(value, current_lng);
                                }}
                            />
                        )}
                        {_.size(selected_equipments_ids) <= 1 && (
                            <XAxis
                                title={i18n._(t`number of points`)}
                                tickLabelAngle={-20}
                                tickFormat={(value, index, scale, tickTotal) => {
                                    try {
                                        const firstRecordOnFirstSerie = _.chain(series).head().get("data").head().get("t").value();
                                        const start = moment.min(moment(firstRecordOnFirstSerie), moment(comparison_params?.ref_start));
                                        const tmstRecordOnFirstSerie = moment(
                                            _.chain(series).head().get("data").get(parseInt(value)).get("t").value()
                                        );
                                        if (lastDrawLocation !== null) {
                                            const hours = tmstRecordOnFirstSerie.diff(start, "hours");
                                            if (hours < 24) {
                                                return `h+${hours}`;
                                            }
                                        }
                                        const days = tmstRecordOnFirstSerie.diff(start, "days");
                                        return `j+${days}`;
                                    } catch (error) {
                                        return "";
                                    }
                                }}
                            />
                        )}
                        <YAxis
                            title={_.chain(series).head().get("unit").value() || "-"}
                            tickFormat={(value, index, scale, tickTotal) => {
                                const format = scale.tickFormat(tickTotal)(value);
                                if (typeof value === "number") {
                                    return i18n.number(value, { minimumFractionDigits: 1, maximumFractionDigits: 2, notation: "compact" });
                                }
                                return format;
                            }}
                        />
                        <Highlight
                            enableY={false}
                            onBrushStart={(area) => {
                                if (area) setBrushing(true);
                            }}
                            onBrushEnd={(area) => {
                                if (area) {
                                    setBrushing(false);
                                    _.size(selected_equipments_ids) > 1 &&
                                        setLastDrawLocation([moment(_.get(area, "left")), moment(_.get(area, "right"))]);
                                    _.size(selected_equipments_ids) <= 1 && setLastDrawLocation([_.get(area, "left"), _.get(area, "right")]);
                                }
                            }}
                        />
                        {_.chain(series)
                            .filter((item) => item.disabled === false)
                            .orderBy((serie) => {
                                return serie.data.length;
                            }, "asc")
                            .map((serie, idx) => {
                                return (
                                    <LineMarkSeries
                                        key={idx}
                                        color={serie.color}
                                        fill={serie.color}
                                        size={3}
                                        getNull={(d) => d.y !== null}
                                        data={serie.data}
                                        markStyle={brushing ? { pointerEvents: "none" } : { pointerEvents: "auto" }}
                                        curve={"curveMonotoneX"}
                                        onNearestX={!checkMobileAndTablet() ? nearestXHandler : null}
                                        onValueMouseOver={
                                            checkMobileAndTablet()
                                                ? (hintValue) => {
                                                      setHintValue(hintValue);
                                                  }
                                                : null
                                        }
                                        onValueMouseOut={
                                            checkMobileAndTablet()
                                                ? () => {
                                                      setHintValue(null);
                                                  }
                                                : null
                                        }
                                    />
                                );
                            })
                            .value()}
                        {!checkMobileAndTablet() && _.size(crosshairValues) !== 0 && tooltipCrosshair()}
                        {checkMobileAndTablet() && !_.isNull(hintValue) && tooltipHint()}
                    </FlexibleXYPlot>
                </Segment>
            </>
        );
    }

    return null;
};

export default DetailGraphic;
