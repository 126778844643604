import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { Trans, t } from "@lingui/macro";
import _ from "lodash";
import { Form, Field } from "react-final-form";
import { Button, Divider, Dropdown, Icon, Modal, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { advancedUnitsOptions, graphicTypeOptions } from "../utils";
import { useCreateAnalysisSectionMutation } from "../analysisAdvancedService";

import { InputAdapter } from "modules/common/components/form";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const AnalysisSectionCreate = (props) => {
    const { org, current_advanced, disabled, setSectionAdded } = props;
    const [openModal, setOpenModal] = useState(false);
    const [createSection, create] = useCreateAnalysisSectionMutation();

    const analysis_id = current_advanced?.id ?? null;

    useEffect(() => {
        if (create.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
            setSectionAdded(false);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`successful create analysis section`), toast_options);
            setSectionAdded(true);
        }
        if (create.isError) {
            let error = i18n._(t`cannot create analysis section`);
            if (create?.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create?.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
            setSectionAdded(false);
        }
    }, [create, setSectionAdded]);

    const onSubmitForm = async (formdata, form) => {
        try {
            if (!disabled) {
                const action = await createSection({ org, formdata });
                const error = _.get(action, "error", null);
                if (!error) {
                    resetForm(form);
                    setOpenModal(false);
                }
            }
        } catch (error) {}
    };

    const resetForm = (form) => {
        form.reset();
        form.getRegisteredFields().forEach((field) => {
            form.resetFieldState(field);
        });
    };

    const initialValues = useMemo(() => {
        return {
            name: "",
            advanced_analysis: analysis_id,
            graph_type: graphicTypeOptions[0].value,
            numerator_unit: advancedUnitsOptions[0].value
        };
    }, [analysis_id]);

    return (
        <Form
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, pristine, invalid, form }) => {
                return (
                    <Modal
                        open={openModal}
                        centered={false}
                        closeOnDimmerClick={false}
                        closeOnEscape
                        onClose={(e) => {
                            resetForm(form);
                            setOpenModal(false);
                        }}
                        trigger={
                            <Dropdown.Item
                                onClick={(e) => {
                                    setOpenModal(true);
                                }}
                                disabled={disabled}
                            >
                                <Icon name="add circle" />
                                <Trans>Add analysis section</Trans>
                            </Dropdown.Item>
                        }
                    >
                        <Modal.Header>
                            <Trans>Advanced analysis section creation</Trans>
                        </Modal.Header>
                        <Modal.Content>
                            <form onSubmit={handleSubmit} className="ui form">
                                <Field
                                    name="name"
                                    label={i18n._(t`section name`)}
                                    placeholder={i18n._(t`enter name of section`)}
                                    isRequired={true}
                                    component={InputAdapter}
                                    validate={(value) => {
                                        if (!value) {
                                            return <Trans>section name is required</Trans>;
                                        }
                                        return undefined;
                                    }}
                                    onKeyDown={(e) => {
                                        //Hack to prevent parent dropdown tabspace interaction
                                        if (e.keyCode === 32) {
                                            e.stopPropagation();
                                        }
                                    }}
                                />
                                <Divider />
                                <Segment attached basic textAlign="right">
                                    <Button
                                        type="button"
                                        negative
                                        icon
                                        labelPosition="left"
                                        onClick={(e) => {
                                            resetForm(form);
                                            setOpenModal(false);
                                        }}
                                    >
                                        <Icon name="arrow left" />
                                        <Trans>cancel</Trans>
                                    </Button>
                                    {!disabled && (
                                        <Button positive icon labelPosition="right" type="submit" disabled={submitting || pristine || invalid}>
                                            <Icon name="send" />
                                            <Trans>Confirm</Trans>
                                        </Button>
                                    )}
                                </Segment>
                            </form>
                        </Modal.Content>
                    </Modal>
                );
            }}
        />
    );
};

export default AnalysisSectionCreate;
