import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { checkStringInclude } from "modules/common/utils";
import { useGetEventsAlertsQuery } from "../alerteventService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import TableEnhanced from "modules/common/components/TableEnhanced";
import { Grid, Input, Label } from "semantic-ui-react";

const renderReason = (evt, mt_type) => {
    switch (mt_type?.name) {
        case "di":
            return <Label color={evt?.[2] === true ? "green" : "red"}>{evt?.[2] === true ? i18n._(t`up`) : i18n._(t`down`)}</Label>;
        case "voltage_detected":
            return <Label color={evt?.[2] === true ? "green" : "red"}>{evt?.[2] === true ? i18n._(t`power on`) : i18n._(t`power off`)}</Label>;
        case "trip":
            return <Label color={evt?.[2] === true ? "red" : "green"}>{evt?.[2] === true ? i18n._(t`tripped`) : i18n._(t`trip reset`)}</Label>;
        default:
            return <Label color="red">-</Label>;
    }
};

const PaneEvents = (props) => {
    const { rangeTime } = props;
    const [localSearchName, setLocalSearchName] = useState("");
    const org = useSelector((state) => state.org);
    const current_lng = useSelector((state) => state.i18n.current); //force refresh for lng

    const eventsalerts = useGetEventsAlertsQuery(
        { org: org.current, start: rangeTime.start.toISOString(), end: rangeTime.end.toISOString() },
        { skip: !org.current }
    );
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "timestamp", label: i18n._(t`triggered_or_event_date`), textAlign: "left" },
        { id: "new_state", label: i18n._(t`triggered_reason`), textAlign: "left" }
    ];

    const rows = useMemo(() => {
        const customStyle = { cursor: "default", whiteSpace: "pre" };
        if (eventsalerts.isSuccess && measurements.isSuccess && measurementtypes.isSuccess && dataflows.isSuccess) {
            return _.chain(eventsalerts.data)
                .reduce((res, evt, idx) => {
                    const measure = _.find(measurements.data, { id: evt?.[0] });
                    const df = _.find(dataflows.data, { id: measure.dataflow });
                    const mt_type = _.find(measurementtypes.data, { id: measure?.measurementtype });

                    const representation = df && mt_type ? `${df?.name} - ${mt_type.repr}` : "-";

                    if (!checkStringInclude(localSearchName, representation)) return res;

                    res.push({
                        id: { render: null, value: idx, textAlign: "left", datatype: "number" },
                        name: {
                            render: <span style={customStyle}>{representation}</span>,
                            value: representation,
                            textAlign: "left",
                            datatype: "string"
                        },
                        timestamp: {
                            render:
                                evt?.[1] !== null ? (
                                    <span style={customStyle}>{`${moment(evt?.[1]).locale(current_lng).format("L")} ${moment(evt?.[1])
                                        .locale(current_lng)
                                        .format("LTS")}`}</span>
                                ) : (
                                    "-"
                                ),
                            value: evt?.[1] !== null ? moment(evt?.[1]) : null,
                            textAlign: "left",
                            datatype: "date"
                        },
                        new_state: {
                            render: renderReason(evt, mt_type),
                            value: +evt?.[2],
                            textAlign: "left",
                            datatype: "number"
                        }
                    });
                    return res;
                }, [])
                .value();
        }
        return [];
    }, [eventsalerts, measurements, measurementtypes, dataflows, current_lng, localSearchName]);

    if (eventsalerts.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }
    if (eventsalerts.isFetching) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (eventsalerts.isSuccess) {
        return (
            <Grid celled>
                <Grid.Column mobile={16} tablet={3} computer={3}>
                    <Input
                        fluid
                        icon="search"
                        placeholder={i18n._(t`search name`)}
                        onChange={(e, { value }) => {
                            setLocalSearchName(value);
                        }}
                        value={localSearchName}
                        disabled={_.size(eventsalerts.data) === 0}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    <TableEnhanced headCells={headCells} rows={rows} textItemPerPages={i18n._(t`items per page`)} />
                </Grid.Column>
            </Grid>
        );
    }
    return null;
};

export default React.memo(PaneEvents);
