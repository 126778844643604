import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Table } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import MessageDisplay from "modules/common/components/MessageDisplay";
import moment from "moment";

const WorstScore = (props) => {
    const { data } = props;
    const current_lng = useSelector((state) => state.i18n.current);
    const { tabpredicthealthscore } = useSelector((state) => state.overview);
    const selected_clusters = _.get(tabpredicthealthscore, "clusters.selected_clusters", []);

    const filtered_clusters = _.chain(data)
        //filter via dropdown cluster
        .filter((cluster) => _.includes(selected_clusters, cluster.index))
        .value();

    if (_.size(filtered_clusters) === 0) {
        return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="info circle" isLoading={false} />;
    }

    return (
        <Table className="pwaWorstScoreTable" attached celled striped compact>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        <Trans>cluster</Trans>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <Trans>date</Trans>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <Trans>score</Trans>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {_.chain(data)
                    //filter via dropdown cluster
                    .filter((cluster) => _.includes(selected_clusters, cluster.index))
                    //remap cluster => all data from cluster
                    .map((cluster, idx) => {
                        const worst = _.chain(cluster).get("data").maxBy("y").value();
                        if (worst) {
                            const date = moment(_.get(worst, "x")).locale(current_lng);

                            return (
                                <Table.Row key={idx}>
                                    <Table.Cell style={{ color: cluster.color }}>{cluster.title}</Table.Cell>
                                    <Table.Cell>{`${date.format("L")} ${date.format("LT")}`}</Table.Cell>
                                    <Table.Cell textAlign="center">{i18n.number(_.get(worst, "y"), { maximumFractionDigits: 2 })}</Table.Cell>
                                </Table.Row>
                            );
                        }
                        return null;
                    })
                    .value()}
            </Table.Body>
        </Table>
    );
};

export default WorstScore;
