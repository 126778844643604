import _ from "lodash";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { checkStringInclude } from "modules/common/utils";
import { siteApi } from "./siteService";

const initialState = {
    sites: [],
    filter: {
        searchName: ""
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    }
};

const siteSlice = createSlice({
    name: "site",
    initialState,
    reducers: {
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.sites = initialState.sites;
            state.filter = initialState.filter;
            state.pagination = initialState.pagination;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addMatcher(isAnyOf(setSearchNameFilter), (state, action) => {
                const filtered_site = _.chain(state.init_sites)
                    .reduce((res, site) => {
                        /* name filter */
                        if (state.filter.searchName === "") {
                            res.push(site);
                        } else if (checkStringInclude(state.filter.searchName, site.name)) {
                            res.push(site);
                        }
                        return res;
                    }, [])
                    .value();
                state.sites = filtered_site;
                if (!state.pagination.stickPage) {
                    state.pagination.page = 1;
                }
            })
            .addMatcher(siteApi.endpoints.getSites.matchFulfilled, (state, action) => {
                state.sites = action.payload;
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const { setSearchNameFilter, resetFilterWithPage, setItemsPerPage, setPage, setStickPage } = siteSlice.actions;

export default siteSlice.reducer;
