import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { t, Trans } from "@lingui/macro";
import { Grid, Segment, Header, Label, Button, Dimmer } from "semantic-ui-react";
import { Form, Field } from "react-final-form";
import Datetime from "react-datetime";
import { toast } from "react-toastify";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import history_app from "history_app";
import { identity, identityNull } from "modules/common/utils/form";
import { checkRights } from "modules/auth/utils";
import { useCreateAlertMutation, useUpdateAlertMutation } from "../alertService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { alertTypeOptions, daysOptions, validityOptions } from "../utils";
import { defaultAlert } from "../alertSlice";

import MessageDisplay from "modules/common/components/MessageDisplay";
import Back from "modules/common/components/back";
import Tree from "./advanced/Tree";
import { InputAdapter, DropDownAdapter, TextAreaAdapter, ToggleAdapter } from "modules/common/components/form";
import MailingAddModalForm from "modules/mailing/components/MailingAddModalForm";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

/**
 * Used for form validation. If errors, you can't submit form
 * @function validate
 * @param {object} values
 */
const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = <Trans>alert name is required</Trans>;
    } else if (values.name.length > 255) {
        errors.name = <Trans>The name can't be longer than 255 characters</Trans>;
    }

    if (values.validity === 0 || values.alert_type === 1) {
        const regex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
        if (!values.start_date) {
            errors.start_date = <Trans>start_hour is required</Trans>;
        } else if (!regex.test(values.start_date)) {
            errors.start_date = <Trans>invalid start_hour</Trans>;
        }
        if (!values.end_date) {
            errors.end_date = <Trans>end_hour is required</Trans>;
        } else if (!regex.test(values.end_date)) {
            errors.end_date = <Trans>invalid end_hour</Trans>;
        }
        if (_.size(values.days) === 0) {
            errors.days = <Trans>days can't be empty</Trans>;
        }
    }

    if (values.mailinglist.length === 0) {
        errors.mailinglist = <Trans>mailinglist can't be empty</Trans>;
    }

    const err_conditions = _.reduce(
        values.conditions,
        (res, item) => {
            if (item.type === null) {
                res[item.id] = <Trans>choose operation</Trans>;
            }
            if (item.type === "threshold") {
                if (item.measure === null) {
                    res[item.id] = <Trans>select measurement</Trans>;
                }
                const valid_thresh = validateNumber(item.threshold, i18n, true, false);
                if (valid_thresh !== undefined) {
                    res[item.id] = valid_thresh;
                }
                if (values.alert_type === 2) {
                    //check range time parameter for sliding alert process
                    const valid_window = validateNumber(item.w_depth, i18n, false, false, true);
                    if (valid_window !== undefined) {
                        res[item.id] = valid_window;
                    }
                }
            }
            return res;
        },
        {}
    );
    if (
        _.chain(err_conditions)
            .filter((item) => item !== undefined)
            .size()
            .value() > 0
    ) {
        errors.conditions = err_conditions;
    }

    return errors;
};

/**
 * Used for displaying error label above input form
 * @function renderError
 * @param {object} meta - Meta object given by react-final-form
 */
const renderError = ({ error, touched, dirty }) => {
    if ((touched || dirty) && error) {
        return (
            <Label basic color="red" pointing="below">
                {error}
            </Label>
        );
    }
};

const TimerAdapter = (props) => {
    const now = moment();
    const { input, meta, label, isRequired } = props;
    return (
        <>
            <div className={`field ${isRequired ? "required" : ""} ${meta.error && meta.touched ? "error" : ""}`}>
                <label>{label}</label>
                {renderError(meta)}
                <Datetime
                    dateFormat={false}
                    timeFormat="HH:mm:ss"
                    isValidDate={(current) => {
                        return current.isBefore(now);
                    }}
                    value={input.value}
                    onChange={(date) => {
                        if (moment.isMoment(date)) {
                            input.onChange(date.format("HH:mm:ss"));
                        } else {
                            input.onChange(date);
                        }
                    }}
                />
            </div>
        </>
    );
};

const Alert = (props) => {
    const { mailings } = props;
    const { id } = useParams();
    const auth = useSelector((state) => state.auth);
    const org = useSelector((state) => state.org);
    const { srv_status } = useSelector((state) => state.notification);
    const current_lng = useSelector((state) => state.i18n.current);

    const siteOptions = useMemo(() => {
        if (_.size(props.sites) > 0) {
            return _.chain(props.sites)
                .map(({ key, text, value }) => ({ key, text, value }))
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
    }, [props.sites]);

    const current_alert = useMemo(() => {
        //rewrite w_depth seconds to minutes
        const rewrite_conditions = _.map(props?.alert?.conditions, (condition) => {
            return _.mapValues(condition, (item, key) => {
                if (key === "w_depth") {
                    return item / 60;
                }
                return item;
            });
        });

        return {
            ...defaultAlert,
            site: _.size(siteOptions) === 1 ? _.head(siteOptions)?.value : null,
            ...props.alert,
            conditions: _.isEmpty(rewrite_conditions) ? defaultAlert.conditions : rewrite_conditions
        };
    }, [props.alert, siteOptions]);

    const rights = checkRights(current_alert, auth.rights);
    const can_change = srv_status.db_status === "rw" && (_.includes(rights, "can_change") || _.size(auth.rights?.sites_rw) > 0);

    const [createAlert, create] = useCreateAlertMutation();
    const [updateAlert, update] = useUpdateAlertMutation();

    useEffect(() => {
        if (create.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`successful create alert`), toast_options);
            history_app.push(`/alerts`);
        }
        if (create.isError) {
            let error = i18n._(t`cannot create alert`);
            if (create?.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create?.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create]);

    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update alert`), toast_options);
            history_app.push(`/alerts`);
        }
        if (update.isError) {
            let error = i18n._(t`cannot update alert`);
            if (update?.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update?.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    const submitForm = async (formData) => {
        const data = {
            ...formData,
            duration_threshold: reparseNumber(formData?.duration_threshold),
            conditions: _.map(formData?.conditions ?? [], (item) => {
                if (item.type === "threshold") {
                    return {
                        ...item,
                        threshold: reparseNumber(item.threshold),
                        w_depth: formData.alert_type === 2 ? reparseNumber(item.w_depth) * 60 : null
                    };
                }
                return item;
            })
        };

        if (can_change) {
            if (_.get(formData, "id") === undefined) {
                createAlert({ org: org.current, data, user: auth.user });
            } else {
                updateAlert({ org: org.current, data });
            }
        }
    };

    const mailings_id = useMemo(() => {
        return _.map(mailings, (item) => item.id);
    }, [mailings]);

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {id === undefined && <Trans>add alert</Trans>}
                            {id !== undefined && <Trans>update alert</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={14}>
                        <Form
                            onSubmit={submitForm}
                            initialValues={current_alert}
                            validate={validate}
                            render={({ handleSubmit, form, submitting, pristine, invalid, values, errors }) => {
                                if (current_alert) {
                                    pristine = false;
                                }
                                const not_visible_mailings = _.chain(values.mailinglist).difference(mailings_id).size().value() > 0;

                                return (
                                    <form onSubmit={handleSubmit} className="ui form">
                                        <Grid verticalAlign="top" centered>
                                            <Grid.Column width={16}>
                                                <Field
                                                    name="is_active"
                                                    label={i18n._(t`is_active`)}
                                                    labelposition="right"
                                                    component={ToggleAdapter}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={16}>
                                                <Field
                                                    name="name"
                                                    placeholder={i18n._(t`enter name of alert`)}
                                                    label={i18n._(t`name`)}
                                                    parse={identity}
                                                    isRequired={true}
                                                    component={InputAdapter}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={16}>
                                                <Field
                                                    name="description"
                                                    placeholder={i18n._(t`enter description of your alert`)}
                                                    parse={identity}
                                                    label={i18n._(t`description`)}
                                                    component={TextAreaAdapter}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={16}>
                                                <Field
                                                    name="site"
                                                    label={i18n._(t`site`)}
                                                    placeholder={i18n._(t`select site`)}
                                                    options={siteOptions}
                                                    helperText={
                                                        <Trans>
                                                            <p>site alert helper</p>
                                                        </Trans>
                                                    }
                                                    component={DropDownAdapter}
                                                    isRequired={true}
                                                    disabled={id !== undefined}
                                                    customAction={(data) => {
                                                        if (id === undefined) {
                                                            //only reset formula when site change on 'add' mode
                                                            form.change("conditions", defaultAlert.conditions);
                                                        }
                                                    }}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={16}>
                                                <Grid centered verticalAlign="bottom" textAlign="center">
                                                    <Grid.Column
                                                        mobile={can_change ? 15 : 16}
                                                        tablet={can_change ? 15 : 16}
                                                        computer={can_change ? 15 : 16}
                                                    >
                                                        <Field
                                                            name="mailinglist"
                                                            label={i18n._(t`mailinglists`)}
                                                            placeholder={i18n._(t`choose mailinglists`)}
                                                            isRequired={true}
                                                            options={mailings}
                                                            multipleselect={1}
                                                            component={DropDownAdapter}
                                                            noResultsMessage={i18n._(t`no mailinglists found`)}
                                                        />
                                                    </Grid.Column>
                                                    {can_change && (
                                                        <Grid.Column width={1} textAlign="center">
                                                            <MailingAddModalForm form={form} mailings={mailings} />
                                                        </Grid.Column>
                                                    )}
                                                </Grid>
                                                {not_visible_mailings && (
                                                    <div style={{ color: "#794b02", fontStyle: "italic" }}>
                                                        <Trans>Some mailing lists are present but you do not have permission to view them</Trans>
                                                    </div>
                                                )}
                                            </Grid.Column>
                                            <Grid.Column width={16}>
                                                <Field
                                                    name="alert_type"
                                                    label={i18n._(t`Alert type`)}
                                                    placeholder={i18n._(t`select alert type`)}
                                                    options={alertTypeOptions}
                                                    helperText={
                                                        <>
                                                            <p>
                                                                <Trans>alert type helper</Trans>
                                                            </p>
                                                            <p>
                                                                <Trans>alert type helper 2</Trans>
                                                            </p>
                                                            <p>
                                                                <Trans>alert type helper 3</Trans>
                                                            </p>
                                                        </>
                                                    }
                                                    component={DropDownAdapter}
                                                    isRequired={true}
                                                    disabled={id !== undefined}
                                                    customAction={(data) => {
                                                        if (id === undefined) {
                                                            //only reset formula when site change on 'add' mode
                                                            form.change("conditions", defaultAlert.conditions);
                                                        }
                                                    }}
                                                />
                                            </Grid.Column>
                                            {_.includes([0, 2], values.alert_type) && (
                                                <Grid.Column width={16}>
                                                    <Field
                                                        name="validity"
                                                        label={i18n._(t`Notification range`)}
                                                        placeholder={i18n._(t`Choose validity`)}
                                                        options={validityOptions}
                                                        isRequired={true}
                                                        component={DropDownAdapter}
                                                        noResultsMessage={i18n._(t`no validity found`)}
                                                        customAction={(data) => {
                                                            if (data === 0) {
                                                                if (values.start_date === null) {
                                                                    form.change("start_date", "00:00:00");
                                                                }
                                                                if (values.end_date === null) {
                                                                    form.change("end_date", "23:59:59");
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </Grid.Column>
                                            )}
                                            {(values.validity === 0 || values.alert_type === 1) && (
                                                <Grid.Column width={16}>
                                                    <Grid verticalAlign="top" centered>
                                                        <Grid.Column mobile={16} tablet={3} computer={3}>
                                                            <Field
                                                                name="start_date"
                                                                label={i18n._(t`start_hour`)}
                                                                isRequired={true}
                                                                locale={current_lng}
                                                                component={TimerAdapter}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column mobile={16} tablet={3} computer={3}>
                                                            <Field
                                                                name="end_date"
                                                                label={i18n._(t`end_hour`)}
                                                                isRequired={true}
                                                                locale={current_lng}
                                                                component={TimerAdapter}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column mobile={16} tablet={10} computer={10}>
                                                            <Field
                                                                name="days"
                                                                label={i18n._(t`days`)}
                                                                placeholder={i18n._(t`choose days`)}
                                                                options={daysOptions}
                                                                multipleselect={1}
                                                                isRequired={true}
                                                                component={DropDownAdapter}
                                                                upward
                                                                noResultsMessage={i18n._(t`no days found`)}
                                                            />
                                                        </Grid.Column>
                                                    </Grid>
                                                </Grid.Column>
                                            )}
                                            {values.alert_type === 0 && (
                                                <Grid.Column width={16}>
                                                    <Field
                                                        name="duration_threshold"
                                                        placeholder={i18n._(t`duration_threshold placeholder`)}
                                                        parse={identityNull}
                                                        inputMode="numeric"
                                                        isRequired={true}
                                                        label={i18n._(t`duration_threshold`)}
                                                        component={InputAdapter}
                                                        unit={"min"}
                                                        helperText={
                                                            <Trans>
                                                                <p>duration_threshold helper</p>
                                                            </Trans>
                                                        }
                                                        validate={(value) => {
                                                            return validateNumber(value, i18n, false, false, true);
                                                        }}
                                                    />
                                                </Grid.Column>
                                            )}
                                            <Grid.Column width={16}>
                                                <Dimmer.Dimmable dimmed={true} style={{ zIndex: 0, overflow: "visible" }}>
                                                    <Dimmer inverted active={_.get(values, "site", null) === null}>
                                                        <MessageDisplay
                                                            message={i18n._(t`select site`)}
                                                            level="warning"
                                                            iconName="warning circle"
                                                            isLoading={false}
                                                            attached={false}
                                                        />
                                                    </Dimmer>
                                                    <Field name="conditions" label={i18n._(t`conditions`)} component={Tree} form={form} />
                                                </Dimmer.Dimmable>
                                            </Grid.Column>
                                        </Grid>
                                        {can_change && (
                                            <Segment basic>
                                                <Button
                                                    type="submit"
                                                    color="green"
                                                    content={i18n._(t`validate`)}
                                                    disabled={submitting || pristine || invalid}
                                                />
                                            </Segment>
                                        )}
                                    </form>
                                );
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default Alert;
