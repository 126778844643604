import React from "react";
import _ from "lodash";

import i18n from "modules/i18n/i18nConfig";

const NodeContent = (props) => {
    const { node_db, unit } = props;

    return (
        <>
            <div style={{ flex: 1 }}>
                <h5>
                    {node_db.equipment === null && node_db.name}
                    {node_db.equipment !== null && <div>{node_db.name || node_db.equipment_name}</div>}
                </h5>
            </div>
            <div style={{ flex: 1, display: "flex", fontStyle: "italic", color: "rgba(0,0,0,.5)" }}>
                {_.isFinite(node_db.consumption) && (
                    <>
                        {_.isFinite(node_db.percentage) && node_db.consumption !== 0 && (
                            <div style={{ flex: 1, textAlign: "right" }}>
                                <b>{`(${i18n.number(node_db.percentage, {
                                    maximumFractionDigits: 1
                                })} %)`}</b>
                            </div>
                        )}
                        <div style={{ flex: 3, textAlign: "right" }}>{`${i18n.number(node_db.consumption, {
                            maximumFractionDigits: 2
                        })} ${unit}`}</div>
                    </>
                )}
            </div>
        </>
    );
};

export default NodeContent;
