import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { jwtDecode as jwt_decode } from "jwt-decode";
import "moment/min/locales.min.js";
//Main css for semantic
//import "semantic-ui-less/semantic.css";
//import "css/pwa_frontend.css";
// Datetime css
import "react-datetime/css/react-datetime.css";
//React vis css
import "../node_modules/react-vis/dist/style.css";
//react-toastify
import "react-toastify/dist/ReactToastify.css";
//reactflow
import "reactflow/dist/style.css";

//Css for react-grid-layout
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

//Redux store
import store from "./store";
import App from "./App";

import { setLng } from "modules/i18n/i18nSlice";
import { login, logout } from "modules/auth/authSlice";
import history_app from "history_app";

const originalConsoleError = console.error;

console.error = function (message, ...args) {
    if (typeof message === "string" && (message.includes("in a future major release.") || message.includes("in the next major release."))) {
        return;
    }
    originalConsoleError.apply(console, [message, ...args]);
};

(async () => {
    const local_lng = localStorage.getItem("lng");
    await store.dispatch(setLng(local_lng));
    const local_token = localStorage.getItem("accessToken");
    if (local_token) {
        try {
            jwt_decode(local_token);
            await store.dispatch(login(local_token));
        } catch (error) {
            localStorage.removeItem("currentOrg");
            localStorage.removeItem("accessToken");
            await store.dispatch(logout());
            history_app.push("/login");
        }
    } else {
        history_app.push("/login");
    }
})();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
