import { configureStore } from "@reduxjs/toolkit";

import { i18nMiddleware } from "modules/i18n/i18nMiddleware";
import { authMiddleware } from "modules/auth/authMiddleware";
import { orgMiddleware } from "modules/organization/orgMiddleware";

import rootReducer from "rootReducer";
import { dashboardMiddleware } from "modules/dashboard/dashboardMiddleware";
import { globalviewMiddleware } from "modules/globalview/globalviewMiddleware";
import { overviewMiddleware } from "modules/overview/overviewMiddleware";
import { notificationApi } from "modules/notification/notificationService";
import { analysisAdvancedApi } from "modules/analysisAdvanced/analysisAdvancedService";
import { reportApi } from "modules/report/reportService";
import { userApi } from "modules/user/userService";
import { authApi } from "modules/auth/authService";
import { orgApi } from "modules/organization/organizationService";
import { defaultApi } from "apis/PowerAdapt";
import { commonMiddleware } from "modules/common/commonMiddleware";
import { hierarchyApi } from "modules/hierarchy/hierarchyService";
import { energySavingApi } from "modules/energysaving/energySavingService";
import { dashboardDndApi } from "modules/dashboardDragNDrop/dashboardDndService";

const customMiddleware = [
    authApi.middleware,
    orgApi.middleware,
    userApi.middleware,
    i18nMiddleware.middleware,
    notificationApi.middleware,
    reportApi.middleware,
    analysisAdvancedApi.middleware,
    hierarchyApi.middleware,
    energySavingApi.middleware,
    dashboardDndApi.middleware,
    defaultApi.middleware,
    authMiddleware,
    orgMiddleware,
    commonMiddleware,
    dashboardMiddleware,
    globalviewMiddleware,
    overviewMiddleware
];

export default configureStore({
    reducer: {
        ...rootReducer,
        [notificationApi.reducerPath]: notificationApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [orgApi.reducerPath]: orgApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [analysisAdvancedApi.reducerPath]: analysisAdvancedApi.reducer,
        [reportApi.reducerPath]: reportApi.reducer,
        [hierarchyApi.reducerPath]: hierarchyApi.reducer,
        [energySavingApi.reducerPath]: energySavingApi.reducer,
        [dashboardDndApi.reducerPath]: dashboardDndApi.reducer,
        [defaultApi.reducerPath]: defaultApi.reducer
    },
    // Note: you can include options in the argument of the getDefaultMiddleware function call.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types (ex:generateExport.fulfilled.type)
                ignoredActions: []
            }
        }).concat(customMiddleware),
    devTools: process.env.NODE_ENV !== "production"
});
