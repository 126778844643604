import { t } from "@lingui/macro";

export const machine_status = {
    1: {
        message: t`Missing kinematic data`,
        popup_content: [
            {
                text: t`To allow the initialization of health scores, you must fill in the information relative to the machine and its kinematics, in the Equipment section.`,
                style: null
            }
        ],
        level: "warning"
    },
    2: {
        message: t`Learning failure: too few data points`,
        popup_content: [
            {
                text: t`The machine has too few points in the expected operating range, in term of frequency and current. Health score learning is impossible in the current state, please contact the support for more information.`,
                style: null
            }
        ],
        level: "error"
    },
    3: {
        message: t`Learning in progress`,
        popup_content: [
            { text: t`An initialization period is necessary to learn to distinguish clusters, and train the health scores.`, style: null },
            { text: t`It can last up to 14 days depending on the operating time of the machine.`, style: null }
        ],
        level: "info"
    },
    4: {
        message: t`Learning in progress`,
        popup_content: [{ text: t`The learning phase lasts 14 days after the beginning of the monitoring`, style: null }],
        level: "info"
    },
    11: {
        message: t`Sensor issue: installation before variable frequency drive`,
        popup_content: [
            {
                text: t`Sensors seem to be installed before the Variable Frequency Drive (VFD). Please check their location to ensure that they are between the VFD and the motor.`,
                style: null
            },
            { text: t`This status is updated every 24 hours.`, style: { fontStyle: "italic" } }
        ],
        level: "error"
    },
    12: {
        message: t`Sensor issue: noise on one phase`,
        popup_content: [
            {
                text: t`One of the three phases does not bear a conformal signal, please check the installation. Look for unclamped sensors, loose sensor connection plug, damaged sensor iron…`,
                style: null
            },
            { text: t`This status is updated every 24 hours.`, style: { fontStyle: "italic" } }
        ],
        level: "error"
    },
    13: {
        message: t`Sensor issue`,
        popup_content: [
            { text: t`The signal read by the sensors is not conformal. Please check the installation.`, style: null },
            { text: t`This status is updated every 24 hours.`, style: { fontStyle: "italic" } }
        ],
        level: "error"
    },
    14: {
        message: t`No score due to lack of data`,
        popup_content: [
            {
                text: t`The device has not communicated any data for more than 24 hours. Please check the power supply and the network connectivity.`,
                style: null
            },
            { text: t`This status is updated every 24 hours.`, style: { fontStyle: "italic" } }
        ],
        level: "warning"
    },
    15: {
        message: t`Out-of-bounds motor operation`,
        popup_content: [
            {
                text: t`No acquisition was made during the last 24 hours, due to out-of-range motor current or frequency, according to the nominal values defined in the machine kinematics. (Possibly due to motor shut down, motor overload, frequency above or below limit).`,
                style: null
            }
        ],
        level: "warning"
    },
    20: {
        message: t`Unstable signal`,
        popup_content: [
            {
                text: t`No acquisition in the last 24 hours due to electrical signal instability. Please contact the support for more information.`,
                style: null
            }
        ],
        level: "warning"
    },
    30: {
        message: t`Acquisition parameters adjustement in progress`,
        popup_content: [
            {
                text: t`The device is currently adjusting the measurement stability criteria.`,
                style: null
            },
            {
                text: t`For machines with intermittent operation, it may be necessary to wait until the motor has accumulated enough, running time, which can take up to 14 days.`,
                style: null
            },
            {
                text: t`Only after this period can the health score training begin`,
                style: null
            }
        ],
        level: "warning"
    }
};
