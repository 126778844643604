import React from "react";
import { Modal } from "semantic-ui-react";
import { Trans } from "@lingui/macro";
import EfficiencyForm from "../form/EfficiencyForm";

const AddEfficiencyModal = (props) => {
    const { setOpen, equipment, measurements, measurementsObject } = props;
    return (
        <Modal open centered={false} onClose={(e) => setOpen(false)} size="fullscreen">
            <Modal.Header>
                <Trans>add efficiency</Trans>
            </Modal.Header>
            <Modal.Content>
                <EfficiencyForm setOpen={setOpen} equipment={equipment} measurements={measurements} measurementsObject={measurementsObject} />
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(AddEfficiencyModal);
