import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { Button, Modal, Icon, Popup } from "semantic-ui-react";
import { t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteFileImportMutation } from "modules/import/fileImportsService";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const DeleteImportFile = (props) => {
    const { item } = props;
    const [open, setOpen] = useState(false);
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    const user_rights = auth.rights;

    const [deleteImportFile, deleted] = useDeleteFileImportMutation();

    useEffect(() => {
        if (deleted.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (deleted.isSuccess) {
            toast.success(i18n._(t`successful delete import file`), toast_options);
            setOpen(false);
        }
        if (deleted.isError) {
            let error = i18n._(t`cannot delete import file`);
            if (deleted.error?.data && !_.includes(deleted.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={deleted.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [deleted]);

    const handleDelete = async (e) => {
        if (user_rights.is_admin && notification.srv_status.db_status === "rw") {
            setOpen(true);
            await deleteImportFile({ org: org.current, id: item.id });
        }
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            trigger={
                <Popup
                    trigger={
                        user_rights.is_admin &&
                        notification.srv_status.db_status === "rw" && (
                            <Button
                                type="button"
                                icon="delete"
                                onClick={async (e) => {
                                    setOpen(true);
                                }}
                            />
                        )
                    }
                >
                    <Popup.Content>
                        <Trans>Deletion of import</Trans>
                    </Popup.Content>
                </Popup>
            }
            onClose={() => {
                setOpen(false);
            }}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>
                <Trans>want delete import file {item.name}</Trans>
            </Modal.Header>

            <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                    <Trans>cancel</Trans>
                </Button>
                {user_rights.is_admin && notification.srv_status.db_status === "rw" && (
                    <Button labelPosition="right" icon positive onClick={handleDelete}>
                        <Trans>validate</Trans>
                        <Icon name="trash" inverted />
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default React.memo(DeleteImportFile);
