import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _, { identity } from "lodash";
import { t, Trans } from "@lingui/macro";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Button, Header, Segment, Grid, Label, Popup, Icon, Divider, Message } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { nextStep, previousStep, setMotorInfo } from "../kinematicSlice";
import { useGetMachinetypesQuery } from "modules/machine/machinetypeService";
import { identityNull } from "modules/common/utils/form";
import { duty_types, efficiency_class, motorTypeOptions, defaultBearing, bearingType, keyToReparseInMotor } from "modules/predict/utils";
import { reparseNumber, validateNumber } from "modules/common/utils";

import { DropDownAdapter, InputAdapter, TextAreaAdapter, ToggleAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import KinematicSearchBearings from "./KinematicSearchBearings";

const KinematicMotorForm = (props) => {
    const { machine, mode } = props;
    const is_predict = _.get(machine, "is_predict", true);
    const dispatch = useDispatch();
    const org = useSelector((state) => state.org);
    const component = useSelector((state) => state.component);
    const kinematic = useSelector((state) => state.kinematic);

    const machinetypes = useGetMachinetypesQuery({ org: org.current }, { skip: !org.current || !machine?.id });

    const motor_component =
        _.chain(component.components)
            .filter({ machine: _.get(machine, "id") })
            .find({ comp_type: 2 })
            .value() || {};

    //Database bearings components
    const motor_bearings_component = is_predict
        ? _.chain(component.components)
              .reduce((res, component) => {
                  if (component.machine === _.get(machine, "id") && component.comp_type === 4 && _.includes([1, 2], component.id_tech)) {
                      res.push({
                          ..._.get(component, "description")
                      });
                  }
                  return res;
              }, [])
              .value()
        : null;

    const onSubmitForm = async (data) => {
        _.forOwn(data.motor, (value, key) => {
            //directly update motor object
            if (_.includes(keyToReparseInMotor, key)) {
                data.motor[key] = reparseNumber(value);
            } else {
                data.motor[key] = value;
            }
        });

        data.motor_bearings = _.map(data.motor_bearings, (bearing) => {
            return {
                ...bearing,
                dimension: _.mapValues(bearing.dimension, (item) => {
                    return reparseNumber(item);
                }),
                order: _.mapValues(bearing.order, (item) => {
                    return reparseNumber(item);
                })
            };
        });

        await dispatch(setMotorInfo(data));
        await dispatch(nextStep());
    };

    const generate_bearing_error = (bearing) => {
        const err = {};
        const { by_order, order, dimension } = bearing;
        if (by_order) {
            err["order"] = {};
            _.each(order, (item, key) => {
                const orderValidate = validateNumber(item, i18n, false, false);
                if (orderValidate !== undefined) {
                    return (err["order"][key] = orderValidate);
                }
            });
            if (!_.every(order, (item) => _.isNull(item))) {
                if (!_.every(order, (item) => !_.isNull(item))) {
                    err["order"]["order_err"] = t`Fill all fields`;
                }
            }
        } else {
            err["dimension"] = {};
            _.each(dimension, (item, key) => {
                const dimensionValidate =
                    key === "rolElements" ? validateNumber(item, i18n, false, false, true) : validateNumber(item, i18n, false, false);
                if (dimensionValidate !== undefined) {
                    return (err["dimension"][key] = dimensionValidate);
                }
            });
            if (!_.every(dimension, (item) => _.isNull(item))) {
                if (!_.every(dimension, (item) => !_.isNull(item))) {
                    err["dimension"]["dimension_err"] = t`Fill all fields`;
                }
            }
        }
        return err;
    };

    const validate = (values) => {
        const errors = {};
        if (mode === "view") {
            return errors;
        }

        if (is_predict) {
            errors.motor_bearings = _.chain(values)
                .get("motor_bearings", [])
                .map((bearing) => {
                    return generate_bearing_error(bearing);
                })
                .value();
        }
        return errors;
    };

    const motor_info = kinematic.kinematic.motor ? kinematic.kinematic.motor : _.get(motor_component, "description", null);

    let initial_bearings = null;
    if (is_predict) {
        //Use form values set by client if already set
        if (kinematic.kinematic.motor_bearings !== null) {
            initial_bearings = kinematic.kinematic.motor_bearings;
        } else if (_.size(motor_bearings_component) > 0) {
            // During kinematic update check id same_bearings or not
            if (motor_info?.same_bearings ?? false) {
                //Only one bearing. Display Bdd bearing or default bearing
                initial_bearings = [motor_bearings_component?.[0] ?? defaultBearing];
            } else {
                // Force display of 2 bearings, Bdd bearing or default bearing
                initial_bearings = [motor_bearings_component?.[0] ?? defaultBearing, motor_bearings_component?.[1] ?? defaultBearing];
            }
        } else {
            // Initial during kinematic creation
            initial_bearings = [];
        }
    }

    const initialValues = useMemo(() => {
        const motor = {
            ...motor_info
        };

        return is_predict ? { motor, motor_bearings: initial_bearings } : { motor };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Form
                onSubmit={onSubmitForm}
                mutators={{ ...arrayMutators }}
                validate={validate}
                initialValues={initialValues}
                render={({
                    handleSubmit,
                    invalid,
                    values,
                    form: {
                        mutators: { push, pop }
                    },
                    form
                }) => {
                    // use to display number of pole
                    const pole = _.get(values, "motor.polePairs");

                    let same_bearing = false;
                    if (is_predict) {
                        same_bearing = _.get(values, "motor.same_bearings", false);
                    }

                    // use to display message with synchronous motor
                    const ratedFrequency = _.get(values, "motor.ratedFrequency");
                    const ratedSpeed = _.get(values, "motor.ratedSpeed");
                    const isSynchro = Number.isInteger((ratedFrequency * 60) / ratedSpeed);

                    const pole_process = Math.floor((parseFloat(ratedFrequency) * 60) / parseFloat(ratedSpeed));
                    let ratedSpeedPoleWarning = false;
                    if (pole_process > 6 && pole_process < 12) {
                        ratedSpeedPoleWarning = true;
                    }

                    return (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Header as={"h3"} attached="top" block textAlign="center">
                                <Trans>motor info</Trans>
                            </Header>
                            <Segment attached>
                                <Grid stackable>
                                    <Grid.Row columns={2} stretched>
                                        <Grid.Column>
                                            <Segment padded>
                                                <Label attached="top" color="blue" style={{ textAlign: "center" }}>
                                                    <Trans>general info</Trans>
                                                </Label>
                                                <Field
                                                    name="motor.name"
                                                    placeholder={i18n._(t`enter name of motor`)}
                                                    label={i18n._(t`name`)}
                                                    isRequired={true}
                                                    component={InputAdapter}
                                                    parse={identity}
                                                    defaultValue={""}
                                                    validate={(value) => {
                                                        if (!value) return <Trans>motor name is required</Trans>;
                                                        if (_.size(value) >= 50) {
                                                            return <Trans>Ensure this field has no more than 50 characters</Trans>;
                                                        }
                                                        return undefined;
                                                    }}
                                                    disabled={mode === "view"}
                                                />
                                                <Field
                                                    name="motor.maker"
                                                    placeholder={i18n._(t`enter maker of motor`)}
                                                    label={i18n._(t`maker`)}
                                                    component={InputAdapter}
                                                    parse={identity}
                                                    defaultValue={""}
                                                    disabled={mode === "view"}
                                                />
                                                <Field
                                                    name="motor.model"
                                                    placeholder={i18n._(t`enter model of motor`)}
                                                    label={i18n._(t`model`)}
                                                    component={InputAdapter}
                                                    parse={identity}
                                                    defaultValue={""}
                                                    disabled={mode === "view"}
                                                />
                                                <Field
                                                    name="motor.motor_type"
                                                    label={i18n._(t`motor_type`)}
                                                    placeholder={i18n._(t`select motor type`)}
                                                    options={motorTypeOptions}
                                                    component={DropDownAdapter}
                                                    defaultValue={""}
                                                    disabled={mode === "view"}
                                                />
                                                <Field
                                                    name="motor.description"
                                                    placeholder={i18n._(t`enter description of your machine`)}
                                                    label={i18n._(t`description`)}
                                                    component={TextAreaAdapter}
                                                    defaultValue={""}
                                                    parse={identity}
                                                    rows={3}
                                                    disabled={mode === "view"}
                                                />
                                            </Segment>
                                            <Segment padded>
                                                <Label attached="top" color="blue" style={{ textAlign: "center" }}>
                                                    <Trans>other</Trans>
                                                </Label>
                                                <Field
                                                    name="motor.serviceFactor"
                                                    placeholder={i18n._(t`enter service factor of motor`)}
                                                    label={i18n._(t`Service factor`)}
                                                    component={InputAdapter}
                                                    helperText={
                                                        <>
                                                            <p>
                                                                <Trans>The service factor is used to evaluate motor’s thermal overloads</Trans>
                                                            </p>
                                                        </>
                                                    }
                                                    isRequired={true}
                                                    parse={identityNull}
                                                    inputMode="decimal"
                                                    defaultValue={1.15}
                                                    validate={(value) => {
                                                        if (mode === "view") return undefined;
                                                        const factorValidate = validateNumber(value, i18n, false, false);
                                                        if (factorValidate !== undefined) {
                                                            return factorValidate;
                                                        }
                                                        const factor = reparseNumber(value);
                                                        if (factor <= 0) {
                                                            return <Trans>invalid format</Trans>;
                                                        }
                                                        return undefined;
                                                    }}
                                                    disabled={mode === "view"}
                                                />
                                                <Field
                                                    name="motor.efficiency_class"
                                                    label={i18n._(t`efficiency_class`)}
                                                    placeholder={i18n._(t`enter efficiency class of motor`)}
                                                    options={efficiency_class}
                                                    component={DropDownAdapter}
                                                    defaultValue={null}
                                                    disabled={mode === "view"}
                                                />
                                                <Field
                                                    name="motor.duty_types"
                                                    label={i18n._(t`duty_types`)}
                                                    placeholder={i18n._(t`enter duty_types of motor`)}
                                                    options={duty_types}
                                                    component={DropDownAdapter}
                                                    defaultValue={null}
                                                    disabled={mode === "view"}
                                                />
                                            </Segment>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Segment padded>
                                                <Label attached="top" color="blue" style={{ textAlign: "center" }}>
                                                    {" "}
                                                    <Trans>rated info</Trans>
                                                </Label>
                                                <Field
                                                    name="motor.ratedVoltage"
                                                    placeholder={i18n._(t`enter voltage of motor`)}
                                                    label={i18n._(t`voltage_motor`)}
                                                    component={InputAdapter}
                                                    isRequired={true}
                                                    unit={"V"}
                                                    parse={identityNull}
                                                    inputMode="decimal"
                                                    defaultValue={400}
                                                    customAction={(data) => {
                                                        const {
                                                            motor: { ratedCurrent, ratedCosphi, ratedMeca_power }
                                                        } = values;
                                                        const elec_power =
                                                            (parseFloat(data) * parseFloat(ratedCurrent) * parseFloat(ratedCosphi) * Math.sqrt(3)) /
                                                            1000;
                                                        if (_.isFinite(elec_power)) {
                                                            form.change("motor.ratedElec_power", _.round(elec_power, 2));
                                                            if (_.isFinite(parseFloat(ratedMeca_power))) {
                                                                const efficiency = (parseFloat(ratedMeca_power) / elec_power) * 100;
                                                                form.change("motor.ratedEfficiency", _.round(efficiency, 1));
                                                            }
                                                        }
                                                    }}
                                                    validate={(value) => {
                                                        if (mode === "view") return undefined;
                                                        return validateNumber(value, i18n, false, false);
                                                    }}
                                                    disabled={mode === "view"}
                                                />
                                                <Field
                                                    name="motor.ratedFrequency"
                                                    placeholder={i18n._(t`select motor frequency`)}
                                                    label={i18n._(t`frequency_motor`)}
                                                    component={InputAdapter}
                                                    isRequired={true}
                                                    unit={"Hz"}
                                                    parse={identityNull}
                                                    inputMode="decimal"
                                                    defaultValue={50}
                                                    customAction={(data) => {
                                                        const {
                                                            motor: { ratedSpeed }
                                                        } = values;
                                                        const pole = Math.floor((parseFloat(data) * 60) / parseFloat(ratedSpeed));
                                                        if (_.isFinite(pole)) {
                                                            form.change("motor.polePairs", pole);
                                                        }
                                                    }}
                                                    validate={(value) => {
                                                        if (mode === "view") return undefined;
                                                        return validateNumber(value, i18n, false, false);
                                                    }}
                                                    disabled={mode === "view"}
                                                />
                                                <Field
                                                    name="motor.ratedCurrent"
                                                    placeholder={i18n._(t`enter current of motor`)}
                                                    label={i18n._(t`current_motor`)}
                                                    component={InputAdapter}
                                                    isRequired={true}
                                                    unit={"A"}
                                                    parse={identityNull}
                                                    inputMode="decimal"
                                                    defaultValue={null}
                                                    customAction={(data) => {
                                                        const {
                                                            motor: { ratedVoltage, ratedCosphi, ratedMeca_power }
                                                        } = values;
                                                        const elec_power =
                                                            (parseFloat(data) * parseFloat(ratedVoltage) * parseFloat(ratedCosphi) * Math.sqrt(3)) /
                                                            1000;
                                                        if (_.isFinite(elec_power)) {
                                                            form.change("motor.ratedElec_power", _.round(elec_power, 2));
                                                            if (_.isFinite(parseFloat(ratedMeca_power))) {
                                                                const efficiency = (parseFloat(ratedMeca_power) / elec_power) * 100;
                                                                form.change("motor.ratedEfficiency", _.round(efficiency, 1));
                                                            }
                                                        }
                                                    }}
                                                    validate={(value) => {
                                                        if (mode === "view") return undefined;
                                                        return validateNumber(value, i18n, false, false);
                                                    }}
                                                    disabled={mode === "view"}
                                                />
                                                <Field
                                                    name="motor.ratedCosphi"
                                                    placeholder={i18n._(t`enter cosphi of motor`)}
                                                    label={i18n._(t`cosphi_motor`)}
                                                    component={InputAdapter}
                                                    isRequired={true}
                                                    parse={identityNull}
                                                    inputMode="decimal"
                                                    defaultValue={null}
                                                    customAction={(data) => {
                                                        const {
                                                            motor: { ratedCurrent, ratedVoltage, ratedMeca_power }
                                                        } = values;
                                                        const elec_power =
                                                            (parseFloat(data) * parseFloat(ratedCurrent) * parseFloat(ratedVoltage) * Math.sqrt(3)) /
                                                            1000;
                                                        if (_.isFinite(elec_power)) {
                                                            form.change("motor.ratedElec_power", _.round(elec_power, 2));
                                                            if (_.isFinite(parseFloat(ratedMeca_power))) {
                                                                const efficiency = (parseFloat(ratedMeca_power) / elec_power) * 100;
                                                                form.change("motor.ratedEfficiency", _.round(efficiency, 1));
                                                            }
                                                        }
                                                    }}
                                                    validate={(value) => {
                                                        if (mode === "view") return undefined;
                                                        return validateNumber(value, i18n, false, false);
                                                    }}
                                                    disabled={mode === "view"}
                                                />
                                                <Field
                                                    name="motor.ratedElec_power"
                                                    placeholder={i18n._(t`enter elec power of motor`)}
                                                    label={i18n._(t`elec_power_motor`)}
                                                    component={InputAdapter}
                                                    unit={"kW"}
                                                    isRequired={true}
                                                    parse={identityNull}
                                                    inputMode="decimal"
                                                    defaultValue={null}
                                                    customAction={(data) => {
                                                        const {
                                                            motor: { ratedMeca_power }
                                                        } = values;
                                                        const efficiency = (parseFloat(ratedMeca_power) / parseFloat(data)) * 100;
                                                        if (_.isFinite(efficiency)) {
                                                            form.change("motor.ratedEfficiency", _.round(efficiency, 1));
                                                        }
                                                    }}
                                                    validate={(value) => {
                                                        if (mode === "view") return undefined;
                                                        return validateNumber(value, i18n, false, false);
                                                    }}
                                                    disabled={mode === "view"}
                                                />
                                                <Field
                                                    name="motor.ratedMeca_power"
                                                    placeholder={i18n._(t`enter meca power of motor`)}
                                                    label={i18n._(t`meca_power_motor`)}
                                                    component={InputAdapter}
                                                    isRequired={true}
                                                    unit={"kW"}
                                                    parse={identityNull}
                                                    inputMode="decimal"
                                                    defaultValue={null}
                                                    customAction={(data) => {
                                                        const {
                                                            motor: { ratedElec_power }
                                                        } = values;
                                                        const efficiency = (parseFloat(data) / parseFloat(ratedElec_power)) * 100;
                                                        if (_.isFinite(efficiency)) {
                                                            form.change("motor.ratedEfficiency", _.round(efficiency, 1));
                                                        }
                                                    }}
                                                    validate={(value) => {
                                                        if (mode === "view") return undefined;
                                                        return validateNumber(value, i18n, false, false);
                                                    }}
                                                    disabled={mode === "view"}
                                                />
                                                <Field
                                                    name="motor.ratedEfficiency"
                                                    placeholder={i18n._(t`enter efficiency of motor`)}
                                                    label={i18n._(t`efficiency_motor`)}
                                                    component={InputAdapter}
                                                    isRequired={true}
                                                    unit={"%"}
                                                    parse={identityNull}
                                                    inputMode="decimal"
                                                    defaultValue={null}
                                                    validate={(value) => {
                                                        if (mode === "view") return undefined;
                                                        return validateNumber(value, i18n, false, false);
                                                    }}
                                                    disabled={mode === "view"}
                                                />
                                                <Field
                                                    name="motor.ratedSpeed"
                                                    placeholder={i18n._(t`enter speed of motor`)}
                                                    label={i18n._(t`speed_motor`)}
                                                    component={InputAdapter}
                                                    isRequired={true}
                                                    unit={"rpm"}
                                                    parse={identityNull}
                                                    inputMode="decimal"
                                                    defaultValue={null}
                                                    customAction={(data) => {
                                                        const pole = Math.floor((reparseNumber(ratedFrequency) * 60) / reparseNumber(data));
                                                        if (_.isFinite(pole)) {
                                                            form.change("motor.polePairs", pole);
                                                        }
                                                    }}
                                                    validate={(value) => {
                                                        if (mode === "view") return undefined;
                                                        const ratedSpeedValidate = validateNumber(value, i18n, false, false);
                                                        if (ratedSpeedValidate !== undefined) {
                                                            return ratedSpeedValidate;
                                                        }

                                                        const pole = Math.floor((reparseNumber(ratedFrequency) * 60) / reparseNumber(value));
                                                        if (pole > 12) {
                                                            return (
                                                                <Trans>
                                                                    The nominal speed value is too low in relation to the nominal frequency value
                                                                </Trans>
                                                            );
                                                        }

                                                        return undefined;
                                                    }}
                                                    disabled={mode === "view"}
                                                />
                                                {ratedSpeedPoleWarning === true && (
                                                    <Message warning style={{ display: "block" }}>
                                                        <Trans>The nominal speed value is very low in relation to the nominal frequency value</Trans>
                                                    </Message>
                                                )}
                                                {isSynchro === true && (
                                                    <Message warning style={{ display: "block" }}>
                                                        <Trans>this is a synchronous motor</Trans>
                                                    </Message>
                                                )}
                                            </Segment>

                                            {is_predict && (
                                                <>
                                                    <Segment padded>
                                                        <Label attached="top" color="blue" style={{ textAlign: "center" }}>
                                                            <Trans>stator</Trans>
                                                        </Label>
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column mobile={16} tablet={10} computer={10}>
                                                                    <Field
                                                                        name="motor.polePairs"
                                                                        placeholder={i18n._(t`enter stator pole pairs of motor`)}
                                                                        label={i18n._(t`polePairs_motor`)}
                                                                        component={InputAdapter}
                                                                        isRequired={true}
                                                                        parse={identityNull}
                                                                        inputMode="numeric"
                                                                        defaultValue={null}
                                                                        validate={(value) => {
                                                                            if (mode === "view") return undefined;
                                                                            const polePairsValidate = validateNumber(value, i18n, false, false, true);
                                                                            if (polePairsValidate !== undefined) {
                                                                                return polePairsValidate;
                                                                            }
                                                                            const pole = reparseNumber(value);
                                                                            if (_.isFinite(pole)) {
                                                                                if (pole === 0) {
                                                                                    return <Trans>polePairs cannot be 0</Trans>;
                                                                                }
                                                                                if (pole > 12) {
                                                                                    return <Trans>The number of pole pairs cannot exceed 12</Trans>;
                                                                                }
                                                                                const ratedFrequency = _.get(values, "motor.ratedFrequency", 50);
                                                                                const ratedSpeed = _.get(values, "motor.ratedSpeed", null);
                                                                                const slip =
                                                                                    1 -
                                                                                    (reparseNumber(ratedSpeed) * pole) /
                                                                                        60 /
                                                                                        reparseNumber(ratedFrequency);

                                                                                const mt_type = _.find(machinetypes.data, {
                                                                                    id: machine?.machine_type
                                                                                });

                                                                                if (mt_type?.is_generator) {
                                                                                    if (slip <= -0.15)
                                                                                        return (
                                                                                            <Trans>
                                                                                                Inconsistency between rated speed and pole pairs
                                                                                            </Trans>
                                                                                        );
                                                                                    if (slip > 0)
                                                                                        return (
                                                                                            <Trans>
                                                                                                Inconsistency between rated speed and pole pairs for
                                                                                                this type of machine (generator)
                                                                                            </Trans>
                                                                                        );
                                                                                } else {
                                                                                    if (slip >= 0.15)
                                                                                        return (
                                                                                            <Trans>
                                                                                                Inconsistency between rated speed and pole pairs
                                                                                            </Trans>
                                                                                        );
                                                                                    if (slip < 0)
                                                                                        return (
                                                                                            <Trans>
                                                                                                Inconsistency between rated speed and pole pairs for
                                                                                                this type of machine (motor)
                                                                                            </Trans>
                                                                                        );
                                                                                }
                                                                                return undefined;
                                                                            }
                                                                            return <Trans>polePairs is required</Trans>;
                                                                        }}
                                                                        disabled={mode === "view"}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={6} computer={6}>
                                                                    <div className="field">
                                                                        <label style={{ display: "inline" }}>{i18n._(t`number_of_poles`)}</label>
                                                                        <div className="ui input">
                                                                            <input
                                                                                type="text"
                                                                                disabled
                                                                                readOnly
                                                                                value={_.isFinite(pole) ? pole * 2 : 0}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <Field
                                                                        name="motor.statorSlots"
                                                                        placeholder={i18n._(t`enter stator slots of motor`)}
                                                                        label={i18n._(t`statorSlots_motor`)}
                                                                        component={InputAdapter}
                                                                        parse={identityNull}
                                                                        inputMode="numeric"
                                                                        defaultValue={null}
                                                                        disabled={mode === "view"}
                                                                        validate={(value) => {
                                                                            if (mode === "view") return undefined;
                                                                            return validateNumber(value, i18n, false, true, true);
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                    <Segment padded>
                                                        <Label attached="top" color="blue" style={{ textAlign: "center" }}>
                                                            <Trans>rotor</Trans>
                                                        </Label>
                                                        <Field
                                                            name="motor.rotorBars"
                                                            placeholder={i18n._(t`enter rotor bars of motor`)}
                                                            label={i18n._(t`rotorBars_motor`)}
                                                            component={InputAdapter}
                                                            parse={identityNull}
                                                            inputMode="numeric"
                                                            defaultValue={null}
                                                            disabled={mode === "view"}
                                                            validate={(value) => {
                                                                if (mode === "view") return undefined;
                                                                return validateNumber(value, i18n, false, true, true);
                                                            }}
                                                        />
                                                    </Segment>
                                                </>
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                            {is_predict && (
                                <>
                                    <Header as={"h3"} attached block textAlign="center">
                                        <Trans>motor bearings</Trans>
                                    </Header>

                                    {_.size(values?.motor_bearings) === 0 && (
                                        <MessageDisplay
                                            message={i18n._(t`This machine has no motor bearings`)}
                                            level="info"
                                            iconName="info circle"
                                            isLoading={false}
                                        />
                                    )}
                                    <FieldArray name={"motor_bearings"}>
                                        {(bearing_field) => {
                                            return bearing_field.fields.map((b_name, b_index) => {
                                                const current_bearing = bearing_field.fields.value[b_index];
                                                return (
                                                    <React.Fragment key={b_index}>
                                                        <Header as="h4" attached block textAlign="center">
                                                            {b_index === 0 && same_bearing && <Trans>de/nde bearing</Trans>}
                                                            {b_index === 0 && !same_bearing && <Trans>de bearing</Trans>}
                                                            {b_index !== 0 && !same_bearing && <Trans>nde bearing</Trans>}
                                                        </Header>
                                                        <Segment attached>
                                                            <Grid stackable>
                                                                <Grid.Row columns={2} stretched>
                                                                    <Grid.Column>
                                                                        <Segment>
                                                                            <Label attached="top" color="blue" style={{ textAlign: "center" }}>
                                                                                <Trans>general description</Trans>
                                                                            </Label>
                                                                            <Field
                                                                                name={`${b_name}.model`}
                                                                                placeholder={i18n._(t`enter model of bearing`)}
                                                                                label={i18n._(t`model`)}
                                                                                component={InputAdapter}
                                                                                parse={identity}
                                                                                defaultValue={""}
                                                                                disabled={mode === "view"}
                                                                            />
                                                                            {mode === "change" && (
                                                                                <KinematicSearchBearings
                                                                                    bearing={current_bearing}
                                                                                    changeForm={form.change}
                                                                                    b_name={b_name}
                                                                                />
                                                                            )}
                                                                            <Field
                                                                                name={`${b_name}.maker`}
                                                                                placeholder={i18n._(t`enter maker of bearing`)}
                                                                                label={i18n._(t`maker`)}
                                                                                component={InputAdapter}
                                                                                parse={identity}
                                                                                defaultValue={""}
                                                                                disabled={mode === "view"}
                                                                            />

                                                                            <Field
                                                                                name={`${b_name}.bearing_type`}
                                                                                label={i18n._(t`bearing_type`)}
                                                                                placeholder={i18n._(t`select type of bearing`)}
                                                                                options={bearingType}
                                                                                component={DropDownAdapter}
                                                                                defaultValue={""}
                                                                                disabled={mode === "view"}
                                                                            />
                                                                            <Field
                                                                                name={`${b_name}.description`}
                                                                                placeholder={i18n._(t`enter description of your bearing`)}
                                                                                label={i18n._(t`description`)}
                                                                                component={TextAreaAdapter}
                                                                                parse={identity}
                                                                                defaultValue={""}
                                                                                disabled={mode === "view"}
                                                                            />
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <Segment>
                                                                            <Label attached="top" color="blue" style={{ textAlign: "center" }}>
                                                                                <Trans>mechanical</Trans>
                                                                            </Label>
                                                                            <Segment basic>
                                                                                <Field
                                                                                    name={`${b_name}.by_order`}
                                                                                    label={i18n._(t`by_dim_by_order`)}
                                                                                    labelposition="right"
                                                                                    component={ToggleAdapter}
                                                                                    defaultValue={false}
                                                                                    disabled={mode === "view"}
                                                                                />
                                                                                &nbsp;&nbsp;
                                                                                <Popup
                                                                                    basic
                                                                                    position="bottom center"
                                                                                    trigger={
                                                                                        <Icon
                                                                                            circular
                                                                                            inverted
                                                                                            color="grey"
                                                                                            name="question"
                                                                                            size="tiny"
                                                                                        />
                                                                                    }
                                                                                >
                                                                                    <Segment basic style={{ width: "50vh" }}>
                                                                                        <Trans>by_dim_by_order_helper</Trans>
                                                                                    </Segment>
                                                                                </Popup>
                                                                            </Segment>
                                                                            <Divider />
                                                                            {current_bearing.by_order === true && (
                                                                                <>
                                                                                    {bearing_field?.meta?.error?.[b_index]?.order?.order_err && (
                                                                                        <MessageDisplay
                                                                                            message={i18n._(
                                                                                                bearing_field?.meta?.error?.[b_index]?.order
                                                                                                    ?.order_err
                                                                                            )}
                                                                                            level="info"
                                                                                            iconName="info circle"
                                                                                            isLoading={false}
                                                                                            attached={false}
                                                                                        />
                                                                                    )}
                                                                                    <Field
                                                                                        name={`${b_name}.order.BPFI`}
                                                                                        placeholder={i18n._(t`bpfi place`)}
                                                                                        label={i18n._(t`bpfi`)}
                                                                                        isRequired={true}
                                                                                        component={InputAdapter}
                                                                                        parse={identityNull}
                                                                                        inputMode="decimal"
                                                                                        defaultValue={null}
                                                                                        disabled={mode === "view"}
                                                                                    />
                                                                                    <Field
                                                                                        name={`${b_name}.order.BPFO`}
                                                                                        placeholder={i18n._(t`bpfo place`)}
                                                                                        label={i18n._(t`bpfo`)}
                                                                                        isRequired={true}
                                                                                        component={InputAdapter}
                                                                                        parse={identityNull}
                                                                                        inputMode="decimal"
                                                                                        defaultValue={null}
                                                                                        disabled={mode === "view"}
                                                                                    />
                                                                                    <Field
                                                                                        name={`${b_name}.order.FTF`}
                                                                                        placeholder={i18n._(t`ftf place`)}
                                                                                        label={i18n._(t`ftf`)}
                                                                                        isRequired={true}
                                                                                        component={InputAdapter}
                                                                                        parse={identityNull}
                                                                                        inputMode="decimal"
                                                                                        defaultValue={null}
                                                                                        disabled={mode === "view"}
                                                                                    />
                                                                                    <Field
                                                                                        name={`${b_name}.order.BSF`}
                                                                                        placeholder={i18n._(t`BSF place`)}
                                                                                        label={i18n._(t`bsf`)}
                                                                                        isRequired={true}
                                                                                        component={InputAdapter}
                                                                                        parse={identityNull}
                                                                                        inputMode="decimal"
                                                                                        defaultValue={null}
                                                                                        disabled={mode === "view"}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {current_bearing.by_order === false && (
                                                                                <>
                                                                                    {bearing_field?.meta?.error?.[b_index]?.dimension
                                                                                        ?.dimension_err && (
                                                                                        <MessageDisplay
                                                                                            message={i18n._(
                                                                                                bearing_field?.meta?.error?.[b_index]?.dimension
                                                                                                    ?.dimension_err
                                                                                            )}
                                                                                            level="info"
                                                                                            iconName="info circle"
                                                                                            isLoading={false}
                                                                                            attached={false}
                                                                                        />
                                                                                    )}
                                                                                    <Field
                                                                                        name={`${b_name}.dimension.rolElements`}
                                                                                        placeholder={i18n._(t`enter number of rolling elements`)}
                                                                                        label={i18n._(t`rolElements`)}
                                                                                        isRequired={true}
                                                                                        component={InputAdapter}
                                                                                        parse={identityNull}
                                                                                        inputMode="numeric"
                                                                                        defaultValue={null}
                                                                                        disabled={mode === "view"}
                                                                                    />
                                                                                    <Field
                                                                                        name={`${b_name}.dimension.rolDiameter`}
                                                                                        placeholder={i18n._(t`enter diameter of rolling elements`)}
                                                                                        label={i18n._(t`rolDiameter`)}
                                                                                        isRequired={true}
                                                                                        component={InputAdapter}
                                                                                        unit={"mm"}
                                                                                        parse={identityNull}
                                                                                        inputMode="decimal"
                                                                                        defaultValue={null}
                                                                                        disabled={mode === "view"}
                                                                                    />
                                                                                    <Field
                                                                                        name={`${b_name}.dimension.pitchDiameter`}
                                                                                        placeholder={i18n._(
                                                                                            t`enter pitch diameter of rolling elements`
                                                                                        )}
                                                                                        label={i18n._(t`pitchDiameter`)}
                                                                                        isRequired={true}
                                                                                        component={InputAdapter}
                                                                                        unit={"mm"}
                                                                                        parse={identityNull}
                                                                                        inputMode="decimal"
                                                                                        defaultValue={null}
                                                                                        disabled={mode === "view"}
                                                                                    />
                                                                                    <Field
                                                                                        name={`${b_name}.dimension.contactAngle`}
                                                                                        placeholder={i18n._(
                                                                                            t`enter contact angle of rolling elements`
                                                                                        )}
                                                                                        label={i18n._(t`contactAngle`)}
                                                                                        isRequired={true}
                                                                                        component={InputAdapter}
                                                                                        unit={"°"}
                                                                                        parse={identityNull}
                                                                                        inputMode="decimal"
                                                                                        defaultValue={null}
                                                                                        disabled={mode === "view"}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Segment>
                                                    </React.Fragment>
                                                );
                                            });
                                        }}
                                    </FieldArray>
                                    <Segment attached>
                                        {mode === "change" && (
                                            <>
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        if (_.size(values?.motor_bearings) === 0) {
                                                            const first_bearing = motor_bearings_component?.[0];
                                                            if (first_bearing) {
                                                                push("motor_bearings", first_bearing);
                                                            } else {
                                                                push("motor_bearings", defaultBearing);
                                                            }
                                                        } else if (_.size(values?.motor_bearings) === 1) {
                                                            const second_bearing = motor_bearings_component?.[1];
                                                            if (second_bearing) {
                                                                push("motor_bearings", second_bearing);
                                                            } else {
                                                                push("motor_bearings", defaultBearing);
                                                            }
                                                        }
                                                    }}
                                                    disabled={
                                                        _.size(values?.motor_bearings) >= 2 || (same_bearing && _.size(values?.motor_bearings) === 1)
                                                    }
                                                >
                                                    <Trans>add bearing</Trans>
                                                </Button>
                                                <Button
                                                    type="button"
                                                    onClick={() => pop("motor_bearings")}
                                                    disabled={_.size(values?.motor_bearings) === 0}
                                                >
                                                    <Trans>remove bearing</Trans>
                                                </Button>
                                            </>
                                        )}
                                        {_.size(values?.motor_bearings) > 0 && (
                                            <Field
                                                name="motor.same_bearings"
                                                label={i18n._(t`same_bearing`)}
                                                labelposition="right"
                                                component={ToggleAdapter}
                                                customAction={({ checked }) => {
                                                    if (checked && _.size(values?.motor_bearings) >= 2) {
                                                        pop("motor_bearings");
                                                    } else if (!checked && _.size(values?.motor_bearings) === 1) {
                                                        const second_bearing = motor_bearings_component?.[1];
                                                        if (second_bearing) {
                                                            push("motor_bearings", second_bearing);
                                                        } else {
                                                            push("motor_bearings", defaultBearing);
                                                        }
                                                    }
                                                }}
                                                defaultValue={_.get(motor_component, "description.same_bearings", true)}
                                                disabled={mode === "view"}
                                            />
                                        )}
                                    </Segment>
                                </>
                            )}
                            <Segment attached>
                                <Grid columns={2}>
                                    <Grid.Column textAlign="left">
                                        <Button
                                            type="button"
                                            icon="left arrow"
                                            compact
                                            labelPosition="left"
                                            content={i18n._(t`previous`)}
                                            onClick={(e) => {
                                                dispatch(previousStep());
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Button
                                            type="submit"
                                            disabled={invalid || _.isUndefined(machine)}
                                            icon="right arrow"
                                            labelPosition="right"
                                            content={i18n._(t`next`)}
                                            compact
                                        />
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </form>
                    );
                }}
            />
        </>
    );
};

export default KinematicMotorForm;
