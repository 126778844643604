import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Segment, Grid, Header, Button, Input, Icon, Dropdown } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { frequencyOptions, sendprotocolOptions } from "../utils";
import { checkStringInclude } from "modules/common/utils";
import { ContentType } from "modules/activityLog/utils";
import { resetFilterWithPage, setFrequencyFilter, setItemsPerPage, setPage, setProtocolFilter, setSearchNameFilter } from "../exportSlice";
import history_app from "history_app";

import Back from "modules/common/components/back";
import TableEnhanced from "modules/common/components/TableEnhanced";
import DeleteExport from "./DeleteExport";
import Generate from "./Generate";

const Exports = (props) => {
    const { exports } = props;
    const dispatch = useDispatch();
    const [localSearchName, setLocalSearchName] = useState("");
    const [deleteExport, setDeleteExport] = useState(null);
    const [generateExport, setGenerateExport] = useState(null);
    const notification = useSelector((state) => state.notification);
    const { filter, pagination } = useSelector((state) => state.expt);

    const renderIsActive = (item) => {
        return item.is_active === true ? <Icon name="checkmark" color="green" /> : <Icon name="delete" color="red" />;
    };

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "is_active", label: i18n._(t`is_active`), textAlign: "left", collapsing: true },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "frequency", label: i18n._(t`frequency`), textAlign: "left" },
        { id: "send_protocol", label: i18n._(t`send_protocol`), textAlign: "left" },
        { id: "owner_email", label: i18n._(t`owner_email`), textAlign: "left" },
        { id: "actions", label: i18n._(t`actions`), textAlign: "left", ordering: false, collapsing: true }
    ];

    const exports_list = _.chain(exports)
        .reduce((res, expt) => {
            if (filter.searchName === "") {
                res.push(expt);
            } else if (checkStringInclude(filter.searchName, expt.name)) {
                res.push(expt);
            }
            return res;
        }, [])
        .reduce((res, expt) => {
            if (_.size(filter.frequencyFilter) === 0) {
                res.push(expt);
            } else if (_.includes(filter.frequencyFilter, expt.frequency)) {
                res.push(expt);
            }
            return res;
        }, [])
        .reduce((res, expt) => {
            if (_.size(filter.protocolFilter) === 0) {
                res.push(expt);
            } else if (_.includes(filter.protocolFilter, expt.send_protocol)) {
                res.push(expt);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "pre" };
            const exp_freq = _.chain(frequencyOptions).find(["value", item.frequency]).get("text").value();
            const exp_protocol = _.chain(sendprotocolOptions).find(["value", item.send_protocol]).get("text").value();

            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                is_active: {
                    render: <span style={customStyle}>{renderIsActive(item)}</span>,
                    value: +item?.is_active ?? null,
                    textAlign: "left",
                    datatype: "number"
                },
                name: {
                    render: <span style={customStyle}>{item?.name ?? "-"}</span>,
                    value: item?.name ?? "-",
                    textAlign: "left",
                    datatype: "string"
                },
                frequency: {
                    render: <span style={customStyle}>{exp_freq ? i18n._(exp_freq) : "-"}</span>,
                    value: exp_freq ? i18n._(exp_freq) : null,
                    textAlign: "right",
                    datatype: "string"
                },
                send_protocol: {
                    render: <span style={customStyle}>{exp_protocol ? i18n._(exp_protocol) : "-"}</span>,
                    value: exp_protocol ? i18n._(exp_protocol) : null,
                    textAlign: "right",
                    datatype: "string"
                },
                owner_email: {
                    render: <span style={customStyle}>{item?.owner_email ?? "-"}</span>,
                    value: item?.owner_email ?? "-",
                    textAlign: "right",
                    datatype: "string"
                },
                actions: {
                    render: (() => (
                        <>
                            <Button.Group>
                                <Button.Group size="small">
                                    <Button
                                        icon="share square"
                                        onClick={(e) => {
                                            setGenerateExport(item);
                                        }}
                                    />
                                    <Button
                                        icon={notification.srv_status.db_status === "rw" ? "edit" : "eye"}
                                        onClick={async (e) => {
                                            history_app.push(`exports/${item.id}`);
                                        }}
                                    />
                                    {notification.srv_status.db_status === "rw" && (
                                        <Button
                                            icon="delete"
                                            onClick={(e) => {
                                                setDeleteExport(item);
                                            }}
                                        />
                                    )}
                                </Button.Group>
                            </Button.Group>
                        </>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back
                            action={async () => {
                                await dispatch(resetFilterWithPage());
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>exports management</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={15}>
                        <Grid celled>
                            <Grid.Column mobile={16} tablet={3} computer={3}>
                                <Input
                                    fluid
                                    icon="search"
                                    placeholder={i18n._(t`search identifier`)}
                                    onChange={(e, { value }) => {
                                        setLocalSearchName(value);
                                        dispatch(setSearchNameFilter(value));
                                        dispatch(setPage(1));
                                    }}
                                    value={localSearchName || filter.searchName}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={3} computer={3}>
                                <Dropdown
                                    fluid
                                    options={_.map(frequencyOptions, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                                    placeholder={i18n._(t`select frequency`)}
                                    multiple
                                    selection
                                    onChange={(e, { value }) => {
                                        dispatch(setFrequencyFilter(value));
                                        dispatch(setPage(1));
                                    }}
                                    value={filter.frequencyFilter}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={3} computer={3}>
                                <Dropdown
                                    fluid
                                    options={_.map(sendprotocolOptions, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                                    placeholder={i18n._(t`select protocol`)}
                                    multiple
                                    selection
                                    onChange={(e, { value }) => {
                                        dispatch(setProtocolFilter(value));
                                        dispatch(setPage(1));
                                    }}
                                    value={filter.protocolFilter}
                                />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                {notification.srv_status.db_status === "rw" && (
                                    <Button
                                        icon
                                        labelPosition="left"
                                        onClick={async (e) => {
                                            await dispatch(resetFilterWithPage());
                                            history_app.push(`exports/add`);
                                        }}
                                    >
                                        <Icon name="add" />
                                        <Trans>add export</Trans>
                                    </Button>
                                )}
                                <Button
                                    icon
                                    labelPosition="left"
                                    onClick={async (e) => {
                                        history_app.push(`activitylogs/${ContentType.SCHEDULEEXPORT}`);
                                    }}
                                >
                                    <Icon name="bullhorn" />
                                    <Trans>activitylog</Trans>
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <TableEnhanced
                                    headCells={headCells}
                                    rows={exports_list}
                                    textItemPerPages={i18n._(t`items per page`)}
                                    order="asc"
                                    orderBy="name"
                                    page={pagination.page}
                                    rowsPerPage={pagination.itemsPerPage}
                                    setPage={(page) => {
                                        dispatch(setPage(page));
                                    }}
                                    setItemsPerPage={(items) => {
                                        dispatch(setItemsPerPage(items));
                                    }}
                                />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {deleteExport !== null && <DeleteExport item={deleteExport} setDeleteExport={setDeleteExport} />}
            {generateExport !== null && <Generate exp={generateExport} setGenerateExport={setGenerateExport} />}
        </Segment>
    );
};

export default Exports;
