import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import moment from "moment";
import _ from "lodash";
import cookie from "react-cookies";

export const alertPackApi = defaultApi.injectEndpoints({
    reducerPath: "apiAlertPack",
    endpoints: (build) => ({
        getAlertPacks: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `alertpacks?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "AlertPacks", id: arg.org.id }];
                }
                return [];
            }
        }),
        getAlertPack: build.query({
            query: ({ org, id }) => {
                const current_org = _.get(org, "name", null);
                return `alertpacks/${id}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response) => {
                const { type } = response;
                if (type === 4) {
                    return {
                        ...response,
                        configuration: {
                            ...response.configuration,
                            duration: (response.configuration.duration /= 60)
                        }
                    };
                }
                return response;
            },
            providesTags: (result, error, arg) => {
                if (!error) return [{ type: "AlertPack", id: arg.id }];
                return [];
            }
        }),
        createAlertPack: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `alertpacks?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "AlertPacks", id: arg.org.id },
                        { type: "AlertPack", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        updateAlertPack: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `alertpacks/${data.id}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "AlertPacks", id: arg.org.id },
                        { type: "AlertPack", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        deleteAlertPack: build.mutation({
            query: ({ org, id }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `alertpacks/${id}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "AlertPacks", id: arg.org.id },
                        { type: "AlertPack", id: arg.id }
                    ];
                }
                return [];
            }
        }),
        getAlertPackOverride: build.query({
            query: ({ org, id }) => {
                const current_org = _.get(org, "name", null);
                return `alertpackoverrides?pack=${id}&org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (!error) return [{ type: "AlertPackOverride", id: arg.id }];
                return [];
            }
        }),
        setAlertPackOverride: build.mutation({
            query: ({ org, formdata, pack_id, type }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `alertpackoverrides?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: { ...formdata, pack_id, type }
                };
            },
            transformResponse: (response, meta, arg) => {
                return { data: response, type: arg.type };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [{ type: "AlertPackOverride", id: arg.pack_id }];
                }
                return [];
            }
        }),
        getAlertPackTypes: build.query({
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `alertpacktypes?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response) => {
                return _.map(response, (alertpacktype) => {
                    return {
                        ...alertpacktype,
                        key: alertpacktype.id,
                        value: alertpacktype.id,
                        text: alertpacktype.name,
                        type: "packType"
                    };
                });
            },
            providesTags: (result, error, arg) => {
                if (!error) return [{ type: "AlertPackTypes", id: arg.org.id }];
                return [];
            }
        })
    })
});

export const {
    useGetAlertPacksQuery,
    useGetAlertPackQuery,
    useCreateAlertPackMutation,
    useUpdateAlertPackMutation,
    useDeleteAlertPackMutation,
    useGetAlertPackOverrideQuery,
    useSetAlertPackOverrideMutation,
    useGetAlertPackTypesQuery
} = alertPackApi;
