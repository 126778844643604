import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { t, Trans } from "@lingui/macro";
import { useReactFlow } from "reactflow";
import { Form } from "react-final-form";
import { Icon, Button, Modal, Divider, Segment } from "semantic-ui-react";

import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteNodeMutation } from "modules/hierarchy/hierarchyService";
import { remapForReactFlow } from "modules/hierarchy/utils";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const DeleteNode = (props) => {
    const {
        openModal,
        setOpenModal,
        i18n,
        node: current_node,
        rangeTime: { start, end },
        org,
        setMenu
    } = props;

    const disabled = false;

    const { setNodes, setEdges } = useReactFlow();
    const [removeNode, remove] = useDeleteNodeMutation();

    useEffect(() => {
        if (remove.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (remove.isSuccess) {
            toast.success(i18n._(t`successful remove node`), toast_options);
            setOpenModal(false);
        }
        if (remove.isError) {
            let error = i18n._(t`cannot remove node`);
            if (remove.error?.data && !_.includes(remove.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={remove.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [remove, i18n, setOpenModal]);

    const onSubmitForm = async (formData) => {
        const action = await removeNode({
            org: org.current,
            node_id: formData.id,
            diagram_id: current_node.diagram,
            start: start.format("YYYY-MM-DD"),
            end: end.format("YYYY-MM-DD")
        });
        const error = _.get(action, "error", null);
        if (!error) {
            const { deleted_nodes, updated_nodes } = action.data;

            const nodes_edges_del = _.reduce(
                deleted_nodes,
                (res, node) => {
                    const { node: remapNode, edge } = remapForReactFlow(node);
                    if (edge) {
                        res.edges.push(edge);
                    }
                    res.nodes.push(remapNode);

                    return res;
                },
                { nodes: [], edges: [] }
            );
            const nodes_edges_upt = _.reduce(
                updated_nodes,
                (res, node) => {
                    const { node: remapNode, edge } = remapForReactFlow(node);
                    if (edge) {
                        res.edges.push(edge);
                    }
                    res.nodes.push(remapNode);

                    return res;
                },
                { nodes: [], edges: [] }
            );
            await setNodes((nds) => {
                const deleted_nodes_ids = _.map(nodes_edges_del.nodes, (node) => node.id);
                const remaining_nodes = _.filter(nds, (node) => {
                    return !_.includes(deleted_nodes_ids, node.id);
                });
                _.each(nodes_edges_upt.nodes, (node) => {
                    const old_node_index = _.findIndex(remaining_nodes, { id: node.id });
                    if (old_node_index !== -1) {
                        remaining_nodes[old_node_index] = {
                            ...remaining_nodes[old_node_index],
                            type: node.type,
                            data: {
                                ...remaining_nodes[old_node_index].data,
                                node_db: node.data.node_db
                            },
                            selected: false
                        };
                    }
                });
                return remaining_nodes;
            });
            await setEdges((eds) => {
                const deleted_edges_ids = _.map(nodes_edges_del.edges, (edge) => edge.id);
                const remaining_edges = _.filter(eds, (edge) => {
                    return !_.includes(deleted_edges_ids, edge.id);
                });
                return remaining_edges;
            });

            await setOpenModal(false);
            await setMenu(null);
        }
    };

    const initialValues = useMemo(() => {
        return current_node;
    }, [current_node]);

    return (
        <Form
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            render={({ handleSubmit }) => {
                return (
                    <Modal
                        open={openModal}
                        centered={false}
                        closeOnDimmerClick
                        closeOnEscape
                        onClose={(e) => {
                            setOpenModal(false);
                        }}
                    >
                        <Modal.Header>
                            <Trans>Delete node</Trans>
                        </Modal.Header>
                        <Modal.Content>
                            <Trans>want delete node {initialValues.name}</Trans>
                            <Modal.Actions>
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Divider />
                                    <Segment attached basic textAlign="right">
                                        <Button
                                            type="button"
                                            negative
                                            icon
                                            labelPosition="left"
                                            onClick={(e) => {
                                                setOpenModal(false);
                                            }}
                                        >
                                            <Icon name="arrow left" />
                                            <Trans>cancel</Trans>
                                        </Button>
                                        {!disabled && (
                                            <Button positive icon labelPosition="right" type="submit">
                                                <Icon name="trash" inverted />
                                                <Trans>validate</Trans>
                                            </Button>
                                        )}
                                    </Segment>
                                </form>
                            </Modal.Actions>
                        </Modal.Content>
                    </Modal>
                );
            }}
        />
    );
};

export default React.memo(DeleteNode);
