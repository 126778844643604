import React from "react";
import { useSelector } from "react-redux";
import { Card, Icon } from "semantic-ui-react";

const RenderMenuItem = ({ item }) => {
    const theme = useSelector((state) => state.common.theme);
    const { icon, color, title, flipped } = item;
    if (theme === "old") {
        return (
            <Card style={{ backgroundColor: "rgba(0, 0, 0, 0)", boxShadow: "none" }}>
                <Card.Content textAlign="center" style={{ padding: 5, border: 0 }}>
                    <Icon name={icon} circular color={color} inverted size="huge" flipped={flipped} />
                </Card.Content>
                <Card.Content textAlign="center" style={{ padding: 5, border: 0 }}>
                    <Card.Header>{title}</Card.Header>
                </Card.Content>
            </Card>
        );
    }
    return (
        <div className="pwaSchaefflerCard">
            <div className="pwaSchaefflerCard__block"></div>
            <div>
                <div>
                    <div className="pwaSchaefflerCard__header-content">
                        <Icon
                            fitted
                            name={item.icon}
                            style={{ color: "var(--foundational-icon-inactive)" }}
                            inverted
                            size="huge"
                            flipped={item.flipped}
                        />
                    </div>
                </div>
                <div className="pwaSchaefflerCard__footer">
                    <h4 className="pwaSchaefflerCard__footer-title">{item.title}</h4>
                </div>
            </div>
        </div>
    );
};

export default React.memo(RenderMenuItem);
