import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";

const initialState = {
    filter: {
        siteFilter: [],
        packTypeFilter: []
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    }
};

const alertPackSlice = createSlice({
    name: "alertpack",
    initialState,
    reducers: {
        setSiteFilter: (state, action) => {
            state.filter.siteFilter = action.payload;
        },
        setPackTypeFilter: (state, action) => {
            state.filter.packTypeFilter = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.filter = initialState.filter;
            state.pagination = initialState.pagination;
        }
    },
    extraReducers(builder) {
        builder.addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
            return initialState;
        });
    }
});

export const { setSiteFilter, setPackTypeFilter, setPage, setItemsPerPage, setStickPage, resetFilterWithPage } = alertPackSlice.actions;

export default alertPackSlice.reducer;
