import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { toast } from "react-toastify";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Button, Icon, Grid, Divider, Segment, Header, Container } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";
import { reparseNumber } from "modules/common/utils";
import { api_frequency_list, apiImportTypes } from "../utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import { useGetSitesQuery } from "modules/site/siteService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetApiImportsQuery, useGetApiImportQuery, useCreateApiImportMutation, useUpdateApiImportMutation } from "../ApiImportService";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import ConfigApiDetailForm from "./apiForms/ConfigApiDetailForm";
import ConfigApiFrequencyForm from "./apiForms/ConfigApiFrequencyForm";
import ConfigApiParamsForm from "./apiForms/ConfigApiParamsForm";
import ConfigApiFileFieldsForm from "./apiForms/ConfigApiFileFieldsForm";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const ApiImport = () => {
    const { mode, id_file } = useParams();
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    const theme = useSelector((state) => state.common.theme);
    const user_rights = auth.rights;

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const apiImports = useGetApiImportsQuery({ org: org.current }, { skip: !org.current });
    const apiImport = useGetApiImportQuery({ org: org.current, id: id_file }, { skip: !org.current || id_file === undefined });

    let err_list = [sites.isError, zones.isError, apiImports.isError];
    let status_list = [sites.isSuccess, zones.isSuccess, apiImports.isSuccess];

    const default_first_site = useMemo(() => {
        if (sites.isSuccess) {
            return _.size(sites.data) === 1 ? sites.data[0].id : null;
        }
        return null;
    }, [sites]);

    const default_first_zone = useMemo(() => {
        if (zones.isSuccess && default_first_site) {
            return _.chain(zones.data)
                .filter((zone) => {
                    return zone.site_id === default_first_site;
                })
                .thru((data) => {
                    if (_.size(data) === 1) {
                        return data[0].id;
                    } else {
                        return null;
                    }
                })
                .value();
        }
        return null;
    }, [zones, default_first_site]);

    const initialValues = useMemo(() => {
        const defaultValues = {
            is_active: true,
            import_type: 0,
            name: "",
            description: "",
            endpoint: "",
            frequency: 0,
            dayofmonth: null,
            dayofweek: null,
            hour: null,
            minute: null,
            depth: "",
            parameters: {
                api_key: "",
                organization_id: null,
                client_ref: ""
            },
            new_fields:
                id_file === undefined ? [{ name: "", site: default_first_site, zone: default_first_zone, dataflowspec: 2, measurementtype: 15 }] : []
        };
        return { ...defaultValues, ...apiImport.data };
    }, [default_first_site, default_first_zone, id_file, apiImport.data]);

    const can_change = notification.srv_status.db_status === "rw" && _.includes(["add", "change"], mode);

    const [createApiImport, create] = useCreateApiImportMutation();
    const [updateApiImportSource, update] = useUpdateApiImportMutation();

    // Create Api import
    useEffect(() => {
        if (create.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`successful create Api import`), toast_options);
            history_app.push(`/imports`);
        }
        if (create.isError) {
            let error = i18n._(t`cannot create Api import`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [create]);

    // Update Api import
    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update Api source`), toast_options);
            history_app.push(`/imports`);
        }
        if (update.isError) {
            let error = i18n._(t`cannot update Api source`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    const submitForm = async (data) => {
        const formData = {
            ...data,
            dayofmonth: _.includes([0, 1, 3, 4], data.frequency) ? null : reparseNumber(data.dayofmonth),
            dayofweek: _.includes([0, 2, 3, 4], data.frequency) ? null : reparseNumber(data.dayofweek),
            hour: data.frequency === 3 ? 0 : reparseNumber(data.hour),
            minute: reparseNumber(data.minute),
            api_fields: id_file === undefined ? data.new_fields : data.api_fields,
            new_fields: id_file === undefined ? [] : data.new_fields
        };

        if (user_rights.is_admin && notification.srv_status.db_status === "rw") {
            if (id_file) {
                await updateApiImportSource({ org: org.current, id: id_file, data: formData });
            } else {
                await createApiImport({ org: org.current, data: formData });
            }
        }
    };

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target={`/imports`} />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {id_file === undefined && <Trans>Add api source</Trans>}
                            {id_file !== undefined && (
                                <>
                                    {can_change && <Trans>Update API source</Trans>}
                                    {!can_change && <Trans>View API source</Trans>}
                                </>
                            )}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                <Grid.Row>
                    {(() => {
                        if (_.some(err_list)) {
                            return (
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            );
                        } else if (_.every(status_list)) {
                            // const can_actions = user_rights.is_admin;
                            const can_actions = user_rights.is_admin || apiImport.data?.owner === auth.user.user_id;

                            return (
                                <Grid.Column width={15}>
                                    <Container>
                                        <Form
                                            onSubmit={submitForm}
                                            mutators={{ ...arrayMutators }}
                                            initialValues={initialValues}
                                            render={({ handleSubmit, submitting, pristine, invalid, values, form }) => {
                                                return (
                                                    <form onSubmit={handleSubmit} className="ui form">
                                                        <Grid centered stackable>
                                                            <Grid.Row stretched>
                                                                <ConfigApiDetailForm
                                                                    can_change={can_change}
                                                                    id_file={id_file}
                                                                    apiImports={apiImports}
                                                                />
                                                            </Grid.Row>

                                                            <Grid.Row>
                                                                <Grid.Column width={8}>
                                                                    <ConfigApiParamsForm
                                                                        can_change={can_change}
                                                                        id_file={id_file}
                                                                        apiImportTypes={apiImportTypes}
                                                                        form={form}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={8}>
                                                                    <ConfigApiFrequencyForm
                                                                        form={form}
                                                                        values={values}
                                                                        api_frequency_list={api_frequency_list}
                                                                        can_change={can_change}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>

                                                            <Grid.Row stretched>
                                                                <ConfigApiFileFieldsForm
                                                                    apiImport={apiImport}
                                                                    form={form}
                                                                    zones={zones}
                                                                    sites={sites}
                                                                    id_file={id_file}
                                                                    can_change={can_change}
                                                                    default_first_zone={default_first_zone}
                                                                    default_first_site={default_first_site}
                                                                    theme={theme}
                                                                />
                                                            </Grid.Row>

                                                            {can_actions && can_change && (
                                                                <Grid.Row>
                                                                    <Grid.Column width={16} textAlign="right">
                                                                        <>
                                                                            <Divider />
                                                                            <Button
                                                                                type="submit"
                                                                                positive
                                                                                icon
                                                                                labelPosition="right"
                                                                                disabled={submitting || pristine || invalid}
                                                                            >
                                                                                <Icon name="check" />
                                                                                <Trans>validate</Trans>
                                                                            </Button>
                                                                        </>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            )}
                                                        </Grid>
                                                    </form>
                                                );
                                            }}
                                        />
                                    </Container>
                                </Grid.Column>
                            );
                        } else {
                            return (
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`loading data`)}
                                        level="info"
                                        iconName="circle notched"
                                        isLoading={true}
                                        attached={false}
                                    />
                                </Grid.Column>
                            );
                        }
                    })()}
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default ApiImport;
