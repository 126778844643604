import React from "react";
import _ from "lodash";
import { isPlainObject } from "../utils";

const RequestErrorRender = ({ errors }) => {
    const renderContent = (content) => {
        if (isPlainObject(content)) {
            return _.map(content, (item, key) => {
                return <div key={key}>{renderContent(item)}</div>;
            });
        } else if (Array.isArray(content)) {
            return _.map(content, (item, key) => {
                return <div key={key}>{renderContent(item)}</div>;
            });
        } else {
            return <div>{content}</div>;
        }
    };

    return <div>{renderContent(errors)}</div>;
};

export default RequestErrorRender;
