import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";

const initialState = {
    pagination: {
        page: 1,
        itemsPerPage: 10
    }
};

const loginHistorySlice = createSlice({
    name: "loginHistory",
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.pagination = initialState.pagination;
        }
    },
    extraReducers(builder) {
        builder.addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
            return initialState;
        });
    }
});

export const { setPage, setItemsPerPage, resetFilterWithPage } = loginHistorySlice.actions;

export default loginHistorySlice.reducer;
