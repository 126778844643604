import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t } from "@lingui/macro";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import moment from "moment";
import { Crosshair, FlexibleXYPlot, HorizontalGridLines, LineMarkSeries, XAxis, YAxis } from "react-vis";
import { Button, Card, Grid, Popup, Segment } from "semantic-ui-react";

import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import GenerateCsv from "modules/common/components/GenerateCsv";
import MessageDisplay from "modules/common/components/MessageDisplay";

const GraphicSummary = (props) => {
    const current_lng = useSelector((state) => state.i18n.current);
    const graphicRef = useRef(null);
    const [crosshairValues, setCrosshairValues] = useState([]);
    const series = props.data;
    // Use data in series instead of time panel to define xLine Axis
    // API always return data on each time
    const firstX = _.chain(series).head().get("data").head().get("x").value();
    const lastX = _.chain(series).head().get("data").last().get("x").value();
    const xDomain = firstX && lastX ? [firstX, lastX] : null;

    const unit = _.chain(series).head().get("unit").value();
    const emptyData = _.chain(series)
        .map((serie) =>
            _.chain(serie)
                .get("data")
                .filter((record) => record.y !== null)
                .isEmpty(serie.data)
                .value()
        )
        .every()
        .value();

    if (emptyData) {
        return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />;
    }

    const tooltip = () => {
        return (
            <Crosshair values={crosshairValues}>
                <Card className="pwaOverviewTooltip" style={{ width: moment(crosshairValues[0].x).locale(current_lng).format("LLL").length * 8 }}>
                    <Card.Content>
                        <Card.Header textAlign="center">{`${moment(crosshairValues[0].x).locale(current_lng).format("L")}`}</Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Card.Description>
                            <Grid className="pwaOverviewTooltip">
                                {_.map(_.reverse(crosshairValues), (v, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <Grid.Column width={1} />
                                            <Grid.Column width={5} textAlign="left">{`${i18n._(v.type)}:`}</Grid.Column>
                                            <Grid.Column width={9} textAlign="right">
                                                {v.y !== null ? `${i18n.number(v.y, { maximumFractionDigits: 2 })} ${v.unit || "-"}` : "-"}
                                            </Grid.Column>
                                            <Grid.Column width={1} />
                                        </React.Fragment>
                                    );
                                })}
                            </Grid>
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Crosshair>
        );
    };

    const onNearestX = (value, { index }) => {
        const cross_val = _.map(series, (serie, key) => {
            return serie.data[index];
        });
        setCrosshairValues(cross_val);
    };

    const handleSaveImage = async () => {
        if (!graphicRef.current) return;
        try {
            const canvas = await html2canvas(graphicRef.current);
            // Convertir en image
            const imageData = canvas.toDataURL("image/png");

            // Création d'un lien de téléchargement
            const a = document.createElement("a");
            a.href = imageData;
            a.download = `${i18n._(t`long term trend`)}.png`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            toast.error(i18n._(t`Image generation has failed`), toast_options_err);
        }
    };

    const RenderSaveImage = () => {
        return (
            <Popup
                trigger={
                    <Button
                        onClick={async (e) => {
                            await toast.info(i18n._(t`Preparing the image to save`), toast_options);
                            setTimeout(() => {
                                //Add delay between toast message && image generation
                                handleSaveImage();
                            }, 1000);
                        }}
                        icon="file image outline"
                    />
                }
            >
                <Popup.Content>{i18n._(t`Save as image`)}</Popup.Content>
            </Popup>
        );
    };

    return (
        <>
            <Segment attached textAlign="right">
                <GenerateCsv series={series} unit={unit} filename={props.csvName || "export"} />
                <RenderSaveImage />
            </Segment>
            <Segment attached>
                <div ref={graphicRef}>
                    <FlexibleXYPlot
                        xType="time"
                        xDomain={xDomain}
                        height={400}
                        onMouseLeave={() => {
                            setCrosshairValues([]);
                        }}
                        margin={{ left: 60, right: 10, top: 10, bottom: 50 }}
                    >
                        <HorizontalGridLines />
                        <XAxis
                            title={i18n._(t`time`)}
                            tickLabelAngle={-20}
                            tickFormat={(value, index, scale, tickTotal) => {
                                const default_fmt = multiI18nFormat(value, current_lng);
                                if (default_fmt.indexOf("06:00") >= 0 || default_fmt.indexOf("12:00") >= 0 || default_fmt.indexOf("18:00") >= 0)
                                    return "";
                                return default_fmt;
                            }}
                        />
                        <YAxis
                            title={unit}
                            tickFormat={(value, index, scale, tickTotal) => {
                                const format = scale.tickFormat(tickTotal)(value);
                                if (typeof value === "number") {
                                    return i18n.number(value, { maximumFractionDigits: 1 });
                                }
                                return format;
                            }}
                        />
                        {_.chain(series)
                            .filter((item) => item.disabled === false)
                            .map((serie, idx) => {
                                return (
                                    <LineMarkSeries
                                        key={idx}
                                        color={serie.color}
                                        fill={serie.color}
                                        size={3}
                                        getNull={(d) => d.y !== null}
                                        curve={"curveMonotoneX"}
                                        data={serie.data}
                                        onNearestX={serie.title === "max" ? onNearestX : null}
                                    />
                                );
                            })
                            .value()}
                        {_.size(crosshairValues) > 0 ? tooltip() : null}
                    </FlexibleXYPlot>
                </div>
            </Segment>
        </>
    );
};

export default React.memo(GraphicSummary);
