import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-final-form";
import { toast } from "react-toastify";
import _ from "lodash";
import { t } from "@lingui/macro";

import { Button, Divider, Grid, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";

import { checkRights } from "modules/auth/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { usePatchEquipmentMutation } from "modules/equipment/equipmentService";

import EquipmentMainFields from "./EquipmentMainFields";
import RequestErrorRender from "modules/common/components/RequestErrorRender";
import UnsavedWrapper from "modules/common/components/UnsavedWrapper";
import history_app from "history_app";

const EquipmentMainForm = (props) => {
    const [nextLocation, setNextLocation] = useState("");
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    useSelector((state) => state.i18n.current); //force refresh for lng

    const [patchEquipment, patch] = usePatchEquipmentMutation();
    useEffect(() => {
        if (patch.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (patch.isSuccess) {
            toast.success(i18n._(t`successful update equipment`), toast_options);
            if (nextLocation) {
                setTimeout(() => {
                    history_app.push(nextLocation);
                }, 1000);
            }
        }
        if (patch.isError) {
            let error = i18n._(t`cannot update equipment`);
            if (patch.error?.data && !_.includes(patch.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={patch.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [patch, nextLocation]);

    const initialValues = useMemo(() => {
        return {
            equipment: props.equipment
        };
    }, [props.equipment]);

    const can_change = useMemo(() => {
        const rights = checkRights(props?.equipment, auth.rights);
        // TODO CHANGE for add Equipment. Site not define. Need another way to get right.
        // Now: validate btn enable for add. Backend check rights
        return _.includes(rights, "can_change");
    }, [auth.rights, props.equipment]);

    const submitForm = (formData) => {
        patchEquipment({ org: org.current, equipment_id: props?.equipment?.id, data: _.omit(formData?.equipment, ["image"]) });
    };

    return (
        <Form
            onSubmit={submitForm}
            initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, invalid, values, errors }) => {
                return (
                    <UnsavedWrapper
                        unsaved={!pristine}
                        invalid={invalid}
                        action={async (location) => {
                            await setNextLocation(location);
                            await form.submit();
                        }}
                    >
                        <form onSubmit={handleSubmit} className="ui form">
                            <Grid centered>
                                <Grid.Row>
                                    <Grid.Column mobile={16} tablet={14} computer={14}>
                                        <EquipmentMainFields form={form} values={values} can_change={can_change} />
                                        {(can_change || (props?.equipment?.id === undefined && _.size(auth.rights?.sites_rw) > 0)) &&
                                            notification.srv_status.db_status === "rw" && (
                                                <>
                                                    <Divider />
                                                    <Segment basic>
                                                        <Button
                                                            type="submit"
                                                            positive
                                                            content={i18n._(t`validate`)}
                                                            disabled={submitting || pristine || invalid}
                                                        />
                                                    </Segment>
                                                </>
                                            )}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </form>
                    </UnsavedWrapper>
                );
            }}
        />
    );
};

export default React.memo(EquipmentMainForm);
