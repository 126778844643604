import React, { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import _ from "lodash";
import { Dropdown, Header, Icon, Segment } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKeyboard } from "@fortawesome/free-solid-svg-icons";

import { WidgetType } from "../utils";
import { ResponsiveBreakpoingWidth } from "./WidgetsGrid";

import WidgetsCreateUpdatePanel from "./WidgetsCreateUpdatePanel";
import WidgetDelete from "./WidgetDelete";
import WidgetRenderer from "./WidgetRenderer";

const WidgetItem = React.forwardRef((content, ref) => {
    const [isHovered, setIsHovered] = useState(false);
    const [displayAsModal, setDisplayAsModal] = useState(false);
    const [openModalWidgetForm, setOpenModalWidgetForm] = useState(false);
    // Needed to extract style with 'width' && 'height'
    const { style, className, onMouseDown, onMouseUp, onTouchEnd, children, ...props } = content;
    const { widget, current_dashboard, org, disabled_dashboard } = props;

    const menuHeight = 40; // Remove Menu's height for widget's height
    const fitWidth = parseFloat(style.width) + 2;
    const fitHeight = parseFloat(style.height) - menuHeight + 2;

    let pollingInterval = 0;
    if (
        _.includes(
            [
                WidgetType.GAUGE_RATIO[0],
                WidgetType.GAUGE_CONSUMPTION[0],
                WidgetType.GAUGE_CONSUMPTION_BREAKDOWN[0],
                WidgetType.SPARKLINE_CONSUMPTION[0],
                WidgetType.SPARKLINE_CONSUMPTIONS[0]
            ],
            widget.widget_type
        )
    ) {
        if (widget.historic === 8 || widget.operator === 0) {
            pollingInterval = 1000 * 60 * 10; //Polling request each 10 min
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "v" && isHovered) {
                setDisplayAsModal(true);
            }
            if (event.key === "v" && displayAsModal) {
                setDisplayAsModal(false);
                setIsHovered(false);
            }
        };
        isHovered && document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [displayAsModal, isHovered]);

    return (
        <div style={{ ...style }} className={className} ref={ref} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onTouchEnd={onTouchEnd}>
            {openModalWidgetForm && (
                <WidgetsCreateUpdatePanel
                    current_dashboard={current_dashboard}
                    org={org.current}
                    disabled={disabled_dashboard}
                    widget={widget}
                    setIsHovered={setIsHovered}
                    setOpenModalWidgetForm={setOpenModalWidgetForm}
                />
            )}
            <div
                id={widget.id}
                style={{ width: style.width, height: style.height, padding: 0, margin: 0 }}
                onMouseOver={() => {
                    setIsHovered(true);
                }}
                onMouseOut={() => {
                    setIsHovered(false);
                }}
            >
                <div>
                    <Header
                        as="h4"
                        attached="top"
                        className="grid-handle-drag"
                        style={{
                            height: "39px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 0
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                whiteSpace: "pre",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                cursor: window.innerWidth >= ResponsiveBreakpoingWidth ? "move" : "default",
                                padding: "1rem"
                            }}
                        >
                            {widget?.name ?? "-"}
                        </div>
                        <div className="grid-cancel-drag" style={{ padding: "1rem" }}>
                            <Dropdown icon="ellipsis vertical" direction="left" className="pwaWidgetItemMenuDropdown no-print">
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setDisplayAsModal(true)}>
                                        <Icon name="eye" />
                                        <Trans>View</Trans> 
                                        <span className="pwaWidgetItemView">
                                            (<FontAwesomeIcon icon={faKeyboard} />
                                            &nbsp;v)
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            setOpenModalWidgetForm(true);
                                        }}
                                    >
                                        {!disabled_dashboard && (
                                            <>
                                                <Icon name="edit" />
                                                <Trans>Edit widget setup</Trans>
                                            </>
                                        )}
                                        {disabled_dashboard && (
                                            <>
                                                <Icon name="eye" />
                                                <Trans>View widget setup</Trans>
                                            </>
                                        )}
                                    </Dropdown.Item>
                                    {!disabled_dashboard && <WidgetDelete item={widget} current_dashboard={current_dashboard} />}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Header>
                    <Segment id={`container_${widget.id}`} attached="bottom" style={{ padding: 0, width: fitWidth, height: fitHeight }}>
                        <WidgetRenderer
                            widget={widget}
                            width={fitWidth}
                            height={fitHeight}
                            displayAsModal={displayAsModal}
                            setDisplayAsModal={setDisplayAsModal}
                            setIsHovered={setIsHovered}
                            pollingInterval={pollingInterval}
                            {...props}
                        />
                    </Segment>
                </div>
            </div>
            <div className="no-print">
                {children} {/* Make sure to include children to add resizable handle */}
            </div>
        </div>
    );
});

export default React.memo(WidgetItem);
