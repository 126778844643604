import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { toast } from "react-toastify";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Button, Divider, Grid, Icon, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";
import { checkRights } from "modules/auth/utils";
import { getMaxDecimalPlaces, reparseNumber, validateNumber } from "modules/common/utils";
import { useCreateEfficiencyMutation, useUpdateEfficiencyMutation } from "modules/equipment/equipmentService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import { DropDownAdapter, InputAdapter } from "modules/common/components/form";
import RequestErrorRender from "modules/common/components/RequestErrorRender";
import history_app from "history_app";

const EfficiencyForm = (props) => {
    const { setOpen, equipment, measurements, measurementsObject, efficiency } = props;
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    useSelector((state) => state.i18n.current); //force refresh for lng

    const [createEfficiency, create] = useCreateEfficiencyMutation();
    const [updateEfficiency, update] = useUpdateEfficiencyMutation();

    useEffect(() => {
        if (create.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`successful create efficiency`), toast_options);
            if (setOpen) {
                history_app.push(`/equipments/${equipment?.id}#efficiency`);
                setOpen(false);
            }
        }
        if (create.isError) {
            let error = i18n._(t`cannot create efficiency`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [create, setOpen, equipment]);

    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update efficiency`), toast_options);
        }
        if (update.isError) {
            let error = i18n._(t`cannot update efficiency`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    const initialValues = useMemo(() => {
        const default_efficiency = {
            name: "",
            equipment: equipment?.id ?? null,
            numerator_factor: 1,
            unit: "",
            numerator: null,
            denominator: null
        };
        return {
            ...default_efficiency,
            ...efficiency
        };
    }, [equipment, efficiency]);

    const can_change = useMemo(() => {
        const rights = checkRights(props?.equipment, auth.rights);
        // TODO CHANGE for add Equipment. Site not define. Need another way to get right.
        // Now: validate btn enable for add. Backend check rights
        return _.includes(rights, "can_change");
    }, [auth.rights, props.equipment]);

    const measurementOptions = _.map(measurements, (measure, m_id) => {
        const option = measurementsObject?.[m_id];
        return option;
    });

    const submitForm = (formData) => {
        const data = _.mapValues(formData, (field, key) => {
            if (_.includes(["numerator", "denominator", "factor"], key)) {
                return reparseNumber(field);
            }
            return field;
        });
        if (efficiency) {
            updateEfficiency({ org: org.current, data });
        } else {
            createEfficiency({ org: org.current, data });
        }
    };

    return (
        <Form
            onSubmit={submitForm}
            initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, invalid, values, errors }) => {
                return (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid centered verticalAlign="top">
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Field
                                        name="name"
                                        placeholder={i18n._(t`Name of efficiency`)}
                                        label={i18n._(t`name`)}
                                        isRequired={true}
                                        component={InputAdapter}
                                        parse={identityNull}
                                        validate={(value) => {
                                            if (!value) {
                                                return <Trans>Required field</Trans>;
                                            }
                                            return undefined;
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                    <Field
                                        name="numerator"
                                        label={i18n._(t`Numerator`)}
                                        isRequired={true}
                                        placeholder={i18n._(t`Select 1 measure`)}
                                        options={measurementOptions}
                                        component={DropDownAdapter}
                                        validate={(value) => {
                                            if (!value) return <Trans>Required field</Trans>;
                                            return undefined;
                                        }}
                                        upward={true}
                                    />
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                    <Field
                                        name="denominator"
                                        label={i18n._(t`denominator`)}
                                        isRequired={true}
                                        placeholder={i18n._(t`Select 1 measure`)}
                                        options={measurementOptions}
                                        component={DropDownAdapter}
                                        validate={(value) => {
                                            if (!value) return <Trans>Required field</Trans>;
                                            return undefined;
                                        }}
                                        upward={true}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                    <Field
                                        name="unit"
                                        placeholder={i18n._(t`Unit of efficiency`)}
                                        label={i18n._(t`Unit`)}
                                        isRequired={true}
                                        component={InputAdapter}
                                        parse={identityNull}
                                        validate={(value) => {
                                            if (!value) {
                                                return <Trans>Required field</Trans>;
                                            }
                                            return undefined;
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                    <Field
                                        name={`numerator_factor`}
                                        placeholder={i18n._(t`enter factor for efficiency`)}
                                        label={i18n._(t`factor`)}
                                        component={InputAdapter}
                                        helperText={`todo text`}
                                        parse={identityNull}
                                        inputMode="decimal"
                                        isRequired={true}
                                        validate={(value) => {
                                            const numValidate = validateNumber(value, i18n, false, false);
                                            if (numValidate !== undefined) {
                                                return numValidate;
                                            }

                                            const val = parseFloat(value);
                                            const max_dec = 5;
                                            const max_digit = 10;
                                            const max_val = Math.pow(10, max_digit - max_dec);
                                            if (_.isFinite(val)) {
                                                if (val > max_val) {
                                                    return <Trans>The factor value must be less than {max_val}</Trans>;
                                                }
                                                const decPart = getMaxDecimalPlaces(value);
                                                if (decPart > max_dec) {
                                                    return <Trans>Make sure there are no more than {max_dec} decimal places</Trans>;
                                                }
                                                if (parseInt(val).toString().length > max_digit - max_dec) {
                                                    return (
                                                        <Trans>Make sure you have only {max_digit - max_dec} digits before the decimal part</Trans>
                                                    );
                                                }
                                                return undefined;
                                            }
                                            return <Trans>invalid factor value</Trans>;
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {can_change && notification.srv_status.db_status === "rw" && (
                            <>
                                <Divider />
                                <Segment basic textAlign="right">
                                    {setOpen && (
                                        <Button type="button" negative icon labelPosition="left" onClick={(e) => setOpen && setOpen(false)}>
                                            <Icon name="arrow left" />
                                            <Trans>cancel</Trans>
                                        </Button>
                                    )}
                                    <Button type="submit" positive content={i18n._(t`validate`)} disabled={submitting || pristine || invalid} />
                                </Segment>
                            </>
                        )}
                    </form>
                );
            }}
        />
    );
};

export default React.memo(EfficiencyForm);
