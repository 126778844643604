import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { jwtDecode as jwt_decode } from "jwt-decode";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import { Form, Field } from "react-final-form";
import { toast } from "react-toastify";
import { Trans, t } from "@lingui/macro";

import store from "store";
import i18n from "modules/i18n/i18nConfig";
import { otpVerified, refreshToken } from "../authSlice";
import { checkToken, resetTokenTimeout, tokenTimeout } from "../authMiddleware";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useOtpCheckMutation } from "modules/user/userService";
import history_app from "history_app";

import { InputAdapter } from "modules/common/components/form";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const OtpValidate = (props) => {
    const dispatch = useDispatch();
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const [checkOtp, check] = useOtpCheckMutation();

    useEffect(() => {
        if (auth.isVerified === true) {
            history_app.push("/");
        }
    }, [auth]);

    useEffect(() => {
        if (check.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (check.isSuccess) {
            toast.success(i18n._(t`successful check otp`), toast_options);
            localStorage.setItem("accessToken", check.data.access);
            dispatch(refreshToken(check.data.access));
            dispatch(otpVerified(true));
            clearTimeout(tokenTimeout);
            resetTokenTimeout();
            const decoded_token = jwt_decode(check.data.access);
            checkToken(store, decoded_token);
            history_app.push("/");
        }
        if (check.isError) {
            let error = i18n._(t`cannot check otp`);
            if (check.error?.data && !_.includes(check.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={check.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [dispatch, check]);

    const submitForm = async (formData) => {
        checkOtp({ org: org.current, user_id: auth.user.user_id, data: formData });
    };

    return (
        <Segment attached>
            <Grid textAlign="center" verticalAlign="middle" id="pwaLogin">
                <Grid.Column>
                    <Form
                        onSubmit={submitForm}
                        render={({ handleSubmit, submitting, pristine, invalid }) => {
                            return (
                                <form className="ui large form error" onSubmit={handleSubmit}>
                                    <Header attached="top" block>
                                        <Trans>Two-Factor authentication</Trans>
                                    </Header>
                                    <Segment padded attached>
                                        <Field
                                            name="token"
                                            placeholder={i18n._(t`Authentication code`)}
                                            onKeyDown={(e) => e.keyCode === 69 && e.preventDefault()}
                                            type="text"
                                            component={InputAdapter}
                                            isRequired={true}
                                            validate={(value) => {
                                                if (!/^[0-9]{6}$/.test(value)) {
                                                    return <Trans>invalid token</Trans>;
                                                }
                                                return undefined;
                                            }}
                                        />
                                        <Button
                                            type="submit"
                                            color="olive"
                                            fluid
                                            content={i18n._(t`Authenticate`)}
                                            disabled={submitting || pristine || invalid}
                                        />
                                    </Segment>
                                </form>
                            );
                        }}
                    />
                </Grid.Column>
            </Grid>
        </Segment>
    );
};

export default React.memo(OtpValidate);
