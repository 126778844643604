import React, { useMemo } from "react";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { Dropdown, Grid, Icon, Input, Label, Popup } from "semantic-ui-react";

import { upsert } from "./TreeItem";
import { Media } from "App";

const operatorList = [
    { key: ">", value: ">", text: ">" },
    { key: "<", value: "<", text: "<" }
];

const EfficiencyItem = (props) => {
    const { node, tree, updateTree, alert_type, form, efficienciesObject } = props;
    const efficiency = _.get(efficienciesObject, node?.efficiency);

    const onChangeNode = (data, field) => {
        const new_tree = [...tree]; //copy of tree for update
        const current_node = { ...node, [field]: data.value };
        upsert(new_tree, current_node);
        updateTree(new_tree);
    };

    const efficienciesOptions = useMemo(() => {
        const site_id = form.getFieldState("site")?.value ?? null;
        return _.reduce(
            efficienciesObject,
            (res, item) => {
                if (item.site === site_id) {
                    res.push(item);
                }
                return res;
            },
            []
        );
    }, [efficienciesObject, form]);

    return (
        <Grid verticalAlign="middle">
            <Grid.Column mobile={16} tablet={9} computer={9}>
                <Dropdown
                    fluid
                    selection
                    options={efficienciesOptions}
                    onChange={(e, data) => {
                        const new_tree = [...tree]; //copy of tree for update
                        const current_node = _.omit({ ...node, efficiency: data.value }, ["measure"]);
                        upsert(new_tree, current_node);
                        updateTree(new_tree);
                    }}
                    value={node.efficiency}
                    upward
                />
            </Grid.Column>
            <Grid.Column mobile={3} tablet={2} computer={2}>
                <Dropdown
                    fluid
                    selection
                    options={operatorList}
                    onChange={(e, data) => onChangeNode(data, "operator")}
                    value={node.operator}
                    upward
                />
            </Grid.Column>
            <Grid.Column mobile={13} tablet={4} computer={4}>
                <Input
                    fluid
                    inputMode="decimal"
                    onChange={(e, data) => onChangeNode(data, "threshold")}
                    value={node.threshold}
                    labelPosition={node?.efficiency !== null ? "right" : undefined}
                >
                    <input />
                    {node?.efficiency != null && (efficiency?.unit ?? null) !== null && <Label color="blue">{efficiency?.unit ?? "-"}</Label>}
                </Input>
            </Grid.Column>
            {alert_type === 2 && (
                <Grid.Column width={16}>
                    <Grid>
                        <Grid.Column mobile={16} tablet={11} computer={11}>
                            <Input
                                fluid
                                inputMode="decimal"
                                labelPosition="right"
                                onChange={(e, data) => onChangeNode(data, "w_depth")}
                                value={node.w_depth}
                            >
                                <Label basic>
                                    <Media lessThan="tablet">
                                        {(mediaClassNames, renderChildren) =>
                                            renderChildren && (
                                                <Popup
                                                    trigger={
                                                        <Icon.Group size="large">
                                                            <Icon name="clock outline" />
                                                            <Icon name="reply" corner="top right" />
                                                        </Icon.Group>
                                                    }
                                                >
                                                    <Popup.Content>
                                                        <Trans>Window depth</Trans>
                                                    </Popup.Content>
                                                </Popup>
                                            )
                                        }
                                    </Media>
                                    <Media greaterThanOrEqual="tablet">
                                        {(mediaClassNames, renderChildren) => renderChildren && <Trans>Window depth</Trans>}
                                    </Media>
                                </Label>
                                <input />
                                <Label color="blue">
                                    <Trans>min</Trans>
                                </Label>
                            </Input>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            )}
        </Grid>
    );
};

export default React.memo(EfficiencyItem);
