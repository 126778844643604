import React, { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Form, Field } from "react-final-form";
import { toast } from "react-toastify";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { Segment, Grid, Header, Icon, Divider, Button, Container, Accordion, Message } from "semantic-ui-react";

import { useGetCurrentOrganizationQuery, useUpdateOrganizationMutation } from "../organizationService";
import i18n from "modules/i18n/i18nConfig";
import { identity } from "modules/common/utils/form";

import { InputAdapter, DropDownAdapter } from "modules/common/components/form";
import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import SecurityPolicy from "./SecurityPolicy";
import ExternalApi from "./ExternalApi";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const Organization = () => {
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    const store_i18n = useSelector((state) => state.i18n);
    const organization = useGetCurrentOrganizationQuery({ org: org.current }, { skip: !org.current });
    const [activeIndex, setActiveIndex] = useState(-1); // show Accordion option
    const [noPolicyForm, setNoPolicyForm] = useState(true);
    const [closeFormWithOutSave, setCloseFormWithOutSave] = useState(false);

    const handleShowAccordion = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;

        // needed to check if the user wants to close the form without saving
        if (!noPolicyForm && newIndex === -1) {
            setCloseFormWithOutSave(true);
        } else {
            setActiveIndex(newIndex);
        }
    };

    const isAdmin = auth.rights?.is_admin ?? false;
    const can_change = notification.srv_status.db_status === "rw" && isAdmin;

    const [updateOrganization, update] = useUpdateOrganizationMutation();

    // Update Organization
    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`Successful update organization`), toast_options);
        }
        if (update.isError) {
            let error = i18n._(t`can't update organization`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    const initialValues = useMemo(() => {
        if (organization.isSuccess) {
            return organization.data;
        }
        return null;
    }, [organization]);

    const validate = (values) => {
        const errors = {};
        if (!values.full_name) {
            errors.full_name = i18n._(t`name is required`);
        } else if (values.full_name.toLowerCase() === "all") {
            errors.full_name = i18n._(t`name cannot be all`);
        }
        return errors;
    };

    const submitForm = async (formData) => {
        if (can_change) {
            const action = await updateOrganization({ org: org.current, data: formData });
            if (action.data) {
                const updated_org = {
                    ...action.data,
                    key: action.data.id,
                    text: action.data.full_name,
                    value: action.data.id
                };
                localStorage.setItem("currentOrg", JSON.stringify(updated_org));
            }
        }
    };

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target="/manage" />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Your organization</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                {organization.isError && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {organization.isFetching && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`loading data`)}
                                level="info"
                                iconName="circle notched"
                                isLoading={true}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {organization.isSuccess && (
                    <>
                        <Grid.Row>
                            <Grid.Column width={15}>
                                <Container>
                                    <Form
                                        onSubmit={submitForm}
                                        initialValues={initialValues}
                                        validate={validate}
                                        render={({ handleSubmit, submitting, pristine, invalid }) => {
                                            return (
                                                <form disabled onSubmit={handleSubmit} className="ui form">
                                                    <Grid verticalAlign="top">
                                                        <Grid.Column width={16}>
                                                            <Field
                                                                disabled={!can_change}
                                                                name="full_name"
                                                                placeholder={i18n._(t`enter name`)}
                                                                label={i18n._(t`name`)}
                                                                isRequired={true}
                                                                parse={identity}
                                                                component={InputAdapter}
                                                            />
                                                        </Grid.Column>
                                                        {can_change && (
                                                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                <Field
                                                                    helperText={i18n._(
                                                                        t`the external id is used to connect your organization to your own IT system`
                                                                    )}
                                                                    disabled={!can_change}
                                                                    name="external_id"
                                                                    placeholder={i18n._(t`enter external id`)}
                                                                    label={i18n._(t`external_id`)}
                                                                    parse={identity}
                                                                    component={InputAdapter}
                                                                />
                                                            </Grid.Column>
                                                        )}
                                                        <Grid.Column {...(can_change ? { mobile: 16, tablet: 8, computer: 8 } : { width: 16 })}>
                                                            <Field
                                                                disabled={!can_change}
                                                                name="language"
                                                                options={store_i18n.options}
                                                                label={i18n._(t`language`)}
                                                                parse={identity}
                                                                component={DropDownAdapter}
                                                            />
                                                        </Grid.Column>
                                                        {can_change && (
                                                            <>
                                                                <Grid.Column width={16}>
                                                                    <Divider />
                                                                </Grid.Column>
                                                                <Grid.Column width={16} floated="right" textAlign="right">
                                                                    <Button
                                                                        type="submit"
                                                                        positive
                                                                        icon
                                                                        labelPosition="right"
                                                                        disabled={submitting || pristine || invalid}
                                                                    >
                                                                        <Icon name="check" />
                                                                        <Trans>validate</Trans>
                                                                    </Button>
                                                                </Grid.Column>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </form>
                                            );
                                        }}
                                    />
                                </Container>
                            </Grid.Column>
                        </Grid.Row>
                        {isAdmin && ( //Only isAdmin can see policy and apiKey
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <Container>
                                        <Accordion fluid styled>
                                            <Accordion.Title
                                                active={activeIndex === 0}
                                                index={0}
                                                onClick={(e, titleProps) => handleShowAccordion(e, titleProps)}
                                            >
                                                <Trans>Security policy</Trans>
                                                {activeIndex === 0 ? <Icon name="caret up" size="small" /> : <Icon name="caret down" size="small" />}
                                            </Accordion.Title>

                                            {closeFormWithOutSave && !noPolicyForm && (
                                                <Message attached="bottom" warning>
                                                    <Message.Content>
                                                        <Icon size="big" name="warning sign" />
                                                        <Trans>
                                                            Your security policy management form has not been saved. If you close this section, you
                                                            will lose your data. Do you still want to continue?
                                                        </Trans>
                                                    </Message.Content>
                                                    <Message.Content>
                                                        <Button
                                                            negative
                                                            onClick={() => {
                                                                setNoPolicyForm(!noPolicyForm);
                                                                setCloseFormWithOutSave(!closeFormWithOutSave);
                                                                setActiveIndex(-1);
                                                            }}
                                                        >
                                                            <Trans>Close</Trans>
                                                        </Button>
                                                        <Button
                                                            positive
                                                            onClick={() => {
                                                                setCloseFormWithOutSave(!closeFormWithOutSave);
                                                            }}
                                                        >
                                                            <Trans>Continue</Trans>
                                                        </Button>
                                                    </Message.Content>
                                                </Message>
                                            )}

                                            <Accordion.Content active={activeIndex === 0}>
                                                {activeIndex === 0 && (
                                                    <SecurityPolicy
                                                        can_change={can_change}
                                                        active={activeIndex === 0}
                                                        org={org}
                                                        setNoPolicyForm={setNoPolicyForm}
                                                        noPolicyForm={noPolicyForm}
                                                    />
                                                )}
                                            </Accordion.Content>

                                            <Accordion.Title
                                                active={activeIndex === 1}
                                                index={1}
                                                onClick={(e, titleProps) => handleShowAccordion(e, titleProps)}
                                            >
                                                <Trans>External API</Trans>
                                                {activeIndex === 1 ? <Icon name="caret up" size="small" /> : <Icon name="caret down" size="small" />}
                                            </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 1}>
                                                {activeIndex === 1 && <ExternalApi can_change={can_change} active={activeIndex === 1} org={org} />}
                                            </Accordion.Content>
                                        </Accordion>
                                    </Container>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                    </>
                )}
            </Grid>
        </Segment>
    );
};

export default Organization;
