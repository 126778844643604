import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { Grid } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import { AreaSeries, FlexibleWidthXYPlot, XAxis, YAxis } from "react-vis";

import i18n from "modules/i18n/i18nConfig";
import { dynamicValueUnit } from "modules/data/utils";
import { priceConversionRate } from "modules/common/utils";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetMeasurementOverview24hQuery } from "modules/dashboard/dashboardService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import Palette from "modules/common/components/graphic/Colors";

const pollingInterval = 1000 * 60 * 10;

export const Val24h = (props) => {
    const { equipment, measure } = props;
    const now = moment();
    const org = useSelector((state) => state.org);
    const theme = useSelector((state) => state.common.theme);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });

    const measurementOverview24h = useGetMeasurementOverview24hQuery(
        { org: org.current, measurement_id: measure?.id },
        { skip: !org.current || !_.isFinite(measure?.id) }
    );

    useEffect(() => {
        const measurementOverview24hTimer = setInterval(() => {
            measurementOverview24h.refetch();
        }, pollingInterval);

        return () => {
            clearInterval(measurementOverview24hTimer);
        };
    }, [measure, measurementOverview24h, org]);

    const success_list = useMemo(() => {
        return [sites.isSuccess, units.isSuccess, dataflows.isSuccess, categories.isSuccess, measurementOverview24h.isSuccess];
    }, [sites, units, dataflows, categories, measurementOverview24h]);

    const { datapoint_type, site, category, factor, new_unit } = useMemo(() => {
        if (_.every(success_list)) {
            /* Need to change usage of datapoint_type */
            const datapoint_type = measure?.measurementtype?.datapoint_type ?? null;
            const site = _.find(sites.data, { id: equipment?.site });
            //For display unit, main unit from measurement else use unit of measurementtype
            const display_unit = _.find(units.data, { id: measure?.display_unit || measure?.measurementtype?.unit });
            const auto_unit = measure?.display_unit === null;
            const dataflow = _.find(dataflows.data, { id: measure?.dataflow });
            const category = _.find(categories.data, { id: dataflow?.dataflowspec });

            const { factor, new_unit } =
                datapoint_type === 3
                    ? dynamicValueUnit(measurementOverview24h.data?.delta_24h, display_unit?.symbol, auto_unit)
                    : dynamicValueUnit(measurementOverview24h.data?.avg_24h, display_unit?.symbol, auto_unit);
            return { datapoint_type, site, category, factor, new_unit };
        }
        return { datapoint_type: null, site: null, category: null, factor: 1, new_unit: "N/C" };
    }, [categories, dataflows, sites, units, equipment, measure, measurementOverview24h, success_list]);

    if (measurementOverview24h.isFetching) {
        return <MessageDisplay message={i18n._(t`loading`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }

    if (measurementOverview24h.isFetching === false && measurementOverview24h.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (measurementOverview24h.isFetching === false && measurementOverview24h.isSuccess) {
        if (_.size(measurementOverview24h.data?.datapoints) === 0) {
            return <MessageDisplay message={i18n._(t`no data 24h`)} level="warning" iconName="warning circle" isLoading={false} attached={false} />;
        }

        if (!_.isFinite(datapoint_type)) {
            return (
                <MessageDisplay
                    message={i18n._(t`error processing val24h`)}
                    level="warning"
                    iconName="warning circle"
                    isLoading={false}
                    attached={false}
                />
            );
        }

        const renderDelta = () => {
            const rate = priceConversionRate(
                measurementOverview24h.data?.delta_24h,
                site?.conversions,
                category?.name,
                measure?.measurementtype?.name,
                i18n
            );
            const conso_fmt = i18n.number(measurementOverview24h.data?.delta_24h * factor, { maximumFractionDigits: 0 });

            return !_.isFinite(measurementOverview24h.data?.delta_24h) ? "-" : `${conso_fmt} ${new_unit || "-"}          ${rate ? rate : ""}`;
        };

        const renderAgv = () => {
            return !_.isFinite(measurementOverview24h.data?.avg_24h)
                ? "-"
                : `${i18n.number(measurementOverview24h.data?.avg_24h * factor, {
                      maximumFractionDigits: 0
                  })} ${new_unit || "-"}`;
        };

        return (
            <Grid stretched centered>
                <Grid.Column width={16} textAlign="center" className="pwaPanelTitle">
                    <Trans>last 24h</Trans>
                </Grid.Column>
                <Grid.Column width={16} textAlign="center">
                    <h3 style={{ zIndex: 1 }}>{_.isEqual(datapoint_type, 3) ? renderDelta() : renderAgv()}</h3>
                </Grid.Column>
                <Grid.Column width={16}>
                    {/* Graphic for last 24h datapoints */}
                    <FlexibleWidthXYPlot
                        dontCheckIfEmpty
                        xType="time"
                        height={80}
                        xDomain={[now.clone().subtract(1, "day"), now.clone()]}
                        yDomain={[Math.min(0, measurementOverview24h.data?.min_24h), Math.max(10, measurementOverview24h.data?.max_24h)]}
                        margin={{ left: 1, right: 1, top: 1, bottom: 1 }}
                        className="pwaVal24hPlot"
                    >
                        <XAxis hideTicks />
                        <YAxis hideTicks />
                        <AreaSeries
                            color={theme === "old" ? Palette.named.yellow : Palette.named.green}
                            fill={theme === "old" ? Palette.named.yellow : Palette.named.green}
                            opacity={0.4}
                            getNull={(d) => {
                                return d.y !== null;
                            }}
                            data={_.map(measurementOverview24h.data?.datapoints, (item) => {
                                return { x: item[0] * 1000, y: item[1] !== null ? item[1] : null };
                            })}
                            curve={"curveMonotoneX"}
                        />
                    </FlexibleWidthXYPlot>
                </Grid.Column>
            </Grid>
        );
    }
    return null;
};

Val24h.propTypes = {
    equipment: PropTypes.object.isRequired,
    measure: PropTypes.object.isRequired
};

export default React.memo(Val24h);
