import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";
import { t } from "@lingui/macro";
import { MarkSeries, FlexibleXYPlot, XAxis, YAxis, HorizontalGridLines, Highlight, DiscreteColorLegend, Hint, LineSeries } from "react-vis";
import { Card, Grid, Segment, Button } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { multiI18nFormat } from "modules/i18n/i18nConfig";
import { roundedDate } from "modules/time/utils";

import { Media } from "App";
import GenerateCsv from "modules/common/components/GenerateCsv";
import MessageDisplay from "modules/common/components/MessageDisplay";

const GraphicOperatingRaw = (props) => {
    const { time, type, titleY, threshold } = props;
    const xDomain = [roundedDate(time.start.clone(), 60), time.end.clone()];
    const [hintValue, setHintValue] = useState(null);
    const current_lng = useSelector((state) => state.i18n.current);
    const [brushing, setBrushing] = useState(false);
    const [lastDrawLocation, setLastDrawLocation] = useState(null);
    const [series, setSeries] = useState(props.data);

    const translate_series = _.chain(series)
        .map((item) => ({
            ...item,
            title: `${i18n._(item.title)} (${item.unit})`
        }))
        .value();

    const last_y = _.chain(translate_series)
        .filter((item) => !item.disabled)
        .reduce((res, item) => {
            res = [...res, ...item.data];
            return res;
        }, [])
        .filter((item) => item.y !== null)
        .sortBy((item) => item.y)
        .last()
        .get("y")
        .defaultTo(threshold || 10)
        .thru((value) => _.max([value, threshold || 10]))
        .value();

    const clickHandler = (serie_clk) => {
        const size_disable = _.chain(series)
            .filter((item) => item.disabled === false)
            .size()
            .value();
        const update_series = _.map(series, (item) => {
            if (item.title === _.get(serie_clk, "name")) return { ...item, disabled: size_disable <= 1 ? false : !item.disabled };
            return item;
        });
        setSeries(update_series);
    };

    const tooltip = () => {
        return (
            <Hint value={hintValue}>
                <Card className="pwaOverviewTooltip" style={{ width: moment(hintValue.x).locale(current_lng).format("LLL").length * 8 }}>
                    <Card.Content>
                        <Card.Header>{moment(hintValue.x).locale(current_lng).format("LLL")}</Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Card.Description>
                            <Grid centered verticalAlign="middle" style={{ color: hintValue.color.toString() }}>
                                <Grid.Row>
                                    <Grid.Column className="pwaTooltipContentComparison" width={16} textAlign="center">
                                        {i18n.use(current_lng)._(hintValue.title)}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16} textAlign="center">
                                        {hintValue.y !== null ? `${i18n.number(hintValue.y, { maximumFractionDigits: 2 })} ${hintValue.unit}` : "-"}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Hint>
        );
    };

    const emptyData = _.chain(series)
        .map((serie) =>
            _.chain(serie)
                .get("data")
                .filter((record) => record.y !== null)
                .isEmpty(serie.data)
                .value()
        )
        .every()
        .value();

    if (emptyData) {
        return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />;
    }

    return (
        <>
            <Segment attached textAlign="right">
                <Media greaterThanOrEqual="computer">
                    <Button
                        onClick={(event, data) => {
                            setLastDrawLocation(null);
                        }}
                        icon="zoom-out"
                        className="no-print"
                    />
                    <GenerateCsv series={translate_series} filename={`${i18n.use(current_lng)._(type)}`} />
                </Media>
            </Segment>
            <Segment attached>
                <FlexibleXYPlot
                    dontCheckIfEmpty
                    xType={"time"}
                    xDomain={lastDrawLocation ? [lastDrawLocation.left, lastDrawLocation.right] : xDomain}
                    yDomain={lastDrawLocation ? [lastDrawLocation.bottom, lastDrawLocation.top] : [0, last_y < 5 ? 10 : last_y * 1.2]}
                    height={460}
                    margin={{ left: 60, right: 10, top: 10, bottom: 100 }}
                >
                    <DiscreteColorLegend
                        className="pwaLegend400"
                        orientation="horizontal"
                        height={75}
                        items={_.orderBy(translate_series, "title", "asc")}
                        onItemClick={clickHandler}
                    />
                    <HorizontalGridLines />
                    <Highlight
                        onBrushStart={(area) => {
                            if (area) setBrushing(true);
                        }}
                        onBrushEnd={(area) => {
                            if (area) {
                                setBrushing(false);
                                setLastDrawLocation({
                                    top: _.get(area, "top"),
                                    bottom: _.get(area, "bottom"),
                                    left: moment(_.get(area, "left")),
                                    right: moment(_.get(area, "right"))
                                });
                            }
                        }}
                    />
                    <XAxis
                        title={i18n._(t`time`)}
                        tickLabelAngle={-20}
                        tickFormat={(value, index, scale, tickTotal) => {
                            return multiI18nFormat(value, current_lng);
                        }}
                    />
                    {_.chain(translate_series)
                        .filter((item) => {
                            return item.disabled === false;
                        })
                        .map((serie, idx) => {
                            return (
                                <MarkSeries
                                    key={idx}
                                    color={serie.color.toString()}
                                    fill={serie.color.toString()}
                                    opacity={0.5}
                                    size={3}
                                    getNull={(d) => {
                                        return d.y !== null;
                                    }}
                                    data={_.reduce(
                                        serie.data,
                                        (res, rec) => {
                                            res.push({ ...rec, y: rec.y });
                                            return res;
                                        },
                                        []
                                    )}
                                    style={{
                                        pointerEvents: brushing ? "none" : "auto"
                                    }}
                                    onValueMouseOver={(hintValue) => {
                                        setHintValue(hintValue);
                                    }}
                                    onValueMouseOut={() => {
                                        setHintValue(null);
                                    }}
                                />
                            );
                        })
                        .value()}
                    {threshold !== null && (
                        <LineSeries
                            style={{ zIndex: 100 }}
                            color={"green"}
                            data={[
                                { x: lastDrawLocation ? lastDrawLocation.left : xDomain[0], y: threshold },
                                { x: lastDrawLocation ? lastDrawLocation.right : xDomain[1], y: threshold }
                            ]}
                        />
                    )}
                    {hintValue ? tooltip() : null}
                    <YAxis
                        title={titleY}
                        tickFormat={(value, index, scale, tickTotal) => {
                            const format = scale.tickFormat(tickTotal)(value);
                            if (typeof value === "number") {
                                return `${i18n.number(value, {
                                    maximumFractionDigits: 2
                                })}`;
                            }
                            return format;
                        }}
                    />
                </FlexibleXYPlot>
            </Segment>
        </>
    );
};

export default React.memo(GraphicOperatingRaw);
