import _ from "lodash";

/**
 *
 * @param {object} obj - Dataflow Object
 * @param {object} user_rights - Rights of user
 * @returns
 */
export const checkRights = (obj, user_rights, site_object = false) => {
    const { is_admin, is_superuser, sites_rw, user_id } = user_rights;

    let rights = ["can_read"];
    let site;

    if (is_superuser) {
        rights = _.uniq([...rights, "can_read", "can_change", "can_delete"]);
    } else if (is_admin) {
        rights = _.uniq([...rights, "can_read", "can_change", "can_delete"]);
    } else if (_.get(obj, "owner") === user_id) {
        rights = _.uniq([...rights, "can_read", "can_change", "can_delete"]);
    } else if (site_object && _.includes(sites_rw, obj?.id)) {
        rights = _.uniq([...rights, "can_read", "can_change", "can_delete"]);
    } else {
        if (_.get(obj, "site") === undefined) {
            return rights;
        } else if (Array.isArray(obj.site)) {
            site = _.head(obj.site);
        } else {
            site = obj.site;
        }
        if (_.includes(sites_rw, site)) {
            rights = _.uniq([...rights, "can_read", "can_change", "can_delete"]);
        }
    }
    return rights;
};
