import { t } from "@lingui/macro";

export const dialect_delimiter_list = [
    { key: 0, text: t`tab`, value: "\t" },
    { key: 1, text: t`semicolon`, value: ";" },
    { key: 2, text: t`comma`, value: "," },
    { key: 3, text: t`pipe`, value: "|" }
];

export const dialect_quotechar_list = [
    { key: 0, text: t`no_quote`, value: 0 },
    { key: 1, text: t`double_quote`, value: 1 },
    { key: 2, text: t`single_quote`, value: 2 }
];

export const encoding_list = [
    { key: 0, text: t`utf-8`, value: "utf-8" },
    { key: 1, text: t`iso-8859-1`, value: "iso-8859-1" },
    { key: 2, text: t`ascii`, value: "ascii" }
];

export const dialect_decimal_list = [
    { key: 0, text: t`,`, value: "," },
    { key: 1, text: t`.`, value: "." }
];
export const field_identification_list = [
    { key: 0, text: t`field by position`, value: 1 },
    { key: 1, text: t`field by header`, value: 2 }
];

export const post_process_list = [
    { key: 0, text: t`Move the file to the folder`, value: 1 },
    { key: 1, text: t`Delete file`, value: 2 },
    { key: 2, text: t`Rename by adding an extension`, value: 3 },
    { key: 3, text: t`Remember names`, value: 4 }
];

export const frequency_list = [
    { key: 0, text: t`daily`, value: 0 },
    { key: 1, text: t`weekly`, value: 1 },
    { key: 2, text: t`monthly`, value: 2 },
    { key: 3, text: t`hourly`, value: 3 },
    { key: 4, text: t`twice_a_day`, value: 4 }
];

export const my_measurementtypes = [
    "co2",
    // "debit_nm3",
    "i_moy_max_ph1",
    "i_moy_max_ph2",
    "i_moy_max_ph3",
    "i_moy_max",
    "index_nm3",
    "p_act_import",
    // "p_act",
    "p_react_import-",
    "p_react_import",
    "p_react_import+",
    // "p_react-",
    // "p_react",
    // "p_react+",
    "pm25",
    "temperature",
    "water_import",
    // "water",
    "hygrometry"
];
export const my_dataflowspec = ["elec", "water", "gas", "temperature", "co2", "hygrometry", "pm25", "calories", "frigories"];

export const protocol_list = [
    { key: "ftp", value: "ftp", text: "FTP" },
    { key: "ftps", value: "ftps", text: "FTPS" },
    { key: "sftp", value: "sftp", text: "SFTP" }
];

export const importTypes = [
    { key: 0, value: 0, text: t`CSV import` },
    { key: 1, value: 1, text: t`Enedis import` },
    { key: 2, value: 2, text: t`GRTgaz import` }
];

export const enedisParams = {
    filename: "ENEDIS_R63*CdC*.zip",
    encoding: "utf-8",
    semaphore_filename: "",
    has_header: true,
    dialect_delimiter: ";",
    dialect_quotechar: 0,
    dialect_decimal: ",",
    field_identification: 2,
    timestamp_column: "Horodate",
    timezone: "Europe/Paris",
    datetime_format: "%Y-%m-%d %H:%M:%S",
    encryption_key: ""
};

export const grtgazParams = {
    filename: "AM_*_*_*.csv",
    encoding: "utf-8",
    semaphore_filename: "",
    has_header: true,
    dialect_delimiter: ";",
    dialect_quotechar: 0,
    dialect_decimal: ".",
    field_identification: 2,
    timestamp_column: "Heure / Hour",
    timezone: "Europe/Paris",
    datetime_format: "%H:%M",
    encryption_key: "",
    post_process: 4
};

export const timestampFilterOptions = [
    { key: 0, text: t`no filter`, value: 0 },
    { key: 1, text: t`10 minutes`, value: 1 }
];

// Import Api
export const apiImportTypes = [{ key: 0, value: 0, text: t`SUEZ WDT` }];

export const api_frequency_list = [
    { key: 0, text: t`daily`, value: 0 },
    { key: 1, text: t`weekly`, value: 1 },
    { key: 2, text: t`monthly`, value: 2 },
    { key: 3, text: t`hourly`, value: 3 }
];

// Regex for check Client ref & Identifier validatioin
export const isTwelveDigits = (value) => {
    return /^[0-9]{12}$/.test(value);
};
export const startsWith98 = (value) => {
    return /^98/.test(value);
};
