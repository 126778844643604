import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Button, Header, Segment, Grid, Icon, Label, Popup, Divider } from "semantic-ui-react";
import { nextStep, previousStep, setAdditionnalBearings } from "../kinematicSlice";
import i18n from "modules/i18n/i18nConfig";
import _ from "lodash";
import { bearingType, defaultBearing } from "modules/predict/utils";
import { DropDownAdapter, InputAdapter, TextAreaAdapter, ToggleAdapter } from "modules/common/components/form";
import { identity, identityNull } from "modules/common/utils/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { reparseNumber, validateNumber } from "modules/common/utils";
import KinematicSearchBearings from "./KinematicSearchBearings";

const KinematicAdditionalBearingForm = (props) => {
    const { machine, mode } = props;
    const dispatch = useDispatch();
    const component = useSelector((state) => state.component);
    const kinematic = useSelector((state) => state.kinematic);

    //Database bearings components
    const additional_bearings_component = _.chain(component.components)
        .reduce((res, component) => {
            if (component.machine === _.get(machine, "id") && component.comp_type === 4 && !_.includes([1, 2], component.id_tech)) {
                res.push({
                    ..._.get(component, "description")
                });
            }
            return res;
        }, [])
        .value();

    const initial_bearings =
        _.size(kinematic.kinematic.additonnal_bearings) > 0
            ? kinematic.kinematic.additonnal_bearings //Use form values set by client if already set
            : _.size(additional_bearings_component) > 0
            ? additional_bearings_component //By default use data from server
            : [];

    //retrieve intermediate shafts from form
    const intermediate_shafts = _.get(kinematic, "kinematic.transmission.gearbox.intermediateShafts", []);
    const shaftOptions = [
        { key: 1, value: 1, text: i18n._(t`motor shaft`) },
        { key: 2, value: 2, text: i18n._(t`belt shaft`), disabled: !_.get(kinematic, "kinematic.transmission.hasBelt", 0) },
        { key: 3, value: 3, text: i18n._(t`gearbox shaft`), disabled: !_.get(kinematic, "kinematic.transmission.hasGearbox", 0) },
        ..._.map(intermediate_shafts, (item, idx) => ({
            key: idx + 4,
            value: idx + 4,
            text: `${i18n._(t`intermediate shaft`)} ${idx + 1} (${i18n._(t`gearbox`)})`,
            disabled: !_.get(kinematic, "kinematic.transmission.hasGearbox", 0)
        }))
    ];

    const onSubmitForm = async (data) => {
        data.additonnal_bearings = _.map(data?.additonnal_bearings, (bearing) => {
            return {
                ...bearing,
                dimension: _.mapValues(bearing.dimension, (item) => {
                    return reparseNumber(item);
                }),
                order: _.mapValues(bearing.order, (item) => {
                    return reparseNumber(item);
                })
            };
        });
        await dispatch(setAdditionnalBearings(data));
        await dispatch(nextStep());
    };

    const initialValues = useMemo(() => {
        return { additonnal_bearings: initial_bearings };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generate_bearing_error = (bearing) => {
        const err = {};
        const { by_order, order, dimension } = bearing;
        if (by_order) {
            err["order"] = {};
            _.each(order, (item, key) => {
                const orderValidate = validateNumber(item, i18n, false, false);
                if (orderValidate !== undefined) {
                    return (err["order"][key] = orderValidate);
                }
            });
            if (!_.every(order, (item) => _.isNull(item))) {
                if (!_.every(order, (item) => !_.isNull(item))) {
                    err["order"]["order_err"] = t`Fill all fields`;
                }
            } else {
            }
        } else {
            err["dimension"] = {};
            _.each(dimension, (item, key) => {
                const dimensionValidate =
                    key === "rolElements" ? validateNumber(item, i18n, false, false, true) : validateNumber(item, i18n, false, false);
                if (dimensionValidate !== undefined) {
                    return (err["dimension"][key] = dimensionValidate);
                }
            });
            if (!_.every(dimension, (item) => _.isNull(item))) {
                if (!_.every(dimension, (item) => !_.isNull(item))) {
                    err["dimension"]["dimension_err"] = t`Fill all fields`;
                }
            }
        }
        return err;
    };

    const validate = (values) => {
        const errors = {};
        if (mode === "view") {
            return errors;
        }

        errors.additonnal_bearings = _.chain(values)
            .get("additonnal_bearings", [])
            .map((bearing) => {
                return generate_bearing_error(bearing);
            })
            .value();
        return errors;
    };

    return (
        <>
            <Form
                onSubmit={onSubmitForm}
                mutators={{ ...arrayMutators }}
                initialValues={initialValues}
                validate={validate}
                render={({
                    handleSubmit,
                    form: {
                        mutators: { push, pop }
                    },
                    form,
                    invalid
                }) => {
                    return (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Header as={"h3"} attached="top" block textAlign="center">
                                <Trans>additonal bearings</Trans>
                            </Header>
                            <Segment attached>
                                {mode === "change" && (
                                    <Segment basic>
                                        <Button type="button" onClick={() => push("additonnal_bearings", defaultBearing)}>
                                            <Trans>add bearing</Trans>
                                        </Button>
                                        <Button type="button" onClick={() => pop("additonnal_bearings")}>
                                            <Trans>remove bearing</Trans>
                                        </Button>
                                    </Segment>
                                )}
                                {mode === "view" && _.size(additional_bearings_component) === 0 && (
                                    <MessageDisplay
                                        message={i18n._(t`this machine has no additional bearings`)}
                                        level="info"
                                        iconName="info circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                )}
                                <FieldArray name="additonnal_bearings">
                                    {(bearing_field) => {
                                        return bearing_field.fields.map((b_name, b_index) => {
                                            const current_bearing = bearing_field.fields.value[b_index];
                                            return (
                                                <Segment key={b_name}>
                                                    <Grid stackable>
                                                        <Grid.Row columns={2} stretched>
                                                            <Grid.Column>
                                                                <Segment>
                                                                    <Label attached="top" color="blue" style={{ textAlign: "center" }}>
                                                                        {" "}
                                                                        <Trans>general description</Trans>
                                                                    </Label>
                                                                    <Field
                                                                        name={`${b_name}.name`}
                                                                        placeholder={i18n._(t`enter bearing name`)}
                                                                        label={i18n._(t`bearing name`)}
                                                                        component={InputAdapter}
                                                                        parse={identity}
                                                                        defaultValue={""}
                                                                        disabled={mode === "view"}
                                                                        validate={(value) => {
                                                                            if (_.size(value) >= 50) {
                                                                                return (
                                                                                    <Trans>Ensure this field has no more than 50 characters</Trans>
                                                                                );
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                    <Field
                                                                        name={`${b_name}.location`}
                                                                        label={i18n._(t`shaft location`)}
                                                                        placeholder={i18n._(t`select shaft location`)}
                                                                        options={shaftOptions}
                                                                        component={DropDownAdapter}
                                                                        isRequired={true}
                                                                        defaultValue={1}
                                                                        validate={(value) => {
                                                                            return _.isFinite(parseFloat(value)) ? undefined : (
                                                                                <Trans>shaft location is required</Trans>
                                                                            );
                                                                        }}
                                                                        disabled={mode === "view"}
                                                                    />
                                                                    <Field
                                                                        name={`${b_name}.model`}
                                                                        placeholder={i18n._(t`enter model of bearing`)}
                                                                        label={i18n._(t`model`)}
                                                                        component={InputAdapter}
                                                                        parse={identity}
                                                                        defaultValue={""}
                                                                        disabled={mode === "view"}
                                                                    />
                                                                    {mode === "change" && (
                                                                        <KinematicSearchBearings
                                                                            bearing={current_bearing}
                                                                            changeForm={form.change}
                                                                            b_name={b_name}
                                                                        />
                                                                    )}
                                                                    <Field
                                                                        name={`${b_name}.maker`}
                                                                        placeholder={i18n._(t`enter maker of bearing`)}
                                                                        label={i18n._(t`maker`)}
                                                                        component={InputAdapter}
                                                                        parse={identity}
                                                                        defaultValue={""}
                                                                        disabled={mode === "view"}
                                                                    />
                                                                    <Field
                                                                        name={`${b_name}.bearing_type`}
                                                                        label={i18n._(t`bearing_type`)}
                                                                        placeholder={i18n._(t`select type of bearing`)}
                                                                        options={bearingType}
                                                                        component={DropDownAdapter}
                                                                        defaultValue={""}
                                                                        disabled={mode === "view"}
                                                                    />
                                                                    <Field
                                                                        name={`${b_name}.description`}
                                                                        placeholder={i18n._(t`enter description of your bearing`)}
                                                                        label={i18n._(t`description`)}
                                                                        component={TextAreaAdapter}
                                                                        parse={identity}
                                                                        defaultValue={""}
                                                                        disabled={mode === "view"}
                                                                    />
                                                                </Segment>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <Segment>
                                                                    <Label attached="top" color="blue" style={{ textAlign: "center" }}>
                                                                        <Trans>mechanical</Trans>
                                                                    </Label>
                                                                    <Field
                                                                        name={`${b_name}.by_order`}
                                                                        label={i18n._(t`by_dim_by_order`)}
                                                                        labelposition="right"
                                                                        component={ToggleAdapter}
                                                                        defaultValue={false}
                                                                        disabled={mode === "view"}
                                                                    />
                                                                    &nbsp;&nbsp;
                                                                    <Popup
                                                                        basic
                                                                        position="bottom center"
                                                                        trigger={<Icon circular inverted color="grey" name="question" size="tiny" />}
                                                                    >
                                                                        <Segment basic style={{ width: "50vh" }}>
                                                                            <Trans>by_dim_by_order_helper</Trans>
                                                                        </Segment>
                                                                    </Popup>
                                                                    <Divider />
                                                                    {current_bearing?.by_order === true && (
                                                                        <>
                                                                            {bearing_field?.meta?.error?.[b_index]?.order?.order_err && (
                                                                                <MessageDisplay
                                                                                    message={i18n._(
                                                                                        bearing_field?.meta?.error?.[b_index]?.order?.order_err
                                                                                    )}
                                                                                    level="info"
                                                                                    iconName="info circle"
                                                                                    isLoading={false}
                                                                                    attached={false}
                                                                                />
                                                                            )}
                                                                            <Field
                                                                                name={`${b_name}.order.BPFI`}
                                                                                placeholder={i18n._(t`bpfi place`)}
                                                                                label={i18n._(t`bpfi`)}
                                                                                isRequired={true}
                                                                                component={InputAdapter}
                                                                                parse={identityNull}
                                                                                inputMode="decimal"
                                                                                defaultValue={null}
                                                                                disabled={mode === "view"}
                                                                            />
                                                                            <Field
                                                                                name={`${b_name}.order.BPFO`}
                                                                                placeholder={i18n._(t`bpfo place`)}
                                                                                label={i18n._(t`bpfo`)}
                                                                                isRequired={true}
                                                                                component={InputAdapter}
                                                                                parse={identityNull}
                                                                                inputMode="decimal"
                                                                                defaultValue={null}
                                                                                disabled={mode === "view"}
                                                                            />
                                                                            <Field
                                                                                name={`${b_name}.order.FTF`}
                                                                                placeholder={i18n._(t`ftf place`)}
                                                                                label={i18n._(t`ftf`)}
                                                                                isRequired={true}
                                                                                component={InputAdapter}
                                                                                parse={identityNull}
                                                                                inputMode="decimal"
                                                                                defaultValue={null}
                                                                                disabled={mode === "view"}
                                                                            />
                                                                            <Field
                                                                                name={`${b_name}.order.BSF`}
                                                                                placeholder={i18n._(t`BSF place`)}
                                                                                label={i18n._(t`bsf`)}
                                                                                isRequired={true}
                                                                                component={InputAdapter}
                                                                                parse={identityNull}
                                                                                inputMode="decimal"
                                                                                defaultValue={null}
                                                                                disabled={mode === "view"}
                                                                            />
                                                                        </>
                                                                    )}
                                                                    {current_bearing?.by_order === false && (
                                                                        <>
                                                                            {bearing_field?.meta?.error?.[b_index]?.dimension?.dimension_err && (
                                                                                <MessageDisplay
                                                                                    message={i18n._(
                                                                                        bearing_field?.meta?.error?.[b_index]?.dimension
                                                                                            ?.dimension_err
                                                                                    )}
                                                                                    level="info"
                                                                                    iconName="info circle"
                                                                                    isLoading={false}
                                                                                    attached={false}
                                                                                />
                                                                            )}
                                                                            <Field
                                                                                name={`${b_name}.dimension.rolElements`}
                                                                                placeholder={i18n._(t`enter number of rolling elements`)}
                                                                                label={i18n._(t`rolElements`)}
                                                                                isRequired={true}
                                                                                component={InputAdapter}
                                                                                parse={identityNull}
                                                                                inputMode="numeric"
                                                                                defaultValue={null}
                                                                                disabled={mode === "view"}
                                                                            />
                                                                            <Field
                                                                                name={`${b_name}.dimension.rolDiameter`}
                                                                                placeholder={i18n._(t`enter diameter of rolling elements`)}
                                                                                label={i18n._(t`rolDiameter`)}
                                                                                isRequired={true}
                                                                                component={InputAdapter}
                                                                                unit={"mm"}
                                                                                parse={identityNull}
                                                                                inputMode="decimal"
                                                                                defaultValue={null}
                                                                                disabled={mode === "view"}
                                                                            />
                                                                            <Field
                                                                                name={`${b_name}.dimension.pitchDiameter`}
                                                                                placeholder={i18n._(t`enter pitch diameter of rolling elements`)}
                                                                                label={i18n._(t`pitchDiameter`)}
                                                                                isRequired={true}
                                                                                component={InputAdapter}
                                                                                unit={"mm"}
                                                                                parse={identityNull}
                                                                                inputMode="decimal"
                                                                                defaultValue={null}
                                                                                disabled={mode === "view"}
                                                                            />
                                                                            <Field
                                                                                name={`${b_name}.dimension.contactAngle`}
                                                                                placeholder={i18n._(t`enter contact angle of rolling elements`)}
                                                                                label={i18n._(t`contactAngle`)}
                                                                                isRequired={true}
                                                                                component={InputAdapter}
                                                                                unit={"°"}
                                                                                parse={identityNull}
                                                                                inputMode="decimal"
                                                                                defaultValue={null}
                                                                                disabled={mode === "view"}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </Segment>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        {mode === "change" && (
                                                            <Grid.Row>
                                                                <Grid.Column mobile={16} tablet={16} computer={5}>
                                                                    <Button
                                                                        type="button"
                                                                        icon="trash"
                                                                        labelPosition="left"
                                                                        color="red"
                                                                        fluid
                                                                        content={i18n._(t`delete bearing`)}
                                                                        onClick={() => bearing_field.fields.remove(b_index)}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        )}
                                                    </Grid>
                                                </Segment>
                                            );
                                        });
                                    }}
                                </FieldArray>
                            </Segment>
                            <Segment attached>
                                <Grid columns={2}>
                                    <Grid.Column textAlign="left">
                                        <Button
                                            type="button"
                                            icon="left arrow"
                                            compact
                                            labelPosition="left"
                                            content={i18n._(t`previous`)}
                                            onClick={(e) => {
                                                dispatch(previousStep());
                                            }}
                                        />
                                    </Grid.Column>
                                    {/* Check props.is_pump when equipment is a pump predict machine. Add ability to go to the next form */}
                                    {(mode === "change" || props.is_pump) && (
                                        <Grid.Column textAlign="right">
                                            <Button
                                                type="submit"
                                                disabled={invalid || _.isUndefined(machine)}
                                                icon="right arrow"
                                                labelPosition="right"
                                                content={i18n._(t`next`)}
                                                compact
                                            />
                                        </Grid.Column>
                                    )}
                                </Grid>
                            </Segment>
                        </form>
                    );
                }}
            />
        </>
    );
};

export default KinematicAdditionalBearingForm;
