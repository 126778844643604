import { t } from "@lingui/macro";

export const reportTypeElementOptions = [
    { key: 1, value: 1, text: t`Advanced analysis` },
    { key: 2, value: 2, text: t`Dashboard` }
];

export const reportTypeElements = {
    ANALYSIS: 1,
    DASHBOARD: 2
};
