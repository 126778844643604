import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import moment from "moment";
import _ from "lodash";
import { Grid, Header, Icon, Label, Popup, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { getClusters, getOperatingPoints, setTime } from "modules/overview/overviewSlice";
import { dateRangeOptions } from "modules/time/utils";

import TimePanel from "modules/common/components/TimePanel";
import MessageDisplay from "modules/common/components/MessageDisplay";
import GraphicOperatingPoints from "../graphic/GraphicOperatingPoints";

const TabPredictOperatingPoints = (props) => {
    const { equipment, default_time } = props;
    const machine = _.get(equipment, "machine") || undefined;

    const motor_wrap = _.chain(machine).get("components").find({ comp_type: 2 });

    const ratedFrequency = motor_wrap.get("description.ratedFrequency", 50).value();
    const ratedCurrent = motor_wrap.get("description.ratedCurrent", null).value();
    const ratedSpeed = motor_wrap.get("description.ratedSpeed", null).value();
    const polePairs = motor_wrap.get("description.polePairs", null).value();

    const thresholdCalc = (ratedFrequency * 60) / polePairs - ratedSpeed;
    const thresholdSlip = _.isFinite(thresholdCalc) ? thresholdCalc : 0;

    const ratedSlip = 1;

    const tab = "tabpredictoperatingpoints";
    const dispatch = useDispatch();
    const org = useSelector((state) => state.org);
    const { tabpredictoperatingpoints, predicttime } = useSelector((state) => state.overview);

    const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(["24h", "48h", "72h"], item.value));
    const rangeTime = predicttime.time
        ? {
              start: moment(predicttime.time.start),
              end: moment(predicttime.time.end),
              plage: predicttime.time.plage
          }
        : default_time;

    useEffect(() => {
        (async () => {
            if (predicttime.time) {
                const { start, end } = predicttime.time;
                if (!_.isUndefined(machine)) {
                    dispatch(getClusters({ org: org.current, machine, tab: "tabpredicthealthscore", rangeTime, type: "clusters" }));
                    dispatch(getOperatingPoints({ org: org.current, machine, start, end, tab, type: "freq_rms" }));
                    dispatch(getOperatingPoints({ org: org.current, machine, start, end, tab, type: "rms_slip" }));
                }
            } else {
                if (!_.isUndefined(machine)) {
                    dispatch(getClusters({ org: org.current, machine, tab: "tabpredicthealthscore", rangeTime, type: "clusters" }));
                    dispatch(
                        getOperatingPoints({
                            org: org.current,
                            machine,
                            start: default_time.start.toISOString(),
                            end: default_time.end.toISOString(),
                            tab,
                            type: "freq_rms"
                        })
                    );
                    dispatch(
                        getOperatingPoints({
                            org: org.current,
                            machine,
                            start: default_time.start.toISOString(),
                            end: default_time.end.toISOString(),
                            tab,
                            type: "rms_slip"
                        })
                    );
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [predicttime.time]);

    return (
        <Grid id="pwaMain" verticalAlign="middle" centered>
            <Popup
                trigger={
                    <Label corner>
                        <Icon name="question circle" />
                    </Label>
                }
            >
                <Popup.Content>
                    <p>
                        <Trans>
                            The label [Reverse] in the name of an operating point means that the currents in the three phases of the motor supply are
                            in the reverse order, which can be caused by sensor installation in the wrong order, or by a motor rotating in the inverse
                            direction.
                        </Trans>
                    </p>
                    <p>
                        <Trans>This has no consequences on health score calculation.</Trans>
                    </p>
                </Popup.Content>
            </Popup>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <TimePanel
                        time={rangeTime}
                        rangeOptions={rangeOptions}
                        accordion={true}
                        rounded={60}
                        action={({ start, end, plage }) => {
                            dispatch(setTime({ start, end, plage, tab: "predicttime" }));
                        }}
                        date_limit={moment()}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8} textAlign="center" className="pwaGraph">
                    <Header as={Segment} attached="top" block textAlign="center">
                        <Trans>operating_points_freq_rms</Trans>
                    </Header>
                    {_.isUndefined(machine) && (
                        <MessageDisplay message={i18n._(t`machine unknown`)} level="warning" iconName="info circle" isLoading={false} />
                    )}
                    {tabpredictoperatingpoints.freq_rms.status === "loading" && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {tabpredictoperatingpoints.freq_rms.status === "failed" && (
                        <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                    )}
                    {tabpredictoperatingpoints.freq_rms.status === "succeeded" && (
                        <GraphicOperatingPoints
                            type="freq_rms"
                            thresholdX={ratedFrequency}
                            thresholdY={ratedCurrent}
                            titleX={`${i18n._(t`supply freq`)} (Hz)`}
                            titleY={`${i18n._(t`rms current`)} (A)`}
                            data={tabpredictoperatingpoints.freq_rms.data}
                        />
                    )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8} textAlign="center" className="pwaGraph">
                    <Header as={Segment} attached="top" block textAlign="center">
                        <Trans>operating_points_rms_slip</Trans>
                    </Header>
                    {_.isUndefined(machine) && (
                        <MessageDisplay message={i18n._(t`machine unknown`)} level="warning" iconName="info circle" isLoading={false} />
                    )}
                    {tabpredictoperatingpoints.rms_slip.status === "loading" && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {tabpredictoperatingpoints.rms_slip.status === "failed" && (
                        <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                    )}
                    {tabpredictoperatingpoints.rms_slip.status === "succeeded" && (
                        <GraphicOperatingPoints
                            type="rms_slip"
                            thresholdX={ratedSlip}
                            thresholdY={ratedCurrent}
                            titleX={`${i18n._(t`slip`)} (rpm)`}
                            titleY={`${i18n._(t`rms current`)} (A)`}
                            data={tabpredictoperatingpoints.rms_slip.data}
                            thresholdSlip={thresholdSlip}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default TabPredictOperatingPoints;
