import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t } from "@lingui/macro";
import { Accordion, Button, Dropdown, Grid, Input, Label } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { sort_list, sortingType, sortingUnitsForType } from "../utils";

import { setFilter, setSorting } from "../dashboardSlice";

import { useGetSitesQuery } from "modules/site/siteService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetUnitsQuery } from "modules/unit/unitService";

import { Media } from "App";

export const categoriesExclusion = ["analog", "pulse", "elec_expert", "battery_status"];
const uniFormStyle = { minHeight: "40px" }; //display all filter with same height

//Active sorting unit for these type of sorting
const unitSortingEnable = [sortingType.LASTVALUE, sortingType.CONSO24H, sortingType.INDEX];

let tick = null;

const Filters = (props) => {
    const dispatch = useDispatch();
    const org = useSelector((state) => state.org);
    const theme = useSelector((state) => state.common.theme);
    const current_lng = useSelector((state) => state.i18n.current);
    const filter = useSelector((state) => state.dash.filter);
    const sorting = useSelector((state) => state.dash.sorting);
    const [localSearchName, setLocalSearchName] = useState(filter?.searchName ?? "");
    //Load data from cache
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });

    const sitesOptions = useMemo(() => {
        if (sites.isSuccess) {
            return _.chain(sites.data || [])
                .map(({ key, text, value }) => {
                    return {
                        key,
                        text: i18n._(text),
                        value,
                        content: <Label color={theme === "old" ? "blue" : "olive"}>{i18n._(text)}</Label>
                    };
                })
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
        // eslint-disable-next-line
    }, [sites, current_lng]);

    const zonesOptions = useMemo(() => {
        if (zones.isSuccess) {
            return _.chain(zones.data || [])
                .filter((zone) => {
                    if (filter.sites.length === 0) return true;
                    return _.includes(filter.sites, zone.site);
                })
                .map(({ key, text, value }) => {
                    return {
                        key,
                        text: i18n._(text),
                        value,
                        content: <Label color="teal">{i18n._(text)}</Label>
                    };
                })
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
        // eslint-disable-next-line
    }, [zones, filter.sites, current_lng]);

    const categoriesOptions = useMemo(() => {
        if (categories.isSuccess) {
            return _.chain(categories.data || [])
                .reduce((res, { key, text, value, id }) => {
                    if (_.includes(categoriesExclusion, text)) return res;
                    res.push({
                        key,
                        text: i18n._(text),
                        value,
                        content: <Label color="purple">{i18n._(text)}</Label>
                    });
                    return res;
                }, [])
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
        // eslint-disable-next-line
    }, [categories, current_lng]);

    const usagesOptions = useMemo(() => {
        if (usages.isSuccess) {
            return _.chain(usages.data || [])
                .map(({ key, text, value }) => {
                    return {
                        key,
                        text: i18n._(text),
                        value,
                        content: <Label color="violet">{i18n._(text)}</Label>
                    };
                })
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
        // eslint-disable-next-line
    }, [usages, current_lng]);

    const tagsOptions = useMemo(() => {
        if (tags.isSuccess) {
            return _.chain(tags.data || [])
                .map(({ key, text, value }) => {
                    return {
                        key,
                        text: i18n._(text),
                        value,
                        content: <Label color="grey">{i18n._(text)}</Label>
                    };
                })
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
        // eslint-disable-next-line
    }, [tags, current_lng]);

    const sortListOptions = useMemo(() => {
        return _.map(sort_list, ({ key, text, value }) => {
            return {
                key,
                text: i18n._(text),
                value
            };
        });
        // eslint-disable-next-line
    }, [current_lng]);

    const unitsOptions = useMemo(() => {
        if (units.isSuccess) {
            return _.chain(units.data || [])
                .thru((units) => {
                    //Append extra unit healthscore
                    return [
                        ...units,
                        {
                            key: 666,
                            text: "healthscore",
                            value: 666
                        }
                    ];
                })
                .reduce((res, { key, text, value, intensive }) => {
                    const unitsFiltered = sortingUnitsForType[sorting.type];
                    if (unitsFiltered === undefined || (unitsFiltered && _.includes(unitsFiltered, value))) {
                        res.push({
                            key,
                            text: sorting.type === sortingType.LASTVALUE && intensive ? i18n._(intensive) : i18n._(text),
                            value: text // display intensive unit (with 'text') but send extensive with 'value'
                        });
                    }
                    return res;
                }, [])
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
    }, [units, sorting.type]);

    return (
        <Grid>
            <Grid.Column mobile={16} tablet={3} computer={3}>
                {/* SITES FILTER */}
                <Dropdown
                    style={uniFormStyle}
                    fluid
                    options={sitesOptions}
                    placeholder={i18n._(t`select sites`)}
                    multiple
                    selection
                    search
                    noResultsMessage={i18n._(t`no result found`)}
                    onChange={async (e, { value }) => {
                        dispatch(setFilter({ field: "sites", value }));
                    }}
                    renderLabel={(label) => ({ color: theme === "old" ? "blue" : "olive", content: i18n._(label.text) })}
                    error={sites.isError}
                    loading={sites.isFetching}
                    disabled={!sites.isSuccess}
                    value={filter?.sites ?? []}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={3} computer={3}>
                {/* ZONES FILTER */}
                <Dropdown
                    style={uniFormStyle}
                    fluid
                    options={zonesOptions}
                    placeholder={i18n._(t`select zones`)}
                    multiple
                    selection
                    search
                    noResultsMessage={i18n._(t`no result found`)}
                    onChange={async (e, { value }) => {
                        dispatch(setFilter({ field: "zones", value }));
                    }}
                    renderLabel={(label) => ({ color: "teal", content: i18n._(label.text) })}
                    error={zones.isError}
                    loading={zones.isFetching}
                    disabled={!zones.isSuccess || (filter?.sites ?? []).length === 0}
                    value={filter?.zones ?? []}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={3} computer={3}>
                {/* CATEGORIES FILTER */}
                <Dropdown
                    style={uniFormStyle}
                    fluid
                    options={categoriesOptions}
                    placeholder={i18n._(t`select categories`)}
                    multiple
                    selection
                    search
                    noResultsMessage={i18n._(t`no result found`)}
                    onChange={async (e, { value }) => {
                        dispatch(setFilter({ field: "categories", value }));
                    }}
                    renderLabel={(label) => ({ color: "purple", content: i18n._(label.text) })}
                    error={categories.isError}
                    loading={categories.isFetching}
                    disabled={!categories.isSuccess}
                    value={filter?.categories ?? []}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={3} computer={3}>
                {/* USAGES FILTER */}
                <Dropdown
                    style={uniFormStyle}
                    fluid
                    options={usagesOptions}
                    placeholder={i18n._(t`select usages`)}
                    multiple
                    selection
                    search
                    noResultsMessage={i18n._(t`no result found`)}
                    onChange={async (e, { value }) => {
                        dispatch(setFilter({ field: "usages", value }));
                    }}
                    renderLabel={(label) => ({ color: "violet", content: i18n._(label.text) })}
                    error={usages.isError}
                    loading={usages.isFetching}
                    disabled={!usages.isSuccess}
                    value={filter?.usages ?? []}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                {/* TAGS FILTER */}
                <Dropdown
                    style={uniFormStyle}
                    fluid
                    options={tagsOptions}
                    placeholder={i18n._(t`select tags`)}
                    multiple
                    selection
                    search
                    noResultsMessage={i18n._(t`no result found`)}
                    onChange={async (e, { value }) => {
                        dispatch(setFilter({ field: "tags", value }));
                    }}
                    renderLabel={(label) => ({ color: "grey", content: i18n._(label.text) })}
                    error={usages.isError}
                    loading={usages.isFetching}
                    disabled={!usages.isSuccess}
                    value={filter?.tags ?? []}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={9} computer={9}>
                <Input
                    style={uniFormStyle}
                    fluid
                    icon="search"
                    placeholder={i18n._(t`search by name`)}
                    onChange={(e, { value }) => {
                        setLocalSearchName(value);
                        clearTimeout(tick);
                        tick = setTimeout(() => {
                            dispatch(setFilter({ field: "searchName", value }));
                        }, 1000);
                    }}
                    value={localSearchName}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={3} computer={3}>
                {/* SORT FILTER */}
                <Dropdown
                    style={uniFormStyle}
                    fluid
                    options={sortListOptions}
                    placeholder={i18n._(t`select sorting type`)}
                    selection
                    labeled
                    button
                    className="icon"
                    icon="sort"
                    onChange={async (e, { value }) => {
                        dispatch(setSorting({ field: "type", value }));
                    }}
                    value={sorting?.type ?? sortingType.ALPHABETICAL}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={3} computer={3}>
                {/* SORT UNIT */}
                <Dropdown
                    style={uniFormStyle}
                    fluid
                    options={unitsOptions}
                    placeholder={!_.includes(unitSortingEnable, sorting?.type) ? i18n._(t`No unit required`) : i18n._(t`select sorting unit`)}
                    selection
                    labeled
                    button
                    className="icon"
                    icon="sort"
                    onChange={async (e, { value }) => {
                        dispatch(setSorting({ field: "unit", value }));
                    }}
                    error={units.isError}
                    loading={units.isFetching}
                    disabled={!_.includes(unitSortingEnable, sorting?.type)}
                    value={sorting?.unit}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={1} computer={1}>
                {/* SORT ORDER */}
                <Button
                    style={uniFormStyle}
                    fluid
                    basic
                    icon={sorting?.order ? "sort amount down" : "sort amount up"}
                    content={sorting?.order ? i18n._(t`asc`) : i18n._(t`desc`)}
                    onClick={() => {
                        dispatch(setSorting({ field: "order", value: !sorting?.order }));
                    }}
                />
            </Grid.Column>
        </Grid>
    );
};

const panel = [{ key: "filters", title: i18n._(t`filters`), content: { content: <Filters /> } }];

const DashboardFilter = (props) => {
    return (
        <Grid.Row>
            <Grid.Column width={16}>
                <Media lessThan="computer">{(mediaClassNames, renderChildren) => renderChildren && <Accordion panels={panel} />}</Media>
                <Media greaterThanOrEqual="computer">{(mediaClassNames, renderChildren) => renderChildren && <Filters />}</Media>
            </Grid.Column>
        </Grid.Row>
    );
};

export default React.memo(DashboardFilter);
