import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import { Trans } from "@lingui/macro";

const UnsavedWrapper = ({ children, unsaved, action, invalid }) => {
    const [showModal, setShowModal] = useState(false);
    const [nextLocation, setNextLocation] = useState("");
    const [isUnsaved, setIsUnsaved] = useState(unsaved);
    const history = useHistory();

    useEffect(() => {
        setIsUnsaved(unsaved);
    }, [unsaved]);

    useEffect(() => {
        const unblock = history.block((location) => {
            if (isUnsaved) {
                setShowModal(true);
                setNextLocation(location);
                return false; // Stop navigation
            }
            return true; // Run navigation
        });

        return () => {
            unblock();
        };
    }, [history, isUnsaved]);

    const handleCancelLeave = () => {
        setShowModal(false);
    };

    const handleCancelContinue = () => {
        setIsUnsaved(false);
        if (nextLocation) {
            setTimeout(() => {
                setShowModal(false);
                history.push(nextLocation.pathname);
            }, 500);
        }
    };

    const handleCancelSave = () => {
        setShowModal(false);
        if (action && nextLocation) {
            action(nextLocation.pathname);
        }
    };

    return (
        <>
            {children}
            {showModal && (
                <Modal centered={false} open>
                    <Modal.Header>
                        <Trans>Unsaved changes</Trans>
                    </Modal.Header>
                    <Modal.Content>
                        <p>
                            <Trans>Changes have not been saved</Trans>
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={handleCancelLeave}>
                            <Trans>cancel</Trans>
                        </Button>
                        <Button type="button" color="facebook" onClick={handleCancelContinue}>
                            <Trans>Continue without saving</Trans>
                        </Button>
                        <Button type="button" color="green" onClick={handleCancelSave} disabled={invalid}>
                            <Trans>Save and continue</Trans>
                        </Button>
                    </Modal.Actions>
                </Modal>
            )}
        </>
    );
};

export default UnsavedWrapper;
