import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Trans, t } from "@lingui/macro";
import _ from "lodash";
import { Dropdown, Icon, Menu, Modal } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { checkStringInclude } from "modules/common/utils";

export const AnalysisAdvancedChoice = (props) => {
    const { adv_analysis, id, setSelectedAdvAnalysis, current_analysis, user_email } = props;
    const current_lng = useSelector((state) => state.i18n.current);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");

    const items = useMemo(() => {
        if (_.size(adv_analysis) === 0) {
            return [];
        }
        const group_by_user = _.chain(adv_analysis).groupBy("owner_email").value();
        const owner_analysis = _.pick(group_by_user, user_email);
        const other_analysis = _.omit(group_by_user, user_email);

        return _.reduce(
            { ...owner_analysis, ...other_analysis },
            (res, analysis, email) => {
                if (_.size(analysis) > 0) {
                    res.push(<Dropdown.Header key={email} icon="user" content={email} />);
                    const remap_analysis = _.chain(analysis)
                        .orderBy("default_date", "desc")
                        .reduce((res, item, idx) => {
                            const { key, text, value } = item;
                            if (search === "" || checkStringInclude(search, text)) {
                                res.push(
                                    <Dropdown.Item
                                        key={key}
                                        text={`${text}${item?.is_public === false ? ` - (${i18n._(t`private analysis`)})` : ""}`}
                                        value={value}
                                        icon={idx === 0 && user_email === email ? { name: "home" } : null}
                                        onClick={(e) => {
                                            setSelectedAdvAnalysis(item.id);
                                            setSearch("");
                                            setOpen(false);
                                        }}
                                        className={item?.is_public === false ? "pwaIsPrivateAnalysis" : ""}
                                    />
                                );
                            }
                            return res;
                        }, [])
                        .value();
                    if (_.size(remap_analysis) === 0) {
                        res.push(<Dropdown.Item key={`${email}_null`} text={i18n._(t`no result found`)} />);
                    } else {
                        res.push(...remap_analysis);
                    }
                }
                return res;
            },
            []
        );
        // eslint-disable-next-line
    }, [adv_analysis, search, user_email, setSelectedAdvAnalysis, current_lng]);

    return (
        <Modal
            centered={false}
            onClose={() => {
                setOpen(false);
                setSearch("");
            }}
            open={open}
            trigger={
                <Menu.Item
                    onClick={(e) => setOpen(true)}
                    disabled={id !== undefined || _.size(adv_analysis) === 0}
                    className={current_analysis?.is_public === false ? "pwaIsPrivateAnalysis" : ""}
                >
                    {_.size(adv_analysis) > 0 && current_analysis && (
                        <>
                            <span>{`${current_analysis?.name ?? "-"}${
                                current_analysis?.is_public === false ? ` - (${i18n._(t`private analysis`)})` : ""
                            }`}</span>
                            <Icon name="sort" />
                        </>
                    )}
                    {_.size(adv_analysis) === 0 && <Trans>You have no analysis</Trans>}
                    {_.size(adv_analysis) > 0 && current_analysis === undefined && (
                        <>
                            <Trans>Select an analysis</Trans>
                            <Icon name="sort" />
                        </>
                    )}
                </Menu.Item>
            }
        >
            <Modal.Header>
                <Trans>Select an analysis</Trans>
            </Modal.Header>
            <Modal.Content>
                <Dropdown
                    className="selection"
                    searchInput={{ autoFocus: true }}
                    search
                    fluid
                    onSearchChange={(e, data) => {
                        setSearch(data.searchQuery);
                    }}
                >
                    <Dropdown.Menu>{items}</Dropdown.Menu>
                </Dropdown>
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(AnalysisAdvancedChoice);
