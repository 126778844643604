import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t } from "@lingui/macro";
import { Button, Grid, Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetEquipmentEfficiencyQuery, useGetEquipmentMeasurementsQuery, useGetEquipmentsQuery } from "../equipmentService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import EfficiencyForm from "./form/EfficiencyForm";
import DeleteEfficiencyModal from "./modal/DeleteEfficiencyModal";

const PaneEfficiency = (props) => {
    const { equipment } = props;
    const [efficiencyDelete, setOpenDelete] = useState(null);
    const org = useSelector((state) => state.org);
    useSelector((state) => state.i18n.current); //force refresh for lng

    const equipmentEfficiency = useGetEquipmentEfficiencyQuery(
        { org: org.current, data: equipment?.efficiency },
        { skip: !org.current || !_.isFinite(equipment?.efficiency) }
    );
    const equipmentMeasurements = useGetEquipmentMeasurementsQuery(
        { org: org.current, data: equipment?.id },
        { skip: !org.current || equipment?.id === undefined }
    );

    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });

    const measurementsObject = useMemo(() => {
        if (measurements.isSuccess && dataflows.isSuccess && measurementtypes.isSuccess && equipments.isSuccess) {
            return _.reduce(
                measurements.data,
                (res, measure) => {
                    const df = _.find(dataflows.data, { id: measure?.dataflow });
                    const mttype = _.find(measurementtypes.data, { id: measure?.measurementtype });
                    const eqt = _.find(equipments.data, { id: df?.equipment });
                    const representation = df && mttype ? `${df?.name} - ${mttype.repr}` : "-";
                    const new_measure = { key: measure?.id, text: representation, value: measure?.id };
                    res[measure.id] = { ...measure, ...new_measure, dataflow: df, measurementtype: mttype, equipment: eqt };
                    return res;
                },
                {}
            );
        }
        return {};
    }, [measurements, dataflows, measurementtypes, equipments]);

    if (equipmentEfficiency.isFetching || equipmentMeasurements.isFetching) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (equipmentEfficiency.isError || equipmentMeasurements.isFetching) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (equipmentEfficiency.isSuccess && equipmentMeasurements.isSuccess) {
        return (
            <>
                <Grid centered>
                    <Grid.Column mobile={16} tablet={14} computer={14}>
                        <Header
                            as="h3"
                            attached="top"
                            block
                            textAlign="right"
                            id="pwaEquipmentMeasurementHeader"
                            style={{ display: "flex", flexWrap: "wrap", alignItems: "center", padding: "0.3rem", minHeight: "45px" }}
                        >
                            <div style={{ flex: 1 }} />
                            <div style={{ flex: 2, textAlign: "center" }}>
                                <h4>{`${i18n._(equipmentEfficiency.data?.name ?? "")}`}</h4>
                            </div>
                            <div style={{ flex: 1 }}>
                                <Button
                                    icon="delete"
                                    onClick={(e) => {
                                        setOpenDelete(equipmentEfficiency.data);
                                    }}
                                />
                            </div>
                        </Header>
                        <Segment attached>
                            <EfficiencyForm
                                equipment={equipment}
                                measurements={equipmentMeasurements.data}
                                measurementsObject={measurementsObject}
                                efficiency={equipmentEfficiency.data}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid>
                {efficiencyDelete && <DeleteEfficiencyModal efficiency={efficiencyDelete} setOpen={setOpenDelete} />}
            </>
        );
    }
    return null;
};

export default React.memo(PaneEfficiency);
