import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import _ from "lodash";
import { t } from "@lingui/macro";
import moment from "moment";
import { FlexibleXYPlot, Hint, HorizontalGridLines, VerticalBarSeries, XAxis, YAxis } from "react-vis";
import { Button, Card, Popup, Segment } from "semantic-ui-react";

import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import GenerateCsv from "modules/common/components/GenerateCsv";
import MessageDisplay from "modules/common/components/MessageDisplay";
import ConsumptionsButton from "../ConsumptionsButton";

const GraphicConsumption = (props) => {
    const initialSeries = _.chain(props)
        .get("consumption.data", [])
        .map((serie) => {
            return {
                ...serie,
                title: `${i18n._(serie.name)} (${serie.unit})`
            };
        })
        .value();

    const current_lng = useSelector((state) => state.i18n.current);
    const graphicRef = useRef(null);
    const [hintValue, setHintValue] = useState(null);
    const [series, setSeries] = useState(initialSeries);
    // Use data in series instead of time panel to define xLine Axis
    // API always return data on each time
    const firstX = _.chain(series).head().get("data").head().get("x").value();
    const lastX = _.chain(series).head().get("data").last().get("x").value();
    const xDomain = firstX && lastX ? [firstX, lastX] : null;

    const emptyData = _.chain(series)
        .map((serie) =>
            _.chain(serie)
                .get("data")
                .filter((record) => record.y !== null)
                .isEmpty(serie.data)
                .value()
        )
        .every()
        .value();

    const unit = _.chain(series).head().get("unit").value();

    const maxY = _.chain(series)
        .reduce((res, serie) => {
            _.each(serie.data, (item) => {
                res.push(item);
            });
            return res;
        }, [])
        .maxBy("y")
        .get("y")
        .value();

    const minY = _.chain(series)
        .reduce((res, serie) => {
            _.each(serie.data, (item) => {
                res.push(item);
            });
            return res;
        }, [])
        .minBy("y")
        .get("y")
        .value();

    const handleSaveImage = async () => {
        if (!graphicRef.current) return;
        try {
            const canvas = await html2canvas(graphicRef.current);
            // Convertir en image
            const imageData = canvas.toDataURL("image/png");

            // Création d'un lien de téléchargement
            const a = document.createElement("a");
            a.href = imageData;
            a.download = `${i18n._(t`consumptions`)}.png`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            toast.error(i18n._(t`Image generation has failed`), toast_options_err);
        }
    };

    const tooltip = () => {
        return (
            <Hint value={hintValue}>
                <Card className="pwaOverviewTooltip" style={{ width: moment(hintValue.x).locale(current_lng).format("LLL").length * 8 }}>
                    <Card.Content>
                        <Card.Header>{moment(hintValue.x).locale(current_lng).format("L")}</Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Card.Description>
                            {hintValue.y !== null ? `${i18n.number(hintValue.y, { maximumFractionDigits: 2 })} ${hintValue.unit || "-"}` : "-"}
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Hint>
        );
    };

    const RenderSaveImage = () => {
        return (
            <Popup
                trigger={
                    <Button
                        onClick={async (e) => {
                            await toast.info(i18n._(t`Preparing the image to save`), toast_options);
                            setTimeout(() => {
                                //Add delay between toast message && image generation
                                handleSaveImage();
                            }, 1000);
                        }}
                        icon="file image outline"
                    />
                }
            >
                <Popup.Content>{i18n._(t`Save as image`)}</Popup.Content>
            </Popup>
        );
    };

    return (
        <>
            <Segment attached textAlign="right">
                <ConsumptionsButton setSeries={setSeries} series={initialSeries} measure={props.measure} />
                {!emptyData && (
                    <>
                        <GenerateCsv series={series} unit={unit} filename={props.csvName || "export"} disabled={emptyData} />
                        <RenderSaveImage />
                    </>
                )}
            </Segment>
            {emptyData && <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />}
            {!emptyData && (
                <>
                    <Segment attached>
                        <div ref={graphicRef}>
                            <FlexibleXYPlot
                                xType="time"
                                xDomain={xDomain}
                                yDomain={[Math.min(0, minY), Math.max(10, maxY)]}
                                height={400}
                                margin={{ left: 60, right: 10, top: 10, bottom: 50 }}
                            >
                                <HorizontalGridLines />
                                <XAxis
                                    title={i18n._(t`time`)}
                                    tickLabelAngle={-20}
                                    tickFormat={(value, index, scale, tickTotal) => {
                                        const default_fmt = multiI18nFormat(value, current_lng);
                                        if (
                                            default_fmt.indexOf("06:00") >= 0 ||
                                            default_fmt.indexOf("12:00") >= 0 ||
                                            default_fmt.indexOf("18:00") >= 0
                                        )
                                            return "";
                                        return default_fmt;
                                    }}
                                />
                                {_.chain(series)
                                    .filter((item) => item.disabled === false)
                                    .map((serie, idx) => {
                                        return (
                                            <VerticalBarSeries
                                                key={idx}
                                                color={serie.color}
                                                fill={serie.color}
                                                getNull={(d) => d.y !== null}
                                                data={_.map(serie.data, (record) => {
                                                    return { ...record, y0: 0 };
                                                })}
                                                onValueMouseOver={(hintValue) => {
                                                    setHintValue(hintValue);
                                                }}
                                                onValueMouseOut={() => {
                                                    setHintValue(null);
                                                }}
                                            />
                                        );
                                    })
                                    .value()}
                                {hintValue ? tooltip() : null}
                                <YAxis
                                    title={unit}
                                    tickFormat={(value, index, scale, tickTotal) => {
                                        const format = scale.tickFormat(tickTotal)(value);
                                        if (typeof value === "number") {
                                            return i18n.number(value, { maximumFractionDigits: 1 });
                                        }
                                        return format;
                                    }}
                                />
                            </FlexibleXYPlot>
                        </div>
                    </Segment>
                </>
            )}
        </>
    );
};

export default React.memo(GraphicConsumption);
