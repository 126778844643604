import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import PropTypes from "prop-types";

import i18n from "modules/i18n/i18nConfig";
import { Button, Modal, Icon, Segment, Checkbox, Grid } from "semantic-ui-react";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteAlertPackMutation } from "../alertPackService";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const DeletePack = (props) => {
    const { item } = props;

    const [confirm, setConfirm] = useState(false);
    const [open, setOpen] = useState(false);

    const org = useSelector((state) => state.org);

    const [deleteAlertPack, deleted] = useDeleteAlertPackMutation();

    useEffect(() => {
        if (deleted.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (deleted.isSuccess) {
            toast.success(i18n._(t`successful delete alert pack`), toast_options);
            setOpen(false);
        }
        if (deleted.isError) {
            let error = i18n._(t`cannot delete alert pack`);
            if (deleted.error?.data && !_.includes(deleted.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={deleted.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [deleted, setOpen]);

    const handleDelete = async (e) => {
        await deleteAlertPack({ org: org.current, id: item.id });
    };

    return (
        <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} trigger={<Button icon="delete" />}>
            <Modal.Header>
                <Trans>delete</Trans>
            </Modal.Header>
            <Modal.Content>
                <Segment basic>
                    <Grid>
                        <Grid.Column style={{ color: "#794b02", fontStyle: "italic" }} verticalAlign="bottom" width={16}>
                            <Trans>want delete alertpack</Trans>
                        </Grid.Column>
                        <Grid.Column style={{ marginTop: ".5rem" }} width={16}>
                            <Checkbox
                                label={
                                    <label>
                                        <Trans>yes, i confirm deletion</Trans>
                                    </label>
                                }
                                onChange={(e, data) => setConfirm(data.checked)}
                                checked={confirm}
                            />
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                    <Trans>cancel</Trans>
                </Button>
                <Button disabled={!confirm} labelPosition="right" icon positive onClick={handleDelete}>
                    <Trans>validate</Trans>
                    <Icon name="trash" inverted />
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

DeletePack.propTypes = {
    item: PropTypes.object.isRequired
};

export default DeletePack;
