import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Grid, Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { resetFilterWithPage } from "../alerteventSlice";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import AlertEventsTab from "./AlertEventsTab";

const AlertEvents = (props) => {
    const org = useSelector((state) => state.org);
    const dispatch = useDispatch();
    useSelector((state) => state.i18n.current); //force refresh for lng

    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });

    const success_list = [equipments.isSuccess, dataflows.isSuccess, measurements.isSuccess, units.isSuccess, measurementtypes.isSuccess];
    const fetching_list = [equipments.isFetching, dataflows.isFetching, measurements.isFetching, units.isFetching, measurementtypes.isFetching];
    const error_list = [equipments.isError, dataflows.isError, measurements.isError, units.isError, measurementtypes.isError];

    return (
        <Segment attached>
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back
                            action={async () => {
                                await dispatch(resetFilterWithPage());
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>alertevents management</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                {/* DISPLAY LOADER */}
                {_.some(fetching_list) && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <MessageDisplay
                                message={i18n._(t`loading data`)}
                                level="info"
                                iconName="circle notched"
                                isLoading={true}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {/* DISPLAY ERROR MESSAGES */}
                {_.some(error_list) && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {_.every(success_list) && (
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="center">
                            <AlertEventsTab />
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        </Segment>
    );
};

export default React.memo(AlertEvents);
