import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Menu, Popup, Icon, Label, Checkbox, Segment } from "semantic-ui-react";
import { Trans } from "@lingui/macro";

const EventWidget = ({ type, helper, events, icon, inner_icon, inner_corner, onChange }) => {
    return (
        <Menu compact size="tiny" className="pwaEventWidget">
            {false && (
                <Fragment>
                    <Menu.Item active>
                        <Popup trigger={<Icon name="alarm" />}>
                            <Segment basic>{helper}</Segment>
                        </Popup>
                    </Menu.Item>
                    <Menu.Item>
                        <Checkbox toggle onChange={onChange} />
                    </Menu.Item>
                </Fragment>
            )}
            <Menu.Item>
                <Label color={events > 0 ? "red" : "green"}>{events !== undefined ? ` ${events}` : "-"}</Label>
                &nbsp;&nbsp;
                {type !== undefined ? type : <Trans>events</Trans>}
                &nbsp;&nbsp;
                {inner_icon !== undefined ? (
                    <Icon.Group size="large">
                        <Icon name={icon} color="red" />
                        <Icon name={inner_icon} color="yellow" corner={inner_corner} />
                    </Icon.Group>
                ) : (
                    <Icon name={icon} color="red" />
                )}
            </Menu.Item>
        </Menu>
    );
};

EventWidget.propTypes = {
    type: PropTypes.object,
    helper: PropTypes.object.isRequired,
    icon: PropTypes.string.isRequired,
    inner_icon: PropTypes.string,
    inner_corner: PropTypes.string,
    events: PropTypes.number,
    onChange: PropTypes.func
};

export default EventWidget;
