import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import tinycolor from "tinycolor2";
import html2canvas from "html2canvas";
import { Card, Header, Segment, Button, Grid, Table, Popup } from "semantic-ui-react";
import { toast } from "react-toastify";
import {
    Crosshair,
    DiscreteColorLegend,
    FlexibleXYPlot,
    HorizontalGridLines,
    LineSeries,
    VerticalGridLines,
    XAxis,
    YAxis,
    Hint,
    LineMarkSeries,
    Highlight
} from "react-vis";

import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { processAggregateTime } from "modules/time/utils";
import { colorMappingThreshold } from "modules/analysisAdvanced/utils";
import { dynamicValueUnit } from "modules/data/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import MessageDisplay from "modules/common/components/MessageDisplay";
import { Media } from "App";
import { CreateSignedStack, VerticalSignedBarSeries } from "modules/common/components/graphic/signedVerticalBarSeries";
import GenerateCsv from "modules/common/components/GenerateCsv";

const default_series = { ref_series: [], comp_series: [] };
const LEGENDHEIGHTHORIZONTAL = 55; //value in pixel

const TooltipTable = React.memo((props) => {
    const { series, displayFooter } = props;
    return (
        <Table unstackable basic="very" className="pwaCrosshairTable">
            <Table.Body>
                {_.isEmpty(series) && (
                    <Table.Row colSpan={2}>
                        <Table.Cell>
                            <Trans>no data for this date</Trans>
                        </Table.Cell>
                    </Table.Row>
                )}
                {_.chain(series)
                    .map((item, idx) => {
                        return (
                            <Table.Row key={idx}>
                                <Table.Cell style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "pre" }}>
                                    <svg height="10" width="14" style={{ marginRight: "5px" }}>
                                        <rect height="10" width="14" style={{ fill: tinycolor(item.color).toString() }}></rect>
                                    </svg>
                                    {`${item.title}`}
                                </Table.Cell>
                                {props.showRepartition && (
                                    <>
                                        {_.isFinite(item.repartition) && (
                                            <Table.Cell>
                                                {_.isFinite(item.y)
                                                    ? `${i18n.number(item.y, { maximumFractionDigits: 2 })} (${i18n.number(item.repartition, {
                                                          maximumFractionDigits: 2
                                                      })} %)`
                                                    : "-"}
                                            </Table.Cell>
                                        )}
                                        {!_.isFinite(item.repartition) && (
                                            <Table.Cell>
                                                {_.isFinite(item.y) ? `${i18n.number(item.y, { maximumFractionDigits: 2 })}` : "-"}
                                            </Table.Cell>
                                        )}
                                    </>
                                )}
                                {!props.showRepartition && (
                                    <Table.Cell>
                                        {_.isFinite(item.y)
                                            ? `${i18n.number(item.y, { maximumFractionDigits: 2 })} ${props.unit}`
                                            : `- ${props.unit}`}
                                    </Table.Cell>
                                )}
                            </Table.Row>
                        );
                    })
                    .value()}
            </Table.Body>
            {!_.isEmpty(series) && displayFooter && (
                <Table.Footer>
                    <Table.Row>
                        <Table.Cell>
                            <Trans>total</Trans>
                        </Table.Cell>
                        <Table.Cell>
                            {(() => {
                                const sum = _.sumBy(series, "y");
                                return _.isFinite(sum) ? `${i18n.number(sum, { maximumFractionDigits: 2 })}` : "-";
                            })()}
                        </Table.Cell>
                    </Table.Row>
                </Table.Footer>
            )}
        </Table>
    );
});

TooltipTable.defaultProps = {
    displayFooter: true
};

const CustomCardTooltip = React.memo((props) => {
    const { values, current_lng, unit, aggregation, sparklineMode, showRepartition, config } = props;
    const data = _.groupBy(values, "type");

    const index_first = _.chain(values).groupBy("current_index").keys().head().defaultTo(null).value();
    const tmst_in_config = config.xFormatTmst[index_first];

    return (
        <Card className={data?.comp_series ? "pwaCrosshairCardExtended" : "pwaCrosshairCard"}>
            <Card.Content>
                <Card.Header>
                    <Grid columns={data?.comp_series ? 2 : 1} divided stackable>
                        <Grid.Row>
                            <Grid.Column>
                                {aggregation === 1 &&
                                    moment(tmst_in_config)
                                        .locale(current_lng)
                                        .format(props.sparklineMode ? "LLL" : "LL")}
                                {aggregation === 2 && `${i18n._(t`week number`)}: ${moment(tmst_in_config).locale(current_lng).format("WW")}`}
                                {aggregation === 3 && `${moment(tmst_in_config).locale(current_lng).format("MMMM YYYY")}`}
                            </Grid.Column>
                            {data?.comp_series && (
                                <Grid.Column>
                                    {aggregation === 1 &&
                                        moment(_.chain(_.chain(data).get("comp_series", [])).head().get("t").value())
                                            .locale(current_lng)
                                            .format(props.sparklineMode ? "LLL" : "LL")}
                                    {aggregation === 2 &&
                                        `${i18n._(t`week number`)}: ${moment(_.chain(_.chain(data).get("comp_series", [])).head().get("t").value())
                                            .locale(current_lng)
                                            .format("WW")}`}
                                    {aggregation === 3 &&
                                        `${moment(_.chain(_.chain(data).get("comp_series", [])).head().get("t").value())
                                            .locale(current_lng)
                                            .format("MMMM YYYY")}`}
                                </Grid.Column>
                            )}
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <TooltipTable series={data.ref_series} unit={unit} displayFooter={!sparklineMode} showRepartition={showRepartition} />
                            </Grid.Column>
                            {data?.comp_series && (
                                <Grid.Column>
                                    <TooltipTable
                                        series={data.comp_series}
                                        unit={unit}
                                        displayFooter={!sparklineMode}
                                        showRepartition={showRepartition}
                                    />
                                </Grid.Column>
                            )}
                        </Grid.Row>
                    </Grid>
                </Card.Header>
            </Card.Content>
        </Card>
    );
});

const GraphicExtensive = (props) => {
    const {
        data,
        data: { config },
        time_periods: { ref_time, comp_time },
        aggregation,
        thresholds,
        PlotType,
        plotMargin,
        tooltipMode,
        displayPopupInfo,
        autoUnit
    } = props;
    const current_lng = useSelector((state) => state.i18n.current);
    const [crosshairValues, setCrosshairValues] = useState([]);
    const [hintValue, setHintValue] = useState(null);
    const [displaySeries, setDisplaySeries] = useState(false);
    const [series, setSeries] = useState(default_series);
    const [isHovered, setIsHovered] = useState(false);
    const [saveImg, setSaveImg] = useState(false);
    const [brushing, setBrushing] = useState(false);
    const [lastDrawLocation, setLastDrawLocation] = useState(null);
    const graphicRef = useRef(null);

    const containerHeight = props.height; //props.height can height of widget (cf.dashboard) or fixed height (cf.analysis)
    const plotWidth = props.width ? { width: props.width } : null; //Put width for XYPlot && no width for FlexibleXYPlot

    useEffect(() => {
        //trigger action when data change && current_lng change
        (async () => {
            const { ref_series, comp_series } = _.chain(data.series)
                .reduce((res, series, serie_type) => {
                    res[serie_type] = _.chain(series)
                        .reduce((res, serie) => {
                            if (_.size(serie.data) > 0) {
                                const all_null = _.every(serie.data, (rec) => {
                                    return rec.y === null;
                                });
                                if (all_null) return res; //remove serie where all data null or 0
                                res.push({
                                    ...serie,
                                    title: `${i18n._(serie.title)}`,
                                    data: _.map(serie.data, (item) => ({ ...item, title: `${i18n._(serie.title)}` }))
                                });
                            }
                            return res;
                        }, [])
                        .sortBy((item) => {
                            return removeAccents(item.title).toLowerCase();
                        })
                        .value();
                    return res;
                }, {})
                .value();
            await setSeries({ ref_series, comp_series });
        })();
    }, [data.series, current_lng]);

    const hideShowSeries = () => {
        const ordre = _.orderBy(["ref_series", "comp_series"], [(key) => series[key].length], ["desc"]);
        let first_serie = null;
        const update_series = _.chain(ordre)
            .reduce((res, ord) => {
                res[ord] = series[ord];
                return res;
            }, {})
            .reduce((res, serie_type, key) => {
                res[key] = _.chain(serie_type)
                    .map((serie, idx) => {
                        if (displaySeries === false) {
                            if (key !== _.head(ordre)) {
                                if (serie.title === first_serie.title) {
                                    return { ...serie, disabled: false };
                                } else {
                                    return { ...serie, disabled: true };
                                }
                            } else {
                                if (idx === 0) {
                                    first_serie = serie;
                                    return { ...serie, disabled: false };
                                }
                                return { ...serie, disabled: true };
                            }
                        }
                        return { ...serie, disabled: false };
                    })
                    .value();
                return res;
            }, {})
            .value();
        setSeries(update_series);
        setDisplaySeries(!displaySeries);
    };

    const isEmptySeries = _.size(series.ref_series) === 0 && _.size(series.comp_series) === 0;

    const max_all = _.chain(series)
        .reduce((res, serie_type) => {
            const serie = _.chain(serie_type)
                .filter((serie) => serie.disabled === false)
                .reduce((res, serie) => {
                    res.push(...serie.data);
                    return res;
                }, [])
                .value();
            res.push(...serie);
            return res;
        }, [])
        .filter((item) => item.y !== null)
        .map((item) => ({ ...item, y: Math.abs(item.y) }))
        .maxBy("y")
        .value();

    const { factor, new_unit } = autoUnit ? dynamicValueUnit(_.get(max_all, "y", null), config.yUnit) : { factor: 1, new_unit: config.yUnit };

    //Process dynamic total when enable/disable series
    const { ref_total, comp_total, ref_repartition, comp_repartition, ref_minmax, comp_minmax } = useMemo(() => {
        return _.chain(series)
            .reduce(
                (res, serie_type, key) => {
                    const available_series_wrap = _.chain(serie_type).filter({ disabled: false });
                    const all_total_null = available_series_wrap.every((serie) => serie.total === null).value();
                    if (all_total_null) return res;
                    switch (key) {
                        case "ref_series":
                            res.ref_total = available_series_wrap.sumBy("total").value() * factor;
                            res.ref_repartition = props.sparklineMode
                                ? available_series_wrap
                                      .flatMap("data")
                                      .groupBy("x")
                                      .map((values) => {
                                          const all_data = _.flatMap(values, "y");
                                          const all_data_null = _.every(all_data, (data) => data === null);
                                          if (all_data_null) return null;
                                          _.each(values, (val) => {
                                              if (_.isFinite(val.y)) {
                                                  res.ref_minmax.push(val.y);
                                              }
                                          });
                                          return null;
                                      })
                                      .value()
                                : available_series_wrap
                                      .flatMap("data")
                                      .groupBy("x")
                                      .map((values) => {
                                          const all_data = _.flatMap(values, "y");
                                          const all_data_null = _.every(all_data, (data) => data === null);
                                          if (all_data_null) return null;
                                          //processing on positive && negative stack for yDomain Axe
                                          let [pos, neg] = [null, null];
                                          _.each(values, (val) => {
                                              if (_.isFinite(val.y)) {
                                                  if (val.y >= 0) {
                                                      pos = pos + val.y * factor;
                                                  } else {
                                                      neg = neg + val.y * factor;
                                                  }
                                              }
                                          });
                                          res.ref_minmax.push(...[pos, neg]);
                                          return pos + neg;
                                      })
                                      .value();
                            break;
                        case "comp_series":
                            res.comp_total = available_series_wrap.sumBy("total").value() * factor;
                            res.comp_repartition = props.sparklineMode
                                ? available_series_wrap
                                      .flatMap("data")
                                      .groupBy("x")
                                      .map((values) => {
                                          const all_data = _.flatMap(values, "y");
                                          const all_data_null = _.every(all_data, (data) => data === null);
                                          if (all_data_null) return null;
                                          _.each(values, (val) => {
                                              if (_.isFinite(val.y)) {
                                                  res.ref_minmax.push(val.y);
                                              }
                                          });
                                          return null;
                                      })
                                      .value()
                                : available_series_wrap
                                      .flatMap("data")
                                      .groupBy("x")
                                      .map((values) => {
                                          const all_data = _.flatMap(values, "y");
                                          const all_data_null = _.every(all_data, (data) => data === null);
                                          if (all_data_null) return null;
                                          let [pos, neg] = [null, null];
                                          _.each(values, (val) => {
                                              if (_.isFinite(val.y)) {
                                                  if (val.y >= 0) {
                                                      pos = pos + val.y * factor;
                                                  } else {
                                                      neg = neg + val.y * factor;
                                                  }
                                              }
                                          });
                                          if (res.comp_minmax === null) {
                                              res.comp_minmax = [pos, neg];
                                          } else {
                                              res.comp_minmax.push(...[pos, neg]);
                                          }
                                          return pos + neg;
                                      })
                                      .value();
                            break;
                        default:
                            break;
                    }
                    return res;
                },
                { ref_total: null, comp_total: null, ref_repartition: [], comp_repartition: null, ref_minmax: [], comp_minmax: null }
            )
            .value();
    }, [series, factor, props.sparklineMode]);

    const signedStacks = {
        ref_series: CreateSignedStack(),
        comp_series: CreateSignedStack()
    };

    const clickHandler = (serie_clk) => {
        const update_series_wrap = _.chain(series).reduce((res, serie_type, key) => {
            const disabled_series = _.chain(serie_type)
                .map((serie) => {
                    if (serie_clk.title === serie.title) {
                        return { ...serie, disabled: !serie.disabled };
                    }
                    return serie;
                })
                .value();
            res[key] = disabled_series;
            return res;
        }, {});

        const check_disable = update_series_wrap
            .reduce((res, serie_type, key) => {
                if (!_.every(serie_type, ["disabled", true])) {
                    res[key] = serie_type;
                }
                return res;
            }, {})
            .value();

        setSeries((prev) => {
            if (!_.isEmpty(check_disable)) {
                return {
                    ...prev,
                    ...update_series_wrap.value()
                };
            }
            return prev;
        });
    };

    const nearestXHandler = (value, { index }) => {
        const cross = _.chain(series)
            .reduce((res, serie, key) => {
                _.chain(serie)
                    .filter((item) => _.size(item.data) > 0)
                    .filter((item) => item.disabled === false)
                    .orderBy((item) => item.data.length, "desc") //Take serie with maximum data 1st for tooltip
                    .each((serie) => {
                        const data = _.find(serie.data, { x: index });
                        if (data && _.isFinite(data.y)) {
                            const exists_serie = _.find(res, { title: serie.name, type: key });
                            if (!exists_serie) {
                                let xTmst = null;
                                // For sparkline mode
                                // Retrieve timestamp from config 'xFormatTmst' when 'ref' series has no data
                                // in same period of 'comp' data to correct display Tooltip
                                if (key === "comp_series" && config.xFormatTmst[index]) {
                                    if (!_.isEmpty(series?.ref_series)) {
                                        xTmst = moment(config.xFormatTmst[index]).unix() * 1000;
                                    }
                                }
                                res.push({
                                    type: key,
                                    ...data,
                                    y: data.y * factor,
                                    y_real: data.y_real * factor,
                                    x: props.sparklineMode ? (key === "comp_series" && xTmst ? xTmst : data.t) : data.x,
                                    current_index: index
                                });
                            }
                        }
                    })
                    .value();
                return res;
            }, [])
            .value();
        setCrosshairValues(cross);
    };

    const renderTotal = useCallback(() => {
        const time_from_fmt = `${_.lowerCase(i18n._(t`from`))} ${processAggregateTime(moment(ref_time.start).clone(), aggregation)
            .locale(current_lng)
            .format("L")}`;
        const time_to_fmt = `${_.lowerCase(i18n._(t`to`))} ${moment(ref_time.end)
            .clone()
            .startOf("day")
            .subtract(1, "day")
            .locale(current_lng)
            .format("L")}`;
        const ref_total_fmt = `${_.isFinite(ref_total) ? i18n.number(ref_total, { maximumFractionDigits: 2 }) : "-"} ${new_unit || "-"}`;
        //If compare exists
        const comp_time_from_fmt = `${_.lowerCase(i18n._(t`from`))} ${
            comp_time ? processAggregateTime(moment(comp_time.start).clone(), aggregation).locale(current_lng).format("L") : "-"
        }`;
        const comp_time_to_fmt = `${_.lowerCase(i18n._(t`to`))} ${
            comp_time ? moment(comp_time.end).clone().startOf("day").subtract(1, "day").locale(current_lng).format("L") : "-"
        }`;
        const comp_total_fmt = `${_.isFinite(comp_total) ? i18n.number(comp_total, { maximumFractionDigits: 2 }) : "-"} ${new_unit || "-"}`;

        return (
            <Segment attached>
                <Grid centered>
                    <Grid.Row>
                        <Grid.Column textAlign="center" mobile={16} tablet={comp_time !== null ? 8 : 16} computer={comp_time !== null ? 8 : 16}>
                            <h3>{`${i18n._(t`Period total consumption`)} ${time_from_fmt} ${time_to_fmt} : ${ref_total_fmt}`}</h3>
                        </Grid.Column>
                        {comp_time !== null && (
                            <Grid.Column textAlign="center" mobile={16} tablet={8} computer={8}>
                                <h3>{`${i18n._(
                                    t`Period total consumption`
                                )} (ref) ${comp_time_from_fmt} ${comp_time_to_fmt} : ${comp_total_fmt}`}</h3>
                            </Grid.Column>
                        )}
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }, [ref_time, comp_time, new_unit, current_lng, ref_total, comp_total, aggregation]);

    const tooltip = () => {
        return (
            <Hint value={hintValue}>
                <Card className="pwaCrosshairCard" style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                    <Card.Content>
                        <Card.Header>
                            {/* need to add 1 day cause moment truncate datatime */}
                            {aggregation === 1 &&
                                moment(hintValue.t)
                                    .clone()
                                    .locale(current_lng)
                                    .format(props.sparklineMode ? "LLL" : "L")}
                            {aggregation === 2 && `${i18n._(t`week number`)}: ${moment(hintValue.t).clone().locale(current_lng).format("WW")}`}
                            {aggregation === 3 && `${i18n._(t`month`)}: ${moment(hintValue.t).clone().locale(current_lng).format("MMMM YYYY")}`}
                        </Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Card.Description>
                            <svg height="10" width="14" style={{ marginRight: "5px" }}>
                                <rect height="10" width="14" style={{ fill: hintValue.color }}></rect>
                            </svg>
                            {!_.isFinite(hintValue.y_real) && `- ${new_unit}`}
                            {`${i18n._(hintValue.title)} : ${
                                _.isFinite(hintValue.y_real) ? i18n.number(hintValue.y_real, { maximumFractionDigits: 2 }) : "-"
                            } ${new_unit} ${
                                props.showRepartition
                                    ? _.isFinite(hintValue.repartition)
                                        ? `(${i18n.number(hintValue.repartition, { maximumFractionDigits: 2 })} %)`
                                        : "(- %)"
                                    : ""
                            }`}
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Hint>
        );
    };

    const handleSaveImage = async () => {
        if (!graphicRef.current) return;
        try {
            await setSaveImg(true);
            const canvas = await html2canvas(graphicRef.current);
            // Convertir en image
            const imageData = canvas.toDataURL("image/png");

            // Création d'un lien de téléchargement
            const a = document.createElement("a");
            a.href = imageData;
            a.download = "widget.png";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            await setSaveImg(false);
        } catch (error) {
            await setSaveImg(false);
            toast.error(i18n._(t`Image generation has failed`), toast_options_err);
        }
    };

    // rewrite data of each series to replace x value => t value (x is a number and t is a timestamp)
    // TODO check if we can update Generate to use t directly instead of x (check other graphics)
    const ref_export = (series?.ref_series ?? []).map((serie) => ({ ...serie, data: serie.data.map((rec) => ({ ...rec, x: rec.t })) }));
    const comp_export = (series?.comp_series ?? []).map((serie) => ({ ...serie, data: serie.data.map((rec) => ({ ...rec, x: rec.t })) }));

    //Merge_repartition is used to define yDomain for graphic
    const merge_repartition = _.chain([...ref_minmax, ...(comp_minmax ? comp_minmax : [])])
        .filter((item) => item !== null)
        .sortBy()
        .value();

    const tickValues = _.range(config.xTickValues);
    const PlotSerie = props.sparklineMode ? LineMarkSeries : VerticalSignedBarSeries;

    return (
        <>
            {isEmptySeries && (
                <>
                    {/* FlexibleXYPlot means you are on Analysis advanced. TODO add param for analysis*/}
                    {PlotType === FlexibleXYPlot && (
                        <Header as="h3" textAlign="center" attached>
                            <Trans>consumptions</Trans>
                        </Header>
                    )}
                    <MessageDisplay
                        message={i18n._(t`no data`)}
                        level="warning"
                        iconName="warning circle"
                        isLoading={false}
                        customStyle={PlotType === FlexibleXYPlot ? null : { height: props.height }}
                    />
                </>
            )}
            {!isEmptySeries && (
                <>
                    {!displayPopupInfo && !props.sparklineMode && (
                        <>
                            {renderTotal()}
                            {_.some(thresholds, (data) => _.isFinite(data)) && (
                                <Table attached compact>
                                    <Table.Body>
                                        <Table.Row>
                                            {_.map(thresholds, (threshold, name) => {
                                                if (!_.isFinite(threshold)) {
                                                    return null;
                                                }
                                                return (
                                                    <Table.Cell key={name} textAlign="center" style={{ color: colorMappingThreshold[name] }}>
                                                        <b>{i18n._(name)}</b> : {`${threshold * factor} ${new_unit}`}
                                                    </Table.Cell>
                                                );
                                            })}
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            )}
                        </>
                    )}
                    <div ref={graphicRef}>
                        <Segment
                            attached
                            style={{ height: containerHeight, borderTop: "none" }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <Media greaterThanOrEqual="computer">
                                {(mediaClassNames, renderChildren) =>
                                    renderChildren &&
                                    !saveImg &&
                                    isHovered && (
                                        <div className="no-print" style={{ position: "absolute", top: 0, right: 0, zIndex: 2 }}>
                                            <Button.Group>
                                                {displayPopupInfo && (
                                                    <Popup flowing trigger={<Button icon="info" />} style={{ padding: 0 }}>
                                                        <Popup.Content>
                                                            {renderTotal()}
                                                            {_.some(thresholds, (data) => _.isFinite(data)) && (
                                                                <Table attached compact>
                                                                    <Table.Body>
                                                                        <Table.Row>
                                                                            {_.map(thresholds, (threshold, name) => {
                                                                                if (!_.isFinite(threshold)) {
                                                                                    return null;
                                                                                }
                                                                                return (
                                                                                    <Table.Cell
                                                                                        key={name}
                                                                                        textAlign="center"
                                                                                        style={{ color: colorMappingThreshold[name] }}
                                                                                    >
                                                                                        <b>{i18n._(name)}</b> : {`${threshold * factor} ${new_unit}`}
                                                                                    </Table.Cell>
                                                                                );
                                                                            })}
                                                                        </Table.Row>
                                                                    </Table.Body>
                                                                </Table>
                                                            )}
                                                        </Popup.Content>
                                                    </Popup>
                                                )}
                                                {props.sparklineMode && (
                                                    <Button
                                                        onClick={(event, data) => {
                                                            setLastDrawLocation(null);
                                                        }}
                                                        icon="zoom-out"
                                                        className="no-print"
                                                    />
                                                )}
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            icon={displaySeries ? "eye" : "eye slash"}
                                                            onClick={async (event, data) => {
                                                                hideShowSeries();
                                                            }}
                                                        />
                                                    }
                                                >
                                                    <Popup.Content>
                                                        {displaySeries ? i18n._(t`display series`) : i18n._(t`hide series`)}
                                                    </Popup.Content>
                                                </Popup>
                                                {
                                                    <GenerateCsv
                                                        series={ref_export}
                                                        unit={config.yUnit}
                                                        filename={i18n._("reference")}
                                                        popupContent={i18n._(t`Download data of reference period`)}
                                                    />
                                                }
                                                {_.size(series?.comp_series) > 0 && (
                                                    <GenerateCsv
                                                        series={comp_export}
                                                        unit={config.yUnit}
                                                        filename={i18n._("comparison")}
                                                        popupContent={i18n._(t`Download data of comparison period`)}
                                                    />
                                                )}
                                                {
                                                    <Popup
                                                        trigger={
                                                            <Button
                                                                onClick={async (e) => {
                                                                    await toast.info(i18n._(t`Preparing the image to save`), toast_options);
                                                                    setTimeout(() => {
                                                                        //Add delay between toast message && image generation
                                                                        handleSaveImage();
                                                                    }, 1000);
                                                                }}
                                                                icon="file image outline"
                                                            />
                                                        }
                                                    >
                                                        <Popup.Content>{i18n._(t`Save as image`)}</Popup.Content>
                                                    </Popup>
                                                }
                                            </Button.Group>
                                        </div>
                                    )
                                }
                            </Media>
                            <Media lessThan="computer">
                                {(mediaClassNames, renderChildren) =>
                                    renderChildren &&
                                    !saveImg &&
                                    isHovered && (
                                        <div className="no-print" style={{ position: "absolute", top: 0, right: 0, zIndex: 2 }}>
                                            <Button.Group>
                                                {displayPopupInfo && (
                                                    <Popup
                                                        on={"click"}
                                                        flowing
                                                        trigger={<Button icon="info" />}
                                                        basic
                                                        style={{ padding: 0, position: "absolute", right: 0 }}
                                                    >
                                                        <Popup.Content>
                                                            {renderTotal()}
                                                            {_.some(thresholds, (data) => _.isFinite(data)) && (
                                                                <Table attached compact>
                                                                    <Table.Body>
                                                                        <Table.Row>
                                                                            {_.map(thresholds, (threshold, name) => {
                                                                                if (!_.isFinite(threshold)) {
                                                                                    return null;
                                                                                }
                                                                                return (
                                                                                    <Table.Cell
                                                                                        key={name}
                                                                                        textAlign="center"
                                                                                        style={{ color: colorMappingThreshold[name] }}
                                                                                    >
                                                                                        <b>{i18n._(name)}</b> : {`${threshold * factor} ${new_unit}`}
                                                                                    </Table.Cell>
                                                                                );
                                                                            })}
                                                                        </Table.Row>
                                                                    </Table.Body>
                                                                </Table>
                                                            )}
                                                        </Popup.Content>
                                                    </Popup>
                                                )}
                                                {<GenerateCsv series={ref_export} unit={config.yUnit} filename={i18n._("reference")} />}
                                                {_.size(series?.comp_series) > 0 && (
                                                    <GenerateCsv series={comp_export} unit={config.yUnit} filename={i18n._("comparison")} />
                                                )}
                                            </Button.Group>
                                        </div>
                                    )
                                }
                            </Media>
                            <PlotType
                                dontCheckIfEmpty
                                height={containerHeight - LEGENDHEIGHTHORIZONTAL}
                                {...plotWidth}
                                margin={plotMargin}
                                yDomain={
                                    _.size(merge_repartition) > 0
                                        ? [Math.min(0, _.head(merge_repartition) * 1.2), Math.max(10, _.last(merge_repartition) * 1.2)]
                                        : null
                                }
                                xDomain={lastDrawLocation ? lastDrawLocation : null}
                                xType={props.sparklineMode ? "time" : undefined}
                                onMouseLeave={
                                    tooltipMode === "crossHair"
                                        ? () => {
                                              setCrosshairValues([]);
                                          }
                                        : null
                                }
                            >
                                <XAxis
                                    title={aggregation === "month" ? i18n._(t`month`) : aggregation === "week" ? i18n._(t`weeks`) : i18n._(t`time`)}
                                    tickLabelAngle={-20}
                                    tickValues={props.sparklineMode ? undefined : tickValues}
                                    tickFormat={(value) => {
                                        if (!props.sparklineMode && comp_time !== null) {
                                            switch (aggregation) {
                                                case 1:
                                                    return `${i18n._(t`day`)}+${value}`;
                                                case 2:
                                                    return `${i18n._(t`week`)}+${value}`;
                                                case 3:
                                                    return `${i18n._(t`month`)}+${value}`;
                                                default:
                                                    return value;
                                            }
                                        } else {
                                            const date = moment(props.sparklineMode ? value : config.xFormatTmst[value]);
                                            switch (aggregation) {
                                                case 1: {
                                                    const format = multiI18nFormat(date, current_lng); //aggregation 'day' case
                                                    return format;
                                                }
                                                case 2: {
                                                    return `${i18n._(t`week number`)}: ${date.locale(current_lng).format("WW")}`;
                                                }
                                                case 3: {
                                                    return `${date.locale(current_lng).format("MMMM YYYY")}`;
                                                }
                                                default:
                                                    return value;
                                            }
                                        }
                                    }}
                                />
                                <HorizontalGridLines />
                                <VerticalGridLines />
                                {props.sparklineMode && (
                                    <Highlight
                                        enableY={false}
                                        onBrushStart={(area) => {
                                            if (area) setBrushing(true);
                                        }}
                                        onBrushEnd={(area) => {
                                            if (area) {
                                                setBrushing(false);
                                                setLastDrawLocation([_.get(area, "left"), _.get(area, "right")]);
                                            }
                                        }}
                                    />
                                )}
                                {_.chain(series)
                                    .map((serie, key) => {
                                        return _.chain(serie)
                                            .filter((item) => _.size(item.data) > 0)
                                            .filter((item) => item.disabled === false)
                                            .orderBy((item) => item.data.length, "desc")
                                            .map((serie, idx) => {
                                                const transformData = _.reduce(
                                                    serie.data,
                                                    (res, item, idx) => {
                                                        //Global repartition is proccessed in processing.js
                                                        //Here we override this repartition process
                                                        let total_stack = null;
                                                        if (key === "ref_series") {
                                                            total_stack = ref_repartition?.[item.x] ?? null;
                                                        } else if (key === "comp_series") {
                                                            total_stack = comp_repartition?.[item.x] ?? null;
                                                        }
                                                        const repartition = _.defaultTo(((item.y * factor) / Math.abs(total_stack)) * 100, null);

                                                        //Force 0 for y data like 0.00XXXX
                                                        const y_val = _.isFinite(item.y)
                                                            ? Math.round((item.y * factor + Number.EPSILON) * 100) / 100
                                                            : item.y;
                                                        if (props.sparklineMode && key === "comp_series") {
                                                            // in Sparkline mode put comp_data series into timelapse of ref_series needed to use 'xType': 'time'
                                                            const override_x = _.chain(series)
                                                                .get("ref_series", [])
                                                                .find({ name: serie.name })
                                                                .get("data", [])
                                                                .find({ x: item.x })
                                                                .get("t", null)
                                                                .value(); //retrieve data index of reference serie to get timestamp
                                                            if (override_x) {
                                                                res.push({ ...item, y: y_val, y_real: y_val, repartition, x: override_x });
                                                                return res;
                                                            } else {
                                                                //if no ref_series so we display compare serie timelapse
                                                                if (_.chain(series).get("ref_series", []).isEmpty().value()) {
                                                                    res.push({
                                                                        ...item,
                                                                        y: y_val,
                                                                        y_real: y_val,
                                                                        repartition,
                                                                        x: props.sparklineMode ? item.t : item.x
                                                                    });
                                                                }
                                                                //exclude comparison points that do not have an equivalent reference point
                                                                return res;
                                                            }
                                                        }
                                                        res.push({
                                                            ...item,
                                                            y: y_val,
                                                            y_real: y_val,
                                                            repartition,
                                                            x: props.sparklineMode ? item.t : item.x
                                                        });
                                                        return res;
                                                    },
                                                    []
                                                );

                                                const data = props.sparklineMode ? transformData : signedStacks[key](transformData);

                                                return (
                                                    <PlotSerie
                                                        cluster={!props.sparklineMode ? key : null}
                                                        size={props.sparklineMode ? 2 : null}
                                                        opacity={props.sparklineMode ? 0.6 : null}
                                                        markStyle={
                                                            props.sparklineMode
                                                                ? brushing
                                                                    ? { pointerEvents: "none" }
                                                                    : { pointerEvents: "auto" }
                                                                : null
                                                        }
                                                        key={idx}
                                                        data={data}
                                                        color={serie.color}
                                                        fill={serie.color}
                                                        getNull={(d) => d.y !== null}
                                                        onNearestX={tooltipMode === "crossHair" && idx === 0 ? nearestXHandler : null}
                                                        onValueMouseOver={
                                                            tooltipMode === "hint"
                                                                ? (hintValue) => {
                                                                      setHintValue(hintValue);
                                                                  }
                                                                : null
                                                        }
                                                        onValueMouseOut={
                                                            tooltipMode === "hint"
                                                                ? () => {
                                                                      setHintValue(null);
                                                                  }
                                                                : null
                                                        }
                                                    />
                                                );
                                            })
                                            .value();
                                    })
                                    .value()}
                                {_.map(thresholds, (threshold, name) => {
                                    if (!_.isFinite(threshold)) return null;
                                    return (
                                        <LineSeries
                                            key={name}
                                            color={colorMappingThreshold[name]}
                                            opacity={0.8}
                                            strokeDasharray={"7,4"}
                                            data={[
                                                { x: _.head(tickValues), y: threshold * factor },
                                                { x: _.last(tickValues), y: threshold * factor }
                                            ]}
                                        />
                                    );
                                })}
                                {tooltipMode === "hint" && hintValue ? tooltip() : null}
                                {tooltipMode === "crossHair" && _.size(crosshairValues) > 0 && (
                                    <Crosshair values={crosshairValues} className={"pwaAnalysisCrossHair"} style={{ zIndex: 10 }}>
                                        <CustomCardTooltip
                                            values={crosshairValues}
                                            unit={new_unit}
                                            current_lng={current_lng}
                                            aggregation={aggregation}
                                            sparklineMode={props.sparklineMode}
                                            showRepartition={props.showRepartition}
                                            config={config}
                                        />
                                    </Crosshair>
                                )}
                                <YAxis
                                    title={new_unit}
                                    tickFormat={(value, index, scale, tickTotal) => {
                                        const format = scale.tickFormat(tickTotal)(value);
                                        if (typeof value === "number") {
                                            return i18n.number(value, { minimumFractionDigits: 1, maximumFractionDigits: 2, notation: "compact" });
                                        }
                                        return format;
                                    }}
                                />
                            </PlotType>
                            <div className="no-print">
                                <DiscreteColorLegend
                                    orientation={"horizontal"}
                                    style={{
                                        overflowY: "hidden",
                                        position: "absolute",
                                        bottom: 0,
                                        width: "99%",
                                        height: `${LEGENDHEIGHTHORIZONTAL}px`
                                    }}
                                    items={_.chain(series)
                                        .reduce((res, serie_type) => {
                                            res.push(...serie_type);
                                            return res;
                                        }, [])
                                        .uniqBy("title")
                                        .value()}
                                    onItemClick={clickHandler}
                                />
                            </div>
                        </Segment>
                    </div>
                </>
            )}
        </>
    );
};

GraphicExtensive.defaultProps = {
    PlotType: FlexibleXYPlot,
    plotMargin: { left: 60, right: 10 },
    height: 500,
    tooltipMode: "crossHair", //value can be 'crossHair' or 'hint'
    displayPopupInfo: false,
    sparklineMode: false,
    showRepartition: true,
    autoUnit: true
};

export default React.memo(GraphicExtensive);
